import React from 'react'
import ApplicationForm from "./ApplicationForm"

const ClubApplication = () => {
  return (
    <>
      <div className="row mx-0 club-application">
        <div className="col-12">
          <div className="form-header">
            <h1 className='text-center'>Join The Datacode Student Learning Club</h1>
            <h6></h6>
          </div>
          <div className="form-container">
            <ApplicationForm />
          </div>
        </div>
      </div>
    </>
  )
}

export default ClubApplication