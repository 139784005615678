import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { addSpeaker } from "../../actions";

import SpeakerRegistration from "./SpeakerRegistration";

const mapStateToProps = ({ user }) => ({
  addSpeakerLoading: user.addSpeakerLoading,
});

const mapDispatchToProps = { addSpeaker };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SpeakerRegistration)
);
