import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import LayoutContainer from "../../LayoutContainer";
import DeleteLessonModal from "./DeleteLessonModal";
import CustomLoader from "../../../../../../../shared_components/CustomLoader";
import { getSort } from "../../../../../../../utils";

const LessonsList = ({
  lessonsList,
  problemsListLoading,
  currentUser,
  getLessons,
  removeLesson,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [tempDeleteId, setTempDeleteId] = useState();
  const [lessonStatus, setProblemStatus] = useState("public");
  const history = useHistory();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    getLessons({ status: lessonStatus });
  }, [lessonStatus]);

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const handleRemoveLesson = () => {
    removeLesson(tempDeleteId).then((res) => {
      if (res) {
        setOpenModal(!openModal);
        console.log(res, "res");
      }
    });
  };

  const deleteLesson = (id) => {
    setTempDeleteId(id);
    setOpenModal(!openModal);
  };

  return (
    <>
      <LayoutContainer>
        <div className='row mx-0 solution-nav'>
          <div className='col-md-8 col-6 mx-0 p-2 d-flex align-items-center'>
            <h4 className='py-md-3 py-2 mb-0'>
              <i className='fal fa-file-alt mr-2' />
              Lessons List
            </h4>
          </div>
          <div className='col-md-4 col-6 d-flex justify-content-end align-items-center'>
            <button
              onClick={() => history.push("/admin/dayscode/lesson/new")}
              type='button'
              className='btn enroll-small-btn'
            >
              <small>
                <i className='fal fa-plus-circle mr-2' />
                Creat New Lesson
              </small>
            </button>
          </div>
        </div>
        {problemsListLoading ? (
          <div className='row mx-0 d-flex justify-items                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            -center'>
            <div className='col-12 align-items-center text-center '>
              <CustomLoader />
            </div>
          </div>
        ) : (
          <>
            <div className='row m-0'>
              <div className='col-12'>
                <div className='event-dashboard-header w-100 px-0 table-responsive table'>
                  <ul>
                    <li>
                      <span
                        onClick={() => setProblemStatus("public")}
                        className={lessonStatus === "public" ? "active" : ""}
                      >
                        Public
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={() => setProblemStatus("draft")}
                        className={lessonStatus === "draft" ? "active" : ""}
                      >
                        Draft
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='px-md-4 px-2'>
              <div className='row m-0 py-2'>
                {lessonsList &&
                  getSort(lessonsList, "day").map((item, i) => (
                    <div className='col-md-4 col-12  p-2'>
                      <div
                        key={i}
                        className='rounded-lg border d-flex justify-content-between card-schadow p-2'
                      >
                        <div className=''>
                          <b>
                            Day:{" "}
                            {item.days &&
                              item.days.map((e, i) => (
                                <span key={i}>{e},</span>
                              ))}
                          </b>
                          <p>{item.title}</p>
                          <span>
                            <i className='fal fa-user-check mr-2 green' />
                            {item.completedBy && item.completedBy.length}
                          </span>
                        </div>
                        <div>
                          <Link to={`/admin/dayscode/lesson/${item.id}`}>
                            <i className='far fa-edit mx-3' />
                          </Link>
                          <i
                            className='far fa-trash red'
                            onClick={() => deleteLesson(item.id)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <DeleteLessonModal
              open={openModal}
              toggle={toggleModal}
              onSubmit={handleRemoveLesson}
              submitButtonName={"Delete Lesson"}
            />
          </>
        )}
      </LayoutContainer>
    </>
  );
};

export default LessonsList;
