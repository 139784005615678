import { faHome, faTasks } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import RightSideSection from "../../../right_section/RightSideSection";
import Technologies from "../../../right_section/Technologies";
import PostCard from "../../PostCard";
import MCQCardCarousel from "./MCQCardCarousel";

class IndexMcqQuestions extends Component {
  state = {};
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const description =
      "Time to choose the correct option for the correct future,better way to improve our imperfections.";
    const title = "Programming MCQs Practice Questions | Datacode.in";
    return (
      <>
        <Helmet>
          <title>{title}</title>
          <meta
            data-react-helmet='true'
            name='description'
            content={description}
          ></meta>
          <meta
            data-react-helmet='true'
            http-equiv='cleartype'
            content='on'
          ></meta>
          <meta
            data-react-helmet='true'
            name='apple-mobile-web-app-capable'
            content='yes'
          ></meta>
          <meta
            data-react-helmet='true'
            name='robots'
            content='max-snippet:-1, max-image-preview:large, max-video-preview:-1'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:locale'
            content='en_US'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:type'
            content='website'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:title'
            content={title}
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:description'
            content={description}
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:url'
            content='https://www.datacode.in/'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:site_name'
            content='Datacode.in'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:image'
            content='https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:image:secure_url'
            content='https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:image:width'
            content='400'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:image:height'
            content='400'
          ></meta>
          <meta
            data-react-helmet='true'
            name='keywords'
            content='programming,coding,online, code online, online judge, practice, run code, test, expected outcome, programming competition, programming contest, online programming, online computer programming'
          ></meta>
          <meta
            data-react-helmet='true'
            name='twitter:card'
            content='summary_large_image'
          ></meta>
          <meta
            data-react-helmet='true'
            name='twitter:description'
            content={description}
          ></meta>
          <meta
            data-react-helmet='true'
            name='twitter:title'
            content={title}
          ></meta>
          <meta
            data-react-helmet='true'
            name='twitter:site'
            content='@datacode_in'
          ></meta>
          <meta
            data-react-helmet='true'
            name='twitter:creator'
            content='@datacode_in'
          ></meta>
          <meta
            data-react-helmet='true'
            name='twitter:image'
            content='https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png'
          ></meta>
          <link
            data-react-helmet='true'
            rel='shortlink'
            href='https://www.datacodoe.in/'
          ></link>
          <link
            data-react-helmet='true'
            rel='dns-prefetch'
            href='//www.datacode.in'
          ></link>
          <link
            data-react-helmet='true'
            rel='dns-prefetch'
            href='//s.w.org'
          ></link>
          <link
            data-react-helmet='true'
            rel='alternate'
            type='application/rss+xml'
            title='Datacode'
            href='https://www.datacode.in/'
          ></link>
          <link
            data-react-helmet='true'
            rel='canonical'
            href='https://www.datacode.in/'
          ></link>
        </Helmet>
        <div className='row m-0 px-3'>
          <div className='col-1'></div>
          <div className='col-md-8 mt-5'>
            <h1
              className='text-center mb-4 rounded p-2'
              style={{ backgroundColor: "#30006b", color: "#ffffff" }}
            >
              <FontAwesomeIcon icon={faTasks} color='#ffffff' size='1x' />{" "}
              Technical MCQs Practice Question
            </h1>
            <h5 className='text-center'>
              Time to choose the correct option for the correct future,better
              way to improve our imperfections.
            </h5>
            <Link
              to='/home'
              className='badge mr-1 my-4'
              style={{ backgroundColor: "#06CB6C" }}
            >
              <FontAwesomeIcon icon={faHome} size='2x' color='#072C71' />
            </Link>

            <div className='card-deck m-2'>
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731157858/k0u4vhphhxvpvaigp4i4.jpg'
                title='Python MCQs Questions'
                paragraph=' Python questions and answers focuses on all areas of Python subject covering most topics in Python. These topics are chosen from a collection of most authoritative and best reference books on Python. '
                link='/index_python_mcqs'
              />
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731157885/wcjvtr3uf4exwzaxgnxj.jpg'
                title='C Programming MCQs Questions'
                paragraph='C questions and answers focuses on all areas of C subject covering most topics in C. These topics are chosen from a collection of most authoritative and best reference books on C. '
                link='/index_c_mcqs'
              />
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731157910/eadmvstw2ango8pnwsiv.jpg'
                title='C++ MCQs Questions'
                paragraph='C++ questions and answers focuses on all areas of C++ subject covering most topics in C++. These topics are chosen from a collection of most authoritative and best reference books on C++. '
                link='/index_cpp_mcqs'
              />
            </div>
            <div className='card-deck m-2'>
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731157975/kr8yum1motjudoewtavh.jpg'
                title='Java MCQs Questions'
                paragraph='Java questions and answers focuses on all areas of Java subject covering most topics in Java. These topics are chosen from a collection of most authoritative and best reference books on Java. '
                link='/index_java_mcqs'
              />
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731158004/bkb6y9mvjw0njxwdhxf8.jpg'
                title='JavaScript MCQs Questions'
                paragraph='JavaScript questions and answers focuses on all areas of JavaScript subject covering most topics in JavaScript. These topics are chosen from a collection of most authoritative and best reference books on JavaScript. '
                link='/index_js_mcqs'
              />
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731158036/omgnyhwpygdzywzewtlv.jpg'
                title='DBMS MCQs Questions'
                paragraph='DBMS questions and answers focuses on all areas of DBMS subject covering most topics in DBMS. These topics are chosen from a collection of most authoritative and best reference books on DBMS.'
                link='/index_dbms_mcqs'
              />
            </div>
            <div className='card-deck m-2'>
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731161367/barujpunipesehx7i0of.jpg'
                title='HTML MCQs Questions'
                paragraph='HTML questions and answers focuses on all areas of HTML subject covering most topics in HTML. These topics are chosen from a collection of most authoritative and best reference books on HTML.'
                link='/index_html_mcqs'
              />
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731161420/xc3vrpixqbtb3mnhti0s.jpg'
                title='CSS MCQs Questions'
                paragraph='CSS questions and answers focuses on all areas of CSS subject covering most topics in CSS. These topics are chosen from a collection of most authoritative and best reference books on CSS.'
                link='/index_css_mcqs'
              />
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731161445/i3ow0r4qpmndkrwhacgh.jpg'
                title='BootStrap MCQs Questions'
                paragraph='Bootstrap questions and answers focuses on all areas of Bootstrap subject covering most topics in Bootstrap. These topics are chosen from a collection of most authoritative and best reference books on Bootstrap.'
                link='/index_bootstrap_mcqs'
              />
            </div>
            <div className='card-deck m-2'>
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731161471/vqzda0mqpgght5trtznl.jpg'
                title='React MCQs Questions'
                paragraph='React questions and answers focuses on all areas of React subject covering most topics in React. These topics are chosen from a collection of most authoritative and best reference books on React.'
                link='/index_react_mcqs'
              />
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731168857/ogtgs5fg21l3t0bwmzvg.jpg'
                title='Django MCQs Questions'
                paragraph='Django questions and answers focuses on all areas of Django subject covering most topics in DJango. These topics are chosen from a collection of most authoritative and best reference books on Django.'
                link='/index_django_mcqs'
              />
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731168889/hkludlrszla9luh2bpsn.jpg'
                title='Git MCQs Questions'
                paragraph='Git questions and answers focuses on all areas of GIt covering most topics. These topics are chosen from a collection of most authoritative and best reference on Git.'
                link='/index_git_mcqs'
              />
            </div>
            <div className='card-deck m-2'>
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731168913/lmfsg8hntqiupp6tseb3.jpg'
                title='Android MCQs Questions'
                paragraph='Android questions and answers focuses on all areas of Android subject covering most topics in Android. These topics are chosen from a collection of most authoritative and best reference books on Android.'
                link='/index_android_mcqs'
              />
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731168950/cxpxowzru4f0gniti5pb.jpg'
                title='SQL MCQs Questions'
                paragraph='SQL questions and answers focuses on all areas of SQL subject covering most topics in SQL. These topics are chosen from a collection of most authoritative and best reference books on SQL.'
                link='/index_sql_mcqs'
              />
              <PostCard
                imgsource='https://res.cloudinary.com/datacode/image/upload/v1731168970/m8j8ha7avbfjj7rm621w.jpg'
                title='DS MCQs Questions'
                paragraph='DS questions and answers focuses on all areas of DS subject covering most topics in DS. These topics are chosen from a collection of most authoritative and best reference books on DS.'
                link='/index_ds_mcqs'
              />
            </div>

            <div className='card-deck m-2'>
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731169003/sqox9cmnjpexsj6atsgi.jpg'
                title='OOPs MCQs Questions'
                paragraph='OOPS questions and answers focuses on all areas of OOPS subject covering most topics in OOPS. These topics are chosen from a collection of most authoritative and best reference books on OOPS.'
                link='/index_oops_mcqs'
              />
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731169030/fsrwjbsj3r5iwcqhnfat.jpg'
                title='Angular MCQs Coming Soon'
                paragraph='Angular questions and answers focuses on all areas of Angular subject covering most topics in Angular. These topics are chosen from a collection of most authoritative and best reference books on Angular.'
                link='/index_angular_mcqs'
              />
            </div>
            <h4 className='text-secondary mt-3'>For More MCQs Practice</h4>
            <MCQCardCarousel />
          </div>
          <div className='col-md-3'>
            <RightSideSection />
            <Technologies />
          </div>
        </div>
      </>
    );
  }
}

export default IndexMcqQuestions;
