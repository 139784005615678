import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";

import { RenderInputField } from "../../../../../../../shared_components/ReduxFormFields";
import { required } from "../../../../../../../utils/validators";
// import TextEditor from '../../../../aticles/BlogsEditor/TextEditor'

const AddTestCases = ({
  addProblemTestCase,
  testCase,
  deleteProblemTestCase,
  id,
  reset,
  handleSubmit,
  submitting,
  editProblemTestCase,
  initialize,
}) => {
  const [isEdit, setEdit] = useState(false);

  // const [outputText, getoutputText] = useState('<p> </p>')
  // const [inputText, getinputText] = useState('<p> </p>')

  const onSubmit = (values) => {
    const testcase = { ...values };
    if (isEdit) {
      editProblemTestCase(id, testcase);
    } else {
      testcase["testCaseId"] = Math.random().toString(36).substr(4, 9);
      addProblemTestCase(id, testcase);
    }
    reset("add-textcase");
  };

  // const handleOutputText = (text) => {
  //   getinputText(text)
  // }

  // const handleInputText = (text) => {
  //   getoutputText(text)
  // }

  const handleDeleteProblemTestCase = (testCaseId) => {
    deleteProblemTestCase(id, testCaseId);
  };

  const handleEditProblemTestCase = (testCase) => {
    setEdit(true);
    initialize(testCase);
  };

  return (
    <>
      <div className='row m-0'>
        <div className='col-12'>
          <form className='py-3 px-md-5 px-1' onSubmit={handleSubmit(onSubmit)}>
            <small>
              Lorazepam, sold under the brand name Ativan among others, is a
              benzodiazepine medication. It is used to treat anxiety disorders,
              trouble sleeping, severe agitation, active seizures including
              status epilepticus, alcohol withdrawal, and chemotherapy-induced
              nausea and vomiting
            </small>
            <div className='my-3'>
              {/* <label className="form-label text-left pt-5">Input {`(${inputText.length - 8})`}</label> */}
              <Field
                type='textarea'
                name='input'
                label='Input'
                placeholder=''
                component={RenderInputField}
                validate={[required]}
              />
              {/* <div className="mb-3 border ">
                <TextEditor handleTextEditor={handleOutputText} text={inputText} />
              </div> */}
            </div>
            <div className='my-3'>
              {/* <label className="form-label text-left pt-5">Output {`(${outputText.length - 8})`}</label> */}
              <Field
                type='textarea'
                name='output'
                label='Output'
                placeholder=''
                component={RenderInputField}
                validate={[required]}
              />
              {/* <div className="mb-3 border ">
                <TextEditor handleTextEditor={handleInputText} text={outputText} />
              </div> */}
            </div>
            <div className='row my-4'>
              <div className='col-12 text-right'>
                <button
                  type='submit'
                  className='btn custom-border-capsule-button'
                  disabled={submitting}
                >
                  <small>{isEdit ? "Edit Test Case" : "Add Test Case"}</small>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className='row m-0'>
        <div className='col-12'>
          {testCase &&
            testCase.map((testcase, i) => (
              <>
                <div className='row m-0 my-4 px-md-5 px-3' key={i}>
                  <div className='col-12 p-md-4 p-2 card-schadow rounded-lg'>
                    <div className='row m-0'>
                      <div className='col-6 d-flex text-left px-0'>
                        <p>Test Case {i + 1}</p>
                      </div>
                      <div className='col-6 text-right px-0'>
                        <div>
                          <i
                            className='far fa-edit mx-3'
                            onClick={() => handleEditProblemTestCase(testcase)}
                          />
                          <i
                            className='far fa-trash'
                            onClick={() =>
                              handleDeleteProblemTestCase(testcase.testCaseId)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <label className='form-label text-left pt-5'>Input:</label>
                    <div className=''>
                      <pre>{testcase.input}</pre>
                    </div>
                    <label className='form-label text-left pt-5'>Output:</label>
                    <div className=''>
                      <pre>{testcase.output}</pre>
                    </div>
                  </div>
                </div>
              </>
            ))}
        </div>
      </div>
    </>
  );
};

export default reduxForm({
  form: "add-textcase",
})(AddTestCases);
