import React, { useState } from "react";
import { Collapse } from "reactstrap";

import { sessionDetails } from "../../constantData";

const DaysCodeSessionTimeLine = () => {
  const [activeTable, setActiveTable] = useState("");

  const toggle = (openTable) => {
    if (openTable === activeTable) {
      return setActiveTable("");
    }
    setActiveTable(openTable);
  };

  return (
    <>
      <div className='row m-0 my-3'>
        <div className='col-12 px-1 text-center'>
          <h1>Session Timeline</h1>
          <h6>Events and Meeting Schedule</h6>
          <div className='timeline-dayscode-body'>
            <div className='timeline-dayscode bg-light'>
              {sessionDetails &&
                sessionDetails.map((item, i) => (
                  <div className='entry-days'>
                    <div className='title d-flex align-item-center'>
                      <h1 className='h1 m-md-0 mt-0 ml-md-4 ml-5 text-nowrap'>
                        <i className='fad fa-calendar-star mr-2 green' />
                        {item.date.substring(0, 2)}
                      </h1>
                      <span className='font-weight-light ml-2'>
                        {item.date.substring(3, 6)}
                      </span>
                    </div>
                    <div className='body bg-white check-mark-list border rounded-lg card-schadow '>
                      <div className='row mx-0 d-flex'>
                        <div className='col-8 mx-0 pl-0 pr-2'>
                          <h3>{item.session}</h3>
                          <h6>{item.speaker}</h6>
                          <div className='align-items-center'>
                            <small className='d-block'>
                              <i className='fal fa-user-chart pr-2' />
                              {item.type}
                            </small>
                            <span className='badge badge-primary mb-0 text-white rounded-lg'>
                              <i className='fal fa-clock pr-2' />
                              {item.time}
                            </span>
                          </div>
                        </div>
                        <div className='col-4 text-right px-0'>
                          <img
                            height='80px'
                            width='80px'
                            src={require(`../../../../../../assets/images/speakers/${
                              item.img ? item.img : "datacode.png"
                            }`)}
                            alt='avatar'
                            loading='lazy'
                            className='rounded-circle mr-2 text-center mx-auto img-fluid border'
                          />
                        </div>
                      </div>
                      <Collapse isOpen={activeTable === i}>
                        <div className='border-top'>
                          <ul>
                            {item.contentList.map((item, i) => (
                              <li key={i}>{item}</li>
                            ))}
                          </ul>
                        </div>
                      </Collapse>
                      <span
                        className='pointer text-primary d-flex align-items-center justify-content-end'
                        onClick={() => toggle(i)}
                      >
                        <small>
                          view {activeTable === i ? "less" : "more"}
                        </small>
                        {activeTable === i ? (
                          <i className='text-primary fa fa-angle-up pl-2' />
                        ) : (
                          <i className='text-primary fa fa-angle-down pl-2' />
                        )}
                      </span>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DaysCodeSessionTimeLine;
