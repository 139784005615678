import { setEventsHandlers } from "./setEvents";
import { getEventsHandlers } from "./getEvents";
import { createReducer } from "../../../../core/reduxUtils";

const initialState = {
  eventRegisterLoading: false,
  eventDetailsLoading: false,
  eventDetails: {},
  eventsList: [],
  upcomingEventsList: [],
  pastEventsList: [],
  liveEventsList: [],
  searchEventsList: [],
  searchQueryObject: {},
  showSearchResultPage: false,
  upcomingEventsListLoading: false,
  pastEventsListLoading: false,
  liveEventsListLoading: false,
  searchEventsListLoading: false,
  eventsListLoading: false,
};

const handlers = {
  ...setEventsHandlers,
  ...getEventsHandlers,
};

const eventReducer = createReducer(initialState, handlers);

export default eventReducer;
