import React from 'react'
import WorkshopCard from "./WorkshopCard"

const ExploreTrainingBootcampSection = () => {
  const data = [
    {
      img: "http://res.cloudinary.com/datacode/image/upload/v1691170388/natghz9cxos2uyp4osxf.png",
      days: '3x Days',
      title: 'Full Stack Web Development Workshop',
      category: 'Programming',
      speaker: '',
      students: '100',
      rating: '3',
    },
    {
      img: "http://res.cloudinary.com/datacode/image/upload/v1691170388/natghz9cxos2uyp4osxf.png",
      days: '10x Days',
      title: 'Full Stack Web Development Workshop',
      category: 'Web Development',
      speaker: '',
      students: '140',
      rating: '5',
    },
    {
      img: "http://res.cloudinary.com/datacode/image/upload/v1691170388/natghz9cxos2uyp4osxf.png",
      days: '4x Days',
      title: 'Full Stack Web Development Workshop',
      category: 'Design',
      speaker: '',
      students: '500',
      rating: '5',
    },
  ]

  return (
    <>
      <div className="row mx-0 d-flex justify-content-center">
        <div className="col-12 col-md-10">
          <h1>Explore Trainings and Bootcamps</h1>
          <div className="row mx-0">
            {data && data.map((item, i) => (
              <div className="col-4 px-0 border rounded-lg" key={i}>
                <WorkshopCard item={item} />
              </div>
            ))}
          </div>
          <div className="text-center">
            <div className="btn btn-primary">Explore All</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ExploreTrainingBootcampSection