import { FaRegClock } from "react-icons/fa6";
import { FaCalendar } from "react-icons/fa";
import { IoMdVideocam } from "react-icons/io";
import React from "react";

const ConfirmWindow = () => {
  return (
    <>
      <div className='row mx-0 p-5'>
        <div className='col-12 px-0 d-flex justify-content-center align-items-center'>
          <div className=''>
            <h6 className='text-center'>Confirmed</h6>
            <p className='text-center'>You are scheduled with Datacode.</p>
            <div className='p-5 border rounded'>
              <div className='time-date-text'>
                <FaRegClock className='pr-2' />
                <span className='p-0'>
                  Datacode Volunteer Introduction Call
                </span>
              </div>
              <div className='time-date-text'>
                <FaCalendar className='pr-2' />
                <span className='p-0'>
                  2:00pm - 2:15pm, Wednesday, August 30, 2023
                </span>
              </div>
              <div className='time-date-text'>
                <FaRegClock className='pr-2' />
                <span className='p-0'>India Standard Time</span>
              </div>
              <div className='time-date-text'>
                <IoMdVideocam className='pr-2' />
                <span className='p-0'>Web conferencing details to follow.</span>
              </div>
            </div>
            <p className='font-weight-bold mt-4'>
              A calendar invitation has been sent to your email address.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmWindow;
