import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Progress } from "reactstrap";

import moment from "moment";
import { Link } from "react-router-dom";
import { Collapse } from "reactstrap";
import { DaysFAQ } from "./DaysCode/helpers";
import { techCompanies } from "../../../../utils";
import UserCertificatePDF from "./DaysCodeDashboard/DownloadCertificate/UserCertificatePDF";

export const UserSubmissionWarning = ({ day, problemId }) => {
  return (
    <>
      <div className='row m-md-5 m-2 info-card card-schadow rounded-lg m-0'>
        <div className='col-12 p-3 d-flex'>
          <i className='fal fa-alarm-exclamation h1 pr-3' />
          <div>
            <h1 className='h5'>Please submit your solution for day {day}</h1>
            <small>
              You will be able to see your problem status over here once you
              submit your solution for this problem.
            </small>
            <Link
              to={{
                pathname: "/days_code_problems",
                state: {
                  problemId: problemId,
                  day: day,
                  contentType: "assigment",
                },
              }}
            >
              <p className='my-2 '>
                Solve Problem <i className='fa fa-arrow-right' />
              </p>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export const UpcomingProblemDisclamer = ({ day }) => {
  return (
    <>
      <div className='row m-md-5 m-2 card-schadow rounded-lg m-0'>
        <div className='col-12 p-3 d-flex'>
          <i className='fal fa-lock h1 pr-3' />
          <div>
            <h1 className='h5'>
              Please wait for the problem to get unlocked for the day {day}
            </h1>
            <small>
              You will be able to see this problem once it gets unlocked. Wait
              for it till then and solve previous problems and continue your
              learning.
            </small>
          </div>
        </div>
      </div>
    </>
  );
};

export const ReviewSubmissionDisclamer = ({ handleSolutionNav }) => {
  return (
    <>
      <div className='row mentor-review-card card-schadow rounded-lg m-0'>
        <div className='col-12 p-3 '>
          <div className='d-flex'>
            <i className='fal fa-poll pr-4' />
            <div className=''>
              <div>
                <h1>
                  Your code is successfully submitted and sent for the review
                </h1>
                <small>
                  You completed your task for the day. Wait for some time, your
                  solution is in touch with our mentors, You will soon get to
                  know it is appropriate or not. Till then Stick to your goal
                  and keep learning:)
                </small>
              </div>
              <div
                className='btn mb-md-0 mb-5 btn-warning mt-2'
                onClick={() => handleSolutionNav("practice")}
              >
                <small>Practice More Problems</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const RejectSubmissionDisclamer = () => {
  return (
    <>
      <div className='row orange-card mentor-review-card card-schadow rounded-lg m-0'>
        <div className='col-12 p-3 d-flex'>
          <i className='fal fa-poll pr-4 orange' />
          <div>
            <h1>Oops!! your code got poor mentor review</h1>
            <small>
              Hey, don’t be sad, this is not the end of the world. You can still
              figure out the appropriate solution to this problem by viewing
              community and mentor solutions and you can contact one of your
              peers or the mentors to understand this problem personally.{" "}
            </small>
          </div>
        </div>
      </div>
    </>
  );
};

export const ApprovedSubmissionDisclamer = () => {
  return (
    <>
      <div className='row voilet-card mentor-review-card rounded-lg card-schadow m-0'>
        <div className='col-12 p-3 d-flex'>
          <i className='fal fa-comment-alt-smile pr-4 voilet' />
          <div>
            <h1>Congratulations!!!, your code got good mentor review</h1>
            <small>
              It’s party time, your code was reviewed by our mentors, and it
              successfully passed all the test cases and constraints,. Now you
              can enjoy your cup of coffee and solve other problems.{" "}
            </small>
          </div>
        </div>
      </div>
    </>
  );
};

export const NotificationCard = ({
  notification,
  admin,
  handleNotificationDelete,
}) => {
  return (
    <>
      {/* <h1 className="dashboard-heading p-3 m-0 text-white rounded-top" style={{ backgroundColor: '#7968ce' }}>
        <i className="fal fa-bell pr-2" /> Notifications
      </h1> */}
      <div className='px-3 notification-section'>
        {notification && notification.length ? (
          notification.map((item, i) => (
            <div className='row m-0 my-3 ' key={i}>
              <div className='col-12 border rounded p-2  '>
                <div className='d-flex'>
                  <i className='pt-1 fal fa-comment-alt-exclamation' />
                  <small className='px-3 text-justify'>{item.message}</small>
                  {admin && (
                    <i
                      className='far fa-trash-alt'
                      onClick={() =>
                        handleNotificationDelete(item.notificationId)
                      }
                    />
                  )}
                </div>
                <div className='d-flex justify-content-end'>
                  <small className='font-weight-bold text-right'>
                    - {moment(item.date).format("LLL")}
                  </small>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className='row m-0'>
            <div className='col-12 text-center p-5'>
              <img
                height='150'
                width='150'
                src={require(`../../../../assets/images/svg/bell.png`)}
                alt='laoding'
                className='img-fluid'
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export const BlankProblemCard = () => {
  return (
    <>
      <div className='row m-0 d-flex justify-content-center align-item-center'>
        <div className='col-5 p-5 d-md-block d-none'>
          <img
            src={require("../../../../assets/images/svg/all-the-best.svg")}
            alt='avtar'
            className='img-fluid'
          />
          <div className='px-0 py-3 qoute text-center'>
            <span className='quote-icon'>
              <i className='fas fa-quote-left' />
            </span>
            <h6>
              There are two ways to write error-free programs; only the third
              works
            </h6>
            <i>- Alan Perlis</i>
          </div>
          <small></small>
        </div>
      </div>
    </>
  );
};

export const DaysCodeFAQ = () => {
  const [activeTable, setActiveTable] = useState("");

  const toggle = (openTable) => {
    if (openTable === activeTable) {
      return setActiveTable("");
    }
    setActiveTable(openTable);
  };

  return (
    <>
      <div className='row mx-0 my-5'>
        <div className='col-12 px-0 text-center'>
          <h1 className='my-4'>Frequently Asked Questions</h1>
        </div>
        <div className='col-12 px-0'>
          {DaysFAQ &&
            DaysFAQ.map((item, i) => (
              <div key={i}>
                <div
                  onClick={() => toggle(i)}
                  className={`m-0 my-4 rounded-lg border bg-light d-flex align-items-center carousel-row justify-content-between`}
                >
                  <h6 className='h5 px-3'>{item.question}</h6>
                  <div className=''>
                    {activeTable === i ? (
                      <i className='fal fa-angle-up text-dark p-3' />
                    ) : (
                      <i className='fal fa-angle-down text-dark p-3' />
                    )}
                  </div>
                </div>
                <Collapse isOpen={activeTable === i}>
                  <div className='row mx-0'>
                    <div className='col-12'>
                      <p>{item.answer}</p>
                    </div>
                  </div>
                </Collapse>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export const CompaniesLogo = ({ companies }) => {
  const tech = techCompanies;
  return (
    <>
      <div className=''>
        {companies.map((item, i) => (
          <img
            src={require(`../../../../assets/images/companies/${
              tech[item.value].img
            }`)}
            alt='avtar'
            width='80'
            height='10'
            className='img-fluid mx-2'
          />
        ))}
      </div>
    </>
  );
};

export const TakeBreak = ({ message }) => {
  return (
    <>
      <div className='row'>
        <div className='col-12 text-center'>
          <h6>{message}</h6>
          <img
            src={require("../../../../assets/images/svg/take-break.svg")}
            alt='break'
            width='300'
            height='300'
            className='img-fluid mx-auto my-auto'
          />
        </div>
      </div>
    </>
  );
};

export const ProgramStepsCard = () => {
  return (
    <>
      <div className='row mx-0 p-md-5 p-0'>
        <div className='col-12 p-md-5 p-2 program-steps-container'>
          <ul className='program-steps-progressbar'>
            <li>
              <i className='fa fa-laptop-code green' />
              <p>Start Daily Learning</p>
            </li>
            <li>
              <i className='fa fa-file-alt blue' />
              <p>Complete DSA Lessons</p>
            </li>
            <li>
              <i className='fa fa-chalkboard-teacher orange' />
              <p>Mentor Learning Session</p>
            </li>
            <li>
              <i className='fa fa-list red' />
              <p>Take Quiz Challenges</p>
            </li>
            <li>
              <i className='fa fa-file-code voilet' />
              <p>Submit Problem Assigments</p>
            </li>
            <li>
              <i className='fa fa-users indigo' />
              <p>Problem Solving Session</p>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export const CertificateProgressCard = () => {
  const totalProgress = useSelector((state) => state?.dayscode?.totalProgress);

  return (
    <>
      <div className='row mx-0'>
        <div className='col-12 p-5'>
          <h1>Certificate Progress</h1>
          <div className='row m-0 d-felx justify-content-center align-items-center main p-md-5'>
            <div className='col-md-2 col-12 text-md-right text-left d-flex left'>
              <h1 className='px-2 mb-0 bg-blue'>
                {parseFloat(totalProgress).toFixed(2)}%{" "}
              </h1>
            </div>
            <div className='col-md-8 col-12 align-items-center certificate-progress left'>
              <Progress
                color='success'
                value={parseFloat(totalProgress).toFixed(2)}
                max={100}
              >
                Keep Learning
              </Progress>
            </div>
            <div className='col-md-2 col-12 text-center right'>
              <img
                src='https://img.icons8.com/color/96/000000/diploma.png'
                alt='img'
              />
            </div>
          </div>
          <span className='text-center'>
            You are creating your certificate by doing daily task and code
            assignments, Once you completed your progress 100%. You will be able
            to download your certificate.
          </span>
          <div className='opacity-25 p-md-5 p-2' style={{ opacity: 0.25 }}>
            <UserCertificatePDF totalProgress={totalProgress} />
          </div>
        </div>
      </div>
    </>
  );
};
