import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import CustomLoader from "../../../../../../../../shared_components/CustomLoader";
import { BlocksFramPreviewDictionary } from "./ContentBlocksFrams/BlocksFramPreviewDictionry";

const LessonPreview = ({
  lessonId,
  handleLessionComplete,
  nextIncompleteLesson,
}) => {
  const dispatch = useDispatch();

  const { lessonDetailsLoading, lessonDetails } =
    useSelector((state) => state.dayscode) || {};

  const completedByuser = lessonDetails && lessonDetails.completedBy;
  const currentUser = JSON.parse(
    sessionStorage.getItem("currentUser") ||
      localStorage.getItem("currentUser"),
  );
  let courseUserId = currentUser?.courseUser;
  const isLessonCompleted = completedByuser?.includes(courseUserId);

  return (
    <>
      <div className='contsiner-fluid'>
        <div className='row mt-5 d-flex  lesson-preview-header'>
          <div className='col-12 col-md-12 p-0'>
            <h4 className='text-center'>{lessonDetails?.name}</h4>
          </div>
        </div>
        <div className=' lesson-craft'>
          <div className=' p-0'>
            {lessonDetailsLoading ? (
              <CustomLoader />
            ) : (
              <>
                {lessonDetails?.contents && (
                  <>
                    {lessonDetails.contents.map((item) => (
                      <div key={item._id} className='mt-5'>
                        {BlocksFramPreviewDictionary(item)}
                      </div>
                    ))}
                    {!isLessonCompleted ? (
                      <div
                        className='d-flex justify-content-center mx-3'
                        onClick={() => handleLessionComplete(lessonDetails._id)}
                      >
                        <Button color='success mt-3'>Mark as Completed</Button>
                      </div>
                    ) : (
                      <div
                        className='d-flex justify-content-center mx-3'
                        onClick={() => nextIncompleteLesson(lessonDetails._id)}
                      >
                        <Button color='primary mt-3 '>Lesson Completed</Button>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LessonPreview;
