import { message, Modal, Steps } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { reduxForm } from "redux-form";

import { useDispatch } from "react-redux";
import { createApplication } from "../../../actions/operations";
import ApplicationStep1 from "./ApplicationStepForms/ApplicationStep1";
import ApplicationStep2 from "./ApplicationStepForms/ApplicationStep2";
import ApplicationStep3 from "./ApplicationStepForms/ApplicationStep3";
import ApplicationStep4 from "./ApplicationStepForms/ApplicationStep4";
import ApplicationStep5 from "./ApplicationStepForms/ApplicationStep5";

const stepsTitle = [
  {
    key: 1,
    title: "Personal Information",
  },
  {
    key: 2,
    title: "Academic Details",
  },
  {
    key: 3,
    title: "Tell us More",
  },
  {
    key: 4,
    title: "Contribution",
  },
  {
    key: 5,
    title: "Finish",
  },
];

const ApplicationForm = ({
  reset,
  handleSubmit,
  submitting,
  initialize,
  nextPage,
  previousPage,
  pristine,
}) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const history = useHistory();
  const totalSteps = 5; // Total number of steps in the form

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    let savedApplication = JSON.parse(localStorage.getItem("club-application"));
    if (savedApplication) {
      initialize(savedApplication);
    }
  }, []);
  // Add more steps as needed

  const renderStep = () => {
    switch (page) {
      case 1:
        return <ApplicationStep1 />;
      case 2:
        return <ApplicationStep2 />;
      case 3:
        return <ApplicationStep3 />;
      case 4:
        return <ApplicationStep4 />;
      case 5:
        return <ApplicationStep5 />;
      default:
        return null;
    }
  };

  const handlePrevious = () => {
    setPage(page - 1);
  };

  const handleNext = (values) => {
    if (page < totalSteps) {
      setPage(page + 1);
    } else {
      handleSubmit(values)();
    }
  };

  const success = () => {
    Modal.success({
      content:
        "Thanks, Your Application is successfully submitted, Please wait for our response over your mail",
      onOk: () => {
        history.push("/club");
      },
    });
  };

  const infoModel = (application) => {
    Modal.error({
      content: `You already applied!!!, Wait your application is in ${application?.status} state`,
      onOk: () => {
        history.push("/club");
      },
    });
  };

  const onSubmit = (values) => {
    const application = { ...values };
    dispatch(createApplication(application)).then(async (res) => {
      if (res?.application?.success) {
        reset("club-application");
        localStorage.removeItem("club-application");
        success();
        message.success("Application Successfully Submitted");
      } else {
        infoModel(res?.application?.data);
      }
    });
  };

  const onSave = (values) => {
    localStorage.setItem("club-application", JSON.stringify(values));
    message.success("Your Application Saved");
  };

  return (
    <>
      <form className='p-4' onSubmit={handleSubmit(handleNext)}>
        <div className='row mx-0 d-flex justify-content-center'>
          <div
            className='col-12 col-md-8 px-0 border form-shadow'
            style={{ borderRadius: "20px" }}
            id='form'
          >
            <div className='p-md-5 p-3'>
              <Steps current={page - 1} items={stepsTitle} />
            </div>
            <div className='py-md-5 px-md-4 px-0 form-body-container'>
              {renderStep()}
            </div>
            <div className='row mx-0 my-3 d-flex justify-content-center form-btn-container'>
              <div className='col-12 col-md-6 text-center p-3 d-flex justify-content-md-between justify-content-around'>
                {page > 1 && (
                  <button
                    className='btn btn-secondary'
                    type='button'
                    onClick={handlePrevious}
                  >
                    <i className='fas fa-arrow-left'>Previous</i>
                  </button>
                )}
                {page < totalSteps && (
                  <button
                    className='btn btn-primary'
                    type='submit'
                    disabled={submitting}
                  >
                    Next <i className='fa fa-arrow-right' />
                  </button>
                )}
                {page === totalSteps && (
                  <button
                    onClick={handleSubmit(onSubmit)}
                    type='submit'
                    className='btn btn-success d-flex'
                    disabled={pristine || submitting}
                  >
                    <span className=''>Submit Application</span>
                    <span>
                      <i className='far fa-cloud-upload-alt' />
                    </span>
                  </button>
                )}
                {page !== totalSteps && (
                  <button
                    onClick={handleSubmit(onSave)}
                    type='button'
                    className='btn btn-info'
                    disabled={pristine || submitting}
                  >
                    Save <i className='far fa-save' />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default reduxForm({
  form: "club-application",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(ApplicationForm);
