import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

const getStatus = (status) => {
  if (status) {
    return <i className="fas fa-user-check green" />
  } else {
    return <i className="fas fa-user-clock" />
  }
}

const getFeelIcon = (feel) => {
  console.log(feel)
  if ('smile-wink' === feel) {
    return <i className="fas fa-smile-wink text-success" />
  }
  if ('smile' == feel) {
    return <i className="fas fa-grin-stars text-warning" />
  }
  if ('sad' == feel) {
    return <i className="fas fa-frown text-danger" />
  }
}

export const getFeedbackColumns = (handleUserAction, handleUserDeleteAction) => [
  {
    dataField: "feedbackWeek",
    text: "Week",
    align: "center",
    headerAlign: "center",
    headerClasses: "table-header s-no",
    formatter: (cell, row, index) => <span>{row.feedbackWeek}</span>,
  },
  {
    dataField: "question5",
    text: "Feel",
    sort: true,
    style: { color: "#757575" },
    align: "center",
    headerAlign: "center",
    headerClasses: "table-header status",
    formatter: (cell, row, index) => <span>{getFeelIcon(row.question5)}</span>,

  },
  {
    dataField: "question4",
    text: "Problems Level?",
    sort: true,
    style: { color: "#757575" },
    align: "left",
    headerAlign: "left",
    headerClasses: "table-header status",
  },
  {
    dataField: "question3",
    text: "Mentor Support Level?",
    sort: true,
    style: { color: "#757575" },
    align: "left",
    headerAlign: "left",
    headerClasses: "table-header status",
  },
  {
    dataField: "question1",
    text: "What would you like to give feedback about?",
    sort: true,
    style: { color: "#757575" },
    align: "left",
    headerAlign: "left",
    headerClasses: "table-header status",
  },
  {
    dataField: "question2",
    text: "What do you don't like?",
    sort: true,
    style: { color: "#757575" },
    align: "left",
    headerAlign: "left",
    headerClasses: "table-header status",
  },
]
export const getColumns = (handleUserAction, handleUserDeleteAction) => [
  {
    dataField: "",
    text: "S.NO.",
    align: "center",
    headerAlign: "center",
    headerClasses: "table-header s-no",
    formatter: (cell, row, index) => <span>{++index}</span>,
  },
  {
    dataField: "isApproved",
    text: "Status",
    align: "center",
    headerAlign: "center",
    sort: true,
    headerClasses: "table-header s-no",
    formatter: (cell, row, index) => <span>{getStatus(row.isApproved)}</span>,
  },
  {
    dataField: "userName",
    text: "Username",
    sort: true,
    style: { color: "#757575" },
    headerClasses: "table-header status",
    formatter: (cell, row) => (
      <span>
        <Link to={`/profile/${row.userName}`}>{row.userName}</Link>
      </span>
    ),
  },
  {
    dataField: "name",
    text: "Name",
    sort: true,
    headerClasses: "table-header name",
    style: { color: "#757575" },
  },
  {
    dataField: "phone",
    text: "Phone",
    sort: true,
    style: { color: "#757575" },
    align: "left",
    headerAlign: "left",
    headerClasses: "table-header group-name",
  },
  {
    dataField: "technology",
    text: "Tech",
    sort: true,
    style: { color: "#757575" },
    align: "left",
    headerAlign: "left",
    headerClasses: "table-header group-name",
  },
  {
    dataField: "gender",
    text: "Gender",
    sort: true,
    style: { color: "#757575" },
    align: "left",
    headerAlign: "left",
    headerClasses: "table-header group-name",
  },
  {
    dataField: "question1",
    text: "Question1",
    sort: true,
    style: { color: "#757575" },
    align: "left",
    headerAlign: "left",
    headerClasses: "table-header status",
  },
  {
    dataField: "",
    text: "Actions",
    sort: true,
    style: { color: "#757575" },
    align: "left",
    headerAlign: "left",
    headerClasses: "table-header status",
    formatter: (cell, row) => (
      <div className='text-left d-flex'>
        <i type="button" className="mr-2 fas fa-user-check"
          onClick={() => handleUserAction(row.userName, 'Approve')}
        />
        <i type="button" className="mr-2 fas fa-user-times"
          onClick={() => handleUserAction(row.userName, 'Reject')}
        />
        <i type="button" className="mr-2 far fa-trash"
          onClick={() => handleUserDeleteAction(row.userName)}
        />
      </div>
    ),
  },
  {
    dataField: "createdAt",
    text: "Registered Date",
    sort: true,
    style: { color: "#757575" },
    align: "left",
    headerAlign: "left",
    headerClasses: "table-header group-name",
    formatter: (cell, row) => (
      <span>
        {row.createdAt === null
          ? "Invalid date"
          : moment(row.createdAt).format("LLL")}
      </span>
    ),
  },
];

