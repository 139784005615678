import React, { useState } from "react";
import CommonModelPopup from "../../../../../../../../../../shared_components/CommonModelPopup";

const QuizBlockMultiOptionsPreview = ({ item }) => {
  const [selected, setSelected] = useState({
    option1: false,
    option2: false,
    option3: false,
    option4: false,
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isWrongAnswerModalVisible, setIsWrongAnswerModalVisible] =
    useState(false);
  const options = () => {
    var row = [];
    for (let i = 1; i <= 4; i++) {
      row.push(
        <div className="d-flex align-items-center">
          <input
            type="checkbox"
            className="mr-3"
            name={`option${i}`}
            onChange={(e) =>
              e.target.checked
                ? setSelected({ ...selected, [e.target.name]: true })
                : setSelected({ ...selected, [e.target.name]: false })
            }
          />
          <label>{item.payload && item?.payload[`text${i}`]} </label>
        </div>
      );
    }
    return row;
  };

  const handelSubmit = (e) => {
    let check = true;
    e.preventDefault();
    for (let i = 1; i <= 4; i++) {
      if (item?.payload[`option${i}`] !== selected[`option${i}`]) {
        check = false;
      }
    }
    if (check) {
      setIsModalVisible(true);
    } else {
      setIsWrongAnswerModalVisible(true);
    }
    setSelected("");

  };
  const closeModal = () => {
    setIsModalVisible(false);
  };
  const closeWrongAnswerModal = () => {
    setIsWrongAnswerModalVisible(false);
  };

  return (
    <>
      <div className="border-bottom rounded-lg p-3">
        <div>
          <p className="my-3">Choose the correct answer(s) : </p>
        </div>
        <form onSubmit={handelSubmit}>
          <div>
            <h5 className="mb-3">{item?.payload?.question}</h5>
            {options()}
          </div>
          {selected ? (
            <>
              <div className="d-flex justify-content-center">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </>
          ) : null}
        </form>
      </div>
      {isModalVisible && (
        <CommonModelPopup
          model={isModalVisible}
          toggle={closeModal}
          message={"Congratulations !! your Answer is correct"}
          submitButtonText={"Okey"}
          modeltitle={"Answer Submited!"}
        />
      )}
      {isWrongAnswerModalVisible && (
        <CommonModelPopup
          model={isWrongAnswerModalVisible}
          toggle={closeWrongAnswerModal}
          message={"Opps !! Wrong Answer"}
          submitButtonText={"Okey"}
          modeltitle={"Wrong Answer Submited!"}
        />
      )}
    </>
  );
};

export default QuizBlockMultiOptionsPreview;
