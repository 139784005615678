import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CustomLoader from "../../../../../../shared_components/CustomLoader";
import { deleteCodeBatch, getCodeBatches } from "../../../actions";
import LayoutContainer from "../LayoutContainer";
import DeleteLessonModal from "../Lesson/LessonsList/DeleteLessonModal";
import BatchCard from "./BatchCard";

const DaysCodeBatches = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { codeBatches, codeBatchesLoading } =
    useSelector((state) => state.dayscode) || {};

  const [openModal, setOpenModal] = useState(false);
  const [tempDeleteId, setTempDeleteId] = useState();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    dispatch(getCodeBatches());
  }, []);

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const handleRemoveBatch = () => {
    dispatch(deleteCodeBatch(tempDeleteId)).then((res) => {
      if (res) {
        setOpenModal(!openModal);
      }
    });
  };

  const deleteBatch = (id) => {
    setTempDeleteId(id);
    setOpenModal(!openModal);
  };

  return (
    <>
      <LayoutContainer>
        <div className='row mx-0 solution-nav'>
          <div className='col-md-8 col-6 mx-0 p-2 d-flex align-items-center'>
            <h4 className='py-md-3 py-2 mb-0'>
              <i className='fas fa-file-alt mr-2' />
              Batches
            </h4>
          </div>
          <div className='col-md-4 col-6 d-flex justify-content-end align-items-center'>
            <button
              onClick={() => history.push("/admin/dayscode/batch/new")}
              type='button'
              className='btn enroll-small-btn'
            >
              <small>
                <i className='fal fa-plus-circle mr-2' />
                Creat New Batch
              </small>
            </button>
          </div>
        </div>
        {codeBatchesLoading ? (
          <CustomLoader />
        ) : (
          <div className='row mx-0 p-5'>
            {codeBatches &&
              codeBatches.map((item) => (
                <div key={item._id}>
                  {/* <Link to={`/admin/dayscode/batch/${item._id}`}> */}
                  <div className='col-4'>
                    <BatchCard batch={item} deleteBatch={deleteBatch} />
                  </div>
                  {/* </Link> */}
                </div>
              ))}
          </div>
        )}
      </LayoutContainer>
      <DeleteLessonModal
        open={openModal}
        toggle={toggleModal}
        onSubmit={handleRemoveBatch}
        submitButtonName={"Delete Lesson"}
      />
    </>
  );
};

export default DaysCodeBatches;
