import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";

import Loader from "react-loader-spinner";
import speaker from "../../../../assets/images/svg/speaker.png";
import { RenderInputField } from "../../../../shared_components/ReduxFormFields";
import { email, required } from "../../../../utils/validators";

const SpeakerRegistration = ({
  reset,
  handleSubmit,
  submitting,
  addSpeaker,
  addSpeakerLoading,
}) => {
  const [speakerAdded, setSpeakerAdded] = useState(false);

  const onSubmit = (values) => {
    const speaker = { ...values };
    addSpeaker(speaker).then((res) => {
      if (res && res.success) {
        setSpeakerAdded(true);
        reset("speakerForm");
      }
    });
  };

  return (
    <>
      <div className='row m-0 login m-0'>
        <div className='col-md-6 sticky'>
          <img
            width='300'
            height='300'
            className='card-img-top d-block mb-0 img-fluid sticky'
            src={speaker}
            alt='avatar'
          />
        </div>
        <div className='col-12 col-md-6 text-center bg-white'>
          <div className='pt-md-3 px-md-5 px-4 login-card'>
            <h1 className='text-center login-text'>Call For Speaker</h1>
            {addSpeakerLoading && addSpeakerLoading ? (
              <Loader
                type='ThreeDots'
                color='#30006d'
                height={100}
                width={100}
                timeout={19000} //8 secs
              />
            ) : (
              <>
                {speakerAdded ? (
                  <>
                    <h6>
                      Thanks for showing interest, We will contact you very soon
                    </h6>
                    <Link to='/signup'>
                      <button className='btn join-us-button text-center'>
                        Join Community
                      </button>
                    </Link>
                  </>
                ) : (
                  <form className='' onSubmit={handleSubmit(onSubmit)}>
                    <div>
                      <Field
                        type='text'
                        name='name'
                        label='Enter Name'
                        placeholder=''
                        component={RenderInputField}
                        validate={[required]}
                      />
                      <Field
                        type='email'
                        name='email'
                        label='Enter Email'
                        placeholder='xyz@yui.com'
                        component={RenderInputField}
                        validate={[required, email]}
                      />
                      <Field
                        type='text'
                        name='phone'
                        label='Enter Phone Number'
                        placeholder=''
                        component={RenderInputField}
                        validate={[required]}
                      />
                      <Field
                        type='text'
                        name='city'
                        label='Enter City'
                        //placeholder="xyz@yui.com"
                        component={RenderInputField}
                        validate={[required]}
                      />
                      <Field
                        type='text'
                        name='university'
                        label='Enter University / Organization'
                        //placeholder="xyz@yui.com"
                        component={RenderInputField}
                        validate={[required]}
                      />
                      <Field
                        type='text'
                        name='technology'
                        label='Enter Your Technologies Separate With (,)'
                        //placeholder="xyz@yui.com"
                        component={RenderInputField}
                        validate={[required]}
                      />
                      <Field
                        type='textarea'
                        name='bio'
                        label='Please Introduce Yourself'
                        //placeholder="xyz@yui.com"
                        component={RenderInputField}
                        validate={[required]}
                      />
                    </div>
                    <div className='row mt-4'>
                      <div className='col-12 text-center'>
                        <button
                          type='submit'
                          className='btn btn-primary login-button'
                          disabled={submitting}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default reduxForm({
  form: "speakerForm",
})(SpeakerRegistration);
