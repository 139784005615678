import DatacodeConfig from "../../../../core/config";
import { getSort } from "../../../../utils";

const currentBatches = DatacodeConfig.currentBatches || [];

export const getUserAttendanceList = (user) => {
  const attendanceList = [];
  user &&
    user.attendance &&
    user.attendance.map((x) => {
      if (currentBatches.includes(x.batchId)) {
        attendanceList.push(x);
      }
    });

  return attendanceList;
};

export const getLeaderBoardPosition = (batchLeaderBoard, codeUser) => {
  if (batchLeaderBoard && batchLeaderBoard.length) {
    let position = getSort(batchLeaderBoard, "score")
      .reverse()
      .findIndex((x) => x.codeUser === codeUser);
    return position + 1;
  }
};

export const getTopicOptions = (DSAContent) => {
  const options = [];
  DSAContent &&
    DSAContent.map((item, i) => {
      options.push({ name: item.name, value: i });
    });
  return options;
};
