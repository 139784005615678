import axios from "axios";
import * as actions from "./actionCreators";
import DatacodeConfig from "../../../../core/config";
import {
  CLUB_APPLICATION_LOADING
} from "../constants";
import { generateQueryParams } from "../../../../utils/helpers";

const baseURL = DatacodeConfig.baseURL;
let abortController = null;

export const createApplication = (application) => (dispatch) => {
  // If there's an ongoing request, abort it
  if (abortController) {
    abortController.abort();
  }
  // Create a new AbortController for the current request
  abortController = new AbortController();
  const signal = abortController.signal;

  dispatch({ type: CLUB_APPLICATION_LOADING });

  return axios
    .post(`${baseURL}/club/application/create`, application, { signal }) //Pass the signal to axios
    .then((res) => {
      if (res.status === 200) {
        dispatch(actions.setClubApplication(res.data.application));
        return { success: true, application: res.data };
      } else {
        return { success: false, message: "Something Went Wrong" };
      }
    })
    .catch((error) => {
      dispatch({ type: CLUB_APPLICATION_LOADING });
      console.log("Create Student Application Error", error);
    })
    .finally(() => {
      // Clear the abort controller after the request completes or fails
      abortController = null;
    });
};

export const getClubApplications = (query) => (dispatch) => {
  const { page, limit, search, status } = query;
  dispatch({ type: CLUB_APPLICATION_LOADING });
  return axios
    .get(`${baseURL}/club/application${generateQueryParams({
      page: page,
      limit: limit,
      search: search,
      status: status,
    })}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch(actions.getClubApplications(res && res.data));
        return { success: true, data: res && res.data };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: CLUB_APPLICATION_LOADING });
      console.log("Courses List Error", error);
    });
};

export const getMemberClubApplication = (id) => (dispatch) => {

  dispatch({ type: CLUB_APPLICATION_LOADING });
  return axios
    .get(`${baseURL}/club/application/${id}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch(actions.setClubApplication(res && res.data.application));
        return { success: true, data: res && res.data.application };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: CLUB_APPLICATION_LOADING });
      console.log("Get Application Error", error);
    });
};

export const deleteClubApplication = (id) => (dispatch) => {
  dispatch({ type: CLUB_APPLICATION_LOADING });
  return axios
    .delete(`${baseURL}/club/application/${id}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: CLUB_APPLICATION_LOADING });
        return { success: true };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: CLUB_APPLICATION_LOADING });
      console.log("Delete Club Application Error", error);
    });
};

export const editClubApplication = (application) => (dispatch) => {
  dispatch({ type: CLUB_APPLICATION_LOADING });
  return axios
    .patch(`${baseURL}/club/application/${application._id}`, { data: application })
    .then((res) => {
      if (res.status === 200) {
        dispatch(actions.setClubApplication(res && res.data.application));
        return { success: true, data: res && res.data };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: CLUB_APPLICATION_LOADING });
      console.log("Edit Lesson Error", error);
    });
};