import React from "react"

const data = [
  {
    name: "Unleash Your Creativity",
    image: "http://res.cloudinary.com/datacode/image/upload/v1688212840/uzszrvk2e0oypvexonwm.svg",
    content: 'Explore innovative learning methods, experiential projects, and out-of-the-box activities.'
  },
  {
    name: "Dive into the Unknown",
    image: "http://res.cloudinary.com/datacode/image/upload/v1688212908/f65zhni7irn2jbqvbkap.svg",
    content: 'Discover uncharted territories of knowledge with our exclusive guest speakers and thought-provoking workshops.'
  },
  {
    name: "Ignite Lifelong Connections",
    image: "http://res.cloudinary.com/datacode/image/upload/v1688212969/so5yy1lbtgun87x3w5o7.svg",
    content: 'Connect with an incredible community of like-minded peers and mentors who will inspire and support you.'
  },
]
const WhyStudentClub = () => {
  return (
    <>
      <div className="row mx-0 px-md-5 p-3 d-flex justify-content-center why-section-container">
        <div className="col-md-10 col-12 why-section">
          <h1 className="welcome-text">Why Choose Our Student Learning Club </h1>
          <div className="row mx-0 my-md-5 my-2 d-flex justify-content-center card-row">
            {
              data && data.map((item, i) => (
                <div key={i} className="col-md-4 col-12 px-md-2 px-0 d-flex align-items-stretch">
                  <div className="text-center border card-why shadow">
                    <img
                      className="rounded-circle border shadow"
                      height="110"
                      width="110"
                      loading="lazy"
                      src={item.image}
                      alt="avatar"
                    />
                    <div className="mt-md-5 mt-0 p-md-2 p-0">
                      <h6 className="">{item.name}</h6>
                      <span>{item.content}</span>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div >
    </>
  )
}

export default WhyStudentClub