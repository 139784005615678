import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import exportFromJSON from "export-from-json";
import Combobox from "react-widgets/Combobox";

import DatacodeConfig from "../../../../../../core/config";
import LayoutContainer from "../LayoutContainer";
import CustomLoader from "../../../../../../shared_components/CustomLoader";
import UserActionModal from "./UserActionModal";
import UserListTable from "./UserListTable/UserListTable";
import ToolTip from "../../../../../../shared_components/Tooltip";
import { Collapse } from "reactstrap";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

const UsersList = ({
  getDaysUsers,
  daysUsers,
  usersListLoading,
  userSelectionAction,
  deleteDaysUser,
  assignUserMentor,
  deleteAssignedUserMentor,
}) => {
  const batchMentors = DatacodeConfig.batchMentors || [];
  const [userAction, setUserAction] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [tempUserId, setTempUserId] = useState();
  const [search, setSearch] = useState("");
  const [mentor, setMentor] = useState({});
  const [listView, setListView] = useState("grid");
  const [dropdownOpen, setDropdownOpen] = useState();
  const { batchId } = useParams();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    getDaysUsers(batchId, "all");
  }, []);

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const toggleDeleteModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  const data = daysUsers;
  const fileName = "DaysCodeUsersReg";
  const exportType = exportFromJSON.types.xls;
  const downloadExel = () => {
    exportFromJSON({ data, fileName, exportType });
  };

  const handleUserSelectionAction = () => {
    const data = {};
    data["isApproved"] = userAction === "Approve" ? true : false;
    userSelectionAction(tempUserId, data).then((res) => {
      if (res) {
        setOpenModal(!openModal);
      }
    });
  };

  const handleUserAction = (id, action) => {
    setTempUserId(id);
    setUserAction(action);
    setOpenModal(!openModal);
  };

  const handleUserDeleteAction = (id, action) => {
    setTempUserId(id);
    setOpenDeleteModal(!openDeleteModal);
  };

  const handleDelete = () => {
    deleteDaysUser(tempUserId && tempUserId.trim()).then((res) => {
      if (res) {
        setOpenDeleteModal(!openDeleteModal);
      }
    });
  };

  const getMentorsOptions = () => {
    const options = [];
    batchMentors &&
      batchMentors.map((item) => {
        options.push({ mentor: item.userName, value: item.email });
      });
    return options;
  };

  const onChangeMentorOption = (e, item) => {
    console.log("item", item);
    setMentor({ user: item.userName, selectMentor: e });
  };

  const handleAssignMentor = (user) => {
    const data = {
      userName: user.userName,
      batchId: "3",
      mentorName: mentor?.selectMentor?.mentor,
      mentorId: mentor?.selectMentor?.value,
    };
    assignUserMentor(user.id, data);
  };

  const handleDeleteAssignedMentor = (id, mentor) => {
    const data = {
      mentorId: mentor.mentorId,
      batchId: "3",
    };
    deleteAssignedUserMentor(id, data);
  };

  const dropdownToggle = (openTable) => {
    if (openTable === dropdownOpen) {
      return setDropdownOpen("");
    }
    setDropdownOpen(openTable);
  };

  const filteredList =
    daysUsers &&
    daysUsers.filter(
      (item) =>
        item?.name?.toUpperCase().includes(search.toUpperCase()) ||
        item?.userName?.toUpperCase().includes(search.toUpperCase()),
    );

  return (
    <>
      <LayoutContainer>
        <div className='row mx-0 d-flex border-bottom card-schadow p-md-3 p-2 align-items-center justify-content-between'>
          <div className='col-6'>
            <h3 className=''>Users List</h3>
            <h6>Count : {daysUsers.length - 17}</h6>
          </div>
          <div className='col-6 text-right align-items-center'>
            <button className='btn btn-primary' onClick={() => downloadExel()}>
              <i className='fas fa-download blue mr-2 text-white' />
              Download File
            </button>
          </div>
        </div>
        <div className='row mx-0 px-md-5 px-2 my-5 category-page'>
          <div className='col-12 col-md-8  text-center d-flex justify-content-center'>
            <div className='d-flex mx-md-3 mx-2 px-md-4 px-1 fontSearch'>
              <input
                type='text'
                className='search'
                value={search}
                placeholder='Username / Name'
                onChange={(e) => setSearch(e.target.value)}
              />
              <i className='fa fa-search search-icon' />
            </div>
          </div>
          <div className='col-md-4 col-12 d-flex justify-content-end align-items-center'>
            <i
              id='table'
              onClick={() => setListView("table")}
              className='p-2 mx-3 border rounded fas fa-list'
            />
            <ToolTip message={"Table View"} id='table' placement='bottom' />
            <i
              id='grid'
              onClick={() => setListView("grid")}
              className='p-2 border rounded fas fa-th-large'
            />
            <ToolTip message={"Grid View"} id='grid' placement='bottom' />
          </div>
        </div>

        {usersListLoading ? (
          <CustomLoader />
        ) : (
          <>
            {listView === "grid" ? (
              <>
                <div className='row m-0 px-md-5 py-2 userlist-card'>
                  {daysUsers &&
                    daysUsers.length &&
                    filteredList.map((item, i) => (
                      <div key={i} className='col-12 col-md-6 p-md-5 p-4'>
                        <div className='row mx-0 border event-card main rounded-lg'>
                          <div className='col-md-12 col-12 p-2 left'>
                            <div className=''>
                              <UncontrolledDropdown
                                setActiveFromChild
                                className='event-host-action-toggle'
                              >
                                <DropdownToggle tag='a' className=''>
                                  <i className='far fa-ellipsis-v text-right' />
                                </DropdownToggle>
                                <DropdownMenu
                                  className='dropdown-menu mt-3 card-schadow'
                                  right
                                >
                                  <DropdownItem header>
                                    <span>Admin Actions</span>
                                  </DropdownItem>
                                  <DropdownItem>
                                    <p className=''>
                                      <i
                                        type='button'
                                        className='mr-2 fas fa-user-check'
                                        onClick={() =>
                                          handleUserAction(
                                            item.userName,
                                            "Approve",
                                          )
                                        }
                                      />
                                      Approve
                                    </p>
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() =>
                                      handleUserAction(item.userName, "Reject")
                                    }
                                  >
                                    <p className=''>
                                      <i
                                        type='button'
                                        className='mr-2 fas fa-user-times'
                                      />
                                      Reject
                                    </p>
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() =>
                                      handleUserDeleteAction(item.userName)
                                    }
                                  >
                                    <p className=''>
                                      <i
                                        type='button'
                                        className='mr-2 far fa-trash'
                                      />
                                      Delete
                                    </p>
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                            <h1 className='h6'>
                              Status:{" "}
                              {item.isApproved ? (
                                <i className='badge badge-success p-1 far fa-check-circle'>
                                  Approved
                                </i>
                              ) : (
                                <i className='badge badge-warning p-1 far fa-clock'>
                                  Waiting
                                </i>
                              )}
                            </h1>
                            <Link
                              target='_blank'
                              to={`/profile/${item.userName}`}
                            >
                              <p className='font-weight-normal'>
                                Username: <span>{item.userName}</span>{" "}
                                <i className='fal fa-external-link' />
                              </p>
                            </Link>
                            <p className='font-weight-normal'>
                              Level: <span>{item?.programming_level}</span>
                            </p>
                            <p className='font-weight-normal'>
                              Gender: <span>{item?.gender}</span>
                            </p>
                            <p className='font-weight-normal'>
                              Batch:{" "}
                              {item.batches &&
                                item.batches.map((batch) => (
                                  <span>{batch},</span>
                                ))}
                            </p>
                            <p className='font-weight-normal'>
                              Mentor:
                              <span className=''>
                                {item.mentorAssign &&
                                  item.mentorAssign.map((mentor) => (
                                    <small className='mx-1 border rounded-lg p-1 bg-pastel-purple'>
                                      {mentor.mentorName}
                                      <i
                                        onClick={() =>
                                          handleDeleteAssignedMentor(
                                            item.id,
                                            mentor,
                                          )
                                        }
                                        className='far fa-trash ml-2 voilet'
                                      />
                                    </small>
                                  ))}
                              </span>
                            </p>
                            {
                              // (!(item.mentorAssign && item.mentorAssign[0]?.mentorName) && item.isApproved) &&
                              <>
                                <label className='form-label '>
                                  Select Mentor
                                </label>
                                <div className='row mx-0 my-2' key={i}>
                                  <div className='col-md-8 col-12 px-0'>
                                    <div className=''>
                                      <Combobox
                                        data={getMentorsOptions(batchMentors)}
                                        dataKey={"value"}
                                        textField='mentor'
                                        placeholder={"Select Mentor Name"}
                                        value={
                                          mentor.user === item.userName
                                            ? mentor.selectMentor
                                            : null
                                        }
                                        onChange={(value) =>
                                          onChangeMentorOption(value, item)
                                        }
                                        // disabled={user && true}
                                      />
                                    </div>
                                  </div>
                                  <div className='col-md-4 col-12 text-left px-0 d-flex align-items-center'>
                                    <button
                                      onClick={() => handleAssignMentor(item)}
                                      className='btn btn-sm btn-primary ml-md-2 ml-0 mt-md-0 mt-2'
                                      type='button'
                                    >
                                      Assign
                                    </button>
                                  </div>
                                </div>
                              </>
                            }
                            <p className='font-weight-normal'>
                              Technology Interest:{" "}
                              <span>{item.technology}</span>
                            </p>
                            <p className='font-weight-normal'>
                              Applied on:{" "}
                              <span>
                                {moment(item.createdAt).format("LLL")}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </>
            ) : (
              <UserListTable
                daysUsers={daysUsers && filteredList}
                handleUserAction={handleUserAction}
                handleUserDeleteAction={handleUserDeleteAction}
              />
            )}
          </>
        )}
      </LayoutContainer>
      <UserActionModal
        open={openModal}
        toggle={toggleModal}
        onSubmit={handleUserSelectionAction}
        submitButtonName={userAction}
      />
      <UserActionModal
        open={openDeleteModal}
        toggle={toggleDeleteModal}
        onSubmit={handleDelete}
        submitButtonName={userAction}
      />
    </>
  );
};
export default UsersList;
