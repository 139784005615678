import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { addEventSpeaker, editEventSpeaker, getEventDetails } from "../../../actions";
import { getSpeakers } from '../../../../../user/actions'

import EventSpeakers from "./EventSpeakers";

const mapStateToProps = ({ event, user }) => ({
  eventRegistationLoading: event.eventRegistationLoading,
  imageUploading: user.imageUploading,
});

const mapDispatchToProps = { addEventSpeaker, editEventSpeaker, getEventDetails, getSpeakers };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EventSpeakers)
);
