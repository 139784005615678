import EditProfile from "./EditProfile";

import { connect } from "react-redux";

import { getUserProfile, editCurrentUser } from "../../../actions";

const mapStateToProps = ({ app, user }) => ({
  userProfileLoading: user.userProfileLoading,
  imageUploading: user.imageUploading,
  currentUser: user.currentUser ? user.currentUser : {},
});

const mapDispatchToProps = {
  getUserProfile,
  editCurrentUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
