import * as constants from "../constants";

const setAppointmentSlotsLoading = (state) => ({
  ...state,
  appointmentSlotsLoading: !state.appointmentSlotsLoading,
});

const setAppointmentEventDetails = (state, action) => ({
  ...state,
  appointmentEventDetails: action.payload.appointmentEvent,
  availableSlots: action.payload.availableSlots
})

export const setAppointmentHandlers = {
  [constants.SET_APPOINTMENT_SLOTS_LOADING]: setAppointmentSlotsLoading,
  [constants.SET_APPOINTMENT_EVENT_DETAILS]: setAppointmentEventDetails
};