import React from "react";
import { Field } from 'redux-form'
import { RenderInputField } from '../../../../../../../shared_components/ReduxFormFields'
import { required } from '../../../../../../../utils/validators'

const ApplicationStep3 = () => {
  return (
    <>
      <div className="row mx-0">
        <div className="col-12">
          <Field
            type="textarea"
            name="why_are_you_interested"
            label="Why are you interested in joining the Datacode Student Learning Club?"
            placeholder="Enter why you are in interested in this club in about 100-200 words"
            component={RenderInputField}
            validate={[required]}
          />
          <Field
            type="textarea"
            name="interested_topics"
            label="What specific technology or areas you interested in?"
            placeholder="Ex: Machine Learning, Datascience, Web Development"
            component={RenderInputField}
            validate={[required]}
          />
          <Field
            type="textarea"
            name="goals_expectations"
            label="What are your goals or expectations from joining the Student Learning Group?"
            placeholder="Please let us know more about that in details (300 Words)"
            component={RenderInputField}
            validate={[required]}
          />
          <Field
            type="textarea"
            name="participated_before"
            label="Have you participated in any other student clubs or organizations related to computer science?"
            placeholder="if yes, Please let us know more about that"
            component={RenderInputField}
            validate={[required]}
          />
        </div>
      </div>
    </>
  )
}

export default ApplicationStep3