import CustomFilterSearch from './CustomFilterSearch'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getFormValues } from "redux-form";

const mapStateToProps = (state) => ({
  formStates: getFormValues("event-filter-form")(state),
});

export default withRouter(
  connect(mapStateToProps, null)(CustomFilterSearch)
);
