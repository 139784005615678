export const techIcon = [
  {
    svgSource: "Python.svg",
    technology: "Python",
    title: "Python",
    techCategory: "programming",
  },
  {
    svgSource: "Angular.svg",
    technology: "Angular",
    title: "Angular",
    techCategory: "webDevelopment",
  },
  {
    svgSource: "Javascript.svg",
    technology: "Javascript",
    title: "Javascript",
    techCategory: "programming",
  },
  {
    svgSource: "Java.svg",
    technology: "Java",
    title: "Java",
    techCategory: "programming",
  },
  {
    svgSource: "Android.svg",
    technology: "Android",
    title: "Android",
    techCategory: "appDevelopment",
  },
  {
    svgSource: "Cpp.svg",
    technology: "cplusplus",
    title: "C++",
    techCategory: "programming",
  },
  {
    svgSource: "C.svg",
    technology: "C",
    title: "C",
    techCategory: "programming",
  },
  {
    svgSource: "Datastructures.svg",
    technology: "Datastructures",
    title: "DataStructure",
    techCategory: "programming",
  },
  {
    svgSource: "html-css.svg",
    technology: "Html",
    title: "Html",
    techCategory: "programming",
  },
  {
    svgSource: "Jquery.svg",
    technology: "jQuery",
    title: "jQuery",
    techCategory: "programming",
  },
  {
    svgSource: "Go_lang.svg",
    technology: "Go",
    title: "Go",
    techCategory: "webDevelopment",
  },
  {
    svgSource: "CSS.svg",
    technology: "Css",
    title: "Css",
    techCategory: "webDevelopment",
  },
  {
    svgSource: "SQL.svg",
    technology: "SQL",
    title: "SQL",
    techCategory: "programming",
  },
  {
    svgSource: "React.svg",
    technology: "React",
    title: "React",
    techCategory: "webDevelopment",
  },
  {
    svgSource: "django.svg",
    technology: "Django",
    title: "Django",
    techCategory: "webDevelopment",
  },
  {
    svgSource: "node.svg",
    technology: "Node",
    title: "Node",
    techCategory: "webDevelopment",
  },
  {
    svgSource: "Vue.svg",
    technology: "Vue",
    title: "Vue",
    techCategory: "webDevelopment",
  },
  {
    svgSource: "PHP.svg",
    technology: "PHP",
    title: "PHP",
    techCategory: "programming",
  },
  {
    svgSource: "Typescript.svg",
    technology: "Typescript",
    title: "Typescript",
    techCategory: "webDevelopment",
  },
  {
    svgSource: "Redux.svg",
    technology: "Redux",
    title: "Redux",
    techCategory: "webDevelopment",
  },
  {
    svgSource: "Swift.svg",
    technology: "Swift",
    title: "Swift",
    techCategory: "appDevelopment",
  },
  {
    svgSource: "Flutter.svg",
    technology: "Flutter",
    title: "Flutter",
    techCategory: "appDevelopment",
  },
  {
    svgSource: "linux.svg",
    technology: "Linux",
    title: "Linux",
    techCategory: "computerScience",
  },
  {
    svgSource: "Git.svg",
    technology: "Git",
    title: "Git/Github",
    techCategory: "computerScience",
  },
  {
    svgSource: "React_native.svg",
    technology: "react_native",
    title: "React Native",
    techCategory: "appDevelopment",
  },
  {
    svgSource: "Ruby.svg",
    technology: "Ruby",
    title: "Ruby",
    techCategory: "webDevelopment",
  },
  {
    svgSource: "Computer_Science.svg",
    technology: "computer_science",
    title: "Computer Science",
    techCategory: "webDevelopment",
  },
  {
    svgSource: "DataScience.svg",
    technology: "machine_learning",
    title: "Machine Learning",
    techCategory: "dataScience",
  },
  {
    svgSource: "DataScience.svg",
    technology: "artificial_intelligence",
    title: "Artificial Intelligence",
    techCategory: "dataScience",
  },
  {
    svgSource: "DataScience.svg",
    technology: "datascience",
    title: "DataScience",
    techCategory: "dataScience",
  },
  {
    svgSource: "Software_Testing.svg",
    technology: "software_testing",
    title: "Software Testing",
    techCategory: "computerScience",
  },
  {
    svgSource: "DataScience.svg",
    technology: "apache_spark",
    title: "Apache Spark",
    techCategory: "webDevelopment",
  },
  {
    svgSource: "DataScience.svg",
    technology: "tensorflow",
    title: "Tensorflow",
    techCategory: "dataScience",
  },
  {
    svgSource: "Bootstrap.svg",
    technology: "Bootstrap",
    title: "Bootstrap",
    techCategory: "webDevelopment",
  },
  {
    svgSource: "DataScience.svg",
    technology: "hadoop",
    title: "Hadoop",
    techCategory: "dataScience",
  },
  {
    svgSource: "seo.svg",
    technology: "SEO",
    title: "SEO",
    techCategory: "webDevelopment",
  },
  {
    svgSource: "C_sharp.svg",
    technology: "CSharp",
    title: "CSharp",
    techCategory: "programming",
  },
];

export const techCategory = [
  {
    iconSource: "fa fa-code",
    link: "",
    title: "All Technologies",
    techCategory: "all",
  },
  {
    iconSource: "fa fa-code",
    link: "",
    title: "Programming",
    techCategory: "programming",
  },
  {
    iconSource: "fa fa-credit-card",
    link: "",
    title: "Computer Science",
    techCategory: "computerScience",
  },
  {
    iconSource: "fa fa-laptop-code",
    link: "",
    title: "Web Development",
    techCategory: "webDevelopment",
  },
  {
    iconSource: "fa fa-mobile-alt",
    link: "",
    title: "App Development",
    techCategory: "appDevelopment",
  },
  {
    iconSource: "fa fa-database",
    link: "",
    title: "DataScience",
    techCategory: "dataScience",
  },
  {
    iconSource: "fa fa-robot",
    link: "",
    title: "Cyber Security",
    techCategory: "cyberSecurity",
  },
  {
    iconSource: "React.png",
    link: "",
    title: "Big Data",
    techCategory: "bigData",
  },
];
