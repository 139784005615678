import IndexHeader from "./IndexHeader";

import { connect } from "react-redux";
import { logoutUser } from "../../../../components/user/actions";
import { showAddTestimonial } from "../../../actions";

const mapStateToProps = ({ app, user }) => ({
  isShowAddTutorial: app.isShowAddTutorial,
  currentUser: user.currentUser ? user.currentUser : {},
  userProfileLoading: user.userProfileLoading,
  testimonials: app.testimonials ? app.testimonials : [],
});

const mapDispatchToProps = {
  logoutUser,
  showAddTestimonial,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndexHeader);
