import React, { useState } from "react";
import { Collapse } from "reactstrap";

import { LearningRoadmap } from "./helper";

const DaysCodeRoadMap = () => {
  const [activeTable, setActiveTable] = useState("");

  const toggle = (openTable) => {
    if (openTable === activeTable) {
      return setActiveTable("");
    }
    setActiveTable(openTable);
  };

  return (
    <>
      <div className="row d-flex align-items-center justify-content-center dayscode-roadmap">
        <div className="col-12">
          {LearningRoadmap.map((item, i) => (
            <div key={i} className="border-bottom">
              <div
                onClick={() => toggle(i)}
                className="row mx-0 p-md-2 p-1 d-flex align-items-center"
              >
                <div className="col-2 d-flex text-center p-0">
                  <img
                    height="60px"
                    width="60px"
                    src={item.img}
                    alt="avatar"
                    className="rounded-circle mr-2 text-center mx-auto img-fluid"
                  />
                </div>
                <div className="col-8 p-0 d-flex align-items-center">
                  <h6 className="m-0 roadmap-title">{item.title}</h6>
                </div>
                <div className="col-2 p-0">
                  {activeTable === i ? (
                    <i className="fa fa-angle-up text-dark p-3" />
                  ) : (
                    <i className="fa fa-angle-down text-dark p-3" />
                  )}
                </div>
              </div>
              <Collapse isOpen={activeTable === i}>
                <div className="row mx-0 d-flex justify-content-center">
                  <div className="col-2"></div>
                  <div className="col-md-8 col-12 px-3">
                    <p>{item.description}</p>
                  </div>
                  <div className="col-2"></div>
                </div>
              </Collapse>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default DaysCodeRoadMap;
