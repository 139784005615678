import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";

import { getColumns } from "./helpers";

const EventFeedbacks = ({ eventDetails }) => {
  let columns = getColumns();

  const [feedbacksData, setFeedbacksData] = useState();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (eventDetails) {
      console.log("eventDetails", eventDetails);
      setFeedbacksData(eventDetails.feedbacks);
    }
  });
  return (
    <>
      <h1>Feedback Details</h1>
      {feedbacksData && feedbacksData.length && (
        <div className='table table-responsive'>
          <>
            <BootstrapTable
              keyField='id'
              bordered={true}
              data={feedbacksData}
              columns={columns}
              hover={true}
            />
          </>
        </div>
      )}
    </>
  );
};

export default EventFeedbacks;
