import { connect } from "react-redux";
import { setUserImgDetails } from "../../components/user/actions";

import BannerImageUpload from "./BannerImageUpload";

const mapStateToProps = ({ app, user }) => ({
  userProfileLoading: user.userProfileLoading,
  currentUser: user.currentUser ? user.currentUser : {},
  imageUploading: user.imageUploading,
});

const mapDispatchToProps = {
  setUserImgDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(BannerImageUpload);
