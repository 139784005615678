import React from "react";
import YouTube from "react-youtube";

const VideoFrame = ({ videoId, }) => {

  const videoOnReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }


  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <div className="text-center">
      <YouTube id="video-id"
        videoId={videoId}
        opts={opts}
        onReady={videoOnReady}
      />
    </div>
  );
}

export default VideoFrame;
