import React from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import { getColumns } from './helpers'

const UserListTable = ({ daysUsers, handleUserAction, handleUserDeleteAction }) => {
  let columns = getColumns(handleUserAction, handleUserDeleteAction)

  return (
    <>
      {daysUsers && daysUsers.length &&
        <div className="table table-responsive">
          <BootstrapTable
            keyField='id'
            bordered={true}
            data={daysUsers}
            columns={columns}
            search
            hover={true}
          />
        </div>
      }
    </>
  )
}

export default UserListTable