import { connect } from "react-redux";
import {
  getDaysUsersListOptions,
  addUserAttendancePresent,
  deleteUserAttendancePresent,
} from "../../../../actions";

import AddUserAttendance from "./AddUserAttendance";

const mapStateToProps = ({ dayscode, user }) => ({
  currentUser: user.currentUser ? user.currentUser : {},
  problemsListLoading: dayscode.problemsListLoading,
  problemDetailsLoading: dayscode.problemDetailsLoading,
  submissions: dayscode.submissions,
  daysUsers: dayscode.daysUsers,
  getUserSubmissionsLoading: dayscode.getUserSubmissionsLoading,
  userAttendancePresentLoading: dayscode.userAttendancePresentLoading,
});

const mapDispatchToProps = {
  getDaysUsersListOptions,
  addUserAttendancePresent,
  deleteUserAttendancePresent,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUserAttendance);
