import React from 'react'
import WorkshopPageHeroSection from './WorkshopPageHeroSection'
import WorkshopDetailsSection from './WorkshopDetailsSection'
import WorkshopPageBodySection from './WorkshopPageBodySection'

const WorkshopPage = () => {
  return (
    <>
      <WorkshopPageHeroSection />
      <WorkshopDetailsSection />
      <WorkshopPageBodySection />
    </>
  )
}

export default WorkshopPage