import React, { useEffect, useState } from "react";
import { GoogleLogin } from "react-google-login";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Field, reduxForm } from "redux-form";

import { ReactComponent as GoogleLogo } from "../../../../assets/images/svg/google.svg";
import CustomLoader from "../../../../shared_components/CustomLoader";
import { RenderInputField } from "../../../../shared_components/ReduxFormFields";
import {
  email,
  required,
  validatePassword,
} from "../../../../utils/validators";

const Signup = ({
  reset,
  handleSubmit,
  submitting,
  currentUser,
  signupUser,
  loginUserLoading,
  initialize,
  googleLogin,
  userError,
  authCheck,
}) => {
  const [userDetails, setUserDetails] = useState();
  const [googleUser, setGoogleUSer] = useState();

  const history = useHistory();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    window.scrollTo(0, 0);
    if (currentUser && currentUser.email) {
      history.push(`/`);
    }
  }, []);

  const onSubmit = (values) => {
    const user = { ...values };
    if (googleUser && googleUser.email) {
      user["email_verify"] = "verified";
    }
    signupUser(user).then((res) => {
      if (res) {
        if (!googleUser) {
          setUserDetails(res.data);
        }
        reset("signupForm");
      } else {
        console.log("Error", res);
        reset("signupForm");
      }
    });
  };

  const responseSuccessGoogle = (response) => {
    if (response && response.profileObj) {
      authCheck(response.profileObj).then(({ data }) => {
        if ({ data }) {
          if (data && !data.data.firstName && !data?.data?.phone) {
            history.push(`/edit_profile`);
          } else {
            history.goBack();
          }
        }
      });
    }
  };

  const responseFailureGoogle = (response) => {};

  return (
    <>
      {userDetails && <Redirect to={`/confirmation/new_email_verify`} />}
      <div className='row m-0 login'>
        <div className='col-12 col-md-8 d-md-block d-none'>
          <div className='px-md-4'>
            {loginUserLoading ? (
              <CustomLoader />
            ) : (
              <div className='px-2'>
                <h1 className='mt-3'>Datacode Learning Community</h1>
                <h6 className='mb-4'>
                  Awesome Learning Community for Computer Science Learners
                </h6>
                <p>
                  We at Datacode.in aims in creating a platform for beginners'
                  communities where they will get the best online learning
                  resources, like the latest tutorials and courses, practice
                  questions, quizzes, training and certification, technical
                  blogs, and lot more on a single platform.
                </p>
                <p>
                  Where students can connect and learn by contributing into the
                  community for upcoming beginners
                </p>
                <img
                  src={require(`../../../../assets/images/svg/laptop-green.png`)}
                  alt='loading...'
                  width='300'
                  className='mx-auto my-auto img-fluid'
                />
              </div>
            )}
          </div>
        </div>
        <div className='col-12 col-md-4 pb-5 login-section'>
          <h1 className='text-center login-text'>Sign Up To Get Inside</h1>
          <div className='pt-md-3 px-4 login-card'>
            <GoogleLogin
              clientId='135253032783-kmpa2k662qc8dkdqhlmml973l3c7khbt.apps.googleusercontent.com'
              render={(renderProps) => (
                <button
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  type='button'
                  className='google-button'
                >
                  <GoogleLogo
                    width='40'
                    height='40'
                    className='bg-white p-1 mr-5'
                  />
                  <div className='google-login-string'>
                    Sign up in with Google
                  </div>
                </button>
              )}
              buttonText='Login'
              onSuccess={responseSuccessGoogle}
              onFailure={responseFailureGoogle}
              cookiePolicy={"single_host_origin"}
              isSignedIn={true}
            />
            {/* <button onClick={() => googleLogin()} type="button" className="google-button">
              <GoogleLogo width="40" height="40" className="bg-white p-1 mr-5" />
              <div className="google-login-string">
                Sign up in with Google
              </div>
            </button> */}

            <div className='or-line'>
              <span>or</span>
            </div>

            <form className='' onSubmit={handleSubmit(onSubmit)}>
              <div>
                <Field
                  name='userName'
                  type='text'
                  component={RenderInputField}
                  label='Datacode Username'
                  placeholder='system@123'
                  validate={[required]}
                  isRequired={true}
                />
                <Field
                  name='email'
                  type='email'
                  component={RenderInputField}
                  label='Email'
                  placeholder='member_name@joe.com'
                  validate={[required, email]}
                  isRequired={true}
                />
                {!googleUser && (
                  <Field
                    name='password'
                    type='password'
                    component={RenderInputField}
                    label='Password'
                    placeholder='******'
                    validate={[required, validatePassword]}
                    isRequired={true}
                  />
                )}
                {userError && <h6 className='red  mt-2 mb-0'>{userError}</h6>}
              </div>
              <div className='px-0 my-4 text-md-right text-center right'>
                <button
                  type='submit'
                  className='btn w-100 login-show-btn'
                  disabled={submitting}
                >
                  Signup
                </button>
              </div>
              <div className='px-0 text-left left'>
                <p className='signup-string p-0 mb-0 mt-5 mt-md-0'>
                  Already have an account?,
                  <Link to='/login' className='signup-string ml-2 link'>
                    <span className='link-text'>Login</span>
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default reduxForm({
  form: "signupForm", // a unique name for this form
})(Signup);
