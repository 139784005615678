import React, { useEffect } from "react";
import {
  FcBusinessman,
  FcBiotech,
  FcReading,
  FcServices,
  FcBullish,
  FcBookmark,
  FcCustomerSupport,
} from "react-icons/fc";

import { FaCode } from "react-icons/fa6";
import {
  GoBookmark,
  GoPeople,
  GoNote,
  GoProjectSymlink,
  GoCopilot,
  GoPerson,
  GoCheckbox,
  GoCrossReference,
} from "react-icons/go";

import AOS from "aos";

const programContain = [
  {
    icon: <GoPeople />,
    head: "3-Month Intensive Training",
    description:
      "A comprehensive program designed to transform beginners into full-fledged web developers.",
  },
  {
    icon: <GoNote />,
    head: "Expert-Led Learning",
    description:
      "Master essential technologies like React, Node.js, HTML, and CSS with guidance from experienced mentors.",
  },
  {
    icon: <GoProjectSymlink />,
    head: "Real-World Projects",
    description:
      "that simulate industry environments, giving you hands-on experience on the real-time work.",
  },
  {
    icon: <GoCopilot />,
    head: "Zero to Hero Approach",
    description:
      "Whether you’re starting from scratch or looking to refine your skills, we help you grow from a complete beginner to a professional developer.",
  },
  {
    icon: <GoBookmark />,
    head: "Follow Industry Best Practices",
    description:
      "Learn how to code the right way—through regular code reviews, clean coding principles, and adopting the best standards.",
  },
  {
    icon: <GoPerson />,
    head: "Certified & Job-Ready",
    description:
      "Earn an industry-recognized certification upon successful completion, boosting your resume and increasing job prospects.",
  },
  {
    icon: <GoCheckbox />,
    head: "Proven Success",
    description:
      "In just 10 months, we’ve trained over 20 students, with the majority now placed in top companies as professional developers.",
  },
  {
    icon: <GoCrossReference />,
    head: "1 : 1 Mentorship",
    description:
      "We Have Provied one to one mentorship to help mentees achieve their specific goals through tailored guidance.",
  },
  {
    icon: <FaCode />,
    head: "Code Review Sessions",
    description:
      "A code review session is a collaborative process where mentor review code to ensure quality, consistency, and best practices.",
  },
];
const BecomeProfessional = () => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <div
        className="row mx-0 BecomeProfessional justify-content-center"
        data-aos="fade-up"
      >
        <div className="col-10 px-0">
          <h1 className="pt-lg-5 mt-lg-5 text-center" data-aos="fade-up">
            Our Internship Program contains-
          </h1>

          <div className="row mx-0 Our-Internship py-5" data-aos="fade-up">
            {programContain.map((items) => (
              <div
                className="col-12 col-sm-12 col-md-4 col-lg-4 px-0 p-3"
                data-aos="fade-left"
              >
                <div className="container p-4 border shadow">
                  {/* <h1 className="icon text-center">{items.icon}</h1> */}
                  <div className="row mx-0">
                    <div className="col-4 px-0">
                      <p className="icon rounded-circle text-center">
                        {items.icon}
                      </p>
                    </div>
                  </div>
                  <h6 className="">{items.head}</h6>
                  <p className="mb-0 para">{items.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default BecomeProfessional;
