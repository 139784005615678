import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getUsersListDetails } from "../../../actions";

import UsersList from "./UsersList";

const mapStateToProps = ({ user }) => ({
  userProfileLoading: user.userProfileLoading,
});

const mapDispatchToProps = { getUsersListDetails };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UsersList)
);
