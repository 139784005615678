import DatacodeConfig from "./config";

const batchMentors = DatacodeConfig.batchMentors || [];

export const checkAdmin = (user) => {
  if (user.userName === process.env.REACT_APP_ADMIN_USERNAME && user.email === process.env.REACT_APP_ADMIN_EMAIL) {
    return true;
  } else {
    return false;
  }
};

export const checkMentorAuth = () => {
  var localUser = JSON.parse(localStorage.getItem("userDetails"));

  if (localUser &&
    batchMentors.find(
      (x) => x.email === localUser.email && x.userName === localUser.userName
    )) {
    return true
  } else {
    return false
  }
};