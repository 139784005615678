import React from 'react'
const VisionSection = () => {
  return (
    <>
      <div className="row mx-0 mb-5 d-flex justify-content-center vision-section">
        <div className="col-md-10 col-12">
          <h1 className='my-md-5 my-3 text-center'>Welcome to Our Learning Wonderland!</h1>
          <div className="row mx-0 main">
            <div className="col-md-4 col-12 text-md-left text-center pr-md-5 px-2 left">
              <h4 className=''>
                Unlock the Power of Knowledge with Datacode Student Learning Club
              </h4>
              <p className='mt-5'>
                Welcome to the Datacode Student Learning Club, where we empower students to harness the transformative potential of information. Our dynamic learning environment fosters curiosity, collaboration, and critical thinking. Gain hands-on experience, collaborate with like-minded peers, and form valuable connections. Our experienced team is dedicated to your success, providing guidance and industry insights. Join us today to unlock your potential in the knowledge-driven world of innovation and success.
              </p>
            </div>
            <div className="col-md-8 col-12 text-md-right text-center right">
              <img
                className="img-fluid rounded-lg"
                loading="lazy"
                src={`http://res.cloudinary.com/datacode/image/upload/v1687775814/dytxm0rommmerwkccopj.png`}
                alt="avat ar"
              />
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default VisionSection