import { Button, InputNumber } from "antd";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { checkAdmin } from "../../../../../../../core/auth";

const ProblemListCard = ({
  item,
  day,
  days,
  batchContentId,
  currentUser,
  deleteProblem,
  showAddBatchIcon,
  showDeleteFromBatchIcon,
  handleAddIntoBatch,
  handleDeleteFromBatch,
  openAddDay,
  setOpenAddDay,
}) => {
  const [batchDay, setBatchDay] = useState(1);
  const { codeBatchDetails } = useSelector((state) => state.dayscode) || {};

  const handelDayChange = (value) => {
    setBatchDay(value);
  };

  console.log("batchContentId", batchContentId);
  return (
    <>
      <div className='row mx-0 border rounded-lg d-flex justify-content-between card-schadow p-2'>
        <div className='col-9 px-1'>
          <div className='row mx-0'>
            {days ? (
              <p className='col-2 p-0'>
                Days:
                {_.isEmpty(days)
                  ? "No Schadule"
                  : days.map((item) => <b>{item}</b>)}
              </p>
            ) : (
              <p className='col-2 p-0'>
                Day: {day ? <b>{day}</b> : "No Schadule"}
              </p>
            )}
            {item?.topic && (
              <p className='col-2'>
                Topic: <b>{item?.topic}</b>
              </p>
            )}
          </div>
          <p>{item?.title}</p>
        </div>
        <div className='col-3 px-0 align-items-center d-flex justify-content-around'>
          <Link
            target={"_blank"}
            to={`/admin/dayscode/problem/preview/${item?._id}`}
          >
            <i className='far fa-eye green' />
          </Link>
          {showAddBatchIcon ? (
            openAddDay === item?._id ? (
              <div className='d-flex flex-column my-2 mx-2'>
                <InputNumber
                  min={1}
                  max={codeBatchDetails.days}
                  defaultValue={1}
                  onChange={handelDayChange}
                />
                <Button
                  className='mx-2'
                  onClick={() => handleAddIntoBatch(item?._id, batchDay)}
                >
                  Add
                </Button>
              </div>
            ) : (
              <i
                className='fas fa-plus'
                onClick={() => setOpenAddDay(item?._id)}
              />
            )
          ) : (
            <Link to={`/admin/dayscode/problem/${item?.id}`}>
              <i className='far fa-edit' />
            </Link>
          )}
          {showDeleteFromBatchIcon && (
            <i
              className='fas fa-trash'
              onClick={() => handleDeleteFromBatch(batchContentId)}
            />
          )}
          {checkAdmin(currentUser) && (
            <i
              className='far fa-trash red'
              onClick={() => deleteProblem(item?.id)}
            />
          )}
        </div>
      </div>
    </>
  );
};

ProblemListCard.defaultProps = {
  item: {},
  currentUser: {},
  deleteProblem: () => {},
  showAddBatchIcon: false,
  showDeleteFromBatchIcon: false,
  handleAddIntoBatch: () => {},
  handleDeleteFromBatch: () => {},
};

ProblemListCard.propTypes = {
  item: PropTypes.object,
  currentUser: PropTypes.object,
  deleteProblem: PropTypes.func,
  day: PropTypes.string,
  days: PropTypes.array,
  batchContentId: PropTypes.string,
  handleAddIntoBatch: PropTypes.func,
  handleDeleteFromBatch: PropTypes.func,
  showAddBatchIcon: PropTypes.bool,
  showDeleteFromBatchIcon: PropTypes.bool,
};

export default ProblemListCard;
