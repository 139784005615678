import React from "react";
import CustomLoader from "../../../../../shared_components/CustomLoader";

export const SharedEventLoadMoreButton = ({
  setPageNumber,
  pageNumber,
  loading,
}) => {
  return (
    <>
      {loading ? (
        <div className='text-center'>
          <CustomLoader />
        </div>
      ) : (
        <div className='row m-0 mt-5'>
          <div className='col-12 text-center'>
            <div
              className='pointer text-center m-3 m-md-0'
              onClick={() => setPageNumber(pageNumber + 1)}
            >
              <span className='text-nowrap'>
                <i className='far fa-plus mr-2' />
                Load More
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
