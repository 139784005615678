import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";

import { getColumns } from "./helpers";

const EventQuestions = ({ eventDetails }) => {
  let columns = getColumns();

  const [questionsData, setQuestionsData] = useState();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (eventDetails) {
      console.log("eventDetails", eventDetails);
      setQuestionsData(eventDetails.questions);
    }
  });
  return (
    <>
      <h1>Question Details</h1>
      {questionsData && questionsData.length && (
        <div className='table table-responsive'>
          <>
            <h1>Table</h1>
            <BootstrapTable
              keyField='id'
              bordered={true}
              data={questionsData}
              columns={columns}
              hover={true}
            />
          </>
        </div>
      )}
    </>
  );
};

export default EventQuestions;
