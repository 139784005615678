import React from "react";

import moment from "moment";
import { useEffect, useState } from "react";
import {
  FaCalendarAlt,
  FaCog,
  FaEye,
  FaHeart,
  FaRegHeart,
  FaShareAlt,
} from "react-icons/fa";

const BlogListCard = ({ lesson, items, selectedBLog }) => {
  const [formatDate, setFormatDate] = useState();
  const [isLiked, setIsLiked] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const date = moment(lesson?.updatedAt);
    setFormatDate(date.format("MMMM Do YYYY"));
    console.log(lesson, "lesson");
  }, [lesson]);
  return (
    // <div>
    //   <div className='blog-card-body p-4'>
    //     <article className='dv'>
    //       <div className='blog-content'>
    //         <div className='blog-a'>
    //           <div className='b'>
    //             <img
    //               className='imge'
    //               src={
    //                 "https://miro.medium.com/v2/resize:fit:679/0*ccCr8F6SL9sdxsfp.png"
    //               }
    //               alt=''
    //             />
    //           </div>
    //           <div className='c'>
    //             <div className='d'>
    //               <div className='author'>
    //                 <div className='i'>
    //                   <div className='d-flex'>
    //                     <img
    //                       className='j mr-2 border'
    //                       src={
    //                         lesson.author?.imgUrl ||
    //                         "https://miro.medium.com/v2/resize:fill:20:20/1*abnkL8PKTea5iO2Cm5H-Zg.png"
    //                       }
    //                       alt=''
    //                     />
    //                     <div className='blog-name'>
    //                       {lesson.author
    //                         ? lesson.author?.firstName +
    //                           " " +
    //                           lesson.author?.lastName
    //                         : "Shawn Michael"}
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>
    //               <div className='blog-desc'>
    //                 <div>
    //                   <h2 className='blog-title'>
    //                     {lesson.name.length > 30
    //                       ? lesson.name.slice(0, 29) + "..."
    //                       : lesson.name ||
    //                         "Nobody Wants To Work With Our be..."}
    //                   </h2>
    //                 </div>
    //                 <div className='pt-3 '>
    //                   <h3 className='sub-head'>Kindness Is Underrated</h3>
    //                 </div>
    //               </div>
    //               <span className='blog-card-footer'>
    //                 <div className='l'>
    //                   <div className='heart'>
    //                     <svg
    //                       aria-label='Unlike'
    //                       className='x1lliihq x1n2onr6 xxk16z8'
    //                       fill='red'
    //                       height='20'
    //                       role='img'
    //                       viewBox='0 0 48 48'
    //                       width='20'
    //                     >
    //                       <title>Unlike</title>
    //                       <path d='M34.6 3.1c-4.5 0-7.9 1.8-10.6 5.6-2.7-3.7-6.1-5.5-10.6-5.5C6 3.1 0 9.6 0 17.6c0 7.3 5.4 12 10.6 16.5.6.5 1.3 1.1 1.9 1.7l2.3 2c4.4 3.9 6.6 5.9 7.6 6.5.5.3 1.1.5 1.6.5s1.1-.2 1.6-.5c1-.6 2.8-2.2 7.8-6.8l2-1.8c.7-.6 1.3-1.2 2-1.7C42.7 29.6 48 25 48 17.6c0-8-6-14.5-13.4-14.5z'></path>
    //                     </svg>
    //                   </div>
    //                   <div>
    //                     <svg
    //                       aria-label='Share Post'
    //                       className='x1lliihq x1n2onr6 x5n08af'
    //                       fill='currentColor'
    //                       height='20'
    //                       role='img'
    //                       viewBox='0 0 24 24'
    //                       width='20'
    //                     >
    //                       <title>Share Post</title>
    //                       <line
    //                         fill='none'
    //                         stroke='currentColor'
    //                         strokeLinejoin='round'
    //                         strokeWidth='2'
    //                         x1='22'
    //                         x2='9.218'
    //                         y1='3'
    //                         y2='10.083'
    //                       ></line>
    //                       <polygon
    //                         fill='none'
    //                         points='11.698 20.334 22 3.001 2 3.001 9.218 10.084 11.698 20.334'
    //                         stroke='currentColor'
    //                         strokeLinejoin='round'
    //                         strokeWidth='2'
    //                       ></polygon>
    //                     </svg>
    //                   </div>

    //                   <div className='date ml-2 share'>
    //                     {" "}
    //                     <svg
    //                       width='18'
    //                       height='18'
    //                       viewBox='0 0 64 64'
    //                       fill='none'
    //                     >
    //                       <path
    //                         d='M39.64 40.83L33.87 56.7a1.99 1.99 0 0 1-3.74 0l-5.77-15.87a2.02 2.02 0 0 0-1.2-1.2L7.3 33.88a1.99 1.99 0 0 1 0-3.74l15.87-5.77a2.02 2.02 0 0 0 1.2-1.2L30.12 7.3a1.99 1.99 0 0 1 3.74 0l5.77 15.87a2.02 2.02 0 0 0 1.2 1.2l15.86 5.76a1.99 1.99 0 0 1 0 3.74l-15.87 5.77a2.02 2.02 0 0 0-1.2 1.2z'
    //                         fill='#FFC017'
    //                       ></path>
    //                     </svg>{" "}
    //                     <span className='fs-4'>{formatDate}</span>
    //                   </div>
    //                 </div>
    //                 <div className='m'>
    //                   <Dropdown
    //                     menu={{ items }}
    //                     trigger={"click"}
    //                     placement='top'
    //                   >
    //                     <div
    //                       style={{
    //                         backgroundColour: "white",
    //                         height: "25px",
    //                         border: "none",
    //                       }}
    //                       className='btn drop'
    //                       onClick={(e) => {
    //                         e.stopPropagation();

    //                         selectedBLog.current = lesson._id;
    //                       }}
    //                     >
    //                       <FaEllipsisV />
    //                     </div>
    //                   </Dropdown>
    //                 </div>
    //               </span>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </article>
    //   </div>
    // </div>

    <div>
      <div className='card h-100 blog-card shadow p-0'>
        <div className='blog-hover'>
          <img
            src={
              lesson.thumbnail ||
              "https://media.licdn.com/dms/image/v2/C5612AQEr3WmxhGSbxg/article-cover_image-shrink_720_1280/article-cover_image-shrink_720_1280/0/1644862734630?e=2147483647&v=beta&t=M5AxxQvXksyGDUNYiIyZ7G0zAhfqdmYgrlQhMdTkLfI"
            }
            className='card-img-top'
            alt={"alt"}
          />
          <div className='card-body px-2 pt-2 '>
            <div className='d-flex justify-content-between align-items-center mb-1'>
              <span className='badge bg-primary'>
                {lesson.category || "Development"}
              </span>
              <span className='text-muted d-flex align-items-center mr-2'>
                <FaEye className='mr-1' />
                {12000}
              </span>
            </div>
            <h5 className='card-title '>
              {lesson.name.length > 24
                ? lesson.name.slice(0, 23) + "..."
                : lesson.name || "Nobody Wants To Wor..."}
            </h5>
            <p className='card-text text-muted'>
              {lesson.description?.length > 0
                ? lesson.description.slice(0, 115) + "..."
                : "Lorem ipsum dolor sit amet consectetur modi illo vel, itaque praesentium eos nihil, minima inventore labore mol..."}
            </p>
          </div>
          <div className='py-0 px-2 bg-transparent border-top-0'>
            <div className='d-flex justify-content-between align-items-center'>
              <small className='text-muted d-flex align-items-center'>
                {/* <FaUser className='mr-1' /> */}
                <img
                  className='j border mr-2 rounded-pill'
                  height={30}
                  width={30}
                  src={
                    lesson.author?.imgUrl ||
                    "https://miro.medium.com/v2/resize:fill:20:20/1*abnkL8PKTea5iO2Cm5H-Zg.png"
                  }
                  alt=''
                />
                {lesson.author
                  ? lesson.author?.firstName + " " + lesson.author?.lastName
                  : "Divy Attarde"}
              </small>
              {/* <small className='text-muted d-flex align-items-center'>
              <FaCalendarAlt className='mr-1' />
              {new Date(lesson?.date).toLocaleDateString()}
              {formatDate}
              </small> */}
            </div>
          </div>
          <hr className='my-2' />
          <div className='d-flex justify-content-between align-items-center card--footer'>
            <div className='d-flex gap-3 px-2'>
              <button
                className={`btn btn-link p-0 ${
                  isLiked ? "text-danger" : "text-muted"
                }`}
                // onClick={() => onLike(blog.id)}
              >
                {isLiked ? <FaHeart /> : <FaRegHeart />}
                <span className='ml-1'>450</span>
              </button>
              <button
                className='btn  p-0 text-muted'
                // onClick={handleShare}
              >
                <FaShareAlt />
              </button>
            </div>
            <div>
              <button
                className='btn btn-link py-0 px-3 text-muted'
                // onClick={handleShare}
              >
                <FaCog />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogListCard;
