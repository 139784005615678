import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Combobox } from "react-widgets";
import { setSpeakersList } from "../../components/main_component/DaysCode/actions/actionCreators";
import { getSpeakers } from "../../components/main_component/DaysCode/actions/operations";
import { BatchMentorRole, technologyOptions } from "../../utils";

const SpeakerFilter = ({
  handleSelectedSpeaker,
  setOpenAddRole,
  openAddRole,
}) => {
  const dispatch = useDispatch();
  const [role, setRole] = useState("");

  const { speakersList } = useSelector((state) => state.dayscode) || {};

  const [filterQuery, setFilterQuery] = useState({
    search: "",
    technology: undefined,
  });

  const handleChangeFilterQuery = (event) => {
    const { name, value } = event.target;
    setFilterQuery({ ...filterQuery, [name]: value });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      if (
        filterQuery.search?.trim() === "" &&
        filterQuery.technology === undefined
      ) {
        dispatch(setSpeakersList({ Speakers: [] }));
      } else {
        dispatch(getSpeakers(filterQuery));
      }
    }, 800);

    return () => clearTimeout(debounceTimeout);
  }, [filterQuery, dispatch]);

  return (
    <>
      <div className='search-section'>
        <Link
          to={{
            pathname: "/speaker/new",
            search: "?type=guest",
          }}
        >
          <button type='button' className='add-guest'>
            Add Guest Speaker
          </button>
        </Link>
      </div>
      <div className='row mx-0'>
        <div className='col-12 px-0'>
          <div className='row mx-0'>
            <div className='col-12'>
              <label className='form-label'>Search</label>
              <input
                className='form-control'
                type='type'
                name='search'
                value={filterQuery.search}
                onChange={(e) => handleChangeFilterQuery(e)}
              />
            </div>
            <div className='col-12 mt-3'>
              <div className='text-left form-set'>
                <label className='form-label'>Technology</label>
                <Combobox
                  data={technologyOptions}
                  dataKey={"value"}
                  textField={"tech"}
                  placeholder={"Select Technology"}
                  value={filterQuery.technology}
                  onChange={(e) =>
                    handleChangeFilterQuery({
                      target: { name: "technology", value: e.value },
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className='col-12 px-0'>
          {
            <div className='row mx-0 event-speaker-search-table'>
              {speakersList &&
                speakersList.map((speaker) => (
                  <div className='col-12 row speaker-search-card '>
                    <h6 className='col-2 mb-0'>{speaker.name}</h6>
                    <p className='col-3 text-truncate'>{speaker.bio}</p>
                    <div className='col-5 mx-2'>
                      {speaker.technologies &&
                        speaker.technologies.map((item) => (
                          <span className='border badge bg-secondary text-white mx-2'>
                            {item.tech}
                          </span>
                        ))}
                    </div>

                    {openAddRole === speaker._id ? (
                      <div className='d-flex justify-content-between my-2'>
                        <Combobox
                          data={BatchMentorRole}
                          dataKey={"value"}
                          textField={"role"}
                          placeholder={"Select Role"}
                          value={role}
                          onChange={(e) => setRole(e.value)}
                        />
                        <Button
                          className='mx-2'
                          onClick={() =>
                            handleSelectedSpeaker(speaker._id, role)
                          }
                        >
                          Add
                        </Button>
                      </div>
                    ) : (
                      <FaPlus
                        className='add'
                        onClick={() => setOpenAddRole(speaker._id)}
                      />
                    )}
                  </div>
                ))}
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default SpeakerFilter;
