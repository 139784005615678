import React, { useEffect, useState } from "react";
import EventActionFormsSection from "../../EventUserAction/EventActionFormsSection";
// import EventOrganizerCard from "./EventOrganizerCard";
import VideoFrame from "../VideoFrame";
import Parser from "html-react-parser";
import { eventActionsController } from "../eventPageController";
// import EventSpeakers from "./EventSpeakers";
import EventVenueMap from "./EventVenueMap";
// import EventTimeline from "./EventTimeline";
// import EventPartners from "./EventPartners";
// import EventSponsors from "./EventSponsors";
// import CustomFAQ from "../../../../../../../shared_components/CustomFAQ";
// import { EventFAQ } from "../helpers";
// import Testimonial from "../../../../../../../shared_components/Testimonial";
// import EventGallary from "./EventGallary";
// import EventResource from "./EventResource";
import GetEventPageActionButton from "../GetEventPageActionButton";
import { Input } from "antd";
import { LuCopy } from "react-icons/lu";
import { RiCupFill } from "react-icons/ri";
// import Carousel from "react-multi-carousel";
import { useDispatch, useSelector } from "react-redux";
import { getOrganiserEvents } from "../../../../actions";
import moment from "moment";
import { IoShareSocialOutline } from "react-icons/io5";
import { BsPeople } from "react-icons/bs";
import Carousel from "react-multi-carousel";

const EventPageBody = ({
  eventDetails,
  showActionSection,
  checkUserEventReg,
  id,
  handleAttendance,
  handleEventType,
  userData,
}) => {
  const [eventActions, setEventActions] = useState({});
  const { email } = useSelector((state) => state?.user?.currentUser);
  const { upcomingEventsList } = useSelector((state) => state?.event);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const dispatch = useDispatch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    let actions = eventActionsController(eventDetails, userData);
    console.log(actions, "act");
    setEventActions(actions);
    dispatch(
      getOrganiserEvents({ page: 0, limit: 4, email, state: "upcoming" }),
    );
  }, []);

  return (
    <div className='event-default-bg'>
      <div className='row mx-0 px-md-5 login mb-3 my-0 main event-reg-section '>
        <div
          className={`${
            eventDetails.state === "live" ? "col-md-12" : "col-md-12"
          }
           px-0 px-md-5 left mt-md-0 mt-3`}
        >
          {eventDetails.state !== "upcoming" &&
            eventDetails &&
            eventDetails.youtube_code && (
              <div className='row d-flex justify-content-center m-0 '>
                <div className='col-12'>
                  <VideoFrame
                    videoId={eventDetails && eventDetails.youtube_code}
                  />
                </div>
              </div>
            )}
          {showActionSection && (
            <EventActionFormsSection
              handleAttendance={handleAttendance}
              eventDetails={eventDetails}
              id={id}
              checkUserEventReg={checkUserEventReg}
              userData={userData}
            />
          )}
        </div>
      </div>
      <div className='row justify-content-center  mx-md-5 mx-0 px-1 px-md-5 mb-3 '>
        <div className=' col-12 col-md-8 pl-md-0 pr-md-1 '>
          <div className=' my-0  card-schadow event-registration-block '>
            <h4>Registartion</h4>
            <p>To join this event, please register below.</p>
            <GetEventPageActionButton
              eventDetails={eventDetails}
              handleEventType={handleEventType}
              userData={userData}
              eventActions={eventActions}
            />
            {/* {state === 'upcoming' ?
                    <div className="mt-5">
                    <LinkScroll to="form" spy={true} smooth={true} onClick={() => handleEventType(event_type)}>
                    <button className="btn mb-5 join-us-button text-center card-schadow">
                    Join Virtual Session!
                    </button>
                    </LinkScroll>
                    </div> :
                    state === 'live' ?
                    <LinkScroll to="form" spy={true} smooth={true} onClick={() => handleEventType(event_type)}>
                    <button className="btn mb-5 join-us-button text-center card-schadow">
                    Join Live Session!
                    </button>
                    </LinkScroll>
                    :
                    <>
                        <LinkScroll to="form" spy={true} smooth={true} onClick={() => handleEventType(event_type)}>
                        <button className="btn mb-5 join-us-button text-center card-schadow">
                        Please Share Your Feedback!
                        </button>
                        </LinkScroll>
                        </>
                        } */}
          </div>
          <div className='col-12 d-block d-md-none px-0 mx-0 mt-2 mt-md-0 pr-md-0 pl-md-1'>
            <div className='event-share bg-white border rounded-corner card-schadow w-100'>
              <label htmlFor='share ' className=''>
                Share
              </label>
              <div className='d-flex align-items-center'>
                <Input
                  type='text'
                  readOnly
                  value={"https:/datacode.in/event/event-name"}
                />
                <LuCopy className='mx-3' />
              </div>
              <div className='event-social-icons mt-3'>
                <span>
                  <i className='fab fa-facebook-f' />
                </span>
                <span>
                  <i className='fab fa-instagram' />
                </span>
                <span>
                  <i className='fab fa-linkedin-in' />
                </span>
                <span>
                  <i className='fab fa-twitter' />
                </span>
              </div>
            </div>
            <div className='bg-white border rounded-corner card-schadow w-100 event-people mt-2'>
              <h4>People</h4>
              <h6 className='mt-3'>Hosts</h6>
              <span className=''>
                <img
                  src='/icon192.png'
                  alt='logo'
                  height={30}
                  width={30}
                  className='mx-2'
                />
                Datacode
              </span>
              <h6 className='mt-3'>Speakers</h6>
              <span className='d-flex flex-column'>
                {eventDetails?.speakers?.map((speaker, i) => {
                  return (
                    <span className='my-1'>
                      <img
                        src={speaker?.posterImage}
                        alt='logo'
                        height={30}
                        width={30}
                        className='mx-2'
                      />
                      {speaker?.speaker?.name}
                    </span>
                  );
                })}
              </span>
            </div>
          </div>
          <div className='px-md-3 mx-md-0 event-description rounded-corner  mt-2 border card-schadow bg-white'>
            <p className=''>
              <h5 className=''>About This Event</h5>
              <hr />
              {eventDetails && Parser(String(eventDetails.description))}
            </p>
          </div>
          <div className='pb-md-5 event-schedule mt-2 border rounded-corner card-schadow bg-white'>
            <h4>Schedule</h4>
            {eventDetails?.timelines?.map((timeline) => {
              return (
                <div>
                  {timeline?.title !== "break" ? (
                    <>
                      <span>
                        <i className='far fa-clock mr-2' />
                        {timeline?.start_time} -{timeline?.end_time}
                      </span>
                      <h5 className='mt-2'>{timeline?.title}</h5>
                      <p>{timeline?.description}</p>
                    </>
                  ) : (
                    ""
                  )}

                  {timeline.title === "break" && (
                    <>
                      <div className='hr' />
                      <h6>
                        <RiCupFill /> Short Break
                      </h6>
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className='col-11 col-md-4 d-none d-md-block px-0 mx-0 mt-2 mt-md-0 pr-md-0 pl-md-1'>
          <div className='event-share bg-white border rounded-corner card-schadow w-100'>
            <label htmlFor='share ' className=''>
              Share
            </label>
            <div className='d-flex align-items-center'>
              <Input
                type='text'
                readOnly
                value={"https:/datacode.in/event/event-name"}
              />
              <LuCopy className='mx-3' />
            </div>
            <div className='event-social-icons mt-3'>
              <span>
                <i className='fab fa-facebook-f' />
              </span>
              <span>
                <i className='fab fa-instagram' />
              </span>
              <span>
                <i className='fab fa-linkedin-in' />
              </span>
              <span>
                <i className='fab fa-twitter' />
              </span>
            </div>
          </div>
          <div className='bg-white border rounded-corner card-schadow w-100 event-people mt-2'>
            <h4>People</h4>
            <h6 className='mt-3'>Hosts</h6>
            <span className=''>
              <img
                src='/icon192.png'
                alt='logo'
                height={30}
                width={30}
                className='mx-2'
              />
              {eventDetails?.host?.firstName}
            </span>
            <h6 className='mt-3'>Speakers</h6>
            <span className='d-flex flex-column'>
              {eventDetails?.speakers?.map((speaker, i) => {
                return (
                  <span className='my-1'>
                    <img
                      src={speaker?.posterImage}
                      alt='logo'
                      height={30}
                      width={30}
                      className='mx-2'
                    />
                    {speaker?.speaker?.name}
                  </span>
                );
              })}
            </span>
          </div>
        </div>
      </div>
      <div className='row justify-content-center  mx-md-5 px-md-5 mb-3 upcoming-event mx-2 px-2 '>
        <div className='col-12 pl-0 pr-1 bg-white border card-schadow p-3 rounded-corner'>
          <h4>More Events from Datacode</h4>
          <div className='row '>
            <Carousel
              responsive={responsive}
              infinite={true}
              autoPlay={true}
              showDots={true}
              swipeable={true}
              draggable={true}
              slidesToShow={3}
              slidesToScroll={1}
              containerClass='carousel-container'
            >
              {upcomingEventsList.map((item) => {
                return (
                  <div className='col-12 my-2 '>
                    <div className=' border card-schadow card-content'>
                      <img
                        className='card-img-top '
                        src={item.eventImg}
                        alt='Card img cap'
                      />
                      <div className='card-body'>
                        <span>
                          {moment(item.start_date).format("ddd, MMMM D, YYYY")}
                        </span>
                        <h5 className='card-title my-1'>{item.title}</h5>
                        <span className='d-flex align-items-center'>
                          <span>
                            <BsPeople className='mr-2' size={16} />
                          </span>
                          <span>442 going</span>
                          <span>
                            <IoShareSocialOutline className='mx-2' size={16} />
                          </span>
                          <span> Share</span>
                        </span>
                        <button className='mt-2 bg-white '>RSPV</button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
      </div>
      <EventVenueMap />

      {/* <div className='my-2 mx-5'>
        {eventDetails?.speakers?.length ? (
          <EventSpeakers eventDetails={eventDetails} />
        ) : (
          ""
        )}
      </div>
      <div className=' mx-md-5'>
        {eventDetails?.timelines?.length ? (
          <EventTimeline eventDetails={eventDetails} />
        ) : (
          ""
        )}
      </div>
      <div className=''>
        {eventDetails?.partners?.length === 0 ? (
          <EventPartners partners={eventDetails.partners} />
        ) : (
          ""
        )}
      </div>
      <div className='my-5'>
        <EventResource eventDetails={eventDetails} />
      </div>
      <div className='my-5'>
        <EventGallary eventDetails={eventDetails} />
      </div>
      <div className='my-5'>
        {eventDetails?.sponsors?.length ? (
          <EventSponsors sponsors={eventDetails.sponsors} />
        ) : (
          ""
        )}
      </div>

      <div className='my-5'>
        {eventDetails?.testimonials?.length ? (
          <Testimonial
            testimonials={eventDetails.testimonials}
            heading={"What our community members saying"}
          />
        ) : (
          ""
        )}
      </div> */}
      {/* <div className='my-5'>
        <h3 className='text-center px-3 px-md-0 my-5'>Event Host</h3>
        <EventOrganizerCard />
      </div>
      <div className='row mx-0 d-flex justify-content-center'>
        <div className='col-md-10 col-12'>
          <CustomFAQ FAQList={EventFAQ} />
        </div>
      </div> */}
    </div>
  );
};
export default EventPageBody;
