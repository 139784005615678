import { connect } from "react-redux";
import {
  getProblems,
  getAllSubmissions,
  approveUserCodeSubmission,
  deleteUserSubmission,
  getDaysUsersListOptions,
  getUserSubmissions,
  getUserSubmissionsByStatus,
  getAllUsersProgressByMentor,
} from "../../../../actions";

import SubmissionsList from "./SubmissionsList";

const mapStateToProps = ({ dayscode, user }) => ({
  currentUser: user.currentUser ? user.currentUser : {},
  problemsListLoading: dayscode.problemsListLoading,
  problemsList: dayscode.problemsList,
  allSubmissions: dayscode.allSubmissions,
  problemDetailsLoading: dayscode.problemDetailsLoading,
  submissions: dayscode.submissions,
  daysUsers: dayscode.daysUsers,
  getUserSubmissionsLoading: dayscode.getUserSubmissionsLoading,
  getAllUsersProgressByMentorLoading: dayscode.getAllUsersProgressByMentorLoading,
});

const mapDispatchToProps = {
  getProblems,
  getAllSubmissions,
  approveUserCodeSubmission,
  deleteUserSubmission,
  getDaysUsersListOptions,
  getUserSubmissions,
  getUserSubmissionsByStatus,
  getAllUsersProgressByMentor,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionsList);
