import React from "react";

const offerCards = [
  {
    title: "Gamified Courses",
    content:
      "We help you develop creative ideas so that your business can grow more rapidly",
    link: "",
    image:
      "http://res.cloudinary.com/datacode/image/upload/v1687996277/i4udfgyqmphbebaquhbp.png",
  },
  {
    title: "Live Classes",
    content:
      "We provide services with the best designs than our designer team for your business",
    link: "",
    image:
      "http://res.cloudinary.com/datacode/image/upload/v1688244498/p3ocr5hmlegepq0dpma4.png",
  },
  {
    title: "Workshops",
    content:
      "We help develop company websites to be more professional and attractive",
    link: "",
    image:
      "http://res.cloudinary.com/datacode/image/upload/v1687996277/i4udfgyqmphbebaquhbp.png",
  },
  {
    title: "Code Challenges",
    content:
      "We help develop company mobile apps to be more professional and attractive",
    link: "",
    image:
      "http://res.cloudinary.com/datacode/image/upload/v1687996277/i4udfgyqmphbebaquhbp.png",
  },
  {
    title: "Masterclasses",
    content:
      "We also provide services by providing input for your business growth",
    link: "",
    image:
      "http://res.cloudinary.com/datacode/image/upload/v1687996277/i4udfgyqmphbebaquhbp.png",
  },
  {
    title: "OpenSource Cont.",
    content:
      "We also help you market your products through an online marketplace",
    link: "",
    image:
      "http://res.cloudinary.com/datacode/image/upload/v1687996277/i4udfgyqmphbebaquhbp.png",
  },
];

const OffersSection = () => {
  return (
    <>
      <div className='row mx-0 my-5 d-flex justify-content-center academy-offer-section'>
        <div className='col-md-10 col-12'>
          <div className='row mx-0'>
            <div className='col-12 text-center'>
              <h1>What Datacode Academy Offers you</h1>
            </div>
          </div>
          <div className='row mx-0'>
            <div className='col-12 text-center offer-card-block'>
              <h1>
                The various Offerings which make your Lrearning More efficient
              </h1>
              <div className='row mx-0'>
                {offerCards &&
                  offerCards.map((item, i) => (
                    <div
                      key={i}
                      className='col-12 col-md-4 p-md-5 p-2 d-flex align-items-stretch'
                    >
                      <div className='offer-card shadow'>
                        <img
                          className='rounded-circle'
                          height='150'
                          width='150'
                          loading='lazy'
                          src={item.image}
                          alt='avatar'
                        />
                        <div className=''>
                          <h6>{item.title}</h6>
                          <span>{item.content}</span>
                        </div>
                        <div className='text-center d-flex justify-content-center my-3'>
                          <i className='fa fa-arrow-right round-btn shadow' />
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OffersSection;
