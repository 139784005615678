import React, { useEffect } from "react";
import AOS from "aos";
const JoinUs = () => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <div className="row mx-0 JoinUs " data-aos="zoom-in">
        <div className="col-12 px-0 py-5 align-items-center justify-content-center">
          <div className="text-center d-flex flex-column">
            <h1 className="pt-5">Ready to Join Us?</h1>
            <h6 className="py-3">
              Click the button below to start your career journey with us!
            </h6>
            <div>
              <button className="btn btn-primary px-5">Apply Now</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default JoinUs;
