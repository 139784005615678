import React, { useEffect, useState } from "react";
import LayoutContainer from "../LayoutContainer";
import { getOptions } from "../../../../../../utils";
import { batchStatusOptions } from "../helpers";
import { Field, getFormValues, reduxForm } from "redux-form";
import { required } from "../../../../../../utils/validators";
import { RenderInputField } from "../../../../../../shared_components/ReduxFormFields";
import { Combobox } from "react-widgets";
import { useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import {
  createCodeBatch,
  editCodeBatch,
  getCodeBatchDetails,
} from "../../../actions";
import { useSelector } from "react-redux";
import moment from "moment";

const CreateCodeBatch = ({ reset, handleSubmit, submitting, initialize }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const [batchStatus, setBatchStatus] = useState();
  const [isEdit, setEdit] = useState(false);
  const [batchDetails, setBatchDetails] = useState();

  const formStates =
    useSelector((state) => getFormValues("create-batch")(state)) || {};

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (id !== "new") {
      dispatch(getCodeBatchDetails(id)).then((res) => {
        if (res && res.success && res.batch) {
          const data = res.batch;
          data["status"] = res.batch && res.batch.status;
          initialize(data);
          setBatchStatus(res.batch.status);
          setBatchDetails(res.batch);
          // getInputText(res.data.input)
          setEdit(true);
        }
      });
    }
  }, []);

  const onSubmit = (values) => {
    const data = {
      ...values,
      status: batchStatus?.value || "draft",
    };
    if (isEdit) {
      dispatch(editCodeBatch(data)).then((res) => {
        if (res.success) {
          history.push(`/admin/dayscode/batch_dashboard/${res.batch._id}`);
        }
      });
    } else {
      dispatch(createCodeBatch(data));
    }
  };

  const calculateEndDate = (days, startDate) => {
    if (!startDate) {
      console.error("Invalid start date");
      return null;
    }

    // Calculate the end date
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + Number(days));
    const formatted = moment(endDate).format("YYYY-MM-DD");
    // Return the end date in a readable format
    initialize({ ...formStates, end_date: formatted });
  };

  return (
    <>
      <LayoutContainer>
        <div className='row mx-0 solution-nav'>
          <div className='col-md-8 col-6 mx-0 d-flex p-2 align-items-center'>
            <h4 className='py-md-3 py-2 mb-0'>
              {batchDetails?.title || `Create New Batch`}
            </h4>
          </div>
          <div className='col-md-4 col-6 d-flex justify-content-end align-items-center'>
            <button
              onClick={() => history.push("/admin/dayscode/batches")}
              type='button'
              className='btn enroll-small-btn'
            >
              <small>Batch List</small>
            </button>
          </div>
        </div>
        <div className='row mx-0'>
          <div className='col-12'>
            <form
              className='py-3 px-md-5 px-1'
              onSubmit={handleSubmit(onSubmit)}
            >
              <small>
                Lorazepam, sold under the brand name Ativan among others, is a
                benzodiazepine medication. It is used to treat anxiety
                disorders, trouble sleeping, severe agitation, active seizures
                including status epilepticus, alcohol withdrawal, and
                chemotherapy-induced nausea and vomiting
              </small>
              <Field
                type='text'
                name='title'
                label='Batch Title'
                placeholder=''
                component={RenderInputField}
                validate={[required]}
              />
              <div className='row m-0 border rounded-lg my-2 p-md-3 p-0'>
                <div className='col-md-6 col-12'>
                  <div className='row mx-0'>
                    <div className='col-md-6 col-12 pl-md-0 pr-md-2 '>
                      <Field
                        type='text'
                        name='level'
                        label='level'
                        placeholder=''
                        component={RenderInputField}
                        validate={[required]}
                      />
                    </div>
                    <div className='col-md-4 col-12 pl-md-2 pr-md-0'>
                      <Field
                        type='number'
                        name='days'
                        label='Batch Days'
                        placeholder=''
                        component={RenderInputField}
                        validate={[required]}
                        onBlur={() =>
                          calculateEndDate(
                            formStates.days,
                            formStates.start_date,
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className='col-md-6 col-12 mt-3'>
                  <label className='mr-4 form-label'>Status:</label>
                  <div className='mb-2'>
                    <Combobox
                      data={getOptions(batchStatusOptions, "status")}
                      dataKey={"value"}
                      textField='status'
                      placeholder={"Batch Status"}
                      value={batchStatus}
                      onChange={(value) => setBatchStatus(value)}
                    />
                  </div>
                  <div className='row'>
                    <div className='col-6'>
                      <Field
                        type='date'
                        name='start_date'
                        label='Batch Start Date'
                        placeholder=''
                        component={RenderInputField}
                        validate={[required]}
                        onBlur={() =>
                          calculateEndDate(
                            formStates.days,
                            formStates.start_date,
                          )
                        }
                      />
                    </div>
                    <div className='col-6'>
                      <Field
                        type='date'
                        name='end_date'
                        label={`Batch End Date`}
                        placeholder=''
                        component={RenderInputField}
                        validate={[required]}
                        isDisabled={formStates.start_date && true}
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="my-3">
                  <label className="form-label text-left pt-5">Constrains {`(${constrainsText.length - 8})`}</label>
                  <div className="mb-3 border ">
                    {(isEdit && problemDetails && problemDetails.constrains) &&
                      <TextEditor handleTextEditor={handleConstrainsText} text={problemDetails?.constrains ? constrainsText : ''} />
                    }
                    {(!isEdit) &&
                      <TextEditor handleTextEditor={handleConstrainsText} text={problemDetails?.constrains ? constrainsText : ''} />
                    }
                  </div>
                </div> */}
                <div className='row my-4'>
                  <div className='col-12 text-right'>
                    <button
                      type='submit'
                      className='btn custom-button'
                      disabled={submitting}
                    >
                      <span>{isEdit ? "Save" : "Create New Batch"}</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </LayoutContainer>
    </>
  );
};

export default reduxForm({
  form: "create-batch",
  fields: ["days"],
})(CreateCodeBatch);
