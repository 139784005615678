import React from "react";

const HeroSection = () => {
  return (
    <div className="row mx-0 justify-content-center pt-lg-5 mt-5">
      <div className="col-10 px-0">
        <div className="row mx-0 herosectionn">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 d-flex align-items-center">
            <div>
              <h1 class=" title pt-5 pt-lg-2">Internship Program</h1>
              <div className="quote pt-lg-2">
                <i className="fas fa-quote-left text-left" />
              </div>
              <h5 class="para py-3">"Step In, Skill Up, Stand Out"</h5>
              <div className="button">
                <button type="button" className="apply-now px-5 py-2 ml-lg-5">
                  Apply Now
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 justify-content-center pt-3 pt-lg-0">
            {/* <img
              src={require("../../../../../assets/images/internship/img.png")}
              className="mx-auto heroimg"
            /> */}
            <div className="side-image">
              <img
                className="activity-img img-fluid"
                loading="lazy"
                src={`http://res.cloudinary.com/datacode/image/upload/v1687763436/vr2hbjnhli7y3iewspc4.png`}
                alt="avat ar"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HeroSection;
