import React, { Component } from "react";
import RightSideSection from "../../../right_section/RightSideSection";
import PracticeQuestionCardCarousel from "./PracticeQuestionCardCarousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PostCard from "../../PostCard";
import { Link } from "react-router-dom";
import { faHome, faCode } from "@fortawesome/free-solid-svg-icons";
import Technologies from "../../../right_section/Technologies";
import { Helmet } from "react-helmet";

class IndexPracticeQuestions extends Component {
  state = {};
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const description =
      "Things can be stolen, Talent can be stolen,but the practice which decides learning are always yours,let’s wave your hands with (“Hello World”);!";
    const title = "Practice Programming Questions | Datacode.in";
    return (
      <>
        <Helmet>
          <title>{title}</title>
          <meta
            data-react-helmet='true'
            name='description'
            content={description}
          ></meta>
          <meta
            data-react-helmet='true'
            http-equiv='cleartype'
            content='on'
          ></meta>
          <meta
            data-react-helmet='true'
            name='apple-mobile-web-app-capable'
            content='yes'
          ></meta>
          <meta
            data-react-helmet='true'
            name='robots'
            content='max-snippet:-1, max-image-preview:large, max-video-preview:-1'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:locale'
            content='en_US'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:type'
            content='website'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:title'
            content={title}
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:description'
            content={description}
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:url'
            content='https://www.datacode.in/'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:site_name'
            content='Datacode.in'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:image'
            content='https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:image:secure_url'
            content='https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:image:width'
            content='400'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:image:height'
            content='400'
          ></meta>
          <meta
            data-react-helmet='true'
            name='keywords'
            content='programming,coding,online, code online, online judge, practice, run code, test, expected outcome, programming competition, programming contest, online programming, online computer programming'
          ></meta>
          <meta
            data-react-helmet='true'
            name='twitter:card'
            content='summary_large_image'
          ></meta>
          <meta
            data-react-helmet='true'
            name='twitter:description'
            content={description}
          ></meta>
          <meta
            data-react-helmet='true'
            name='twitter:title'
            content={title}
          ></meta>
          <meta
            data-react-helmet='true'
            name='twitter:site'
            content='@datacode_in'
          ></meta>
          <meta
            data-react-helmet='true'
            name='twitter:creator'
            content='@datacode_in'
          ></meta>
          <meta
            data-react-helmet='true'
            name='twitter:image'
            content='https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png'
          ></meta>
          <link
            data-react-helmet='true'
            rel='shortlink'
            href='https://www.datacodoe.in/'
          ></link>
          <link
            data-react-helmet='true'
            rel='dns-prefetch'
            href='//www.datacode.in'
          ></link>
          <link
            data-react-helmet='true'
            rel='dns-prefetch'
            href='//s.w.org'
          ></link>
          <link
            data-react-helmet='true'
            rel='alternate'
            type='application/rss+xml'
            title='Datacode'
            href='https://www.datacode.in/'
          ></link>
          <link
            data-react-helmet='true'
            rel='canonical'
            href='https://www.datacode.in/'
          ></link>
        </Helmet>
        <div className='row m-0 px-3'>
          <div className='col-1'></div>
          <div className='col-md-8 mt-4'>
            <h1
              className='text-center mb-4 rounded p-2 '
              style={{ backgroundColor: "#30006b", color: "#ffffff" }}
            >
              <FontAwesomeIcon icon={faCode} color='#072C71' /> Programming
              Practice Questions
            </h1>

            <div className='mb-3'>
              <Link
                to='/home'
                className='badge mr-1'
                style={{ backgroundColor: "#06CB6C" }}
              >
                <FontAwesomeIcon icon={faHome} size='2x' color='#072C71' />
              </Link>
            </div>

            <div className='card-deck m-2'>
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731169465/j3cfvuutdkcjuclamkne.jpg'
                title='C Programming Practice Questions'
                paragraph='We Love to Code and this is the best'
                link='/index_c_practice_questions'
              />
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731169492/m9mg1d4o9p390nzkjayk.jpg'
                title='Python Programming Practice Questions'
                paragraph='We Love to Code and this is the best '
                link='/index_python_practice_questions'
              />
            </div>
            <div className='card-deck m-2'>
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731169539/nrg6oo7qnr1p73geieb4.jpg'
                title='Java Programming Practice Questions'
                paragraph='We Love to Code and this is the best '
                link='/index_java_practice_questions'
              />
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731169560/ess37qsi8yc1rpwufhad.jpg'
                title='Datastructre Programming Practice Questions'
                paragraph='We Love to Code and this is the best way to express our
                  love to Computer Science Wolrd.'
                link='/index_ds_practice_questions'
              />
            </div>

            <h5 className='text-secondary mt-2 mb-3'>
              For More Programming Practice Questions
            </h5>
            <PracticeQuestionCardCarousel />
          </div>

          <div className='col-md-3'>
            <RightSideSection />
            <Technologies />
          </div>
        </div>
      </>
    );
  }
}

export default IndexPracticeQuestions;
