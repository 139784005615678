import exportFromJSON from "export-from-json";
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";

import { getColumns } from "./helpers";

const RegisterList = ({ getEventDetails, eventDetails }) => {
  let columns = getColumns();

  const [registerData, setRegisterData] = useState();

  const fileName = eventDetails && eventDetails.title;
  const exportType = exportFromJSON.types.xls;
  const data = registerData;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // getEventDetails(20).then((res) => {
    //   if (res) {
    //     setRegisterData(res.data && res.data.registrations)
    //     console.log("res list", res.data)
    //   }
    // })
    setRegisterData(eventDetails && eventDetails.registrations);
  }, []);

  const downloadExel = () => {
    exportFromJSON({ data, fileName, exportType });
  };

  return (
    <>
      <h1 className='m-4'>Event Register List</h1>
      <div className='mb-2 d-flex align-items-center'>
        <h6 className='mx-4'>{`Total Registration : ${
          registerData && registerData.length
        }`}</h6>
        <button className='btn btn-secondary' onClick={() => downloadExel()}>
          Download File
        </button>
      </div>
      {registerData && registerData.length && (
        <div className='table table-responsive'>
          <BootstrapTable
            keyField='id'
            bordered={false}
            data={registerData}
            columns={columns}
          />
        </div>
      )}
    </>
  );
};

export default RegisterList;
