import { Button, Drawer, Space } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Combobox } from "react-widgets";
import { addQuizIntoBatch, getQuizChallenges } from "../../../actions";
import { DSAContent } from "../../DaysCodeDashboard/DaysCodeTimeLine/helper";
import { getTopicOptions } from "../../utils";
import ProblemListCard from "../Problems/ProblemsList/ProblemListCard";

const AddBatchChallengeDrawer = ({ batch }) => {
  const dispatch = useDispatch();
  const { quizChallengesList } = useSelector((state) => state.dayscode) || {};

  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("right");
  const [topic, setTopic] = useState();
  const [openAddDay, setOpenAddDay] = useState("");

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    setOpenAddDay("");
  };

  const handleQuizTopic = (topic) => {
    setTopic(topic.name);
    dispatch(getQuizChallenges({ status: "public", topic: topic.name }));
  };

  const handleAddQuizIntoBatch = (quiz, day) => {
    dispatch(addQuizIntoBatch({ batch: batch, quizDetails: quiz, day: day }));
  };

  return (
    <>
      <Space>
        <Button type='primary' onClick={showDrawer}>
          Add Quiz Challenge
        </Button>
      </Space>
      <Drawer
        title='Add Quiz Challenge Into Batch'
        placement={placement}
        closable={false}
        onClose={onClose}
        open={open}
        key={placement}
      >
        <h1>Add Quiz Challenge</h1>
        <Combobox
          data={getTopicOptions(DSAContent)}
          dataKey={"value"}
          textField='name'
          placeholder={"Select Topic Name"}
          value={topic}
          onChange={(value) => handleQuizTopic(value)}
        />
        {quizChallengesList &&
          quizChallengesList.map((quiz) => (
            <ProblemListCard
              handleAddIntoBatch={handleAddQuizIntoBatch}
              showAddBatchIcon={true}
              item={quiz}
              openAddDay={openAddDay}
              setOpenAddDay={setOpenAddDay}
            />
          ))}
      </Drawer>
    </>
  );
};

export default AddBatchChallengeDrawer;
