import React from "react";
import { Field } from 'redux-form'
import { RenderInputField, renderRadioField } from '../../../../../../../shared_components/ReduxFormFields'
import { required } from '../../../../../../../utils/validators'

const ApplicationStep2 = () => {
  return (
    <>
      <div className="row mx-0">
        <div className="col-12 col-md-4">
          <Field
            type="text"
            name="university"
            label="University / Institution"
            placeholder="ex: university"
            component={RenderInputField}
            validate={[required]}
          />
        </div>
        <div className="col-12 col-md-4">
          <Field
            type="text"
            name="degree"
            label="Degree Program"
            placeholder="ex: Computer Science Engineering"
            component={RenderInputField}
            validate={[required]}
          />
        </div>
        <div className="col-12 col-md-4">
          <Field
            type="text"
            name="graduation_year"
            label="Graduation Year"
            placeholder="ex: 2025"
            component={RenderInputField}
            validate={[required]}
          />
        </div>
      </div>
      <div className="row mx-0 my-4">
        <div className="col-12">
          <label className="form-label">Year of Study</label>
          <div className="my-2">
            <Field
              name='year'
              component={renderRadioField}
              value="1"
              label='1st Year'
              type="radio"
              validate={[required]}
            />
          </div>
          <div className="my-2">
            <Field
              name='year'
              component={renderRadioField}
              value="2"
              label='2nd Year'
              type="radio"
              validate={[required]}
            />
          </div>
          <div className="my-2">
            <Field
              name='year'
              component={renderRadioField}
              value="3"
              label='3rd Year'
              type="radio"
              validate={[required]}
            />
          </div>
          <div className="my-2">
            <Field
              name='year'
              component={renderRadioField}
              value="4"
              label='Final Year'
              type="radio"
              validate={[required]}
            />
          </div>
          <div className="my-2">
            <Field
              name='year'
              component={renderRadioField}
              value="graduated"
              label='Graduated'
              type="radio"
              validate={[required]}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default ApplicationStep2