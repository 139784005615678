import React from "react";

const EventVenueMap = () => {
  return (
    <>
      <div className='row justify-content-center  mx-md-5 px-md-5 mb-3 upcoming-event mx-2 px-2 '>
        <div className='col-12 pl-0 pr-1 bg-white border card-schadow p-3 rounded-corner'>
          <h4>Event Location</h4>
          <div className='event-map'>
            <iframe
              title='location'
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3260.434575892877!2d75.76881505494953!3d23.181155197459887!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fd09b912fd2f%3A0xb647b34c69dc300d!2sMahakal%20Mandir!5e0!3m2!1sen!2sin!4v1726929167580!5m2!1sen!2sin'
              width='600'
              height='350'
              allowFullScreen
              loading='lazy'
              referrerPolicy='no-referrer-when-downgrade'
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventVenueMap;
