import PropTypes from "prop-types";
import React, { Component } from "react";
import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../assets/css/Carousel.css";

class CarouselComponent extends Component {
  state = {};
  render() {
    return (
      <>
        <Carousel
          nextIcon=''
          nextLabel=''
          prevIcon=''
          prevLabel=''
          indicators={false}
          id='carousel'
          className='p-0 mt-0'
        >
          <Carousel.Item>
            <div className='row carousel-card pt-2 pb-3 pb-0 px-md-5 px-2 text-center'>
              <div className='col-12 col-md-6'>
                <div className='text-left px-3 px-md-0'>
                  <h1 className='mt-3'>
                    {`Welcome ${
                      this.props.name && this.props.name !== null
                        ? `back, ${
                            this.props.name !== null
                              ? this.props.name
                              : "Learner"
                          }`
                        : "Learner"
                    } `}
                  </h1>
                  <h3 className='heading-second'>
                    To The Awesome Learning Community
                  </h3>
                </div>
                <div className='px-0 qoute text-left'>
                  <span className='quote-icon'>
                    <i className='fas fa-quote-left' />
                  </span>
                  <h6>
                    Speed doesn’t matter when progress start working, let’s
                    emphasize on learning and reframe the world with magical
                    power
                  </h6>
                  <i>- Every Programmer's Words</i>
                </div>
                {this.props.name ? (
                  <Link to='/index_technologies'>
                    <button className='btn get-started-button shadow-lg my-5 my-md-5'>
                      <i className='fa fa-search search-icon' /> Find Courses
                    </button>
                  </Link>
                ) : (
                  <Link to='/signup'>
                    <button className='btn get-started-button shadow-lg my-5 my-md-5'>
                      Let’s explore
                    </button>
                  </Link>
                )}
              </div>
              <div className='col-12 px-0 col-md-6 text-right'>
                <img
                  height='500px'
                  width='500px'
                  src={require(`../../assets/images/svg/landing-illustrate.png`)}
                  alt='avatar'
                  loading='lazy'
                  className='img-fluid mx-auto p-md-0 p-3'
                />
              </div>
            </div>
          </Carousel.Item>
          {/* <Carousel.Item>
            <div className="card text-center border border-white">
              <img
                className=" card-img d-block w-100"
                src={require("../../assets/images/datacodeheader1.jpeg")}
                alt="First slide"
              />
            </div>
          </Carousel.Item> */}
          {/* <Carousel.Item>
            <div className="card text-center border border-white">
              <img
                className=" card-img d-block w-100"
                src={require("../../assets/images/datacodeheader2.jpeg")}
                alt="First slide"
              />

              <div className="card-img-overlay d-none d-md-block text-right text-align-center ml-5 mr-1 pl-5 mr-1 mt-5 pr-1">
                <h2 className="h1 display-5 m-5 pl-5 pr-0  text-weight-bold">
                  Datacode helps people to provide Online Learning Tutorials and
                  Courses from all over the Internet.
                </h2>
                <center>
                  <Link to="/home">
                    <button className="btn btn-lg btn-default border border-primary rounded-0 pl-5 pr-5 pt-3 pb-3">
                      <h5 className="text-primary">Getting Started</h5>
                    </button>
                  </Link>
                </center>
              </div>
              <div className="card-img-overlay d-block d-sm-none m-0 p-0 text-right">
                <h2 className="text-weight-bold p-0 ">Free Learning</h2>
                <p>Find | Learn | Code</p>
                <Link to="/home">
                  <div className="btn btn-warning">Getting Start</div>
                </Link>
              </div>
            </div>
          </Carousel.Item> */}
        </Carousel>
      </>
    );
  }
}

CarouselComponent.defaultProps = {
  name: "",
};

CarouselComponent.propTypes = {
  name: PropTypes.string,
};

export default CarouselComponent;
