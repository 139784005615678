import React from "react";

const ImageUploadBlockPreview = ({ item }) => {
  return (
    <div className="d-flex justify-content-center align-items-center p-2 image-upload-preview">
      <img src={item.payload} alt="" />
    </div>
  );
};

export default ImageUploadBlockPreview;
