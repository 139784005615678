import { connect } from "react-redux";
import {
  getEventsList,
  getSearchEvents,
  setShowSearchResultPage,
} from "../../actions";

import EventsList from "./EventsList";

const mapStateToProps = ({ event, user }) => ({
  currentUser: user.currentUser ? user.currentUser : {},
  eventsList: event.eventsList,
  upcomingEventsList: event.upcomingEventsList,
  pastEventsList: event.pastEventsList,
  liveEventsList: event.liveEventsList,
  eventsListLoading: event.eventsListLoading,
  searchEventsList: event.searchEventsList,
  searchEventsListLoading: event.searchEventsListLoading,
  showSearchResultPage: event.showSearchResultPage,
});

const mapDispatchToProps = {
  getEventsList,
  getSearchEvents,
  setShowSearchResultPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventsList);
