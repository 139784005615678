import React from 'react'
import LayoutContainer from '../LayoutContainer'

const MentorsList = () => {
  return (
    <>
      <LayoutContainer>
        <h1>MentorsList</h1>
      </LayoutContainer>
    </>
  )
}
export default MentorsList

