import React, { useEffect, useState } from "react";
import CreateQuizHeader from "../contributor_home/create_quiz_header/CreateQuizHeader";
import QuizTestsList from "./QuizTestsList";

const ContributorHome = ({ getQuizTestsList, userName }) => {
  const [quizTestsList, setQuizTestsList] = useState([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    getQuizTestsList().then((res) => {
      if (res && res.data) {
        setQuizTestsList(res.data);
      }
    });
  }, []);

  return (
    <>
      <div className='row'>
        <div className='col'>
          <CreateQuizHeader />
        </div>
      </div>
      <h1>ContributorHome</h1>
      <QuizTestsList quizTestsList={quizTestsList} />
    </>
  );
};

export default ContributorHome;
