import React, { useEffect, useState } from "react";
import { Field, reduxForm } from "redux-form";
import { RenderInputField } from "../../../../../../shared_components/ReduxFormFields";
import { required } from "../../../../../../utils/validators";
import { NotificationCard } from "../../SharedComponents";
import LayoutContainer from "../LayoutContainer";

const Notification = ({
  reset,
  handleSubmit,
  submitting,
  initialize,
  adminNotificationDaysCodeUser,
  getDaysUserReg,
  deleteAdminNotification,
  daysUserDetails,
}) => {
  const [isEdit, setEdit] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    getDaysUserReg(process.env.REACT_APP_ADMIN_USERNAME);
  }, []);

  const onSubmit = (values) => {
    const notification = { ...values };
    notification["notificationId"] = Math.random().toString(36).substr(4, 9);
    notification["date"] = new Date();
    console.log("444444", notification);
    adminNotificationDaysCodeUser(notification).then((res) => {
      if (res && res.success) {
        getDaysUserReg(process.env.REACT_APP_ADMIN_USERNAME);
      }
    });
    reset("notification");
  };

  const handleNotificationDelete = (id) => {
    deleteAdminNotification(id).then((res) => {
      if (res && res.success) {
        getDaysUserReg(process.env.REACT_APP_ADMIN_USERNAME);
      }
    });
  };

  return (
    <>
      <LayoutContainer>
        <h1 className='py-md-3 py-2'>Notification</h1>
        <div className='row m-0 '>
          <div className='col-12 py-3 px-md-5 px-1'>
            <form className=' ' onSubmit={handleSubmit(onSubmit)}>
              <h1 className='h4'>Add Notification</h1>
              <small>
                Lorazepam, sold under the brand name Ativan among others, is a
                benzodiazepine medication. It is used to treat anxiety
                disorders, trouble sleeping, severe agitation, active seizures
                including status epilepticus, alcohol withdrawal, and
                chemotherapy-induced nausea and vomiting
              </small>
              <Field
                name='message'
                label='Message'
                type='textarea'
                placeholder='Search Technology'
                component={RenderInputField}
                validate={[required]}
              />

              <div className='row my-4'>
                <div className='col-12 text-right'>
                  <button
                    type='submit'
                    className='btn custom-button'
                    disabled={submitting}
                  >
                    <span>{isEdit ? "Edit Notification" : "Send"}</span>
                  </button>
                </div>
              </div>
            </form>
            <NotificationCard
              notification={daysUserDetails && daysUserDetails.notification}
              admin={true}
              handleNotificationDelete={handleNotificationDelete}
            />
          </div>
        </div>
      </LayoutContainer>
    </>
  );
};

export default reduxForm({
  form: "add-solution",
})(Notification);
