import React from "react";
import BecomeProfessional from "./BecomeProfessional";
import JoinOurInternship from "./JoinOurInternship";
import OurVibrantWork from "./OurVibrantWork";
import JoinUs from "./JoinUs";
import OurCommunityEvents from "./OurCommunityEvents";
import HiringPartners from "./HiringPartners";
import Testimonials from "./Testimonials";
import Technologies from "./Technologies";
import HeroSection from "./HeroSection";
import Registration from "./Registration";
import Events from "./Events";
import FirstStepToFuture from "./FirstStepToFuture";
import Projects from "./Projects";
const InternshipProgram = () => {
  return (
    <>
      <div className="row mx-0 ">
        <div className="col-12 px-0">
          <div className="">
            <HeroSection />
          </div>
          <div className="pt-lg-5">
            <FirstStepToFuture />
          </div>
          <div className="pt-lg-5 pt-4">
            <BecomeProfessional />
          </div>
          <div className="pt-lg-5 pt-4">
            <Registration />
          </div>
          <div className="pt-lg-5">
            <Technologies />
          </div>
          <div className="pt-lg-5">
            <Projects />
          </div>
          <div className="pt-lg-5">
            <Events />
          </div>
          <div className="pt-lg-5">
            <HiringPartners />
          </div>
          <div className="pt-lg-5">
            <Testimonials />
          </div>
          <div className="pt-lg-5">
            <JoinUs />
          </div>
          {/* <div className="py-lg-5 text-center">
            <h3>Datacode Community</h3>
            <p className="py-3 px-lg-0 px-2">
              is driven to make web development accessible to everyone. Let us
              shape your future, together with Techvriksh IT!
            </p>
          </div> */}
        </div>
      </div>
    </>
  );
};
export default InternshipProgram;
