import React from "react";

const CustomSearch = ({ setSearch, search }) => {
  return (
    <>
      <div className="row px-md-5 px-3 category-page">
        <div className="col-12 text-center d-flex justify-content-center">
          <div className="d-flex mx-2 px-md-2 px-1 fontSearch">
            <input
              type="text"
              className="search"
              value={search}
              placeholder="Username / Name"
              onChange={(e) => setSearch(e.target.value)}
            />
            <i className="fa fa-search search-icon" />
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomSearch;
