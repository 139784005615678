import React from "react";
import { Progress } from "reactstrap";
import { jwtDecode } from "jwt-decode";

const getTokenExpiration = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    if (decodedToken.exp) {
      return decodedToken.exp * 1000; // convert to milliseconds
    }
  } catch (error) {
    console.error("Invalid token", error);
  }
  return null;
};

export const scheduleTokenExpiryLogout = (token, logoutFunction) => {
  const expirationTime = getTokenExpiration(token);
  if (expirationTime) {
    const timeLeft = expirationTime - Date.now();
    if (timeLeft >= 0) {
      setTimeout(logoutFunction, timeLeft);
    } else {
      logoutFunction(); // Token already expired
    }
  }
};

export const handleTextVisibility = (text, limit) => {
  if (text) {
    return `${text.slice(0, limit)}${text.length > limit ? "..." : ""}`;
  }
};

export const timeConvert = (time) => {
  // Check correct time format and split into components
  time = (time &&
    time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/)) || [
    time,
  ];
  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
};

export const getSort = (array, item) => {
  return array.sort((a, b) => {
    return a[item] - b[item];
  });
};

export const getOptions = (array, textField) => {
  const options = [];
  array.map((item, i) =>
    options.push({ [textField]: item.name, value: item.value }),
  );
  return options;
};

export const getSortByArrayLength = (array, item) => {
  return array.sort((a, b) => {
    return b[item].length - a[item].length;
  });
};

export const technologyOptions = [
  { tech: "Machine Learning", value: "machine_learning" },
  { tech: "Artificial Intelligence", value: "artificial_intelligence" },
  { tech: "DataScience", value: "datascience" },
  { tech: "Hadoop", value: "hadoop" },
  { tech: "Apache Spark", value: "apache_spark" },
  { tech: "Tensorflow", value: "tensorflow" },
  { tech: "Javascript", value: "Javascript" },
  { tech: "Android", value: "Android" },
  { tech: "Datastructures", value: "Datastructures" },
  { tech: "C++", value: "cplusplus" },
  { tech: "Java", value: "Java" },
  { tech: "React", value: "React" },
  { tech: "Html", value: "Html" },
  { tech: "Angular", value: "Angular" },
  { tech: "SQL", value: "SQL" },
  { tech: "Python", value: "Python" },
  { tech: "C", value: "C" },
  { tech: "Node", value: "Node" },
  { tech: "PHP", value: "PHP" },
  { tech: "Django", value: "Django" },
  { tech: "Bootstrap", value: "Bootstrap" },
  { tech: "Typescript", value: "Typescript" },
  { tech: "React Native", value: "react_native" },
  { tech: "Git", value: "Git" },
  { tech: "Flutter", value: "Flutter" },
  { tech: "Swift", value: "Swift" },
  { tech: "Redux", value: "Redux" },
  { tech: "Vue", value: "Vue" },
  { tech: "Software Testing", value: "software_testing" },
  { tech: "Computer Science", value: "computer_science" },
  { tech: "Ruby", value: "Ruby" },
  { tech: "SEO", value: "SEO" },
  { tech: "CSharp", value: "CSharp" },
  { tech: "jQuery", value: "jQuery" },
  { tech: "Go", value: "Go" },
  { tech: "Css", value: "Css" },
  { tech: "Linux", value: "Linux" },
];

export const BatchMentorRole = [
  { role: "Assistant", value: "Assistant" },
  { role: "Director", value: "Director" },
  { role: "HOD", value: "HOD" },
];

export const languageOptions = [
  { tech: "C++", value: "cplusplus" },
  { tech: "C", value: "c" },
  // { tech: "Java", value: "java" },
  // { tech: "Python", value: "python" },
];

export const getTechLable = (tech) => {
  const data = technologyOptions.filter((item) => item.value === tech);
  return data && data[0].tech;
};

export const testimonialResponsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const workshopFilterResponsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
    slidesToSlide: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 5,
    slidesToSlide: 5,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 5,
  },
};

export const CustomArrow = ({ onClick, direction }) => {
  return (
    <button
      onClick={onClick}
      className={`custom-arrow custom-${direction}-arrow`}
    >
      {direction === "left" ? "<" : ">"}
    </button>
  );
};

export const academymentorresponsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const techCompaniesResponsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

export const techCompanies = [
  {
    img: "7.png",
    name: "facebook",
  },
  {
    img: "4.png",
    name: "amazon",
  },
  {
    img: "14.png",
    name: "netflix",
  },
  {
    img: "5.png",
    name: "google",
  },
  {
    img: "3.png",
    name: "ibm",
  },
  {
    img: "8.png",
    name: "cognizant",
  },
  {
    img: "9.png",
    name: "Tcs NQT",
  },
  {
    img: "10.png",
    name: "Mind Tree",
  },
  {
    img: "11.png",
    name: "Capgemini",
  },
  {
    img: "12.png",
    name: "Wipro",
  },
  {
    img: "13.png",
    name: "amdocs",
  },
  {
    img: "6.png",
    name: "cisco",
  },
  {
    img: "15.png",
    name: "FlipKart",
  },
  {
    img: "16.png",
    name: "Infosys",
  },
  {
    img: "17.png",
    name: "Persistent",
  },
  {
    img: "18.png",
    name: "HCL",
  },
  {
    img: "19.png",
    name: "Deloitte",
  },
];

export const alphabaticPosition = (number) => {
  if (number % 10 === 1) {
    return (
      <>
        <span>{number}</span>
        <span style={{ verticalAlign: "super" }}>st</span>
      </>
    );
  }
  if (number % 10 === 2 || 0) {
    return (
      <>
        <span>{number}</span>
        <span style={{ verticalAlign: "super" }}>nd</span>
      </>
    );
  }
  if (number % 10 === 3) {
    return (
      <>
        <span>{number}</span>
        <span style={{ verticalAlign: "super" }}>rd</span>
      </>
    );
  }
  if (number % 10 >= 4) {
    return (
      <>
        <span>{number}</span>
        <span style={{ verticalAlign: "super" }}>th</span>
      </>
    );
  }
  if (number % 10 === 0) {
    return (
      <>
        <span>{number}</span>
        <span style={{ verticalAlign: "super" }}>th</span>
      </>
    );
  }
};

export const checkProfileProgress = (profile) => {
  const data = [
    "firstName",
    "lastName",
    "bio",
    "gender",
    "university",
    "graduationYear",
    "branch",
    "phone",
    "linkedin",
    "github",
    "twitter",
  ];

  let count = 0;
  for (const [key, value] of Object.entries(profile)) {
    if (data.includes(key) && value !== "") {
      count = count + 1;
    }
  }
  if (
    profile &&
    profile.imgUrl &&
    profile.imgUrl.includes("http://res.cloudinary.com/datacode")
  ) {
    count = count + 1;
  }
  const setColor = (count) => {
    if (count === data.length + 1) {
      return "success";
    }
    if (count < 3) {
      return "danger";
    }
    if (count > 3 && count < data.length - 4) {
      return "warning";
    }
    if (count > 3 && count < data.length - 2) {
      return "primary";
    }
  };

  return (
    <>
      <span>
        Profile Completed ({Math.round((count / (data.length + 1)) * 100)} %)
      </span>
      <Progress color={setColor(count)} value={count} max={12} />
    </>
  );
};
