import { connect } from "react-redux";
import { getLessons, removeLesson } from "../../../../actions";

import LessonsList from "./LessonsList";

const mapStateToProps = ({ dayscode, user }) => ({
  currentUser: user.currentUser ? user.currentUser : {},
  problemsListLoading: dayscode.problemsListLoading,
  lessonsList: dayscode.lessonsList,
});

const mapDispatchToProps = {
  getLessons,
  removeLesson,
};

export default connect(mapStateToProps, mapDispatchToProps)(LessonsList);
