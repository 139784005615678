import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";
import Combobox from "react-widgets/Combobox";

import { checkAdmin } from "../../../../../../../core/auth";
import { getSort } from "../../../../../../../utils";
import LayoutContainer from "../../LayoutContainer";
import {
  getProblemTypeOptions,
  problemTypeOptions,
} from "../AddProblem/helper";
import DeleteProblemModal from "./DeleteProblemModal";
import ProblemListCard from "./ProblemListCard";

const ProblemsList = ({
  getProblems,
  problemsList,
  problemsListLoading,
  removeDaysProblem,
  currentUser,
}) => {
  const history = useHistory();

  const [openModal, setOpenModal] = useState(false);
  const [tempDeleteId, setTempDeleteId] = useState();
  const [problemStatus, setProblemStatus] = useState("public");
  const [problemType, setProblemType] = useState("assignment");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    getProblems({ status: problemStatus, problem_type: problemType });
  }, [problemStatus, problemType]);

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const handleRemoveProblem = () => {
    removeDaysProblem(tempDeleteId).then((res) => {
      if (res) {
        setOpenModal(!openModal);
        console.log(res, "res");
      }
    });
  };

  const deleteProblem = (id) => {
    setTempDeleteId(id);
    setOpenModal(!openModal);
  };

  const onChangeProblemType = (type) => {
    setProblemType(type.value);
    getProblems({ status: problemStatus, problem_type: type.value });
  };

  return (
    <>
      <LayoutContainer>
        <div className='row mx-0 solution-nav'>
          <div className='col-md-8 col-6 mx-0 d-flex align-items-center'>
            <h4 className='py-md-3 py-2 mb-0'>
              <i className='fa fa-code mr-2' />
              Problems List
            </h4>
          </div>
          <div className='col-md-4 col-6 d-flex justify-content-end align-items-center'>
            {checkAdmin(currentUser) && (
              <button
                onClick={() => history.push("/admin/dayscode/problem/new")}
                type='button'
                className='btn enroll-small-btn'
              >
                <small>
                  <i className='fal fa-plus-circle mr-2' />
                  Create Problem
                </small>
              </button>
            )}
          </div>
        </div>
        {problemsListLoading ? (
          <div className='row d-flex justify-items                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            -center'>
            <div className='col-12 align-items-center text-center '>
              <Loader
                type='TailSpin'
                color='#797676'
                height={50}
                width={50}
                timeout={19000} //8 secs
              />
            </div>
          </div>
        ) : (
          <>
            <div className='px-md-4 px-2 mt-4'>
              <div className='row m-0 d-flex justify-content-between'>
                <div className='col-md-6 col-12'>
                  <div className='event-dashboard-header w-100 px-0 table-responsive table'>
                    <ul>
                      <li>
                        <span
                          onClick={() => setProblemStatus("public")}
                          className={problemStatus === "public" ? "active" : ""}
                        >
                          Public
                        </span>
                      </li>
                      <li>
                        <span
                          onClick={() => setProblemStatus("draft")}
                          className={problemStatus === "draft" ? "active" : ""}
                        >
                          Draft
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='col-md-3 col-12'>
                  <label className='mr-4 form-label'>Problem Type:</label>
                  <div className='mb-2'>
                    <Combobox
                      data={getProblemTypeOptions(problemTypeOptions)}
                      dataKey={"value"}
                      textField='problem_type'
                      placeholder={"Select Problem Type"}
                      value={problemType}
                      onChange={(value) => onChangeProblemType(value)}
                    />
                  </div>
                </div>
              </div>
              <div className='row m-0 py-2'>
                {problemsList &&
                  getSort(problemsList, "day").map((item, i) => (
                    <div className='col-md-4 col-12 p-2' key={item._id}>
                      <ProblemListCard
                        item={item}
                        deleteProblem={deleteProblem}
                        currentUser={currentUser}
                      />
                    </div>
                  ))}
              </div>
            </div>

            <DeleteProblemModal
              open={openModal}
              toggle={toggleModal}
              onSubmit={handleRemoveProblem}
              submitButtonName={"Delete Problem"}
            />
          </>
        )}
      </LayoutContainer>
    </>
  );
};

export default ProblemsList;
