import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomLoader from "../../../../../shared_components/CustomLoader";
import EventCard from "./EventCard";
import _ from "lodash";
import { SharedEventLoadMoreButton } from "../EventSharedComponent/EventSharedComponent";
import { getSearchEvents, setShowSearchResultPage } from "../../actions";

const EventSearchPage = ({
  showSearchResultPage,
  searchEventsListLoading,
  searchEventsList,
}) => {
  const dispatch = useDispatch();
  const searchQueryObject = useSelector((state) =>
    state.event.searchQueryObject ? state.event.searchQueryObject : {},
  );
  const { userName, email } = useSelector((state) =>
    state.user.currentUser ? state.user.currentUser : {},
  );

  const [pageNumber, setPageNumber] = useState(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    dispatch(
      getSearchEvents({
        page: 0,
        limit: 4,
        email,
        state: searchQueryObject.state,
        tags: searchQueryObject.tags,
        order: 1,
      }),
    );
  }, [searchQueryObject]);

  const handleLoadMore = (page) => {
    if (userName && email) {
      dispatch(
        getSearchEvents({
          page,
          limit: 4,
          email,
          state: searchQueryObject.state,
          tags: searchQueryObject.tags,
          order: 1,
        }),
      );
    } else {
      dispatch(
        getSearchEvents({
          page,
          limit: 4,
          state: searchQueryObject.state,
          tags: searchQueryObject.tags,
          order: 1,
        }),
      );
    }
    setPageNumber(page);
  };

  return (
    <>
      <div className='row d-flex pt-5 justify-content-center mx-0'>
        <div className='col-md-11 col-12'>
          <div className='border-bottom mb-3 d-flex align-items-center justify-content-between'>
            <h1>Search Result</h1>
            <h1 className='p-3 d-flex justify-content-end'>
              <i
                onClick={() => dispatch(setShowSearchResultPage(false))}
                className='fal fa-times'
              />
            </h1>
          </div>
        </div>
        <div className='col-md-11 col-12 px-0'>
          {searchEventsListLoading ? (
            <div className='text-center'>
              <CustomLoader />
            </div>
          ) : (
            <>
              {!_.isEmpty(searchEventsList) ? (
                <div className='row mx-0  mx-0 d-flex align-items-stretch px-md-0 px-1'>
                  {searchEventsList &&
                    searchEventsList.map((event, i) => (
                      <EventCard data={event} key={i} />
                    ))}
                </div>
              ) : (
                <h1 className='text-center'>No events matched your search</h1>
              )}
              <SharedEventLoadMoreButton
                loading={searchEventsListLoading}
                setPageNumber={handleLoadMore}
                pageNumber={pageNumber}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default EventSearchPage;
