import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
class ArticlePostCard extends Component {
  state = {};

  render() {
    return (
      <>
        <div className='card border border-light shadow mb-2'>
          <img
            className=' card-img-top mx-auto img-fluid img-circle d-block mb-0'
            src={this.props.imgsource}
            alt='avatar'
          />

          <div className='card-body mt-0 pt-2 pb-1'>
            <span className='text-right text-white badge badge-primary'>
              {this.props.tag1}
            </span>
            <span className='text-right text-white ml-2 badge badge-success'>
              {this.props.tag2}
            </span>
            <span className='text-right text-white ml-2 badge badge-danger'>
              {this.props.tag3}
            </span>
            <h6 className='card-title mt-2' style={{ color: "#072C71" }}>
              {this.props.title}
            </h6>
            <p className='card-text text-secondary '>{this.props.paragraph}</p>
          </div>
          <div className='card-footer border border-white bg-white pt-2'>
            <div className='row m-0 shadow'>
              <div className='col text-right'>
                <a
                  href={this.props.link}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <h6 className='text-secondary'>
                    Read More
                    <FontAwesomeIcon
                      icon={faExternalLinkAlt}
                      className='ml-1'
                    />
                  </h6>
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ArticlePostCard;
