import React from "react"

const data = [
  {
    image: 'https://res.cloudinary.com/datacode/image/upload/v1688212542/trkpwopcucnpmye2holr.svg',
    title: 'Workshops and Training Sessions',
    content: 'Hands-on workshops and training for practical skills and knowledge.'
  },
  {
    image: 'http://res.cloudinary.com/datacode/image/upload/v1688212631/ne5d0fqy3qwehiv2thwe.svg',
    title: 'Peer Presentations and Showcases',
    content: 'Sed perspiciatis unde omnisnatus error voluptatem'
  },
  {
    image: 'http://res.cloudinary.com/datacode/image/upload/v1688212705/nomvywnsa9ddq5iwm23x.svg',
    title: 'Study Groups and Collaborative Learning',
    content: 'Work together to solve problems, discuss course materials, and share knowledge'
  },
  {
    image: 'https://res.cloudinary.com/datacode/image/upload/v1688213627/nhfm9vvvjkp0qxwkt9ts.png',
    title: 'Hackathons and Coding Challenges',
    content: 'challenges that encourage problem-solving, innovation, and creative thinking '
  },
  {
    image: 'http://res.cloudinary.com/datacode/image/upload/v1688212777/vrvmoyxiukjwnwwd48eh.svg',
    title: 'Projects and Case Studies',
    content: 'Engage in practical projects and case studies that allow you to apply their knowledge'
  },
]
const ClubActivitiesSection = () => {
  return (
    <>
      <div className="row mx-0 my-md-5 py-md-5 activities-section d-flex justify-content-center">
        <div className="col-md-10 col-12 py-md-5 py-0">
          <div className="activity-heading-text">
            <h1 className="text-center">
              Exciting Events and Engaging Activities to Ignite Your Learning Journey
            </h1>
          </div>
          <div className="row mx-0 d-flex justify-content-center ">
            <div className="col-md-6 col-12 d-flex align-items-center justify-content-center">
              <img
                className="activity-img"
                height='500'
                width='500'
                loading="lazy"
                src={`http://res.cloudinary.com/datacode/image/upload/v1688215114/u7dngfxiyvcyn5jb8dld.png`}
                alt="avat ar"
              />
            </div>
            <div className="col-md-6 col-12 px-0 px-md-3">
              <div className="row mx-0 activities-list">
                {
                  data && data.map((item, i) => (
                    <div key={i} className="col-12 my-3 activities-card">
                      <div className="d-flex py-3">
                        <img
                          className=""
                          height="50"
                          width="50"
                          loading="lazy"
                          src={item.image}
                          alt="avatar"
                        />
                        <div className="ml-3">
                          <h6>{item.title}</h6>
                          <span>{item.content}</span>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  )
}

export default ClubActivitiesSection