import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faCopyright,
  faDatabase,
  faDigitalTachograph,
  faObjectGroup,
  faAward,
  faColumns,
  faTv,
  faCogs,
  faBrain,
} from "@fortawesome/free-solid-svg-icons";
import RightSideSection from "../../right_section/RightSideSection";
import {
  faPython,
  faJava,
  faJs,
  faHtml5,
  faNodeJs,
  faAngular,
  faReact,
  faGithub,
  faPhp,
  faSwift,
  faAndroid,
} from "@fortawesome/free-brands-svg-icons";
import Technologies from "../../right_section/Technologies";
import TrainingCertificationCardCarousel from "./TrainingCertificationCardCarousel";
import { Helmet } from "react-helmet";

class IndexTrainingCertification extends Component {
  state = {};
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <>
        <Helmet>
          <title>{`Find the Best Training & Certification | Datacode.in`}</title>
          <meta data-react-helmet="true" name="description" content="Are you desperate for learning? Here’s the Best Material awaiting for you, show your determination towards the knowledgeable world."></meta>
          <meta data-react-helmet="true" http-equiv="cleartype" content="on"></meta>
          <meta data-react-helmet="true" name="apple-mobile-web-app-capable" content="yes"></meta>
          <meta data-react-helmet="true" name="robots" content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"></meta>
          <meta data-react-helmet="true" property="og:locale" content="en_US"></meta>
          <meta data-react-helmet="true" property="og:type" content="website"></meta>
          <meta data-react-helmet="true" property="og:title" content={`Find the Best Training & Certification | Datacode.in`}></meta>
          <meta data-react-helmet="true" property="og:description" content="Are you desperate for learning? Here’s the Best Material awaiting for you, show your determination towards the knowledgeable world."></meta>
          <meta data-react-helmet="true" property="og:url" content="https://www.datacode.in/"></meta>
          <meta data-react-helmet="true" property="og:site_name" content="Datacode.in"></meta>
          <meta data-react-helmet="true" property="og:image" content="https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png"></meta>
          <meta data-react-helmet="true" property="og:image:secure_url" content="https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png"></meta>
          <meta data-react-helmet="true" property="og:image:width" content="400"></meta>
          <meta data-react-helmet="true" property="og:image:height" content="400"></meta>
          <meta data-react-helmet="true" name="keywords" content="programming,coding,online, code online, online judge, practice, run code, test, expected outcome, programming competition, programming contest, online programming, online computer programming"></meta>
          <meta data-react-helmet="true" name="twitter:card" content="summary_large_image"></meta>
          <meta data-react-helmet="true" name="twitter:description" content="Are you desperate for learning? Here’s the Best Material awaiting for you, show your determination towards the knowledgeable world."></meta>
          <meta data-react-helmet="true" name="twitter:title" content={`Find the Best Training & Certification | Datacode.in`}></meta>
          <meta data-react-helmet="true" name="twitter:site" content="@datacode_in"></meta>
          <meta data-react-helmet="true" name="twitter:creator" content="@datacode_in"></meta>
          <meta data-react-helmet="true" name="twitter:image" content="https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png"></meta>
          <link data-react-helmet="true" rel="shortlink" href="https://www.datacodoe.in/"></link>
          <link data-react-helmet="true" rel="dns-prefetch" href="//www.datacode.in"></link>
          <link data-react-helmet="true" rel="dns-prefetch" href="//s.w.org"></link>
          <link data-react-helmet="true" rel="alternate" type="application/rss+xml" title="Datacode" href="https://www.datacode.in/"></link>
          <link data-react-helmet="true" rel="canonical" href="https://www.datacode.in/"></link>
        </Helmet>
        <div className="row m-0 px-3">
          <div className="col-1"></div>
          <div className="col-md-8 mt-5">
            <h1
              className="text-center mb-4 rounded p-2"
              style={{ backgroundColor: "#30006b", color: "#ffffff" }}
            >
              <FontAwesomeIcon icon={faAward} /> Training & Certification
            </h1>
            <h5 className="text-center">It’s  not always easy but it’s always worth it,time to convert knowledge into progress,for training yourself as a champion!</h5>
            <Link
              to="/home"
              className="badge mr-1 my-5"
              style={{ backgroundColor: "#06CB6C" }}
            >
              <FontAwesomeIcon icon={faHome} size="2x" color="#072C71" />
            </Link>
            <div className="card-deck mt-4">
              <div className="card text-center p-2">
                <Link to="/webdevelopment_training_certification">
                  <center>
                    <FontAwesomeIcon
                      icon={faColumns}
                      size="4x"
                      color="#072C71"
                    />
                  </center>
                  Web Development
                </Link>
              </div>
              <div className="card text-center p-2">
                <Link to="/datascience_training_certification">
                  <center>
                    <FontAwesomeIcon icon={faBrain} size="4x" color="#072C71" />
                  </center>
                  DataScience
                </Link>
              </div>
              <div className="card text-center p-2">
                <Link to="/machinelearning_training_certification">
                  <center>
                    <FontAwesomeIcon icon={faCogs} size="4x" color="#072C71" />
                  </center>
                  Machine Learning
                </Link>
              </div>
              <div className="card text-center p-2">
                <Link to="/computerscience_training_certification">
                  <center>
                    <FontAwesomeIcon icon={faTv} size="4x" color="#072C71" />
                  </center>
                  Computer Science
                </Link>
              </div>
            </div>
            <div className="card-deck mt-4">
              <div className="card text-center p-2">
                <Link to="/c_training_certification">
                  <center>
                    <FontAwesomeIcon
                      icon={faCopyright}
                      size="4x"
                      color="#072C71"
                    />
                  </center>
                  C Programming
                </Link>
              </div>
              <div className="card text-center p-2">
                <Link to="/python_training_certification">
                  <center>
                    <FontAwesomeIcon
                      icon={faPython}
                      size="4x"
                      color="#072C71"
                    />
                  </center>
                  Python
                </Link>
              </div>
              <div className="card text-center p-2">
                <Link to="/java_training_certification">
                  <center>
                    <FontAwesomeIcon icon={faJava} size="4x" color="#072C71" />
                  </center>
                  Java
                </Link>
              </div>
              <div className="card text-center p-2">
                <Link to="/cpp_training_certification">
                  <center>
                    <FontAwesomeIcon
                      icon={faCopyright}
                      size="4x"
                      color="#072C71"
                    />
                  </center>
                  C++
                </Link>
              </div>
            </div>
            <div className="card-deck mt-4">
              <div className="card text-center p-2">
                <Link to="oops_training_certification">
                  <center>
                    <FontAwesomeIcon
                      icon={faObjectGroup}
                      size="4x"
                      color="#072C71"
                    />
                  </center>
                  OOPs
                </Link>
              </div>
              <div className="card text-center p-2">
                <Link to="/dbms_training_certification">
                  <center>
                    <FontAwesomeIcon
                      icon={faDatabase}
                      size="4x"
                      color="#072C71"
                    />
                  </center>
                  DBMS/SQL
                </Link>
              </div>

              <div className="card text-center p-2">
                <Link to="/datastructure_training_certification">
                  <center>
                    <FontAwesomeIcon
                      icon={faDigitalTachograph}
                      size="4x"
                      color="#072C71"
                    />
                  </center>
                  Data Structure
                </Link>
              </div>
              <div className="card text-center p-2">
                <Link to="/html_training_certification">
                  <center>
                    <FontAwesomeIcon icon={faHtml5} size="4x" color="#072C71" />
                  </center>
                  HTML/CSS
                </Link>
              </div>
            </div>
            <div className="card-deck mt-4">
              <div className="card text-center p-2">
                <Link to="/js_training_certification">
                  <center>
                    <FontAwesomeIcon icon={faJs} size="4x" color="#072C71" />
                  </center>
                  JavaScript
                </Link>
              </div>
              <div className="card text-center p-2">
                <Link to="/nodejs_training_certification">
                  <center>
                    <FontAwesomeIcon
                      icon={faNodeJs}
                      size="4x"
                      color="#072C71"
                    />
                  </center>
                  Node JS
                </Link>
              </div>
              <div className="card text-center p-2">
                <Link to="/angular_training_certification">
                  <center>
                    <FontAwesomeIcon
                      icon={faAngular}
                      size="4x"
                      color="#072C71"
                    />
                  </center>
                  Angular
                </Link>
              </div>
              <div className="card text-center p-2">
                <Link to="/react_training_certification">
                  <center>
                    <FontAwesomeIcon icon={faReact} size="4x" color="#072C71" />
                  </center>
                  React
                </Link>
              </div>
            </div>
            <div className="card-deck mt-4">
              <div className="card text-center p-2">
                <Link to="/php_training_certification">
                  <center>
                    <FontAwesomeIcon icon={faPhp} size="4x" color="#072C71" />
                  </center>
                  PHP
                </Link>
              </div>
              <div className="card text-center p-2">
                <Link to="/swift_training_certification">
                  <center>
                    <FontAwesomeIcon icon={faSwift} size="4x" color="#072C71" />
                  </center>
                  Swift
                </Link>
              </div>
              <div className="card text-center p-2">
                <Link to="/android_training_certification">
                  <center>
                    <FontAwesomeIcon
                      icon={faAndroid}
                      size="4x"
                      color="#072C71"
                    />
                  </center>
                  Android
                </Link>
              </div>
              <div className="card text-center p-2">
                <Link to="/github_training_certification">
                  <center>
                    <FontAwesomeIcon
                      icon={faGithub}
                      size="4x"
                      color="#072C71"
                    />
                  </center>
                  GitHub
                </Link>
              </div>
            </div>
            <h4 className="text-secondary mt-5">
              <code className="text-primary">
                {" "}
                For More Opportunities
              </code>
            </h4>
            <TrainingCertificationCardCarousel />
          </div>
          <div className="col-md-3">
            <RightSideSection />
            <Technologies />
          </div>
        </div>
      </>
    );
  }
}

export default IndexTrainingCertification;
