import React from "react";
import { useSelector } from "react-redux";

import EventsSectionFrame from "./EventsSectionFrame";

const EventsListSection = ({
  upcomingEventsList,
  pastEventsList,
  liveEventsList,
}) => {
  const {
    upcomingEventsListLoading,
    pastEventsListLoading,
    liveEventsListLoading,
  } = useSelector((state) => state?.event);

  return (
    <>
      <div className='row mx-0 d-flex justify-content-center event-list-section'>
        <div className='col-md-10 col-12 p-0'>
          <EventsSectionFrame
            eventStateTitle='OnGoing Events'
            eventsListByState={liveEventsList}
            eventsListLoading={liveEventsListLoading}
            state='live'
          />
          <EventsSectionFrame
            eventStateTitle='Upcoming Events'
            eventsListByState={upcomingEventsList}
            eventsListLoading={upcomingEventsListLoading}
            state='upcoming'
          />
          <EventsSectionFrame
            eventStateTitle='Past Events'
            eventsListByState={pastEventsList}
            eventsListLoading={pastEventsListLoading}
            state='past'
          />
        </div>
      </div>
    </>
  );
};
export default EventsListSection;
