import React, { useState, useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import {
  renderCheckboxField,
  RenderInputField,
  renderNumberRadioField,
  renderRadioField,
} from "../../../../../../shared_components/ReduxFormFields";
import { required } from "../../../../../../utils/validators";
import bgImage from "../../../../../../assets/images/bg/Rectangle 14formbg.jpg";
import { FaArrowRightLong } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { eventFeedback, getEventDetails } from "../../../actions/operations";

const EventFeedbackForm = ({ reset, handleSubmit, submitting }) => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [eventDetails, setEventDetails] = useState();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    dispatch(getEventDetails(id)).then((res) => {
      setEventDetails(res.data);
    });
  }, []);

  const onSubmit = (values) => {
    console.log(values);
    const register = { ...values };
    register["id"] = id;
    dispatch(eventFeedback(register)).then((res) => {
      if (res && res.success) {
        reset("eventfeedbackForm");
      }
    });
  };

  const handleBack = () => {
    setPage(1);
  };

  return (
    <>
      <div className='event-form mx-0 my-5 '>
        <div className='form-img'>
          <img src={bgImage} alt='img' />
        </div>
        <div className='title'>
          <h1>Secure Your Spot</h1>
          <h3>Register Now For </h3>
          <h3>Exclusive Event </h3>
          <h2>
            <FaArrowRightLong size={"80px"} className='mt-4' />
          </h2>
        </div>
        <div className='form-content border rounded-lg card-schadow'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='row'>
              {eventDetails?.form_fields.length > 0 ? (
                eventDetails?.form_fields
                  .filter((state) => state.formName === "feedback")
                  .map((field) => {
                    switch (field.type) {
                      case "text":
                        return (
                          <div className='form-group col-md-6'>
                            {/* <label className='form-label'>{field.label}</label> */}
                            <Field
                              type='text'
                              label={field.label}
                              name={field.name}
                              component={RenderInputField}
                              validate={[required]}
                            />
                          </div>
                        );
                      case "radio":
                        return (
                          <div className='form-group col-md-6'>
                            <label className='form-label'>{field.label}</label>
                            {field.options.map((option) => (
                              <div className='form-check'>
                                <label
                                  className=''
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Field
                                    name={field.name}
                                    type='radio'
                                    component={renderRadioField}
                                    value={option.label}
                                  />
                                  {option.label}
                                </label>
                              </div>
                            ))}
                          </div>
                        );
                      case "checkbox":
                        return (
                          <div className='form-group col-md-6'>
                            <label className='form-label'>{field.label}</label>
                            {field.options.map((option, index) => (
                              <div className='form-check' key={index}>
                                <label
                                  className=''
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Field
                                    type='checkbox'
                                    name={`${field.name}[${index}]`}
                                    component={renderCheckboxField}
                                    value={option.label}
                                  />
                                  {option.label}
                                </label>
                              </div>
                            ))}
                          </div>
                        );
                      case "textarea":
                        return (
                          <div className='form-group col-md-6'>
                            <Field
                              label={field.label}
                              type='textarea'
                              name={field.name}
                              component={RenderInputField}
                              validate={[required]}
                            />
                          </div>
                        );
                      case "linearScale":
                        return (
                          <div className='form-group col-md-12'>
                            <label className='form-label'>{field.label}</label>
                            <div className='d-flex justify-content-between mb-2'>
                              <span>{field.labelStart}</span>
                              <span>{field.labelEnd}</span>
                            </div>
                            <div className='d-flex justify-content-around mx-0'>
                              {Array.from(
                                {
                                  length:
                                    parseInt(field.end) -
                                    parseInt(field.start) +
                                    1,
                                },
                                (_, index) => {
                                  const value = parseInt(field.start) + index;
                                  return (
                                    <div key={value} className='form-check'>
                                      <Field
                                        name={field.name}
                                        component={renderNumberRadioField}
                                        type='radio'
                                        value={value.toString()}
                                        label={value}
                                        isDisabled={submitting}
                                      />
                                    </div>
                                  );
                                },
                              )}
                            </div>
                          </div>
                        );

                      default:
                        return null;
                    }
                  })
              ) : (
                <h1>no forrm</h1>
              )}
            </div>
            <div className='row mx-0 mt-4 d-flex justify-content-center'>
              {eventDetails?.state === "upcoming" && page === 2 && (
                <div className='col-md-2 col-6 text-center'>
                  <button
                    onClick={() => handleBack()}
                    className='btn reg-button text-white'
                  >
                    Back
                  </button>
                </div>
              )}
              <div className='col-md-2 col-6 text-center'>
                <button
                  type='submit'
                  className='btn text-white reg-button'
                  disabled={submitting}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className='empty'></div>
    </>
  );
};

export default reduxForm({
  form: "eventfeedbackForm",
})(EventFeedbackForm);
