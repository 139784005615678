import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";
import Loader from "react-loader-spinner";
import Filter from "../Filter";
import { techIcon } from "../helpers";
import TutorialCard from "../TutorialCard";

const TechTutorials = ({
  getTutorialsList,
  match: {
    params: { technology },
  },
  tutorialsList,
  tutorialListLoading,
  totalTutorials,
}) => {
  const [techDetails, setTechDetails] = useState({});

  const [pageNumber, setPageNumber] = useState(0);
  const [checkedItems, setCheckedItems] = useState([]);

  // Pagination
  const tutorialsPerPage = 6;
  const pageCount = Math.ceil(totalTutorials / tutorialsPerPage);

  const handleTutorialList = async (page, tags) => {
    await getTutorialsList(technology, page, tutorialsPerPage, tags);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    handleTutorialList(pageNumber, checkedItems);
    techIcon.forEach((item) => {
      if (item.technology === technology) {
        return setTechDetails(item);
      }
    });
    window.scrollTo(0, 0);
  }, []);

  const handleFilter = (event) => {
    let checkedItemsRef = checkedItems;
    if (event.target.checked && event.target.name) {
      checkedItemsRef.push(event.target.name);
      setCheckedItems(checkedItemsRef);
      handleTutorialList(0, checkedItemsRef);
    } else {
      const check = checkedItems.filter((e) => e !== String(event.target.name));
      setCheckedItems(check);
      handleTutorialList(0, check);
    }
  };

  const changePage = ({ selected }) => {
    setPageNumber(selected);
    handleTutorialList(selected, checkedItems);
  };

  return (
    <>
      <Helmet>
        <title>{`Find the Best ${
          techDetails && techDetails.title
        } Courses & Tutorials | Datacode.in`}</title>
        <meta
          data-react-helmet='true'
          name='description'
          content='Are you desperate for learning? Here’s the Best Material awaiting for you, show your determination towards the knowledgeable world.'
        ></meta>
        <meta
          data-react-helmet='true'
          http-equiv='cleartype'
          content='on'
        ></meta>
        <meta
          data-react-helmet='true'
          name='apple-mobile-web-app-capable'
          content='yes'
        ></meta>
        <meta
          data-react-helmet='true'
          name='robots'
          content='max-snippet:-1, max-image-preview:large, max-video-preview:-1'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:locale'
          content='en_US'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:type'
          content='website'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:title'
          content={`Find the Best ${
            techDetails && techDetails.title
          } Courses & Tutorials | Datacode.in`}
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:description'
          content='Are you desperate for learning? Here’s the Best Material awaiting for you, show your determination towards the knowledgeable world.'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:url'
          content='https://www.datacode.in/'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:site_name'
          content='Datacode.in'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:image'
          content='https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:image:secure_url'
          content='https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:image:width'
          content='400'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:image:height'
          content='400'
        ></meta>
        <meta
          data-react-helmet='true'
          name='keywords'
          content='programming,coding,online, code online, online judge, practice, run code, test, expected outcome, programming competition, programming contest, online programming, online computer programming'
        ></meta>
        <meta
          data-react-helmet='true'
          name='twitter:card'
          content='summary_large_image'
        ></meta>
        <meta
          data-react-helmet='true'
          name='twitter:description'
          content='Are you desperate for learning? Here’s the Best Material awaiting for you, show your determination towards the knowledgeable world.'
        ></meta>
        <meta
          data-react-helmet='true'
          name='twitter:title'
          content={`Find the Best ${
            techDetails && techDetails.title
          } Courses & Tutorials | Datacode.in`}
        ></meta>
        <meta
          data-react-helmet='true'
          name='twitter:site'
          content='@datacode_in'
        ></meta>
        <meta
          data-react-helmet='true'
          name='twitter:creator'
          content='@datacode_in'
        ></meta>
        <meta
          data-react-helmet='true'
          name='twitter:image'
          content='https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png'
        ></meta>
        <link
          data-react-helmet='true'
          rel='shortlink'
          href='https://www.datacodoe.in/'
        ></link>
        <link
          data-react-helmet='true'
          rel='dns-prefetch'
          href='//www.datacode.in'
        ></link>
        <link
          data-react-helmet='true'
          rel='dns-prefetch'
          href='//s.w.org'
        ></link>
        <link
          data-react-helmet='true'
          rel='alternate'
          type='application/rss+xml'
          title='Datacode'
          href='https://www.datacode.in/'
        ></link>
        <link
          data-react-helmet='true'
          rel='canonical'
          href='https://www.datacode.in/'
        ></link>
      </Helmet>
      <div className='row m-0 category-page'>
        <div className='col-12 px-0'>
          <div className='row m-0 tech-tutorial-header'>
            <div className='col-12 px-md-5 px-2'>
              <div className='row m-0'>
                <div className='col-12 px-0'>
                  <p className='nav-link pt-2'>
                    <Link to='/home'>Library</Link> /{" "}
                    <Link to='/index_technologies'>Tutorials</Link> /{" "}
                    <Link to={`/tutorials/${technology}`}>{`${
                      techDetails && techDetails.title
                    } Tutorials`}</Link>
                  </p>
                </div>
              </div>
              <div className='row m-0 px-2 px-md-5'>
                <div className='col-md-6 col-12 pt-md-4 pt-1 left-header-section px-md-3 px-0'>
                  <h2>{techDetails && techDetails.title}</h2>
                  <h1>Tutorials & Courses</h1>
                  <p className='desktop-tutorial-text px-3 px-0'>
                    From building websites to analyzing data, the choice is
                    yours. Find the best of the best courses to learn from...
                  </p>
                </div>
                <div className='col-md-6 col-12 text-center'>
                  {techIcon.map(
                    (item, i) =>
                      item.technology === technology && (
                        <img
                          key={i}
                          height='300px'
                          width='300px'
                          src={require(`../../../../../../assets/images/svg/${item.svgSource}`)}
                          alt='avatar'
                          loading='lazy'
                          className='tech-turorial-img mx-md-auto'
                        />
                      ),
                  )}
                </div>
              </div>
            </div>
          </div>
          <h2 className='my-md-5 px-3 px-md-0 mt-5 mb-2 text-center'>{`Find Best ${
            techDetails && techDetails.title
          } Tutorials and Courses`}</h2>
          <div className='row m-0'>
            <div className='col-12 pl-md-5 py-2 filter-section d-inline col-md-3 pt-2 main'>
              <Filter checkedItems={checkedItems} handleFilter={handleFilter} />
              <div className='add-tutorial mt-3 mb-md-0 mb-4 p-4 align-items-center'>
                <Link to='/add_tutorial'>
                  <i className='fad fa-plus-circle green' />
                </Link>
                <h5>Add your Tutorial and contribute in communtiy</h5>
              </div>
            </div>
            <div className='col-12 col-md-9'>
              {
                <>
                  {tutorialListLoading ? (
                    <div className='d-flex text-center justify-content-center'>
                      <Loader
                        type='ThreeDots'
                        color='#30006d'
                        height={100}
                        width={100}
                        timeout={11000} //8 secs
                      />
                    </div>
                  ) : (
                    <div className='row m-0 d-flex justify-content-center tutorial-card-section'>
                      {tutorialsList && tutorialsList.length ? (
                        tutorialsList.map((item, i) => {
                          return (
                            <TutorialCard
                              handleTutorialList={handleTutorialList}
                              tutorial={item}
                              id={item.id}
                              tags={item.tags}
                              title={item.title}
                              likesCount={item.likes.length}
                              key={i}
                            />
                          );
                        })
                      ) : (
                        <img
                          height='350px'
                          width='350px'
                          src={require(`../../../../../../assets/images/svg/noitem.png`)}
                          alt='avatar'
                          loading='lazy'
                          className='mx-auto img-fluid img-circle'
                        />
                      )}
                    </div>
                  )}
                  {totalTutorials > 0 && (
                    <ReactPaginate
                      previousLabel={"Prev"}
                      nextLabel={"Next"}
                      pageCount={pageCount}
                      onPageChange={changePage}
                      containerClassName={"pagination"}
                      pageLinkClassName={"page"}
                      previousLinkClassName={"previousBtn"}
                      nextLinkClassName={"nextBtn"}
                      disabledClassName={"disabled"}
                      activeClassName={"activePage"}
                      activeLinkClassName={"activeClassLink"}
                    />
                  )}
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TechTutorials;
