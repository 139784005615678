import { Button, Drawer, Space } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Combobox } from "react-widgets";
import { Field, getFormValues, reduxForm } from "redux-form";
import { RenderInputField } from "../../../../../../shared_components/ReduxFormFields";
import { required } from "../../../../../../utils/validators";
import { createBatchRoadMap } from "../../../actions/operations";
import { DSAContent } from "../../DaysCodeDashboard/DaysCodeTimeLine/helper";
import { getTopicOptions } from "../../utils";

const AddBRoadmapDrawer = ({ batch, handleSubmit, submitting, initialize }) => {
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("right");
  const [topic, setTopic] = useState();

  const dispatch = useDispatch();

  const formStates =
    useSelector((state) => getFormValues("create-roadmap")(state)) || {};

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const onSubmit = (values) => {
    dispatch(
      createBatchRoadMap({
        topic: topic.name,
        batch: batch,
        day: values.day,
        title: values.title,
      }),
    );

    // dispatch(reset("create-roadmap"));
  };

  return (
    <>
      <Space>
        <Button type='primary' onClick={showDrawer}>
          Create Roadmap
        </Button>
      </Space>
      <Drawer
        title='Create Roadmap for batch'
        placement={placement}
        closable={false}
        onClose={onClose}
        open={open}
        key={placement}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Field
            type='number'
            name='day'
            label='Day'
            placeholder='Select Roadmap Day'
            component={RenderInputField}
            validate={[required]}
          />
          <label htmlFor='topic'>Select Topic</label>
          <Combobox
            data={getTopicOptions(DSAContent)}
            dataKey={"value"}
            textField='name'
            label='Select TOpic'
            placeholder={"Select Topic Name"}
            value={topic}
            validate={[required]}
            onChange={(value) => setTopic(value)}
          />

          <Field
            type='text'
            name='title'
            label='Roadmap Title'
            placeholder=''
            component={RenderInputField}
            validate={[required]}
          />
          <button
            type='submit'
            className='btn custom-button'
            // disabled={submitting}
          >
            Add
          </button>
        </form>
      </Drawer>
    </>
  );
};

export default reduxForm({
  form: "create-roadmap",
  fields: ["days"],
})(AddBRoadmapDrawer);
