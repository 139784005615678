import React from "react";
import PostEventRegSection from "../EventPage/PostEventRegSection";
import EventRegistration from "../EventRegistration";
import EventFeedbackForm from "./EventFeedbackForm";
import LoginRequireAction from "./LoginRequireAction";

const EventActionFormsSection = ({
  handleAttendance,
  eventDetails,
  id,
  checkUserEventReg,
  userData,
}) => {
  const getAction = (eventDetails) => {
    let action = "";
    if (eventDetails.hasRegistred) {
      return (action = "postReg");
    }
    if (eventDetails.hasRegistred !== true && eventDetails.state !== "past") {
      return (action = "regForm");
    }
    if (eventDetails.state === "past" && eventDetails.hasRegistred) {
      return (action = "feedbackForm");
    }
    if (eventDetails.state === "past" && !userData.email) {
      return (action = "loginRequire");
    }
    return action;
  };

  const renderActionComponent = (action) => {
    console.log(checkUserEventReg);

    const actionsConponents = {
      postReg: (
        <PostEventRegSection
          handleAttendance={handleAttendance}
          eventDetails={eventDetails}
        />
      ),
      regForm: (
        <EventRegistration
          id={id}
          checkUserEventReg={checkUserEventReg}
          eventDetails={eventDetails}
          userData={userData}
        />
      ),
      feedbackForm: <EventFeedbackForm eventDetails={eventDetails} />,
      loginRequire: <LoginRequireAction />,
    };
    return actionsConponents[action];
  };

  return (
    <>
      <div className='row m-0 my-5 px-md-0 px-3'>
        <div
          className='col-md-12 col-12 px-0  border text-center bg-white right form card-schadow rounded-lg'
          id='form'
        >
          {renderActionComponent(getAction(eventDetails))}
        </div>
      </div>
    </>
  );
};

export default EventActionFormsSection;
