import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import exportFromJSON from "export-from-json";
import {
  deleteClubApplication,
  editClubApplication,
  getClubApplications,
} from "../actions/operations";
import BootstrapTable from "react-bootstrap-table-next";
import CustomLoader from "../../../../shared_components/CustomLoader";
import { getColumns } from "./helpers";
import ApplicationSearchHeader from "./ApplicationSearchHeader";
import StatusUpdateModal from "./StatusUpdateModal";
import Pagination from "../../../../shared_components/Pagination";
import { Select } from "antd";

const Applications = () => {
  const dispatch = useDispatch();
  const { applicationsList, clubApplicationLoading, totalApplications } =
    useSelector((state) => state.club) || {};
  const [searchText, setSearchText] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [tempStatus, setTempStatus] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    dispatch(
      getClubApplications({
        search: searchText,
        page: currentPage,
        limit: pageLimit,
      }),
    );
  }, [searchText, pageLimit]);

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const handleStatus = (value, application) => {
    setTempStatus({ status: value.status, _id: application._id });
    setOpenModal(!openModal);
  };

  const handleUpdateStatusModal = (data) => {
    dispatch(editClubApplication({ _id: data._id, status: data.status })).then(
      (res) => {
        if (res.success) {
          dispatch(
            getClubApplications({
              search: searchText,
              page: currentPage,
              limit: pageLimit,
            }),
          );
          toggleModal();
        }
      },
    );
  };

  const handleUserDeleteAction = (id) => {
    dispatch(deleteClubApplication(id)).then((res) => {
      if (res) {
        dispatch(
          getClubApplications({
            search: searchText,
            page: currentPage,
            limit: pageLimit,
          }),
        );
      }
    });
  };

  const handleSearch = (text) => {
    setSearchText(text);
  };

  // Pagination
  const applicationsPerPage = pageLimit;
  const pageCount = Math.ceil(totalApplications / applicationsPerPage);
  const handlePageChange = (page) => {
    setCurrentPage(page);
    dispatch(
      getClubApplications({ search: searchText, page, limit: pageLimit }),
    );
  };

  const fileName = "ClubApplication";
  const exportType = exportFromJSON.types.xls;
  const downloadExel = () => {
    dispatch(getClubApplications({ search: searchText, limit: "all" })).then(
      (res) => {
        if (res) {
          exportFromJSON({ data: res.data.applications, fileName, exportType });
        }
      },
    );
  };

  let columns = getColumns(handleStatus, handleUserDeleteAction);
  return (
    <>
      <ApplicationSearchHeader
        downloadExel={downloadExel}
        totalApplications={totalApplications}
        handleSearch={handleSearch}
        searchText={searchText}
      />
      {clubApplicationLoading ? (
        <CustomLoader />
      ) : (
        applicationsList &&
        applicationsList.length && (
          <div className='table table-responsive application-table'>
            <div className=''>
              <Select
                defaultValue={10}
                style={{
                  width: 120,
                }}
                onChange={(value) => setPageLimit(value)}
                options={[
                  {
                    value: 10,
                    label: "10 Rows",
                  },
                  {
                    value: 25,
                    label: "25 Rows",
                  },
                  {
                    value: 50,
                    label: "50 Rows",
                  },
                  {
                    value: 100,
                    label: "100 Rows",
                  },
                  {
                    value: 500,
                    label: "500 Rows",
                  },
                  {
                    value: "all",
                    label: "All Rows",
                  },
                ]}
              />
            </div>
            <BootstrapTable
              keyField='_id'
              bordered={true}
              data={applicationsList}
              columns={columns}
              search
              hover={true}
              classes='table-td'
            />
            {totalApplications > 0 && (
              <div className='d-flex justify-content-center'>
                <Pagination
                  currentPage={currentPage}
                  totalPages={pageCount}
                  onPageChange={handlePageChange}
                />
              </div>
            )}
          </div>
        )
      )}
      <StatusUpdateModal
        open={openModal}
        toggle={toggleModal}
        onSubmit={(e) => handleUpdateStatusModal(e)}
        tempStatus={tempStatus}
      />
    </>
  );
};
export default Applications;
