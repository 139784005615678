import React, { Component } from "react";
import RecommendPost from "../../../right_section/RecommendPost";
import RightSideSection from "../../../right_section/RightSideSection";
class DjangoInterviewQuestions extends Component {
  state = {
    users: [
      {
        question: "Whats is Asjay",
        answer: "Magic Boy",
      },
      {
        question: "Whats is Asjay",
        answer: "Magic Boy",
      },
      {
        question: "Whats is Asjay",
        answer: "Magic Boy",
      },
      {
        question: "Whats is Asjay",
        answer: "Magic Boy",
      },
      {
        question: "Whats is Asjay",
        answer: "Magic Boy",
      },
    ],
  };
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <>
        <div className='container-fluids px-3'>
          <div className='row m-0'>
            <div className='col'>
              <div className='card bg-warning'>
                <div className='card-body'>
                  <h1 className='card-title text-dark display-5 '>
                    Dome Interview Question
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className='row mt-5'>
            <div className='col-md-1'></div>

            <div className='col-md-7'></div>
            <div className='col-md-4 mt-5'>
              <h5 className='mb-4'>Other Learning Resources</h5>
              <RecommendPost
                imgsource='indexcover1.jpeg'
                RecPostHading='Interview Heading'
              />
              <RecommendPost
                imgsource='indexcover1.jpeg'
                RecPostHading='Interview Heading'
              />
              <RecommendPost
                imgsource='indexcover1.jpeg'
                RecPostHading='Interview Heading'
              />
              <RecommendPost
                imgsource='indexcover1.jpeg'
                RecPostHading='Interview Heading'
              />
              <RecommendPost
                imgsource='indexcover1.jpeg'
                RecPostHading='Interview Heading'
              />
              <RecommendPost
                imgsource='indexcover1.jpeg'
                RecPostHading='Interview Heading'
              />

              <RightSideSection />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default DjangoInterviewQuestions;
