import { setClubHandlers } from "./setClub";
import { getClubHandlers } from "./getClub";
import { createReducer } from "../../../../core/reduxUtils";

const initialState = {
  applicationsList: [],
  totalApplications: 0,
  clubApplication: {},
  clubApplicationLoading: false,
  hideFooter: false,
};

const handlers = {
  ...setClubHandlers,
  ...getClubHandlers,
};

const clubReducer = createReducer(initialState, handlers);

export default clubReducer;
