import { connect } from "react-redux";
import {
  getDaysUsers,
  userSelectionAction,
  deleteDaysUser,
  assignUserMentor,
  deleteAssignedUserMentor,
} from "../../../actions";

import UsersList from "./UsersList";

const mapStateToProps = ({ dayscode, user }) => ({
  currentUser: user.currentUser ? user.currentUser : {},
  usersListLoading: dayscode.problemsListLoading,
  problemsList: dayscode.problemsList,
  daysUsers: dayscode.daysUsers,
});

const mapDispatchToProps = {
  getDaysUsers,
  userSelectionAction,
  deleteDaysUser,
  assignUserMentor,
  deleteAssignedUserMentor,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);
