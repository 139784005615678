import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrganiserEvents, handleDeleteEvent } from "../../actions";
import DeleteEventModal from "../EventSharedComponent/DeleteEventModal";
import { SharedEventLoadMoreButton } from "../EventSharedComponent/EventSharedComponent";
import EventCard from "../EventsList/EventCard";

const EventDashboardPage = () => {
  const dispatch = useDispatch();
  const { userName, email } = useSelector((state) => state?.user?.currentUser);
  const { eventsList, eventsListLoading } = useSelector(
    (state) => state?.event,
  );

  const [pageNumber, setPageNumber] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [tempDeleteId, setTempDeleteId] = useState();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (userName && email) {
      dispatch(
        getOrganiserEvents({ page: pageNumber, limit: 4, email, state: "all" }),
      );
    }
  }, [userName]);

  // Scroller  Loader ===========---------------------------------

  // const observer = useRef()
  // const lastBookElementRef = useCallback(node => {
  //   if (eventsListLoading) return
  //   if (observer.current) observer.current.disconnect()
  //   observer.current = new IntersectionObserver(entries => {
  //     if (entries[0].isIntersecting) {
  //       setPageNumber(prevPageNumber => prevPageNumber + 1)
  //     }
  //   })
  //   if (node) observer.current.observe(node)
  // }, [eventsListLoading])

  const handleLoadMore = (page) => {
    if (userName && email) {
      dispatch(getOrganiserEvents({ page, limit: 4, email, state: "all" }));
    }
    setPageNumber(page);
  };

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const handleRemoveEvent = () => {
    dispatch(handleDeleteEvent(tempDeleteId)).then((res) => {
      if (res && res.success) {
        setOpenModal(!openModal);
        dispatch(
          getOrganiserEvents({ page: 0, limit: 4, email, state: "all" }),
        );
      }
    });
  };

  const deleteEvent = (id) => {
    setTempDeleteId(id);
    setOpenModal(!openModal);
  };

  return (
    <>
      <h1>Event Dashboard</h1>

      <div className='row m-0 mx-md-5  mx-0 d-flex align-items-stretch px-md-5 px-1'>
        {eventsList &&
          eventsList.map((event, i) => {
            if (eventsList.length === i + 1) {
              // return <div ref={lastBookElementRef} key={i}><EventCard deleteEvent={deleteEvent} data={event} /></div>
              return (
                <div key={i}>
                  <EventCard deleteEvent={deleteEvent} data={event} />
                </div>
              );
            } else {
              return <EventCard deleteEvent={deleteEvent} data={event} />;
            }
          })}
      </div>
      <SharedEventLoadMoreButton
        loading={eventsListLoading}
        setPageNumber={handleLoadMore}
        pageNumber={pageNumber}
      />
      <DeleteEventModal
        open={openModal}
        title={"Are you sure want to delete event"}
        toggle={toggleModal}
        onSubmit={handleRemoveEvent}
        submitButtonName={"Delete Event"}
      />
    </>
  );
};
export default EventDashboardPage;
