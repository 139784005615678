import PropTypes from "prop-types";
import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Compiler from "./Compiler";

const CompilerModal = ({
  open,
  toggle,
  preCode,
  preCompilerLanguage,
  onSubmit,
  submitButtonName,
  submitButtonColor,
  title,
  message,
}) => (
  <Modal isOpen={open} toggle={toggle} size='lg'>
    <ModalHeader toggle={toggle} className='modal-header'>
      Compiler Code
    </ModalHeader>
    <ModalBody>
      <Compiler
        solutionSubmitted={true}
        preCompilerCode={preCode}
        preCompilerLanguage={preCompilerLanguage}
      />
    </ModalBody>
  </Modal>
);

CompilerModal.defaultProps = {
  message: "",
  title: "",
  submitButtonName: "Okay",
  onSubmit: () => {},
  submitButtonColor: "danger",
};

CompilerModal.propTypes = {
  message: PropTypes.string,
  title: PropTypes.string,
  submitButtonName: PropTypes.string,
  submitButtonColor: PropTypes.string,
  onSubmit: PropTypes.func,
  toggle: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default CompilerModal;
