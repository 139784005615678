import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import PropTypes from "prop-types";
import { GoogleLogout } from "react-google-login";
import { debounce } from "../helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import Loader from "react-loader-spinner";

const Header = ({
  drawerClickHandler,
  isShowAddTutorial,
  currentUser,
  logoutUser,
  userProfileLoading,
  testimonials,
  showAddTestimonial,
}) => {
  const history = useHistory();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffSet);
  const [visible, setVisible] = useState(true);

  const handleScroll = debounce(() => {
    const currentScrollPos = window.pageYOffset;

    setVisible(
      (prevScrollPos > currentScrollPos &&
        prevScrollPos - currentScrollPos > 60) ||
        currentScrollPos < 10,
    );

    setPrevScrollPos(currentScrollPos);
  }, 100);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos, visible, handleScroll]);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const handleLogout = () => {
    logoutUser();
    history.push("/");
  };

  return (
    <>
      <div
        className={`${
          !visible && `header-hide`
        } row m-0 header card-schadow px-md-3 px-2 py-md-0 py-2`}
      >
        {currentUser.email ? (
          <div className='col-4 m-0 left-section px-2 px-md-0'>
            <i
              className='toggle-button fas fa-bars mx-md-3 mt-0 mt-1'
              onClick={() => drawerClickHandler()}
            />
            {/* <Link to="/"><h1 className="m-0 text-sm-right header-text">Learning Portal | Datacode.in</h1></Link> */}
            {/* <h1 className="m-0 ">Datacode.in</h1> */}
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle caret className='category-dropdown'>
                Categories
              </DropdownToggle>
              <DropdownMenu className='dropdown-menu p-3 text-center'>
                <h2>How datacode.in community works?</h2>
                <div className='row m-0 d-flex'>
                  <div className='col-3 text-center'>
                    <Link to='/index_technologies'>
                      <img
                        height='70px'
                        width='70px'
                        src={require(`../../../../assets/images/svg/TutorialDark.png`)}
                        alt='avatar'
                        className='my-3'
                      />
                    </Link>
                    <Link to='/index_technologies'>
                      <h6 className='my-2'>Learn</h6>
                    </Link>
                  </div>
                  <div className='col-3 text-center'>
                    <Link to='/index_programming_questions'>
                      <img
                        height='70px'
                        width='70px'
                        src={require(`../../../../assets/images/svg/PracticeDark.png`)}
                        alt='avatar'
                        className='my-3'
                      />
                    </Link>
                    <Link to='/index_programming_questions'>
                      <h6 className='my-2'>Practice</h6>
                    </Link>
                  </div>
                  <div className='col-3 text-center'>
                    <Link to='/index_quize_tests'>
                      <img
                        height='70px'
                        width='70px'
                        src={require(`../../../../assets/images/svg/TestDark.png`)}
                        alt='avatar'
                        className='my-3'
                      />
                    </Link>
                    <Link to='/index_quize_tests'>
                      <h6 className='my-2'>Test</h6>
                    </Link>
                  </div>
                  <div className='col-3 text-center'>
                    <Link to='/contributor'>
                      <img
                        height='70px'
                        width='70px'
                        src={require(`../../../../assets/images/svg/Mcq.png`)}
                        alt='avatar'
                        className='my-3'
                      />
                    </Link>
                    <Link to='/contributor'>
                      <h6 className='my-2'>Contribute</h6>
                    </Link>
                  </div>
                </div>
              </DropdownMenu>
            </Dropdown>
          </div>
        ) : (
          <Link to='/'>
            <img
              height='50px'
              width='180px'
              src={require(`../../../../assets/images/svg/logo.svg`)}
              alt='avatar'
              loading='lazy'
              className='header-logo-show'
            />
          </Link>
        )}
        {currentUser.email && (
          <div className='col-4 p-0 text-center justify-content-center'>
            <Link to='/'>
              <img
                height='50px'
                width='180px'
                src={require(`../../../../assets/images/svg/logo.svg`)}
                alt='avatar'
                loading='lazy'
                className='header-text'
              />
            </Link>
            <Link to='/'>
              <img
                height='40px'
                width='40px'
                src={require(`../../../../assets/images/svg/monolog.svg`)}
                alt='avatar'
                loading='lazy'
                className='d-md-none'
              />
            </Link>
          </div>
        )}
        <div className='col-4 p-0 d-md-flex text-right right-section m-0'>
          {!currentUser.email ? (
            <div>
              {isShowAddTutorial ? (
                <Link to='/add_tutorial'>
                  <i className='fa fa-plus-circle green mr-2' />
                  Add Tutorial
                </Link>
              ) : (
                <>
                  <Link to='/login'>
                    <div className='btn join-us mx-3'>Login</div>
                  </Link>
                  <Link to='/signup'>
                    <div className='btn login-show-btn '>Join Us</div>
                  </Link>
                </>
              )}
            </div>
          ) : (
            <>
              {isShowAddTutorial ? (
                <Link to='/add_tutorial'>
                  <i className='fa fa-plus-circle green mr-2' />
                  Add Tutorial
                </Link>
              ) : (
                <>
                  <div className='header-profile d-flex align-items-center'>
                    <UncontrolledDropdown
                      setActiveFromChild
                      className='profile-dropdown'
                    >
                      <DropdownToggle tag='a' className='mr-3'>
                        <i className='fa fa-plus black' />
                      </DropdownToggle>
                      <DropdownMenu
                        className='dropdown-menu mt-3 card-schadow'
                        right
                      >
                        <DropdownItem header>
                          <Link to={`/contributor`}>
                            <span>Add Contribution</span>
                          </Link>
                        </DropdownItem>
                        <DropdownItem>
                          <Link to={`/add_tutorial`}>
                            <span>
                              <i className='fa fa-book mr-2' />
                              Add Tutorials
                            </span>
                          </Link>
                        </DropdownItem>
                        <DropdownItem>
                          <Link to={`/speaker_form`}>
                            <span>
                              <i className='fa fa-star mr-2' />
                              Become Tech Speaker
                            </span>
                          </Link>
                        </DropdownItem>
                        {/* {!(testimonials && testimonials.find((item) => item.email === currentUser.email)) &&
                          <DropdownItem onClick={() => showAddTestimonial()} className="btn add-testimomnial-btn"><i className="fal fa-bug mr-2" /> What do you say for us?</DropdownItem>
                        } */}
                        <DropdownItem>
                          <Link to={`/contact_us`}>
                            <span>
                              <i className='fa fa-bug mr-2' />
                              Report Bug
                            </span>
                          </Link>
                        </DropdownItem>
                        <DropdownItem>
                          <Link to='/contact_us'>
                            <span>
                              <i className='fa fa-puzzle-piece mr-2' />
                              Ask Feature
                            </span>
                          </Link>
                        </DropdownItem>
                        <DropdownItem>
                          <Link to='/contact_us'>
                            <span>
                              <i className='fa fa-edit mr-2' />
                              Contact us
                            </span>
                          </Link>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <Link
                      to={`/my_profile/${currentUser && currentUser.userName}`}
                    >
                      {userProfileLoading ? (
                        <div className='rounded-circle header-profile-img'>
                          <Loader
                            type='Audio'
                            color='#30006d'
                            height={40}
                            width={40}
                            timeout={8000} //3 secs
                          />
                        </div>
                      ) : (
                        <img
                          className='rounded-circle header-profile-img'
                          height='40'
                          width='40'
                          loading='lazy'
                          src={
                            currentUser && currentUser.imgUrl
                              ? currentUser.imgUrl
                              : require(`../../../../assets/images/svg/monolog.svg`)
                          }
                          alt='datacode'
                        />
                      )}
                    </Link>
                    <UncontrolledDropdown
                      setActiveFromChild
                      className='profile-dropdown'
                    >
                      <DropdownToggle tag='a' className='ml-1'>
                        <i className='fa fa-angle-down black' />
                      </DropdownToggle>
                      <DropdownMenu className='dropdown-menu mt-3' right>
                        <DropdownItem header>
                          <span>{currentUser && currentUser.firstName}</span>
                        </DropdownItem>
                        <DropdownItem>
                          <Link to={`/my_profile/${currentUser.userName}`}>
                            <span>
                              <i className='fa fa-user mr-2' />
                              My Profile
                            </span>
                          </Link>
                        </DropdownItem>
                        <DropdownItem>
                          <Link to={`/my_library`}>
                            <span>
                              {" "}
                              <i className='fa fa-window-restore mr-2' />
                              My Library
                            </span>
                          </Link>
                        </DropdownItem>
                        <DropdownItem>
                          <Link to='/edit_profile'>
                            <span>
                              <i className='fa fa-edit mr-2' />
                              Edit Profile
                            </span>
                          </Link>
                        </DropdownItem>
                        <DropdownItem>
                          <span onClick={() => handleLogout()}>
                            <FontAwesomeIcon
                              icon={faSignOutAlt}
                              className='mr-2'
                            />
                            Log out
                          </span>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

Header.defaultProps = {
  email: "",
  currentUser: {},
};

Header.propTypes = {
  email: PropTypes.string,
  currentUser: PropTypes.object,
};
export default Header;
