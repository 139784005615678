import React, { useEffect, useState } from "react";
import { reduxForm } from "redux-form";

import TextEditor from "../../../../../aticles/BlogsEditor/TextEditor";

const ImplementationSection = ({
  reset,
  handleSubmit,
  submitting,
  id,
  lessonDetails,
  editLesson,
}) => {
  const [implementationText, getImplementationText] = useState("<p> </p>");
  const [isEdit, setEdit] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setEdit(true);
    getImplementationText(lessonDetails && lessonDetails.implementations);
  }, []);

  const handleImplementationText = (text) => {
    getImplementationText(text);
  };

  const onSubmit = (values) => {
    const lesson = { ...values };
    lesson["implementations"] = implementationText;
    if (id) {
      lesson["id"] = id;
      editLesson(lesson);
    }
    reset("edit-lesson");
  };

  return (
    <>
      <form className='p-3 px-1' onSubmit={handleSubmit(onSubmit)}>
        <small>
          Lorazepam, sold under the brand name Ativan among others, is a
          benzodiazepine medication. It is used to treat anxiety disorders,
          trouble sleeping, severe agitation, active seizures including status
          epilepticus, alcohol withdrawal, and chemotherapy-induced nausea and
          vomiting
        </small>
        <div className='my-3 lesson-texteditor'>
          <label className='form-label text-left pt-5'>
            Implementation{" "}
            {`(${implementationText && implementationText.length - 8})`}
          </label>
          <div className='mb-3 border '>
            {isEdit && lessonDetails && lessonDetails.implementations && (
              <TextEditor
                handleTextEditor={handleImplementationText}
                text={lessonDetails.implementations ? implementationText : ""}
              />
            )}
            {!isEdit && (
              <TextEditor
                handleTextEditor={handleImplementationText}
                text={lessonDetails.implementations ? implementationText : ""}
              />
            )}
          </div>
        </div>
        <div className='row my-4'>
          <div className='col-12 text-right'>
            <button
              type='submit'
              className='btn custom-border-capsule-button'
              disabled={submitting}
            >
              <span>Save</span>
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default reduxForm({
  form: "edit-lesson",
})(ImplementationSection);
