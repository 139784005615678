import PropTypes from "prop-types";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Link as LinkScroll } from "react-scroll";

import CustomFilterSearch from "../../../../../shared_components/CustomFilterSearch";
import EventCarousel from "./EventCarousel";
import EventSearchPage from "./EventSearchPage";
import EventsListSection from "./EventsListSection/EventsListSection";

const EventsList = ({
  upcomingEventsList,
  pastEventsList,
  liveEventsList,
  eventsList,
  searchEventsList,
  showSearchResultPage,
  searchEventsListLoading,
  eventsListLoading,
  currentUser: { userName, email },
}) => {
  // const [openFilter, setOpenFilter] = useState(false);

  return (
    <>
      <EventCarousel />
      <div className='row m-0 px-md-5 px-3 create-event-promo align-items-center'>
        <div className='col-md-8 col-12 px-0'>
          <h1 className='align-item-center'>
            Create and Share Community Events and Webinars
          </h1>
        </div>
        <div className='col-md-4 col-12 px-0 align-items-center text-md-right text-center'>
          <LinkScroll to='create-event' spy={true} smooth={true}>
            <button className='btn custom-button m-3 m-md-0'>
              <span className='text-nowrap'>
                <i className='fas fa-plus search-icon' /> Add Event
              </span>
            </button>
          </LinkScroll>
        </div>
      </div>
      <div className='row mx-0'>
        <div className='col-12 px-md-0 px-3 mb-2 mb-md-0 d-flex event-list-nav'>
          <Link to='/event/dashboard'>
            <span className='my-events mx-3'>My Dashboard</span>
          </Link>
          <Link to='/events/me'>
            <span className='my-events mx-3'>My Events</span>
          </Link>
          {/* {
              openFilter ?
              <>
              <i className="fal fa-times filter-icon" onClick={() => setOpenFilter(false)} />
              <EventFilter />
              </>
              :
              <i className="fal fa-sliders-h filter-icon " onClick={() => setOpenFilter(true)} />
            } */}
        </div>
      </div>
      <CustomFilterSearch />
      {showSearchResultPage ? (
        <EventSearchPage
          searchEventsListLoading={searchEventsListLoading}
          searchEventsList={searchEventsList}
          showSearchResultPage={showSearchResultPage}
        />
      ) : (
        <EventsListSection
          upcomingEventsList={upcomingEventsList}
          pastEventsList={pastEventsList}
          liveEventsList={liveEventsList}
          eventsList={eventsList}
          eventsListLoading={eventsListLoading}
        />
      )}
      <div
        className='row m-0 px-md-5 px-2 d-flex justigy-content-center align-items-center event-promot-section'
        id='create-event'
      >
        <div className='col-12 p-3 px-md-5 text-center'>
          <h2 className='text-white'>
            Got an event to promote? Want to be involved in community?
          </h2>
          <p>
            We are welcoming people from all over the world into Datacode
            community. Datacode is the perfect place to put on an event to boost
            your brand, find the right audience and make the best impacts on
            others.
          </p>
          <h3 className='mb-md-5 mb-3'>Become an event host</h3>
          <div className='row m-0 justify-content-center'>
            <div className='col-md-3 text-center col-12 my-md-0'>
              <div
                type='button'
                className='btn custom-button text-center m-3 m-md-0'
              >
                <span className='text-nowrap'>As A Contributor</span>
              </div>
            </div>
            <div className='col-md-3 text-center col-12 my-md-0'>
              <Link to='/create_event'>
                <div
                  type='button'
                  className='btn custom-button text-center m-3 m-md-0'
                >
                  <span className='text-nowrap'>As An Organizer</span>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

EventsList.defaultProps = {
  eventsList: [],
  eventsListLoading: false,
};

EventsList.propTypes = {
  eventsList: PropTypes.array,
  getEventsList: PropTypes.func.isRequired,
  eventsListLoading: PropTypes.bool,
};

export default EventsList;
