import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { getQuizChallenges, removeQuizChallenge } from "../../../../actions";

import QuizChallengesList from "./QuizChallengesList";

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser ? state.user.currentUser : {},
  problemsListLoading: state.dayscode.problemsListLoading,
  quizChallengesList: state.dayscode.quizChallengesList,
});

const mapDispatchToProps = {
  getQuizChallenges,
  removeQuizChallenge,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(QuizChallengesList),
);
