import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import PropTypes from "prop-types";

const StatusUpdateModal = ({
  open,
  toggle,
  onSubmit,
  tempStatus
}) => (
  <Modal isOpen={open} toggle={toggle} className="">
    <ModalHeader className="p-3 m-0 text-white rounded-top" toggle={toggle}>
      <i className="fal fa-bell pr-2" /> Application Status Update to {tempStatus?.status}
    </ModalHeader>
    <ModalBody>
      <span>Are you sure, you want to change status to {tempStatus?.status}</span>
    </ModalBody>
    <ModalFooter>
      <div className="display-flex">
        <Button className="btn-success" onClick={() => onSubmit(tempStatus)}>
          Confirm
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </div>
    </ModalFooter>
  </Modal>
);

StatusUpdateModal.defaultProps = {
  onSubmit: () => { },
};

StatusUpdateModal.propTypes = {
  onSubmit: PropTypes.func,
  toggle: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default StatusUpdateModal;
