import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Combobox from "react-widgets/Combobox";

import { getInterviewQuestionsList } from '../../actions'
import { technologyOptions } from '../../../../../utils'
import CustomLoader from '../../../../../shared_components/CustomLoader';

const InterviewQuestions = () => {
  const dispatch = useDispatch()

  const interviewQuestionsList = useSelector(({ placement }) => placement.interviewQuestionsList)
  const interviewQuestionsListLoading = useSelector(({ placement }) => placement.interviewQuestionsListLoading)

  const [tech, setTech] = useState()

  const onSubmit = () => {
    dispatch(getInterviewQuestionsList(tech.value))
  }

  return (
    <>
      <h1 className="my-5 text-center">Interview Questions</h1>
      <div className="row mx-0 d-flex justify-content-center">
        <div className="col-md-6 col-12 px-md-4 px-0 my-3 px-3">
          <label className="form-label">Select Technology</label>
          <Combobox
            data={technologyOptions}
            dataKey={"value"}
            textField='tech'
            placeholder={"Select Technology"}
            value={tech}
            onChange={(e) => setTech(e)}
          />
          <button onClick={() => onSubmit()} className="my-2 text-right btn-sm btn-primary">Show</button>
          {
            interviewQuestionsListLoading ?
              <CustomLoader />
              :
              <div className="my-5">
                {
                  interviewQuestionsList && interviewQuestionsList.length ? interviewQuestionsList.map((item, i) => (
                    <div className="row mx-0 border my-3 card-schadow rounded" key={i}>
                      <div className="col-12 p-2">
                        <div className="d-flex justify-content-between">
                          <p className="text-right mb-1">{item.technology}</p>
                        </div>
                        <h6 className="bg-light">{item.question}</h6>
                        <p>{item.answer}</p>
                      </div>
                    </div>
                  ))
                    :
                    interviewQuestionsList && interviewQuestionsList.length === 0 && <h1 className="text-center">No Question Available</h1>
                }
              </div>
          }
        </div>
      </div>
    </>
  )
}

export default InterviewQuestions