import React from "react";
import { Link } from "react-scroll";

const PeopleWords = ({
  item,
  currentUser,
  handleTestimonialEdit,
  handleTestimonialDelete,
}) => {
  return (
    <>
      <div className='row mx-0 people-card h-100'>
        <div className='col-12 text-center card-schadow people-card-body card h-100 border-none '>
          <div className='card-body p-0 '>
            <div className='quote'>
              <i className='fas fa-quote-left text-left' />
            </div>
            <p className=''>{item.testimonial}</p>
          </div>
          <div className='card-footer bg-white border-top-0 p-0'>
            <img
              className='rounded-circle'
              height='100'
              width='100'
              src={
                item && item.imgUrl
                  ? item.imgUrl
                  : require(`../../../../assets/images/svg/monolog.svg`)
              }
              alt='avatar'
              loading='lazy'
            />
            <h2>{item.name}</h2>
            <span>
              <a href={item.linkedin} target='_blank' rel='noopener noreferrer'>
                <i className='fab fa-linkedin-in mr-2' />
              </a>
              <Link to='/'>
                <img
                  height='15px'
                  width='15px'
                  src={require(`../../../../assets/images/svg/monolog.svg`)}
                  alt='avatar'
                  loading='lazy'
                  // className="d-md-none"
                />
              </Link>
              {/* <a href={item.github} target="_blank"><FontAwesomeIcon className="ml-2" icon={faGithub} size="1x" /></a> */}
              {currentUser && currentUser.email === item.email && (
                <>
                  <i
                    onClick={() => handleTestimonialEdit(item)}
                    className='far fa-edit ml-2 voilet'
                  />
                  <i
                    onClick={() => handleTestimonialDelete(item)}
                    className='far fa-trash ml-2 voilet'
                  />
                </>
              )}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default PeopleWords;
