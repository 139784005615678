import axios from "axios";

import * as actions from "./actionCreators";
import DatacodeConfig from "../../../../core/config";
import {
  SET_ADD_INTERVIEW_QUESTION_LOADING,
  SET_INTERVIEW_QUESTIONS_LIST_LOADING,
} from "../constants";

const baseURL = DatacodeConfig.baseURL;

export const addInterviewQuestion = (question) => (dispatch) => {
  dispatch({ type: SET_ADD_INTERVIEW_QUESTION_LOADING });
  return axios
    .post(`${baseURL}add_interview_question`, question)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: SET_ADD_INTERVIEW_QUESTION_LOADING });
        return { success: true };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_ADD_INTERVIEW_QUESTION_LOADING });
      console.log("Add Interview Question Error", error);
    });
};

export const linkPreview = (link) => (dispatch) => {
  const data = {
    key: "3d1af686ac55371d129d64d81d3f2625",
    q: link,
  };
  return axios
    .post(`https://api.linkpreview.net`, data)
    .then((res) => {
      if (res.status === 200) {
        return { success: true, linkPrev: res.data };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      console.log("Add Tutorial Error", error);
    });
};

export const getInterviewQuestionsList = (technology) => (dispatch) => {
  dispatch({ type: SET_INTERVIEW_QUESTIONS_LIST_LOADING });
  return axios
    .get(`${baseURL}get_interview_questions_tech/${technology}`)
    .then(({ data }) => {
      if (data.success) {
        dispatch(actions.setInterviewQuestionsList(data));
        return { success: true, data: data.tutorial };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_INTERVIEW_QUESTIONS_LIST_LOADING });
      console.log("get tutorials error", error);
    });
};

// export const getInterviewQuestionById = (id) => (dispatch) => {
//   dispatch({ type: SET_INTERVIEW_QUESTIONS_LIST_LOADING });
//   return axios
//     .post(`${baseURL}/tutorial/get_tutorial`, { id })
//     .then(({ data }) => {
//       if (data.success) {
//         dispatch(getUserProfile(data.tutorial.userName));
//         dispatch(actions.setTutorialById(data));
//         return { success: true, data: data.tutorial };
//       }
//     })
//     .catch((error) => {
//       dispatch({ type: SET_INTERVIEW_QUESTIONS_LIST_LOADING });
//       console.log("get tutorials error", error);
//     });
// };

export const getInterviewQuestionByUserName = (userName) => (dispatch) => {
  dispatch({ type: SET_INTERVIEW_QUESTIONS_LIST_LOADING });
  return axios
    .get(`${baseURL}get_interview_questions_username/${userName}`)
    .then(({ data }) => {
      if (data.success) {
        dispatch(actions.setInterviewQuestionsByUserName(data));
        return { success: true, data: data.interviewQuestions };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_INTERVIEW_QUESTIONS_LIST_LOADING });
      console.log("get interview error", error);
    });
};

export const fetchInterviewQuestions = () => (dispatch) => {
  dispatch({ type: SET_INTERVIEW_QUESTIONS_LIST_LOADING });
  return axios
    .get(`${baseURL}/fetch_interview_questions`)
    .then(({ data }) => {
      if (data.success) {
        //dispatch(actions.setTutorialsByUserName(data));
        return { success: true, data: data.tutorials };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_INTERVIEW_QUESTIONS_LIST_LOADING });
      console.log("get tutorials error", error);
    });
};

// export const likeInterviewQuestion = (data) => (dispatch) => {
//   return axios
//     .put(`${baseURL}/tutorial/like_tutorial`, data)
//     .then(({ data }) => {
//       if (data.success) {
//         return { success: true, data: data.tutorial };
//       }
//     })
//     .catch((error) => {
//       console.log("get tutorials error", error);
//     });
// };

// export const unlikeInterviewQuestion = (data) => (dispatch) => {
//   return axios
//     .put(`${baseURL}/tutorial/unlike_tutorial`, data)
//     .then(({ data }) => {
//       if (data.success) {
//         return { success: true, data: data.tutorial };
//       }
//     })
//     .catch((error) => {
//       console.log("get tutorials error", error);
//     });
// };

// export const commentInterviewQuestion = (data) => (dispatch) => {
//   return axios
//     .put(`${baseURL}/tutorial/comment_tutorial`, data)
//     .then(({ data }) => {
//       if (data.success) {
//         dispatch(actions.setTutorialById(data));
//         return { success: true, data: data.tutorial };
//       }
//     })
//     .catch((error) => {
//       console.log("comment tutorials error", error);
//     });
// };

export const editInterviewQuestion = (id, data) => (dispatch) => {
  dispatch({ type: SET_ADD_INTERVIEW_QUESTION_LOADING });
  return axios
    .put(`${baseURL}/interview_question/${id}`, data)
    .then(({ data }) => {
      if (data.success) {
        dispatch({ type: SET_ADD_INTERVIEW_QUESTION_LOADING });
        dispatch(
          getInterviewQuestionByUserName(data.interviewQuestion.userName),
        );
        return { success: true };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_ADD_INTERVIEW_QUESTION_LOADING });
      console.log("edit interview error", error);
    });
};

export const deleteInterviewQuestion = (item) => (dispatch) => {
  dispatch({ type: SET_INTERVIEW_QUESTIONS_LIST_LOADING });
  return axios
    .delete(`${baseURL}/interview_question/${item.id}`)
    .then(({ data }) => {
      if (data.success) {
        dispatch({ type: SET_INTERVIEW_QUESTIONS_LIST_LOADING });
        dispatch(getInterviewQuestionByUserName(item.userName));
        return { success: true };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_INTERVIEW_QUESTIONS_LIST_LOADING });
      console.log("delete interview error", error);
    });
};
