import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { setSearchQueryObject } from "../../components/main_component/events/actions";
import {
  renderMultiCheckboxField,
  RenderInputField,
  renderMultiRadioField,
} from "../../shared_components/ReduxFormFields";
import { required } from "../../utils/validators";

const CustomFilterSearch = ({
  reset,
  handleSubmit,
  submitting,
  initialize,
  formStates,
}) => {
  const dispatch = useDispatch();
  const [openFilterBox, setOpenFilterBox] = useState(false);

  const onSubmit = (values) => {
    const event = { ...values };
    console.log("Event Search ", event);
    dispatch(setSearchQueryObject(event));
  };

  let optionsList = [
    { id: 1, label: "All", value: "all" },
    { id: 2, label: "Upcoming", value: "upcoming" },
    { id: 3, label: "Live", value: "live" },
    { id: 4, label: "Past", value: "past" },
  ];

  let tagsList = [
    { id: 1, label: "C", value: "c" },
    { id: 2, label: "C++", value: "cpp" },
    { id: 3, label: "Python", value: "python" },
    { id: 4, label: "Java", value: "java" },
    { id: 5, label: "Javascript", value: "javascript" },
    { id: 6, label: "Go", value: "go" },
  ];

  return (
    <>
      <div className='row m-4 d-flex justify-content-center custom-filter-search'>
        <div className='col-md-6 col-lg-4 col-12'>
          {!openFilterBox ? (
            <div className='row px-md-5 px-3'>
              <div className='col-12 text-center d-flex justify-content-center'>
                <div className='d-flex mx-2 px-md-2 px-1 fontSearch'>
                  <input
                    type='text'
                    className='search'
                    placeholder='Username / Name'
                    onClick={() => setOpenFilterBox(true)}
                  />
                  <i className='fa fa-search search-icon' />
                </div>
              </div>
            </div>
          ) : (
            <div className='row mx-0'>
              <div className='col-12 p-0 border custom-filter-search-body '>
                <form className='' onSubmit={handleSubmit(onSubmit)}>
                  <div className='border-bottom mb-4 d-flex p-3 justify-content-between align-items-center'>
                    <Field
                      name='textTag'
                      component={RenderInputField}
                      type='text'
                      isAutoFocus={true}
                      placeholder='search for events'
                      className=''
                    />
                    <span className=''>
                      <i
                        onClick={() => {
                          setOpenFilterBox(false);
                          reset("event-filter-form");
                        }}
                        className='fal fa-times'
                      />
                    </span>
                  </div>
                  <div className='row mx-0'>
                    <div className='col-12 px-3 text-left'>
                      <h5 className=''>Event State</h5>
                      <div className='row mx-0 mb-3 pb-3 border-bottom'>
                        <div className='col-6'>
                          <Field
                            name='state'
                            value={formStates?.state}
                            component={renderMultiRadioField}
                            label='Featured'
                            type='radio'
                            items={optionsList.slice(0, optionsList.length / 2)}
                            validate={[required]}
                          />
                        </div>
                        <div className='col-6'>
                          <Field
                            name='state'
                            value={formStates?.state}
                            component={renderMultiRadioField}
                            label='Featured'
                            type='radio'
                            items={optionsList.slice(
                              optionsList.length / 2,
                              optionsList.length,
                            )}
                            validate={[required]}
                          />
                        </div>
                      </div>

                      <h5>Topics</h5>
                      <div className='row mx-0 my-3 d-flex align-items-start justify-content-around'>
                        <div className='col-6'>
                          <Field
                            name='tags'
                            component={renderMultiCheckboxField}
                            options={tagsList.slice(0, tagsList.length / 2)}
                            // validate={[required]}
                          />
                        </div>
                        <div className='col-6'>
                          <Field
                            name='tags'
                            component={renderMultiCheckboxField}
                            options={tagsList.slice(
                              tagsList.length / 2,
                              tagsList.length,
                            )}
                            // validate={[required]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row mx-0 py-2 border-top'>
                    <div className='col-12 text-right d-flex align-items-center justify-content-between'>
                      <p
                        className='mb-0'
                        onClick={() => reset("event-filter-form")}
                      >
                        Clear Filter
                      </p>
                      <button
                        type='submit'
                        className='btn button-filled mt-0'
                        disabled={submitting}
                      >
                        <span>Apply</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default reduxForm({
  form: "event-filter-form",
  fields: ["event_type"],
})(CustomFilterSearch);
