import React from 'react';
import AddSpeakerDrawer from '../../../../../../shared_components/AddSpeakerDrawer';
import { useDispatch } from 'react-redux';
import { createMentorBatch, deleteMentorFromBatch } from '../../../actions';
import MentorListCard from './MentorListCard';


const MentorList = ({ batch, codeBatchDetails, codeBatchesLoading }) => {
  const dispatch = useDispatch()


  const handleSelectedSpeaker = (speakerId, role) => {
    console.log(speakerId, role);
    dispatch(createMentorBatch({ batch: batch, mentor: speakerId, role: role }));
  }

  const handleDeleteFromBatch = (mentorId) => {
    console.log(mentorId);
    dispatch(deleteMentorFromBatch({ batch: batch, mentor: mentorId }));
  }

  return (
    <>
      <div className='d-flex justify-content-between'>
        <h1>Mentor</h1>
        <AddSpeakerDrawer batch={batch} btnName="Add Speaker" handleSelectedSpeaker={handleSelectedSpeaker} />
      </div>
      {
        codeBatchDetails?.mentorAssign && codeBatchDetails.mentorAssign.map((mentor) => (
          mentor?.mentor !== null && (<MentorListCard mentor={mentor} handleDeleteFromBatch={handleDeleteFromBatch} />)
        ))
      }
    </>
  )
}

export default MentorList;