import { connect } from "react-redux";
import {
  getAllUsersProgressByMentor,
} from "../../../../actions";

import UsersProgress from "./UsersProgress";

const mapStateToProps = ({ dayscode, user }) => ({
  currentUser: user.currentUser ? user.currentUser : {},
  daysUsers: dayscode.daysUsers,
  getUserSubmissionsLoading: dayscode.getUserSubmissionsLoading,
  getAllUsersProgressByMentorLoading: dayscode.getAllUsersProgressByMentorLoading,
});

const mapDispatchToProps = {
  getAllUsersProgressByMentor,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersProgress);
