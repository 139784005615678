import { FaRegClock } from "react-icons/fa6";
import { IoMdArrowRoundBack } from "react-icons/io";
import { FaCalendar } from "react-icons/fa";
import { IoMdVideocam } from "react-icons/io";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CustomCalendar from "../../../../../shared_components/CustomCalendar";
import {
  createUserAppointment,
  getAppointmentEventDetails,
} from "../../actions";
import AppointmentBookForm from "./AppointmentBookForm";
import ConfirmWindow from "./ConfirmWindow";
import { removeDuplicatesByStartTime } from "./helper";

// export const slots = [
//   {
//     time: "10:00 PM",
//     status: "booked",
//   },
//   {
//     time: "11:00 PM",
//     status: "available",
//   },
//   {
//     time: "01:00 PM",
//     status: "available",
//   },
//   {
//     time: "09:00 PM",
//     status: "booked",
//   },
//   {
//     time: "10:00 PM",
//     status: "available",
//   },
//   {
//     time: "08:00 PM",
//     status: "available",
//   },
//   {
//     time: "10:00 PM",
//     status: "booked",
//   },
//   {
//     time: "10:00 PM",
//     status: "available",
//   },
// ];

const UserAppointment = () => {
  const dispatch = useDispatch();
  const { event } = useParams();

  const [selectSlot, setSelectSlot] = useState("");
  const [setConfirm, setShowConfirm] = useState(false);

  const { appointmentEventDetails, availableSlots } = useSelector(
    ({ appointment }) => appointment,
  ) || { appointmentEventDetails: {} };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    dispatch(getAppointmentEventDetails(event));
  }, [selectSlot]);

  const handleSelectBookingSlot = (slot) => {
    setSelectSlot(slot);
  };
  const handleSchadule = (formData) => {
    console.log("formData", formData);
    dispatch(createUserAppointment(formData));
    setSelectSlot("");

    setShowConfirm(true);
  };

  return (
    <>
      <div className='row mx-0 d-flex justify-content-center align-items-center p-5'>
        <div className='col-12 col-md-9 border bg-body-tertiary shadow appointment-event-link-section'>
          {setConfirm ? (
            <ConfirmWindow />
          ) : (
            <div className='row mx-0'>
              <div className='col-md-4 col-12 p-4'>
                {selectSlot?.length ? (
                  <span onClick={() => setSelectSlot("")} className='back-icon'>
                    <IoMdArrowRoundBack />
                  </span>
                ) : (
                  <p className='datacode-title'>Datacode</p>
                )}
                <h1 className='pt-3'>{appointmentEventDetails?.title}</h1>
                <div className='py-3'>
                  <div className='time-date-text'>
                    <FaRegClock className='pr-2' />
                    <span className='p-0'>
                      {appointmentEventDetails?.duration &&
                        appointmentEventDetails?.duration?.type}
                    </span>
                  </div>
                  <div className='time-date-text'>
                    <IoMdVideocam className='pr-2' />
                    <span className='p-0'>
                      Web conferencing details provided upon confirmation.
                    </span>
                  </div>
                  <p className='time-date-text'>
                    <FaCalendar className='pr-2' />
                    9:00am - 09:10am, Thursday, August 31, 2023
                  </p>
                </div>
                <h6 className='mb-3'>Meeting details</h6>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  nec urna consequat, ultricies risus in, aliquam justo. Proin
                  fringilla libero sed metus consectetur, id consectetur justo
                </p>
              </div>
              {!selectSlot.length ? (
                <>
                  <div className='col-12 col-md-5 py-5 border-left'>
                    <h6>Select a Date & Time</h6>
                    <div className='d-flex justify-content-center align-items-center'>
                      <CustomCalendar
                        startDate={appointmentEventDetails?.startDate}
                        endDate={appointmentEventDetails?.endDate}
                      />
                    </div>
                  </div>
                  <div className='col-12 col-md-3 py-5 border-left slot-list-section'>
                    <p>Wednesday, August 30</p>
                    <div className=''>
                      {availableSlots &&
                        removeDuplicatesByStartTime(availableSlots).map(
                          (slot, i) => (
                            <div
                              key={i}
                              onClick={() => handleSelectBookingSlot(slot._id)}
                              className={`my-2 border text-center slot-bar
                            ${slot.status === "booked" ? "booked" : ""}`}
                            >
                              <p className='mb-0 py-2'>{slot.slottime}</p>
                            </div>
                          ),
                        )}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <AppointmentBookForm
                    slot={selectSlot}
                    handleSchadule={handleSchadule}
                  />
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UserAppointment;
