import React from 'react'
import Gist from 'react-gist'

const GithubGistPreview = () => {
  return (
    <>
        <Gist id='623c385d9976e42e048c875c4039102e'/>
    </>
  )
}

export default GithubGistPreview