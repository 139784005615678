import React, { useEffect, useState } from "react";
import Combobox from "react-widgets/Combobox";

import DatacodeConfig from "../../../../../../../core/config";
import CustomLoader from "../../../../../../../shared_components/CustomLoader";
import LayoutContainer from "../../LayoutContainer";
import UserAttendanceTable from "./UserAttendanceTable";

import { checkAdmin } from "../../../../../../../core/auth";
import { sessionDetails } from "../../../constantData";

const AddUserAttendance = ({
  getDaysUsersListOptions,
  getUserSubmissionsLoading,
  currentUser,
  daysUsers,
  userAttendancePresentLoading,
  addUserAttendancePresent,
  deleteUserAttendancePresent,
  problemsListLoading,
}) => {
  const batchMentors = DatacodeConfig.batchMentors || [];
  const admin = checkAdmin(currentUser);
  const [session, setSession] = useState();
  const [mentor, setMentor] = useState({ mentor: "All Mentors", value: "all" });
  const [search, setSearch] = useState("");
  const [filterUsersByMentor, setfilterUsersByMentor] = useState();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    getDaysUsersListOptions(currentUser?.email, checkAdmin(currentUser));
    batchMentors &&
      batchMentors.map((m) => {
        if (m.userName === currentUser?.userName && !admin) {
          setMentor({ mentor: m.userName, value: m.email });
          onChangeMentorOption({ mentor: m.userName, value: m.email });
        }
      });
  }, [session]);

  const getSessionOptions = (sessionDetails) => {
    const options = [];
    sessionDetails &&
      sessionDetails.map((item) => {
        return options.push({ session: item.session, value: item.date });
      });
    return options;
  };

  const onChangeSessionOption = (session) => {
    setSession(session);
  };

  const getMentorsOptions = () => {
    const options = [{ mentor: "All Mentors", value: "all" }];
    batchMentors &&
      batchMentors.map((item) => {
        return options.push({ mentor: item.userName, value: item.email });
      });
    return options;
  };

  const onChangeMentorOption = (e) => {
    setMentor(e);
    // mentorFilter(e)
    getDaysUsersListOptions(e.value, false);
  };

  // const mentorFilter = (mentor) => {
  //   let filterData = []
  //   if (mentor.value === 'all') {
  //     filterData = daysUsers
  //   } else {
  //     daysUsers && daysUsers.map((item) => {
  //       if (item.mentorAssign && (item?.mentorAssign[0] && item?.mentorAssign[0].batchId === '2' && item.mentorAssign[0].mentorId === mentor?.value)) {
  //         filterData.push(item)
  //       }
  //     })
  //   }
  //   return setfilterUsersByMentor(filterData)
  // }

  const handlePresentAction = (user, sessionDetails) => {
    const userAttendance = {
      userName: user.userName,
      batchId: "3",
      mentor: currentUser?.email,
      date: sessionDetails.value,
      session: sessionDetails.session,
      isAdmin: checkAdmin(currentUser),
    };
    addUserAttendancePresent(user.id, userAttendance);
  };

  const handleDeleteAttendanceAction = (user, sessionDetails) => {
    const data = {
      date: sessionDetails.value,
      mentor: currentUser?.email,
      isAdmin: checkAdmin(currentUser),
    };
    deleteUserAttendancePresent(user.id, data);
  };

  const handleResetFilter = () => {
    setSession();
    setMentor();
  };

  const getSearchResult = () => {
    return (
      daysUsers &&
      daysUsers.filter(
        (item) =>
          item?.name?.toUpperCase().includes(search.toUpperCase()) ||
          item?.userName?.toUpperCase().includes(search.toUpperCase()),
      )
    );
  };

  return (
    <>
      <LayoutContainer>
        <div className='row mx-0 solution-nav'>
          <div className='col-12 mx-0 d-flex align-items-center'>
            <h4 className='py-md-3 py-2 mb-0'>
              <i className='fal fa-poll-people mr-2' /> Session Attendance
            </h4>
          </div>
          {/* <div className="col-4 d-flex justify-content-end align-items-center">
            </div> */}
        </div>
        <div className='px-3'>
          <div className='p-md-5 px-0'>
            <div className='card-schadow border rounded-lg mt-3'>
              <div className='row m-0 px-2'>
                <div className='col-md-4 col-12 px-md-4 px-0 my-3'>
                  <label className='form-label'>Select Session</label>
                  <Combobox
                    data={getSessionOptions(sessionDetails)}
                    dataKey={"value"}
                    textField='session'
                    placeholder={"Select Session Name"}
                    value={session}
                    onChange={(value) => onChangeSessionOption(value)}
                  />
                </div>
                {currentUser?.email === process.env.REACT_APP_ADMIN_EMAIL && (
                  <div className='col-md-4 col-12 px-md-4 px-0 my-3'>
                    <label className='form-label'>Select Mentor</label>
                    <Combobox
                      data={getMentorsOptions(batchMentors)}
                      dataKey={"value"}
                      textField='mentor'
                      placeholder={"Select Mentor Name"}
                      value={mentor}
                      onChange={(value) => onChangeMentorOption(value)}
                      // disabled={!session && true}
                    />
                  </div>
                )}
              </div>
              <div className='row mx-0 my-3'>
                <div className='col-12 col-12 px-md-4 px-2'>
                  <div
                    className='btn btn-sm btn-secondary'
                    onClick={() => handleResetFilter()}
                  >
                    <small>
                      <i className='far fa-history mr-2' />
                      Reset Filter
                    </small>
                  </div>
                </div>
              </div>
            </div>
            {session !== undefined &&
              (problemsListLoading ? (
                <CustomLoader />
              ) : (
                <div className='row m-0 my-5'>
                  <div className='col-12 px-0'>
                    <UserAttendanceTable
                      handlePresentAction={handlePresentAction}
                      handleDeleteAttendanceAction={
                        handleDeleteAttendanceAction
                      }
                      userAttendancePresentLoading={
                        userAttendancePresentLoading
                      }
                      daysUsers={getSearchResult(filterUsersByMentor)}
                      sessionDetails={session}
                      setSearch={setSearch}
                      search={search}
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </LayoutContainer>
    </>
  );
};

export default AddUserAttendance;
