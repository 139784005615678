import React from "react";
import { useDispatch } from "react-redux";
import { deleteQuizFromBatch } from "../../../actions";
import ProblemListCard from "../Problems/ProblemsList/ProblemListCard";
import AddBatchChallengeDrawer from "./AddBatchChallengeDrawer";

const QuizList = ({ batch, codeBatchDetails, codeBatchesLoading }) => {
  const dispatch = useDispatch();

  const handleDeleteQuizFromBatch = (quiz) => {
    dispatch(deleteQuizFromBatch({ batch: batch, quiz }));
  };
  return (
    <>
      <div className='d-flex justify-content-between'>
        <h1>Quiz</h1>
        <AddBatchChallengeDrawer batch={batch} />
      </div>

      {codeBatchDetails.quizes &&
        codeBatchDetails.quizes.map((quiz) => (
          <ProblemListCard
            day={quiz?.day}
            batchContentId={quiz._id}
            item={quiz.quizDetails}
            showDeleteFromBatchIcon={true}
            handleDeleteFromBatch={handleDeleteQuizFromBatch}
          />
        ))}
    </>
  );
};

export default QuizList;
