import { getProblemsHandlers } from "./getProblems";
import { createReducer } from "../../../../core/reduxUtils";

const initialState = {
  problemsListLoading: false,
  problemsList: [],
  practiceProblems: [],
  practiceProblemsLoading: false,
  problemDetailsLoading: false,
  problemDetails: {},
  solutionSubmissionLoading: false,
  submissions: [],
  batchLeaderBoard: [],
  activeTab: "problemsList",
  allSubmissions: [],
  daysUsers: [],
  daysUserRegLoading: false,
  daysUserDetailsLoading: false,
  daysUserDetails: {},
  // Progress by user
  submissionsCount: 0,
  userTotalScore: 0,
  totalQuizChallengesCompletedCount: 0,
  totalQuizChallengesCount: 0,
  totalLessonsCompletedCount: 0,
  totalLessonsCount: 0,
  totalProgress: 0,
  progressCount: {
    goodCount: 0,
    poorCount: 0,
    waitingCount: 0,
    correctCount: 0,
    wrongCount: 0,
  },
  allUsersProgressByMentor: [],
  lessonsList: [],
  lessonDetails: {},
  quizChallengesList: [],
  quizChallengeDetails: {},
  quizchallenges: [],
  contentDetails: {},
  contentsList: [],
  feedbacks: [],
  currentDay: "",
  testCasesResponces: {
    data: [],
    isLoading: false,
    error: false,
  },
  getAllUsersProgressByMentorLoading: false,
  contentDetailsLoading: false,
  quizChallengeReportLoading: false,
  getUserSubmissionsLoading: false,
  userAttendancePresentLoading: false,
  feedbacksLoading: false,
  setUserFeedbackLoading: false,
  contentCompletedStatus: {
    challengeCompletedStatus: {},
    lessonCompletedStatus: false,
    submissionCompletedStatus: false,
    day: "",
  },
  codeBatchesLoading: false,
  codeBatches: [],
  codeBatchDetails: {},
  speakersList: [],
  speakersListLoading: false,
  roadmapList: [],
  batchParticipant: {},
  checkInBatchLoading: false
};

const handlers = {
  ...getProblemsHandlers,
};

const dayscodeReducer = createReducer(initialState, handlers);

export default dayscodeReducer;
