import React from 'react'
import { Link } from "react-router-dom";
import CustomAddToCalendar from '../../../../../../shared_components/CustomAddToCalendar'

const EventRegThankyouCard = ({
  state
}) => {
  return (
    <>
      <div className="row mx-0">
        <div className="col-12 p-3 rounded-lg border card-schadow">
          {
            (state === 'upcoming' || 'live') ?
              <p>Thank you for Registration, Stay tune with us, We will send you all detail over registered mail</p>
              :
              <p> Thank you for Feedback, Stay tune with us, We will organize more such events very soon </p>
          }
          <CustomAddToCalendar />
          <Link to="/signup">
            <button className="btn join-us-button text-center">
              Join Community
            </button>
          </Link>
        </div>
      </div>
    </>
  )
}

export default EventRegThankyouCard