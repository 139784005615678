import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Field, reduxForm } from "redux-form";

import {
  RenderInputField,
  renderRadioField,
} from "../../../../../../shared_components/ReduxFormFields";
import { required } from "../../../../../../utils/validators";

const EventTimeline = ({
  setActiveTab,
  speakersLoading,
  eventDetails,
  reset,
  handleSubmit,
  submitting,
  initialize,
  addEventSpeaker,
  imageUploading,
}) => {
  const [imgUrl, setImgUrl] = useState("");
  const history = useHistory();

  const getUploadImgUrl = (url) => {
    if (url) {
      setImgUrl(url);
    }
  };

  const onSubmit = (values) => {
    const speaker = { ...values };
    console.log("speaker", speaker);
    speaker["img"] = imgUrl;
    speaker["id"] = eventDetails && eventDetails.id;
    addEventSpeaker(speaker).then((res) => {
      if (res && res.success) {
        history.push(`/event/dashboard/${res.event && res.event.id}`);
        reset("add-timeline");
      }
    });
  };

  return (
    <>
      <h1>Timeline Details</h1>
      <div className='row p-5 p-md-2 d-flex justify-content-center'>
        <div className='col-12'>
          <form className='' onSubmit={handleSubmit(onSubmit)}>
            <div className='row m-0 mt-5 p-md-5 p-3 border rounded-lg bg-white event-reg'>
              <div className='col-12 col-md-4 px-md-2 px-0 py-md-0 py-2'>
                <h6>Basic Details</h6>
                <p>
                  This information will be displayed publicly so be careful what
                  you share.{" "}
                </p>
              </div>
              <div
                className='col-12 col-md-8 px-0 border rounded-lg form-shadow'
                id='form'
              >
                <div className='pt-md-3 px-md-4 px-3 login-card '>
                  <Field
                    type='text'
                    name='title'
                    label='Event Name'
                    placeholder=''
                    component={RenderInputField}
                    validate={[required]}
                  />
                  <Field
                    type='text'
                    name='sub_title'
                    label='Event Sub Heading'
                    placeholder=''
                    component={RenderInputField}
                    validate={[required]}
                  />
                  <div className='row'>
                    <div className='col-6'>
                      <Field
                        type='date'
                        name='start_date'
                        label='Event Start Date'
                        placeholder=''
                        component={RenderInputField}
                        validate={[required]}
                      />
                    </div>
                    <div className='col-6'>
                      <Field
                        type='time'
                        name='start_time'
                        label='Event Start Time'
                        placeholder=''
                        component={RenderInputField}
                        validate={[required]}
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-6'>
                      <Field
                        type='date'
                        name='end_date'
                        label='Event End Date'
                        placeholder='xyz@yui.com'
                        component={RenderInputField}
                        // validate={[required]}
                      />
                    </div>
                    <div className='col-6'>
                      <Field
                        type='time'
                        name='end_time'
                        label='Event End Time'
                        placeholder=''
                        component={RenderInputField}
                        // validate={[required]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row m-0 mt-5 p-md-5 p-3 border rounded bg-white event-reg'>
              <div className='col-12 col-md-4 px-md-2 px-0 py-md-0 py-2'>
                <h6>Settings</h6>
                <p>
                  Event settings can’t be changed once the event is created.
                  Learn more
                </p>
              </div>
              <div
                className='col-12 col-md-8 px-0 border rounded-lg form-shadow'
                id='form'
              >
                <div className='p-md-4 px-3 login-card '>
                  <div className='row mx-0'>
                    <div className='col-12 px-0 text-left'>
                      <div className=''>
                        <label className='form-label'>This Event is:</label>
                        <div className='type-tag'>
                          <div className='type-checkbox'>
                            <Field
                              name='event_type'
                              component={renderRadioField}
                              value='public'
                              label='Public'
                              type='radio'
                              validate={[required]}
                            />
                          </div>
                          <div className='mx-3 type-checkbox'>
                            <Field
                              name='event_type'
                              component={renderRadioField}
                              value='private'
                              label='Private'
                              type='radio'
                              validate={[required]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row my-4'>
              <div className='col-12 text-right'>
                <button
                  type='submit'
                  className='btn custom-button'
                  disabled={submitting}
                >
                  <span>Add Slote</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default reduxForm({
  form: "add-timeline",
})(EventTimeline);
