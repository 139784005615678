import { connect } from "react-redux";
import {
  adminNotificationDaysCodeUser,
  getDaysUserReg,
  deleteAdminNotification,
} from "../../../actions";

import Notification from "./Notification";

const mapStateToProps = ({ dayscode, user }) => ({
  currentUser: user.currentUser ? user.currentUser : {},
  problemsListLoading: dayscode.problemsListLoading,
  problemDetailsLoading: dayscode.problemDetailsLoading,
  daysUserDetails: dayscode.daysUserDetails,
});

const mapDispatchToProps = {
  adminNotificationDaysCodeUser,
  getDaysUserReg,
  deleteAdminNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
