import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { hideCompleteHeader } from "../../../../../../app/actions";
import Carousel from "react-multi-carousel";
import { techCompaniesResponsive } from "../../../../../../utils";
import { Link } from "react-router-dom";

const data = [
  {
    name: "Collaborative Learning",
    image:
      "http://res.cloudinary.com/datacode/image/upload/v1688214002/ifkvgoi2cjywlfkqv4zc.png",
  },
  {
    name: "Motivational Community",
    image:
      "http://res.cloudinary.com/datacode/image/upload/v1688213836/l7qiskqybsfhwrpkvgag.png",
  },
  {
    name: "Networking Oportunities",
    image:
      "http://res.cloudinary.com/datacode/image/upload/v1688213796/ueefprsopdklv8sgjshi.png",
  },
  {
    name: "Skill Development",
    image:
      "https://res.cloudinary.com/datacode/image/upload/v1688213739/b5uzlmrymnbuu7f7ww37.png",
  },
  {
    name: "Progress Tracking",
    image:
      "http://res.cloudinary.com/datacode/image/upload/v1688213264/w5yl9q5phxnqrda8eg5b.svg",
  },
];

const HeroSection = () => {
  const dispatch = useDispatch();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (window.location.hash === "#/club") {
      // hideCompleteFooter(true)
      dispatch(hideCompleteHeader(true));
    }

    return () => {
      dispatch(hideCompleteHeader(false));
    };
  }, []);

  return (
    <>
      <div className='row mx-0 hero'>
        <div className='col-12 text-center d-flex align-items-md-center align-items-start justify-content-center'>
          <div className='text-section'>
            <h1 className='text-white'>Student Learning Club</h1>
            <h2 className='text-white'>Ignite Your Learning Journey</h2>
            <div className='d-md-flex d-block justify-content-md-center justify-content-left'>
              <p className=''>
                Join Our Student Learning Club and Unleash Your Potential!Excel
                professionally while fostering personal growth and meaningful
                connections
              </p>
            </div>
            <Link to='/club/application'>
              <button className='btn club-btn'>Apply Now!</button>
            </Link>
          </div>
        </div>
      </div>
      <div className='row mx-0 welcome-section'>
        <div className='col-md-4 col-12'>
          <div className='side-image'>
            <img
              className='activity-img'
              height='500'
              width='500'
              loading='lazy'
              src={`http://res.cloudinary.com/datacode/image/upload/v1687763436/vr2hbjnhli7y3iewspc4.png`}
              alt='avat ar'
            />
          </div>
        </div>
        <div className='col-md-8 col-12 mt-md-0 mt-5 px-md-2 px-0'>
          <div className='row mx-0 d-md-flex d-none justify-content-between'>
            {data &&
              data.map((item, i) => (
                <div className='col-12 col-md-2 d-flex justify-content-between'>
                  <div className='text-center'>
                    <img
                      className='rounded-circle shadow'
                      height='100'
                      width='100'
                      loading='lazy'
                      src={item.image}
                      alt='avatar'
                    />
                    <p className='mt-3'>{item.name}</p>
                  </div>
                </div>
              ))}
          </div>
          <div className='row mx-0'>
            <div className='col-12 px-0'>
              <div
                className='my-5 d-block d-md-none'
                style={{ position: "relative" }}
              >
                <Carousel
                  draggable={true}
                  infinite={true}
                  dotListClass='custom-dot-list-style'
                  responsive={techCompaniesResponsive}
                  autoPlay={true}
                  className='card-group'
                >
                  {data &&
                    data.map((item, i) => (
                      <div className='col-12 col-md-2 d-flex justify-content-center'>
                        <div className='text-center'>
                          <img
                            className='rounded-circle shadow'
                            height='100'
                            width='100'
                            loading='lazy'
                            src={item.image}
                            alt='avatar'
                          />
                          <p className='mt-3'>{item.name}</p>
                        </div>
                      </div>
                    ))}
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
