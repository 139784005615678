import React, { useEffect, useState } from "react";
import EventBasicDetails from "../EventBasicDetails";
import RegisterList from "../RegisterList";
import EventSpeakers from "../EventSpeakers";
import EventSponsors from "../EventSponsors";
import EventQuestions from "../EventQuestions";
import EventFeedbacks from "../EventFeedbacks";
import EventLayoutDetails from "../EventLayoutDetails";
import LayoutContainer from "../LayoutContainer";
import EventTimeline from "../EventTimeline";

const EventDashboard = ({
  getEventDetails,
  match: {
    params: { id },
  },
  currentUser: { userName, email },
}) => {
  const [eventDetails, setEventDetails] = useState();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    getEventDetails({ id, email }).then((res) => {
      if (res && res.success) {
        setEventDetails(res.data);
      }
    });
  }, []);

  const [activeTab, setActiveTab] = useState("basic");

  const renderActiveComponent = (activeTab) => {
    const componentDictionary = {
      basic: (
        <EventBasicDetails
          setActiveTab={setActiveTab}
          eventDetails={eventDetails}
          // data={profileObject(userProfile, "profile")}
          // setProfileDetails={setProfileDetails}
        />
      ),
      speaker: (
        <EventSpeakers
          setActiveTab={setActiveTab}
          eventDetails={eventDetails}
        />
      ),
      sponsor: (
        <EventSponsors
          setActiveTab={setActiveTab}
          eventDetails={eventDetails}
        />
      ),
      question: (
        <EventQuestions
          setActiveTab={setActiveTab}
          eventDetails={eventDetails}
        />
      ),
      registerList: (
        <RegisterList setActiveTab={setActiveTab} eventDetails={eventDetails} />
      ),
      feedback: (
        <EventFeedbacks
          setActiveTab={setActiveTab}
          eventDetails={eventDetails}
        />
      ),
      layout: (
        <EventLayoutDetails
          setActiveTab={setActiveTab}
          eventDetails={eventDetails}
        />
      ),
      timeline: <EventTimeline eventDetails={eventDetails} />,
    };

    return componentDictionary[activeTab];
  };

  return (
    <>
      <div className='row mx-0'>
        <div className='col-12 px-0'>
          <div className='p-4 border-bottom'>
            <h1>{eventDetails && eventDetails.title}</h1>
          </div>
          <LayoutContainer
            setActiveTab={setActiveTab}
            activeTab={activeTab}
            eventDetails={eventDetails}
          >
            {renderActiveComponent(activeTab)}
          </LayoutContainer>
        </div>
      </div>
    </>
  );
};

export default EventDashboard;
