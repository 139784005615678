import React from "react";
import { Field } from 'redux-form'
import { RenderInputField } from '../../../../../../../shared_components/ReduxFormFields'
import { required } from '../../../../../../../utils/validators'

const ApplicationStep4 = () => {
  return (
    <>
      <div className="row mx-0">
        <div className="col-12">
          <Field
            type="textarea"
            name="interest"
            label="Are you interested in participating in workshops, guest lectures, or other interactive events?, Let us know what do you want to join"
            placeholder="if yes, Please let us know about your interested (300 Words)"
            component={RenderInputField}
            validate={[required]}
          />
          <Field
            type="textarea"
            name="shared_ideas"
            label="How would you like to contribute to the group by sharing your knowledge, organizing events, or mentoring other members?"
            placeholder="Enter any Creative idea you to contribute"
            component={RenderInputField}
            validate={[required]}
          />
          <Field
            type="textarea"
            name="suggestions"
            label="Do you have any suggestions or ideas for activities or topics you'd like to see in the group?"
            placeholder="Please let us know more about that in details (300 Words)"
            component={RenderInputField}
            validate={[required]}
          />
        </div>
      </div>
    </>
  )
}

export default ApplicationStep4