import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
const _ = require('lodash');

const PrivateRoute = ({
  component: Component, alternateComponent: AlternateComponent, roles, checkBatchUser, checkDaysCodeUser, checkBatchStatus, ...rest
}) => {
  const { currentUser } = useSelector(({ user }) => user) || {}
  let batchParticipant = JSON.parse(localStorage.getItem("batchParticipant"))
  let codeBatchDetails = JSON.parse(localStorage.getItem("batchDetails"))

  return (
    <Route
      {...rest}
      render={props => {
        if (_.isEmpty(currentUser)) {
          // Not logged in
          return <Redirect to="/login" />;
        }

        if (roles && !roles.includes(currentUser.role)) {
          // Role not authorized
          return <Redirect to="/unauthorized" />;
        }

        if (checkBatchStatus && codeBatchDetails && codeBatchDetails.status !== 'live') {
          // Batch start status not matched
          return <Redirect to="/batch-not-started" />;
        }

        if (checkBatchUser && batchParticipant && batchParticipant.status !== 'accept') {
          // Batch valid participant not matched
          return <Redirect to="/not-valid-batch-participant" />;
        }

        if (checkDaysCodeUser && currentUser && currentUser.dayscodeUser) {
          return <AlternateComponent {...props} />;
        }

        // Authorized, return the component
        return <Component {...props} />;
      }}
    />
  );
};

PrivateRoute.defaultProps = {
  checkBatchUser: false,
  checkBatchStatus: false,
  checkDaysCodeUser: false,
};

export default PrivateRoute;
