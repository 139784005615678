import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Combobox from "react-widgets/Combobox";
import { Field, reduxForm } from "redux-form";
import {
  RenderInputField,
  renderSwitchField,
} from "../../../../../../../shared_components/ReduxFormFields";
import { required } from "../../../../../../../utils/validators";

import CustomLoader from "../../../../../../../shared_components/CustomLoader";
import { DSAContent } from "../../../DaysCodeDashboard/DaysCodeTimeLine/helper";
import { getTopicOptions } from "../../../utils";
import LayoutContainer from "../../LayoutContainer";
import AdvDisadvSection from "./AdvDisadvSection";
import ApplicationSection from "./ApplicationSection";
import ImplementationSection from "./ImplementationSection";
import IntroductionSection from "./IntroductionSection";
import OperationSection from "./OperationSection";
import ReferenceSection from "./ReferenceSection";

const AddLesson = ({
  match: {
    params: { id },
  },
  reset,
  handleSubmit,
  submitting,
  initialize,
  createLesson,
  editLesson,
  getLessonDetails,
  problemDetailsLoading,
  lessonDetails,
  formStates,
}) => {
  const history = useHistory();
  const [isEdit, setEdit] = useState(false);
  const [editSection, setEditSection] = useState("setting");
  const [days, setDays] = useState([]);
  const [topic, setTopic] = useState();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (id !== "new") {
      getLessonDetails(id).then((res) => {
        if (res && res.success && res.data) {
          const data = res.data;
          data["status"] =
            res.data && res.data.status === "public" ? true : false;
          initialize(data);
          setEditSection("introduction");
          setTopic({ name: res.data && res.data.topic, value: -1 });
          setDays(res.data && res.data.days);
          setEdit(true);
        }
      });
    }
  }, []);

  const onSubmit = (values) => {
    const lesson = { ...values };
    lesson["status"] = lesson.status ? "public" : "draft";
    lesson["days"] = days;
    lesson["topic"] = topic && topic.name;

    if (isEdit) {
      lesson["completedBy"] = [];
      editLesson(lesson);
    } else {
      lesson["id"] = Math.random().toString(36).substr(4, 9);
      // Textedito States----
      lesson["introduction"] = "<p> </p>";
      lesson["implementations"] = "<p> </p>";
      lesson["operations"] = "<p> </p>";
      lesson["adv_disadv"] = "<p> </p>";
      lesson["applications"] = "<p> </p>";
      lesson["references"] = "<p> </p>";

      createLesson(lesson).then((res) => {
        if (res) {
          history.push("/admin/dayscode/lessons");
        }
      });
    }
    reset("add-lesson");
  };

  const addDays = () => {
    setDays([...days, formStates.day]);
  };

  const onDelete = (e) => {
    const data = days.filter((item) => item !== e);
    setDays(data);
  };

  return (
    <>
      <LayoutContainer>
        {problemDetailsLoading ? (
          <CustomLoader />
        ) : (
          <>
            <div className='row mx-0 solution-nav'>
              <div className='col-md-8 col-6 mx-0 d-flex align-items-center'>
                <h4 className='py-md-3 mb-0 py-2'>
                  {isEdit
                    ? `Edit Lesson Section | (${editSection})`
                    : "Create New Lesson"}
                </h4>
              </div>
              <div className='col-md-4 col-6 d-flex justify-content-end align-items-center'>
                <button
                  onClick={() => history.push("/admin/dayscode/lessons")}
                  type='button'
                  className='btn enroll-small-btn'
                >
                  <small>
                    <i className='fal fa-file-alt mr-2' />
                    All Lessons List
                  </small>
                </button>
              </div>
            </div>
            <div className='row m-0 '>
              <div className='col-12 px-0 d-flex border-bottom justify-content-center'>
                <div
                  className={`px-4 py-2 m-0 pointer ${
                    editSection === "setting" &&
                    "active-with-border font-weight"
                  } text-center `}
                  onClick={() => setEditSection("setting")}
                >
                  <small>Settings</small>
                </div>
                {id !== "new" && (
                  <>
                    <div
                      className={`px-4 py-2 m-0 pointer ${
                        editSection === "introduction" &&
                        "active-with-border font-weight"
                      } text-center `}
                      onClick={() => setEditSection("introduction")}
                    >
                      <small>Introduction</small>
                    </div>
                    <div
                      className={`px-4 py-2 m-0 pointer ${
                        editSection === "implementation" &&
                        "active-with-border font-weight"
                      } text-center `}
                      onClick={() => setEditSection("implementation")}
                    >
                      <small>Implementations</small>
                    </div>
                    <div
                      className={`px-4 py-2 m-0 pointer ${
                        editSection === "operation" &&
                        "active-with-border font-weight"
                      } text-center `}
                      onClick={() => setEditSection("operation")}
                    >
                      <small>Operations</small>
                    </div>
                    <div
                      className={`px-4 py-2 m-0 pointer ${
                        editSection === "adv_disadv" &&
                        "active-with-border font-weight"
                      } text-center `}
                      onClick={() => setEditSection("adv_disadv")}
                    >
                      <small>Adv-DisAdv</small>
                    </div>
                    <div
                      className={`px-4 py-2 m-0 pointer ${
                        editSection === "applications" &&
                        "active-with-border font-weight"
                      } text-center `}
                      onClick={() => setEditSection("applications")}
                    >
                      <small>Applications</small>
                    </div>
                    <div
                      className={`px-4 py-2 m-0 pointer ${
                        editSection === "references" &&
                        "active-with-border font-weight"
                      } text-center `}
                      onClick={() => setEditSection("references")}
                    >
                      <small>References</small>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className='row m-0'>
              <div className='col-12'>
                {editSection === "setting" && (
                  <form
                    className='py-3 px-md-5 px-1'
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <small>
                      Lorazepam, sold under the brand name Ativan among others,
                      is a benzodiazepine medication. It is used to treat
                      anxiety disorders, trouble sleeping, severe agitation,
                      active seizures including status epilepticus, alcohol
                      withdrawal, and chemotherapy-induced nausea and vomiting
                    </small>
                    <small>
                      Lorazepam, sold under the brand name Ativan among others,
                      is a benzodiazepine medication. It is used to treat
                      anxiety disorders, trouble sleeping, severe agitation,
                      active seizures including status epilepticus, alcohol
                      withdrawal, and chemotherapy-induced nausea and vomiting
                    </small>
                    <Field
                      type='text'
                      name='title'
                      label='Lesson Title'
                      placeholder=''
                      component={RenderInputField}
                      validate={[required]}
                    />
                    <div className='row m-0 border rounded-lg my-2 p-md-3 p-0'>
                      <div className='col-md-6 col-12'>
                        <div className=''>
                          <label className='mr-4 form-label'>
                            Status: Public
                          </label>
                          <label className='switch mt-4'>
                            <Field
                              type='checkbox'
                              name='status'
                              component={renderSwitchField}
                            />
                          </label>
                        </div>
                        <div>
                          <label className='form-label'>Select Topic</label>
                          <Combobox
                            data={getTopicOptions(DSAContent)}
                            dataKey={"value"}
                            textField='name'
                            placeholder={"Select Topic Name"}
                            value={topic}
                            onChange={(value) => setTopic(value)}
                            // disabled={user && true}
                          />
                        </div>
                        <Field
                          type='text'
                          name='level'
                          label='level'
                          placeholder=''
                          component={RenderInputField}
                          validate={[required]}
                        />
                      </div>
                      <div className='col-md-6 col-12 mt-3'>
                        <label className='mr-4 form-label'>Lesson Days:</label>
                        <div className='border rounded-lg card-schadow p-3'>
                          <div className='row mx-0'>
                            {days.map((item, i) => (
                              <div
                                key={i}
                                className='col-2 p-2 mx-2 border d-flex align-items-center justify-content-between'
                              >
                                <h6 className='mb-0'>{item}</h6>
                                <i
                                  className='fal fa-times'
                                  onClick={() => onDelete(item)}
                                />
                              </div>
                            ))}
                          </div>
                          <div className='mt-4'>
                            <Field
                              type='number'
                              name='day'
                              label='Lesson Day'
                              placeholder=''
                              component={RenderInputField}
                            />
                            <div className='d-flex mt-3 justify-content-end'>
                              <button
                                className='btn btn-sm btn-primary'
                                type='button'
                                onClick={() => addDays()}
                              >
                                Add
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row my-4'>
                      <div className='col-12 text-right'>
                        <button
                          type='submit'
                          className='btn custom-button'
                          disabled={submitting}
                        >
                          <span>
                            {isEdit ? "Edit Lessson" : "Create Lesson"}
                          </span>
                        </button>
                      </div>
                    </div>
                  </form>
                )}
                {editSection === "introduction" && id !== "new" && (
                  <IntroductionSection
                    id={id}
                    editLesson={editLesson}
                    lessonDetails={lessonDetails}
                  />
                )}
                {editSection === "implementation" && id !== "new" && (
                  <ImplementationSection
                    id={id}
                    lessonDetails={lessonDetails}
                    editLesson={editLesson}
                  />
                )}
                {editSection === "operation" && id !== "new" && (
                  <OperationSection
                    id={id}
                    lessonDetails={lessonDetails}
                    editLesson={editLesson}
                  />
                )}
                {editSection === "adv_disadv" && id !== "new" && (
                  <AdvDisadvSection
                    id={id}
                    lessonDetails={lessonDetails}
                    editLesson={editLesson}
                  />
                )}
                {editSection === "applications" && id !== "new" && (
                  <ApplicationSection
                    id={id}
                    lessonDetails={lessonDetails}
                    editLesson={editLesson}
                  />
                )}
                {editSection === "references" && id !== "new" && (
                  <ReferenceSection
                    id={id}
                    lessonDetails={lessonDetails}
                    editLesson={editLesson}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </LayoutContainer>
    </>
  );
};

export default reduxForm({
  form: "add-lesson",
  fields: ["day"],
})(AddLesson);
