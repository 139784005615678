import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { eventRegister } from "../../../actions";

import GettingStartedWebDev from "./GettingStartedWebDev";

const mapStateToProps = ({ event }) => ({
  eventRegistationLoading: event.eventRegistationLoading,
});

const mapDispatchToProps = { eventRegister };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GettingStartedWebDev)
);
