import React from "react";
import moment from "moment";

export const getColumns = () => [
  {
    dataField: "",
    text: "S.NO.",
    align: "center",
    headerAlign: "center",
    headerClasses: "table-header s-no",
    formatter: (cell, row, index) => <span>{++index}</span>,
  },
  {
    dataField: "name",
    text: "Name",
    sort: true,
    headerClasses: "table-header name",
    style: { color: "#757575" },
  },
  {
    dataField: "email",
    text: "Email",
    sort: true,
    style: { color: "#757575" },
    headerClasses: "table-header status",
  },
  {
    dataField: "phone",
    text: "Phone",
    sort: true,
    style: { color: "#757575" },
    align: "left",
    headerAlign: "left",
    headerClasses: "table-header group-name",
  },
  {
    dataField: "university",
    text: "University",
    sort: true,
    style: { color: "#757575" },
    align: "left",
    headerAlign: "left",
    headerClasses: "table-header group-name",
  },
  {
    dataField: "linkedin",
    text: "Linkedin",
    sort: true,
    style: { color: "#757575" },
    align: "left",
    headerAlign: "left",
    headerClasses: "table-header group-name",
  },
  {
    dataField: "question1",
    text: "Why do you want to JOin",
    sort: true,
    style: { color: "#757575" },
    align: "left",
    headerAlign: "left",
    headerClasses: "table-header group-name",
  },
  {
    dataField: "question2",
    text: "What are your expectations from this session?",
    sort: true,
    style: { color: "#757575" },
    align: "left",
    headerAlign: "left",
    headerClasses: "table-header group-name",
  },
  // {
  //   dataField: "bio",
  //   text: "Question",
  //   sort: true,
  //   style: { color: "#757575" },
  //   align: "left",
  //   headerAlign: "left",
  //   headerClasses: "table-header group-name",
  // },
  {
    dataField: "createdAt",
    text: "Registered Date",
    sort: true,
    style: { color: "#757575" },
    align: "left",
    headerAlign: "left",
    headerClasses: "table-header group-name",
    formatter: (cell, row) => (
      <span>
        {row.createdAt === null
          ? "Invalid date"
          : moment(row.createdAt).format("LLL")}
      </span>
    ),
  },
];
