import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';

const ToolTip = ({
  message, id, placement
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)

  return (
    <Tooltip
      placement={placement || "auto-end"}
      isOpen={tooltipOpen}
      target={id}
      delay={100}
      toggle={() => setTooltipOpen(!tooltipOpen)}
      hideArrow={true}
    >
      <small>{message}</small>
    </Tooltip>
  )
}

export default ToolTip