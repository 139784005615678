import React from "react";

const data = [
  {
    icon: 'fal fa-clock',
    content: "Self Paced Learning",
    color: '#FFD6A5'
  },
  {
    icon: 'fal fa-users',
    content: "Community and Mentor Support",
    color: "#80F2CD",
  },
  {
    icon: 'fal fa-bolt',
    content: "Daily Challenges and learning",
    color: "#9BF6FF",
  },
  {
    icon: 'fal fa-book',
    content: "Curated Learning Resources",
    color: "#FF8BDD"
  },
  {
    icon: 'fal fa-hand-holding-heart',
    content: "Guidance and assistance",
    color: '#9672FF',
  },
]
const DevelopSkillsSection = () => {
  return (
    <>
      <div className="row mx-0 my-5 py-5 d-flex justify-content-center develop-skill-section">
        <div className="col-md-10 col-12">
          <div className="row mx-0">
            <div className="col-12">
              <h1>Develop skills with Code Challenges</h1>
              <p>Learn and practice coding the write way with 1500+ coding problems curated by industry-best programming experts. Boost your potential to crack coding interviews by solving Code Challenges.</p>
            </div>
          </div>
          <div className="row mx-0 my-5 justify-content-center">
            {
              data.map((item, i) => (
                <div key={i} className="col-md-4 col-12 mt-5 px-md-5 px-2">
                  <div className="d-flex shadow skill-card">
                    <div className="p-md-4 p-2 icon-block" style={{ background: item.color }}>
                      <i className={item.icon} />
                    </div>
                    <div className="">
                      <p className="p-md-4 p-2">{item.content}</p>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
          <div className="text-center">
            <button className="btn btn-primary">
              Explore Code Challenges
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default DevelopSkillsSection