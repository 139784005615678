import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";

import {
  RenderInputField,
  renderSelectField,
} from "../../../../../../../shared_components/ReduxFormFields";
import { languageOptions } from "../../../../../../../utils";
import { required } from "../../../../../../../utils/validators";
import PrismCode from "../../../../../../PrismCode";
import TextEditor from "../../../../../aticles/BlogsEditor/TextEditor";

const AddSolution = ({
  reset,
  handleSubmit,
  submitting,
  initialize,
  addAdminSolution,
  adminSolutions,
  id,
  deleteAdminSolution,
}) => {
  const [isEdit, setEdit] = useState(false);
  const [explanationText, getExplanationText] = useState("<p> </p>");
  // const [codeText, getCodeText] = useState('<p> </p>')

  const onSubmit = (values) => {
    const solution = { ...values };
    // solution['code'] = codeText
    solution["explanation"] = explanationText;
    solution["technology"] = solution.technology && solution.technology.value;
    solution["solutionId"] = Math.random().toString(36).substr(4, 9);
    addAdminSolution(id, solution);
    reset("add-solution");
  };

  // const handleCodeText = (text) => {
  //   getCodeText(text)
  // }

  const handleExplanationText = (text) => {
    getExplanationText(text);
  };

  const handleDeleteAdminSolution = (solutionId) => {
    deleteAdminSolution(id, solutionId);
  };

  return (
    <>
      <div className='row m-0'>
        <div className='col-12'>
          <form className='py-3 px-md-5 px-1' onSubmit={handleSubmit(onSubmit)}>
            <small>
              Lorazepam, sold under the brand name Ativan among others, is a
              benzodiazepine medication. It is used to treat anxiety disorders,
              trouble sleeping, severe agitation, active seizures including
              status epilepticus, alcohol withdrawal, and chemotherapy-induced
              nausea and vomiting
            </small>
            <Field
              name='technology'
              label='Technology'
              options={languageOptions}
              placeholder='Search Technology'
              component={renderSelectField}
              validate={[required]}
              textField='tech'
              dataKey='value'
            />
            <div className='my-3'>
              {/* <label className="form-label text-left pt-5">Code {`(${codeText.length - 8})`}</label> */}
              <div className='mb-3 border '>
                {/* {(isEdit && eventDetails.description) && */}
                {/* <TextEditor handleTextEditor={handleCodeText} text={codeText} /> */}
                {/* } */}
                {/* {(!isEdit) && */}
                {/* <TextEditor handleTextEditor={handleCodeText} text={codeText} /> */}
                {/* } */}
                <Field
                  type='textarea'
                  name='code'
                  label='Code'
                  placeholder=''
                  component={RenderInputField}
                  validate={[required]}
                />
              </div>
            </div>
            <div className='my-3'>
              <label className='form-label text-left pt-5'>
                Explanation {`(${explanationText.length - 8})`}
              </label>
              <div className='mb-3 border '>
                {/* {(isEdit && eventDetails.description) && */}
                <TextEditor
                  handleTextEditor={handleExplanationText}
                  text={explanationText}
                />
                {/* } */}
                {/* {(!isEdit) && */}
                {/* <TextEditor handleTextEditor={handleExplanationText} text={explanationText} /> */}
                {/* } */}
              </div>
            </div>
            <div className='row my-4'>
              <div className='col-12 text-right'>
                <button
                  type='submit'
                  className='btn custom-button'
                  disabled={submitting}
                >
                  <span>{isEdit ? "Edit Solution" : "Add Solution"}</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className='row m-0'>
        <div className='col-12'>
          {adminSolutions &&
            adminSolutions.map((solution, i) => (
              <>
                <div className='row m-0 my-4 px-md-5 px-3' key={i}>
                  <div className='col-12 p-md-4 p-2 card-schadow green-card rounded-lg'>
                    <div className='row m-0'>
                      <div className='col-6 d-flex text-left px-0'>
                        <span className='days-heading pr-2'>Language: </span>
                        <p>{solution.technology}</p>
                      </div>
                      <div className='col-6 text-right px-0'>
                        <div>
                          <i className='far fa-edit mx-3' />
                          <i
                            className='far fa-trash'
                            onClick={() =>
                              handleDeleteAdminSolution(solution.solutionId)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <span className='days-heading'>Code:</span>
                    {solution && (
                      <PrismCode
                        code={solution.code || ""}
                        language={"javascript"}
                        plugins={["line-numbers"]}
                      />
                    )}
                  </div>
                </div>
              </>
            ))}
        </div>
      </div>
    </>
  );
};

export default reduxForm({
  form: "add-solution",
})(AddSolution);
