import React, { useState } from "react";

const AppointmentBookForm = ({
  slot, handleSchadule
}) => {
  const [formData, setFormData] = useState({
    email: '',
    fullName: '',
    remark: '',
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  return (
    <>
      <div className="row mx-0 border-left justify-content-center align-items-center">
        <div className="col-12 px-5">
          <div className="">
            <h6>Enter Details</h6>
            <div className="mb-3">
              <label className="form-label">Name</label>
              <input type="text" name="fullName" value={formData.fullName} className="form-control" onChange={(e) => handleChange(e)} placeholder="name@example.com" />
            </div>
            <div className="mb-3">
              <label className="form-label">Email</label>
              <input type="email" name="email" value={formData.email} onChange={(e) => handleChange(e)} className="form-control" placeholder="name@example.com" />
            </div>
            <div className="mb-3">
              <label className="form-label">Please share anything that will help prepare for our meeting.</label>
              <textarea name="remark" value={formData.remark} onChange={(e) => handleChange(e)} className="form-control" rows="3"></textarea>
            </div>
          </div>
          <div className="btn btn-primary" onClick={() => handleSchadule(formData)}>
            Schedule Event
          </div>
        </div>
      </div>
    </>
  )
}

export default AppointmentBookForm