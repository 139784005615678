import React from "react";
import HeroSection from "./HeroSection";
import WelcomeToClubSection from "./WelcomeToClubSection";
import WhyStudentClub from "./WhyStudentClub";
import ClubActivitiesSection from "./ClubActivitiesSection";
import WeAreHereToHelp from "./WeAreHereToHelp";
import VisionSection from "./VisionSection";

const StudentLearningClubLanding = () => {
  // ----------Why hook is not working here
  // const dispatch = useDispatch()  // const [data, setData] = useState("ed")
  // useEffect(() => {
  //   alert("vcsdfghjk")
  //   if (window.location.hash === "#/club") {
  //     debugger
  //     // hideCompleteFooter(true)
  //     dispatch(hideCompleteHeader(true))
  //   }

  //   return () => {
  //     dispatch(hideCompleteHeader(false))
  //   }
  // }, [])

  return (
    <>
      <HeroSection />
      <WelcomeToClubSection />
      <WhyStudentClub />
      <VisionSection />
      <ClubActivitiesSection />
      {/* <EventGlimpse />
      <StudentTestimonial /> */}
      <WeAreHereToHelp />
    </>
  );
};

export default StudentLearningClubLanding;
