export const techFlowCards = [
  {
    svgSource: "Find_flow-card.svg",
    title: "Find best resources of your choice and start your learning",
  },
  {
    svgSource: "Add.svg",
    title: "Add your resources and contribute in the community",
  },
  {
    svgSource: "Join.svg",
    title: "Join Learning community and get ahead with your peers",
  },
  {
    svgSource: "Love.svg",
    title: "Show some love towards your favourite resources.",
  },
  {
    svgSource: "SaveLogo.svg",
    title: "Save your favorite resources and create your own library",
  },
  {
    svgSource: "Comment.svg",
    title: "Comment and review different resources and interact with community",
  },
  {
    svgSource: "Help.svg",
    title: "Help to make Best Tutorial at top",
  },
];
