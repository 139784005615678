export const sessionDetails = [
  {
    session: "Orientation",
    date: "29/May/2022",
    time: '6:00 PM',
    speaker: "Datacode Team",
    id: 1,
    contentList: [
      'Introduction to the program',
      'Live Demo',
      'Get to know the mentors and team members',
      'Team allotment and intro',
    ],
    type: 'Problem Solving Session',
  },
  {
    session: "Getting started with Array",
    date: "30/May/2022",
    time: '6:00 PM',
    speaker: "Akshay Mandliya",
    id: 2,
    contentList: [
      'Start with your first Code',
      'How to react a problem statement, understand various cases and constraints',
      'Discuss the advantages of CP',
      'Intro to Array in DataStructures',

    ],
    type: 'Problem Solving Session',
  },


  {
    session: "Array with Bitwise Operator",
    date: "31/May/2022",
    time: '6:00 PM',
    speaker: "Manas",
    id: 3,
    contentList: [
      'Learn Array Fundamentals',
      'Understand Bitwise Operator',
    ],
    type: 'Problem Solving Session',
  },



  {
    session: "Two way with Two Pointers",
    date: "01/June/2022",
    time: '6:00 PM',
    speaker: "Manas",
    id: 4,
    contentList: [
      'Two Pointer in details',
      'Example problems',
    ],
    type: 'Problem Solving Session',
  },

  {
    session: "Matrix Implementation & Problem Solving",
    date: "02/June/2022",
    time: '6:00 PM',
    speaker: "Akshay Mandliya",
    id: 5,
    contentList: [
      'Understand Matrix',
      'Searching',
      'Sorting',

    ],
    type: 'Problem Solving Session',
  },
  {
    session: "Searching & Problem Solving",
    date: "03/June/2022",
    time: '6:00 PM',
    speaker: "Sonakshi",
    id: 6,
    contentList: [
      'Implementation of Searching',
      'Learn different Searching techniques',
    ],
    type: 'Problem Solving Session',
  },
  {
    session: "Sorting Implementation and Problem Solving",
    date: "06/June/2022",
    time: '6:00 PM',
    speaker: "Ankit",
    id: 7,
    contentList: [
      'Introduction to Sorting',
      'Sorting Implementation',

    ],
    type: 'Problem Solving Session',
  },


  {
    session: "Sorting Implementation and Problem Solving",
    date: "07/June/2022",
    time: '6:00 PM',
    speaker: "Ankit",
    id: 8,
    contentList: [
      'Sorting in Details',
      'Learn different sorting techniques',

    ],
    type: 'Problem Solving Session',
  },


  {
    session: "Getting started with Strings",
    date: "08/June/2022",
    time: '6:00 PM',
    speaker: "Shivam",
    id: 9,
    contentList: [
      'Introduction & Implementation of Strings',
      'String functions',
      'Sliding window Implementation',
    ],
    type: 'Problem Solving Session',
  },
  {
    session: "Subsequences and Substrings",
    date: "09/June/2022",
    time: '6:00 PM',
    speaker: "Shivam",
    id: 10,
    contentList: [
      'String in details',
      'Sorting and Searching Techniques on Strings',

    ],
    type: 'Problem Solving Session',
  },

  {
    session: "Occurrence Based Strings",
    date: "10/June/2022",
    time: '6:00 PM',
    speaker: "Shivam",
    id: 11,
    contentList: [
      'Palindrome & Pattern Searching in String',
      'Spacing, Anagram & string splitting',
      'Balance Parentheses & Bracket Evaluation',
    ],
    type: 'Problem Solving Session',
  },


  {
    session: "Understanding Concept of Recursion",
    date: "13/June/2022",
    time: '6:00 PM',
    speaker: "Ayushi",
    id: 12,
    contentList: [
      'Recursion Implementation',
      'Problem Solving with Recursion',

    ],
    type: 'Problem Solving Session',
  },
  {
    session: "Understanding Concept of Recursion",
    date: "14/June/2022",
    time: '6:00 PM',
    speaker: "Ayushi",
    id: 13,
    contentList: [
      'Recursion Implementation in details',
      'Divide and Conquer Approach',

    ],
    type: 'Problem Solving Session',
  },


  {
    session: "Understanding Concept of Backtracking",
    date: "15/June/2022",
    time: '6:00 PM',
    speaker: "Gopal",
    id: 14,
    contentList: [
      'Backtracking Implementation',
      'Solving N Queens Problem',

    ],
    type: 'Problem Solving Session',
  },


  {
    session: "Understanding Concept of Backtracking",
    date: "16/June/2022",
    time: '6:00 PM',
    speaker: "Gopal",
    id: 15,
    contentList: [
      'Backtracking in Details',
      'Problem solving through Backtracking',

    ],
    type: 'Problem Solving Session',
  },





  {
    session: "Get to know LinkedList",
    date: "17/June/2022",
    time: '6:00 PM',
    speaker: "Aaveg",
    id: 16,
    contentList: [
      'Concept of LinkedList',
      'Problem solving through LinkedList',

    ],
    type: 'Problem Solving Session',
  },

  {
    session: "Understanding the Concept of Stack",
    date: "20/June/2022",
    time: '6:00 PM',
    speaker: "Abhinav",
    id: 17,
    contentList: [
      'Introduction to Stack',
      'Implementation of Stack with Array',

    ],
    type: 'Problem Solving Session',
  },


  {
    session: "Stack with LinkedList",
    date: "21/June/2022",
    time: '6:00 PM',
    speaker: "Abhinav",
    id: 18,
    contentList: [
      'Implementation of Stack with LinkedList',

    ],
    type: 'Problem Solving Session',
  },


  {
    session: "Stack and Recursion",
    date: "22/June/2022",
    time: '6:00 PM',
    speaker: "Akshay Mandliya",
    id: 19,
    contentList: [
      'Implementation of Stack with Recursion',
    ],
    type: 'Problem Solving Session',
  },



  {
    session: "Understanding the Concept of Queue",
    date: "23/June/2022",
    time: '6:00 PM',
    speaker: "Kirti Gera",
    id: 20,
    contentList: [
      'Implementation of Queue',

    ],
    type: 'Problem Solving Session',
  },



  {
    session: "Queue in details",
    date: "24/June/2022",
    time: '6:00 PM',
    speaker: "Akshay Mandliya",
    id: 21,
    contentList: [
      'Problem solving in Queue',
    ],
    type: 'Problem Solving Session',
  },
  {
    session: "Concept of Hashing",
    date: "27/June/2022",
    time: '6:00 PM',
    speaker: "Chitrakshi",
    id: 22,
    contentList: [
      'Implementation of Hashing',
      'Hashing Problem Solving',

    ],
    type: 'Problem Solving Session',
  },

  {
    session: "Ending Ceremony",
    date: "28/June/2022",
    time: '6:00 PM',
    speaker: "Akshay Mandliya",
    id: 23,
    contentList: [
      'Say Goodbye',
      'Share your achievement',
      'Get Recognised',

    ],
    type: 'Problem Solving Session',
  },
]


export const daysContentIndexList = [
  // 0
  {
    day: 0,
    title: "Introduction to 30 Days of Code",
    topic: "Introduction",
    contentList: [
      {
        type: "lesson",
        text: "Get to know the program",
      },
      {
        type: "lesson",
        text: "Understand the functionalities",
      },
      {
        type: "lesson",
        text: "Submit your first assignment",
      },
      {
        type: "lesson",
        text: "And you are done",
      },
      {
        type: "quiz",
        text: "Test your knowledge",
      },
      {
        type: "problem",
        text: "Your First Program ",
      },
    ],
  },

  // 1
  {
    day: 1,
    title: "Array in DataStructures",
    topic: "Array",
    contentList: [
      {
        type: "lesson",
        text: "Introduction to Array",
      },
      {
        type: "quiz",
        text: "Test your knowledge",
      },
      {
        type: "problem",
        text: "Reverse an Array?",
      },
    ],
  },



  // 2
  {
    day: 2,
    title: "Array - 2",
    topic: "Array",
    contentList: [
      {
        type: "lesson",
        text: "Understanding Array in details",
      },
      {
        type: "quiz",
        text: "Test your knowledge",
      },
      {
        type: "problem",
        text: "Chocolate Distribution Problem",
      },
    ],
  },







  // 3
  {
    day: 3,
    title: "Array - 3",
    topic: "Array",
    contentList: [
      {
        type: "lesson",
        text: "Let’s Play with Array",
      },
      {
        type: "quiz",
        text: "Test your knowledge",
      },
      {
        type: "problem",
        text: "Maximum bitwise AND value of a pair in an array",
      },
    ],
  },












  // 4
  {
    day: 4,
    title: "Array - 4",
    topic: "Array",
    contentList: [
      {
        type: "lesson",
        text: "Advance Topics in Array",
      },
      {
        type: "quiz",
        text: "Test your knowledge",
      },
      {
        type: "problem",
        text: "Maximum bitwise AND value of a pair in an array",
      },
    ],
  },












  // 5
  {
    day: 5,
    title: "Matrix in Programming",
    topic: "Matrix",
    contentList: [
      {
        type: "lesson",
        text: "Let’s Play with Matrices",
      },

      {
        type: "quiz",
        text: "Test your knowledge",
      },
      {
        type: "problem",
        text: "Addition of two matrices.",
      },
    ],
  },


  // 6
  {
    day: 6,
    title: "Matrix - 2",
    topic: "Matrix",
    contentList: [
      {
        type: "lesson",
        text: "Let’s Play with Matrices",
      },

      {
        type: "quiz",
        text: "Test your knowledge",
      },
      {
        type: "problem",
        text: "Inplace rotate matrix by 90 degrees",
      },
    ],
  },
  // 7
  {
    day: 7,
    title: "Searching in Datastructures",
    topic: "Searching",
    contentList: [
      {
        type: "lesson",
        text: "Introduction to Searching",
      },
      {
        type: "quiz",
        text: "Test your knowledge",
      },
      {
        type: "problem",
        text: "Searching in an array",
      },
    ],
  },






  // 8
  {
    day: 8,
    title: "Searching - 2",
    topic: "Searching",
    contentList: [
      {
        type: "lesson",
        text: "Advance Topics in Searching",
      },
      {
        type: "quiz",
        text: "Test your knowledge",
      },
      {
        type: "problem",
        text: "Find a peak element index",
      },
    ],
  },


  // 9
  {
    day: 9,
    title: "Sorting in Datastructures",
    topic: "Sorting",

    contentList: [
      {
        type: "lesson",
        text: "Let’s understand Sorting",
      },
      {
        type: "quiz",
        text: "Test your knowledge",
      },
      {
        type: "problem",
        text: "Sort Colors",
      },
    ],
  },


  // 10
  {
    day: 10,
    title: "Sorting - 2",
    topic: "Sorting",
    contentList: [
      {
        type: "lesson",
        text: "Advance Techniques in Sorting",
      },
      {
        type: "quiz",
        text: "Test your knowledge",
      },
      {
        type: "problem",
        text: "Sort an array in wave form",
      },
    ],
  },





  // 11
  {
    day: 11,
    title: "String in Datastructures",
    topic: "String",
    contentList: [
      {
        type: "lesson",
        text: "Let’s understand Strings",
      },
      {
        type: "quiz",
        text: "Test your knowledge",
      },
      {
        type: "problem",
        text: "Is Anagram?",
      },
    ],
  },

  // 12
  {
    day: 12,
    title: "String - 2",
    topic: "String",
    contentList: [
      {
        type: "lesson",
        text: "String in details",
      },
      {
        type: "quiz",
        text: "Test your knowledge",
      },
      {
        type: "problem",
        text: "Remove Duplicates",
      },
    ],
  },







  // 13
  {
    day: 13,
    title: "String - 3",
    topic: "String",
    contentList: [
      {
        type: "lesson",
        text: "Playing with strings",
      },
      {
        type: "quiz",
        text: "Test your knowledge",
      },
      {
        type: "problem",
        text: "Longest Palindromic Sub-String",
      },
    ],
  },






  //14
  {
    day: 14,
    title: "String - 4",
    topic: "String",
    contentList: [
      {
        type: "lesson",
        text: "Introduction to Bit Manipulation",
      },

      {
        type: "quiz",
        text: "Test your knowledge",
      },
      {
        type: "problem",
        text: "Mangloo and the secret message",
      },
    ],
  },


  //15
  {
    day: 15,
    title: "String - 5",
    topic: "String",
    contentList: [
      {
        type: "lesson",
        text: "Advance Topics in String",
      },
      {
        type: "quiz",
        text: "Test your knowledge",
      },
      {
        type: "problem",
        text: "Sum of Numbers",
      },
    ],
  },


  //16
  {
    day: 16,
    title: "Recursion in Programming",
    topic: "Recursion",
    contentList: [
      {
        type: "lesson",
        text: "Get started with Recursion",
      },
      {
        type: "quiz",
        text: "Test your knowledge",
      },
      {
        type: "problem",
        text: "Colorful Number",
      },
    ],
  },


  //17
  {
    day: 17,
    title: "Recursion - 2",
    topic: "Recursion",
    contentList: [
      {
        type: "lesson",
        text: "Recursion in details",
      },
      {
        type: "quiz",
        text: "Test your knowledge",
      },
      {
        type: "problem",
        text: "Search your X",
      },
    ],
  },


  //18
  {
    day: 18,
    title: "Backtracking in Programming",
    topic: "Backtracking",
    contentList: [
      {
        type: "lesson",
        text: "Let’s get backtracking",
      },
      {
        type: "quiz",
        text: "Test your knowledge",
      },
      {
        type: "problem",
        text: "Bracket Matching",
      },
    ],
  },


  //19
  {
    day: 19,
    title: "Backtracking - 2",
    topic: "Backtracking",
    contentList: [
      {
        type: "lesson",
        text: "Deep diving into backtracking",
      },

      {
        type: "quiz",
        text: "Test your knowledge",
      },
      {
        type: "problem",
        text: "Reverse a string using stack",
      },
    ],
  },


  //20
  {
    day: 20,
    title: "LinkedList in Datastructures",
    topic: "LinkedList",

    contentList: [
      {
        type: "lesson",
        text: "Getting started with LinkedList",
      },
      {
        type: "quiz",
        text: "Test your knowledge",
      },
      {
        type: "problem",
        text: "Linked List Traversing",
      },
    ],
  },




  //21
  {
    day: 21,
    title: "LinkedList - 2",
    topic: "LinkedList",
    contentList: [
      {
        type: "lesson",
        text: "LinkedList basics",
      },
      {
        type: "quiz",
        text: "Test your knowledge",
      },
      {
        type: "problem",
        text: "First Non-Repeating Character In A Stream Of Characters",
      },
    ],
  },




  // 22
  {
    day: 22,
    title: "LinkedList - 3",
    topic: "LinkedList",
    contentList: [
      {
        type: "lesson",
        text: "Deep Dive into LinkedList",
      },
      {
        type: "quiz",
        text: "Test your knowledge",
      },
      {
        type: "problem",
        text: "Count your family members",
      },
    ],
  },


  //23
  {
    day: 23,
    title: "Stack in Datastructures",
    topic: "Stack",
    contentList: [
      {
        type: "lesson",
        text: "Get started with stack",
      },

      {
        type: "quiz",
        text: "Test your knowledge",
      },
      {
        type: "problem",
        text: "The bicycle rider",
      },
    ],
  },


  //24
  {
    day: 24,
    title: "Stack - 2",
    topic: "Stack",
    contentList: [
      {
        type: "lesson",
        text: "Different stack techniques",
      },
      {
        type: "quiz",
        text: "Test your knowledge",
      },
      {
        type: "problem",
        text: "State whether given tree is BST or not",
      },
    ],
  },


  //25
  {
    day: 25,
    title: "Stack - 3",
    topic: "Stack",
    contentList: [
      {
        type: "lesson",
        text: "Deep Dive into Stack",
      },
      {
        type: "quiz",
        text: "Test your knowledge",
      },
      {
        type: "problem",
        text: "Find Kth Largest element",
      },
    ],
  },


  // 26
  {
    day: 26,
    title: "Queue in Datastructures",
    topic: "Queue",
    contentList: [
      {
        type: "lesson",
        text: "Introduction to Queue",
      },

      {
        type: "quiz",
        text: "Test your knowledge",
      },
      {
        type: "problem",
        text: "Print the smallest elements",
      },
    ],
  },


  //27
  {
    day: 27,
    title: "Queue - 2",
    topic: "Queue",
    contentList: [
      {
        type: "lesson",
        text: "Deep Dive into Queue",
      },
      {
        type: "quiz",
        text: "Test your knowledge",
      },
      {
        type: "problem",
        text: "Detect Cycle",
      },
    ],
  },




  //28
  {
    day: 28,
    title: "Hashing in Programming",
    topic: "Hashing",
    contentList: [
      {
        type: "lesson",
        text: "Introduction to Hashing"
      },

      {
        type: "quiz",
        text: "Test your knowledge",
      },
      {
        type: "problem",
        text: "Clone a Graph",
      },
    ],
  },


  // 29
  {
    day: 29,
    title: "Hashing - 2",
    topic: "Hashing",
    contentList: [
      {
        type: "lesson",
        text: "Let’s understand the Hashing",
      },
      {
        type: "quiz",
        text: "Test your knowledge",
      },
      {
        type: "problem",
        text: "Longest Common Subsequence",
      },
    ],
  },


  //30
  {
    Day: 30,
    title: "Hashing - 3",
    topic: "Hashing",
    contentList: [
      {
        type: "lesson",
        text: "Deep dive into Hashing",
      },
      {
        type: "quiz",
        text: "Test your knowledge",
      },
      {
        type: "problem",
        text: "Your last Day of 30 Days of Code - Word Break",
      },
    ],
  },
];
