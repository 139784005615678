import React from "react";
import { useDispatch } from "react-redux";
import { deleteProblemFromBatch } from "../../../actions";
import ProblemListCard from "../Problems/ProblemsList/ProblemListCard";
import AddBatchProblemDrawer from "./AddBatchProblemDrawer";

const ProblemList = ({ batch, codeBatchDetails, codeBatchesLoading }) => {
  const dispatch = useDispatch();

  const handleDeleteProblemFromBatch = (problem) => {
    dispatch(deleteProblemFromBatch({ batch: batch, problem }));
  };
  return (
    <>
      <div className='d-flex justify-content-between'>
        <h1>Problems</h1>
        <AddBatchProblemDrawer batch={batch} />
      </div>

      {codeBatchDetails?.problems &&
        codeBatchDetails.problems.map((problem) => (
          <ProblemListCard
            day={problem?.day}
            batchContentId={problem._id}
            item={problem.problemDetails}
            showDeleteFromBatchIcon={true}
            handleDeleteFromBatch={handleDeleteProblemFromBatch}
          />
        ))}
    </>
  );
};

export default ProblemList;
