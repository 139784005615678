import { Button, Drawer, Space } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Combobox } from "react-widgets";
import { addProblemIntoBatch, getProblems } from "../../../actions";
import { DSAContent } from "../../DaysCodeDashboard/DaysCodeTimeLine/helper";
import { getTopicOptions } from "../../utils";
import ProblemListCard from "../Problems/ProblemsList/ProblemListCard";

const AddBatchProblemDrawer = ({ batch }) => {
  const dispatch = useDispatch();
  const { problemsList } = useSelector((state) => state.dayscode) || {};

  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("right");
  const [topic, setTopic] = useState();
  const [openAddDay, setOpenAddDay] = useState("");

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    setOpenAddDay("");
  };

  const handleProblemTopic = (topic) => {
    setTopic(topic.name);
    dispatch(getProblems({ status: "public", topic: topic.name }));
  };

  const handleAddProblemIntoBatch = (problem, day) => {
    dispatch(
      addProblemIntoBatch({ batch: batch, problemDetails: problem, day: day }),
    );
  };

  return (
    <>
      <Space>
        <Button type='primary' onClick={showDrawer}>
          Add Problem
        </Button>
      </Space>
      <Drawer
        title='Add Problem Into Batch'
        placement={placement}
        closable={false}
        onClose={onClose}
        open={open}
        key={placement}
      >
        <h1>Add Problems</h1>
        <Combobox
          data={getTopicOptions(DSAContent)}
          dataKey={"value"}
          textField='name'
          placeholder={"Select Topic Name"}
          value={topic}
          onChange={(value) => handleProblemTopic(value)}
        />
        {problemsList &&
          problemsList.map((problem) => (
            <ProblemListCard
              handleAddIntoBatch={handleAddProblemIntoBatch}
              showAddBatchIcon={true}
              item={problem}
              openAddDay={openAddDay}
              setOpenAddDay={setOpenAddDay}
            />
          ))}
      </Drawer>
    </>
  );
};

export default AddBatchProblemDrawer;
