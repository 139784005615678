import React from 'react'
import LayoutContainer from '../../LayoutContainer'

const UserAttendanceList = () => {
  return (
    <>
      <LayoutContainer>
      </LayoutContainer>
    </>
  )
}

export default UserAttendanceList