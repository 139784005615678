import { Popover } from "antd";
import React from "react";
import WorkshopCard from "./WorkshopCard";
import WorkshopFilterPopover from "./WorkshopFilterPopover";
import WorkshopSortPopover from "./WorkshopSortPopover";

const PopularWorkshops = () => {
  const data = [
    {
      img: "http://res.cloudinary.com/datacode/image/upload/v1691170388/natghz9cxos2uyp4osxf.png",
      days: "3x Days",
      title: "Full Stack Web Development Workshop",
      category: "Programming",
      speaker: "",
      students: "100",
      rating: "3",
    },
    {
      img: "http://res.cloudinary.com/datacode/image/upload/v1691170388/natghz9cxos2uyp4osxf.png",
      days: "10x Days",
      title: "Full Stack Web Development Workshop",
      category: "Web Development",
      speaker: "",
      students: "140",
      rating: "5",
    },
    {
      img: "http://res.cloudinary.com/datacode/image/upload/v1691170388/natghz9cxos2uyp4osxf.png",
      days: "4x Days",
      title: "Full Stack Web Development Workshop",
      category: "Design",
      speaker: "",
      students: "500",
      rating: "5",
    },
    {
      img: "http://res.cloudinary.com/datacode/image/upload/v1691170388/natghz9cxos2uyp4osxf.png",
      days: "",
      title: "Full Stack Web Development Workshop",
      category: "",
      speaker: "",
      students: "200",
      rating: "6",
    },
    {
      img: "http://res.cloudinary.com/datacode/image/upload/v1691170388/natghz9cxos2uyp4osxf.png",
      days: "",
      title: "Full Stack Web Development Workshop",
      category: "",
      speaker: "",
      students: "110",
      rating: "4",
    },
  ];

  // const categories = [
  //   "Programming",
  //   "Web Development",
  //   "Design",
  //   "Data Science",
  //   "Placement Prep",
  // ];

  return (
    <>
      <div className='row mx-0 d-flex justify-content-center popular-workshop-section'>
        <div className='col-12 col-md-10 border'>
          <div className='row mx-0'>
            <div className='col-12 px-0'>
              <div className='row mx-0 d-flex'>
                <div className='col-md-6 col-12'>
                  <h1 className='heading'>Popular Workshops</h1>
                </div>
                <div className='col-md-6 col-12 justify-content-end workshop-filter-bar d-flex'>
                  <Popover
                    arrow={false}
                    placement='bottomRight'
                    content={WorkshopSortPopover}
                    title='Sort Workshop'
                    trigger='click'
                  >
                    <div className='d-flex align-items-center btn border'>
                      <i className='fas fa-filter search-icon' />
                      <div className='border rounded p-2 ml-4'>Sort</div>
                    </div>
                  </Popover>
                  <Popover
                    arrow={false}
                    placement='bottomRight'
                    content={WorkshopFilterPopover}
                    title='Filter Applications'
                    trigger='click'
                  >
                    <div className='d-flex align-items-center btn border'>
                      <i className='fas fa-filter search-icon' />
                      <div className='border rounded p-2 ml-4'>Filter</div>
                    </div>
                  </Popover>
                </div>
              </div>
            </div>
          </div>
          <div className='row mx-0'>
            <div className='col-12 px-0'>
              <div className='row mx-0'>
                {data &&
                  data.map((item, i) => (
                    <div className='col-6 px-0 border rounded-lg' key={i}>
                      <WorkshopCard item={item} />
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className='row mx-0 my-5'>
            <div className='col-12 text-center'>
              <div className='btn btn-primary'>Explore All Workshops....</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopularWorkshops;
