import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { Field, reduxForm } from "redux-form";

import CustomLoader from "../../../../../../shared_components/CustomLoader";
import {
  RenderInputField,
  renderSelectField,
} from "../../../../../../shared_components/ReduxFormFields";
import { getTechLable, technologyOptions } from "../../../../../../utils";
import { required } from "../../../../../../utils/validators";

const AddInterviewQuestions = ({
  reset,
  handleSubmit,
  submitting,
  currentUser,
  currentUser: { userName },
  interviewQuestionsByUser,
  getInterviewQuestionByUserName,
  deleteInterviewQuestion,
  interviewQuestionsListLoading,
  initialize,
  addInterviewQuestion,
  editInterviewQuestion,
  addInterviewQuestionLoading,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  // const [openModal, setOpenModal] = useState(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // const userName = currentUser
    if (userName) {
      handleGetTutorialByUserName();
    }
    window.scrollTo(0, 0);
  }, [userName]);

  const handleGetTutorialByUserName = () => {
    getInterviewQuestionByUserName(userName).then((res) => {
      if (res.data) {
        //setTutorialsList(res.data)
      }
    });
  };

  const onSubmit = async (values) => {
    const interviewQuestion = { ...values };
    if (isEdit) {
      const tags = [
        interviewQuestion.cost,
        interviewQuestion.type,
        interviewQuestion.level,
      ];
      interviewQuestion["tags"] = tags;
      interviewQuestion["technology"] =
        interviewQuestion.technology && interviewQuestion.technology.value;
      editInterviewQuestion(interviewQuestion.id, interviewQuestion).then(
        (res) => {
          if (res && res.success) {
            reset("interviewQuestion");
            initialize({});
            setIsEdit(!isEdit);
          }
        },
      );
    } else {
      interviewQuestion["userName"] = currentUser && currentUser.userName;
      interviewQuestion["technology"] =
        interviewQuestion.technology && interviewQuestion.technology.value;
      interviewQuestion["id"] = Math.random().toString(36).substr(4, 9);
      const tags = [
        interviewQuestion.cost,
        interviewQuestion.type,
        interviewQuestion.level,
      ];
      interviewQuestion["tags"] = tags;
      addInterviewQuestion(interviewQuestion).then((res) => {
        if (res && res.success) {
          handleGetTutorialByUserName();
          reset("interviewQuestion");
        }
      });
    }
  };

  const handleEditTutorial = (id) => {
    window.scrollTo(0, 0);
    const editTutotial = interviewQuestionsByUser.find(
      (item) => item.id === id,
    );
    initialize({
      ...editTutotial,
      technology: {
        label: getTechLable(editTutotial.technology),
        value: editTutotial.technology,
      },
    });
    setIsEdit(!isEdit);
  };

  const handleCancelEdit = () => {
    initialize({});
    setIsEdit(!isEdit);
  };

  return (
    <div className='row mx-0 my-5 px-3 justify-content-center'>
      <div className='col-md-4 col-12 p-3 my-md-0 my-5 border rounded'>
        <h5 className='pb-3 border-bottom'>Add Interview Question</h5>
        <form className='' onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Field
              name='question'
              type='text'
              component={RenderInputField}
              label='Enter Question'
              placeholder=''
              validate={[required]}
              isDisabled={isEdit ? true : false}
            />
            <Field
              name='answer'
              type='textarea'
              component={RenderInputField}
              label='Enter Answer'
              placeholder=''
              validate={[required]}
            />
          </div>
          <div>
            <Field
              name='technology'
              label='Enter Tutorial Technology'
              options={technologyOptions}
              placeholder='Search Technology'
              component={renderSelectField}
              validate={[required]}
              textField='tech'
              dataKey='value'
            />
          </div>
          <div className='row mt-4'>
            <div className='col-12 text-right'>
              {
                <>
                  {isEdit && (
                    <div
                      className='btn cancel-button mr-3'
                      onClick={() => handleCancelEdit()}
                    >
                      Cancel
                    </div>
                  )}
                  <button
                    type='submit'
                    className={`btn ${
                      isEdit ? "edit-button" : "add-button"
                    } border bg-light login-button`}
                    disabled={submitting}
                  >
                    {
                      <>
                        {addInterviewQuestionLoading ? (
                          <div className='text-center'>
                            <Loader
                              type='TailSpin'
                              color='#30006d'
                              height={30}
                              width={30}
                              timeout={19000} //8 secs
                            />
                          </div>
                        ) : (
                          <>
                            {isEdit ? (
                              <span>
                                <i className='far fa-edit voilet mr-2' />
                                Edit Interview Question
                              </span>
                            ) : (
                              <>
                                <i className='fad fa-plus-circle green mr-2' />
                                <span>Add Interview Question</span>
                              </>
                            )}
                          </>
                        )}
                      </>
                    }
                  </button>
                </>
              }
            </div>
          </div>
        </form>
      </div>
      <div className='col-md-6 col-12 p-3 mx-5 border rounded'>
        <h5 className='pb-3 border-bottom'>Questions List</h5>
        {interviewQuestionsListLoading ? (
          <CustomLoader />
        ) : (
          <div className='my-5'>
            {interviewQuestionsByUser && interviewQuestionsByUser.length
              ? interviewQuestionsByUser.map((item, i) => (
                  <div
                    className='row mx-0 border my-3 card-schadow rounded'
                    key={i}
                  >
                    <div className='col-12 p-2'>
                      <div className='d-flex justify-content-between'>
                        <p className='text-right mb-1'>{item.technology}</p>
                        <div className=''>
                          <i
                            onClick={() => handleEditTutorial(item.id)}
                            className='far fa-edit mx-2'
                          />
                          <i
                            onClick={() => deleteInterviewQuestion(item)}
                            className='far fa-trash mx-2'
                          />
                        </div>
                      </div>
                      <h6 className='bg-light'>{item.question}</h6>
                      <p>{item.answer}</p>
                    </div>
                  </div>
                ))
              : interviewQuestionsByUser &&
                interviewQuestionsByUser.length === 0 && (
                  <h1 className='text-center'>No Question Available</h1>
                )}
          </div>
        )}
      </div>
    </div>
  );
};

export default reduxForm({
  form: "interviewQuestion", // a unique name for this form
})(AddInterviewQuestions);
