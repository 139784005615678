import React from "react"
import SettingsDashboardNav from "./SettingsDashboardNav"

const LayoutContainer = ({
  children,
  activeTab,
  setActiveTab,
}) => {
  return (
    <>
      <div className="row m-0 days-setting-layout">
        <div className="col-12 side-nav px-0 col-md-2 border-right">
          <SettingsDashboardNav
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>
        <div className="col-12 offset-sm-0 offset-md-2 col-md-10 px-0 main-section">
          {children}
        </div>
      </div>
    </>
  )

}

export default LayoutContainer