import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Collapse } from "reactstrap";
import { checkAdmin } from "../../../../../../../core/auth";
import ToolTip from "../../../../../../../shared_components/Tooltip";

import PrismCode from "../../../../../../PrismCode";
import TextEditor from "../../../../../aticles/BlogsEditor/TextEditor";
import { getSubmissionStatusIndicator } from "../../../helper";

const SubmissionFrame = ({
  handleSubmittionApproval,
  deleteSolution,
  solution,
  compileSolution,
  i,
  problem,
  day,
  problemId,
  currentUser,
}) => {
  const [status, setStatus] = useState();
  const [isEdit, setEdit] = useState(false);
  const [codeText, getCodeText] = useState("<p> </p>");

  const [showError, setShowError] = useState("");
  const [showWriteReview, setShowWriteReview] = useState();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (solution && solution.review !== null) {
      if (solution.isApproved === "waiting") {
        setEdit(false);
      } else {
        setStatus(solution.isApproved);
        setEdit(true);
        getCodeText(`${solution && solution.review}`);
      }
    }
  }, [solution]);

  // const handleReviewChange = (e) => {
  //   setReview(e.target.value)
  // }

  const handleSubmitReview = (problemId, userName) => {
    if (codeText !== "<p> </p>" && status !== undefined) {
      let reviewData = {
        review: codeText,
        isApproved: status,
        id: problemId,
        userName: userName,
      };
      handleSubmittionApproval(reviewData);
    } else {
      setShowError("Both fields Required");
    }
  };

  const toggle = (openTable) => {
    if (openTable === showWriteReview) {
      return setShowWriteReview("");
    }
    setShowWriteReview(openTable);
  };

  const handleCodeText = (text) => {
    getCodeText(text);
  };

  return (
    <div className='row m-0 my-4 px-md-5 px-0' key={i}>
      <div className='col-12 p-md-4 p-2 card-schadow rounded-lg border'>
        <div className='row mx-0 mb-3 d-flex justify-content-between pb-2 border-bottom'>
          <div className='col-6 px-0'>
            <div className='badge badge-primary p-2 rounded-lg text-white'>
              <span>Day: {day}</span>
            </div>
          </div>
          <div className='col-6 px-2 d-flex align-items-center justify-content-end'>
            {checkAdmin(currentUser) && (
              <i
                className='fa fa-trash mx-2'
                onClick={() =>
                  deleteSolution(problem?.value || problemId, solution.userName)
                }
              />
            )}
            <Link
              target={"_blank"}
              to={`/admin/dayscode/problem/preview/${problemId}`}
            >
              <i className='far fa-eye green' />
            </Link>
            <i
              className='fa fa-terminal ml-2'
              onClick={() =>
                compileSolution(solution.code, solution.technology)
              }
            />
          </div>
        </div>
        <div className='row mx-0'>
          <div className='col-12 col-md-6 px-0 text-left'>
            <div>
              <small className='mr-2'>
                <i className='fa fa-file-code mr-2' />
                Code Review:
              </small>
              <span
                className={
                  solution.isApproved === "good"
                    ? "text-success"
                    : solution.isApproved === "poor"
                    ? "text-danger"
                    : "text-warning"
                }
              >
                {solution.isApproved}
              </span>
            </div>
            <div className='d-flex '>
              <small className='mr-2'>
                <i className='fa fa-poll mr-2' />
                Status:{" "}
              </small>
              {getSubmissionStatusIndicator(solution)}
            </div>
          </div>
          <div className='col-12 col-md-6 px-0 text-left'>
            <small className='mr-2'>
              <i className='fa fa-user mr-2' />
              Submitted By: <b>{solution.userName}</b>
            </small>
            <div className='d-flex'>
              <small className='mr-2'>
                <i className='fa fa-code mr-2' />
                Language: <b>{solution.technology}</b>
              </small>
            </div>
            <div className='d-flex '>
              <small className='mr-2'>
                <i className='fa fa-puzzle-piece mr-2' />
                Problem Id: <b>{problemId}</b>
              </small>
            </div>
          </div>
        </div>
        {solution && (
          <PrismCode
            code={solution.code || ""}
            language={"javascript"}
            plugins={["line-numbers"]}
          />
        )}
        <Collapse isOpen={showWriteReview === i}>
          <div className='border-top mt-3 pt-2'>
            <div className='text-left mt-3 mt-md-4 bg-light border rounded-lg p-2 p-md-4'>
              <div className='mb-3'>
                <label className='form-label mb-2'>How did you like?</label>
                <div>
                  {(solution.isApproved === "poor" ||
                    solution.isApproved === "waiting") && (
                    <>
                      <i
                        id='goodIcon'
                        className={`mx-2 p-2 border rounded-circle green ${
                          status === "good" && "bg-success text-white"
                        } fa fa-thumbs-up`}
                        onClick={() => setStatus("good")}
                      />
                      <ToolTip
                        message={"Good Code"}
                        id='goodIcon'
                        placement='bottom'
                      />
                    </>
                  )}
                  {(solution.isApproved === "good" ||
                    solution.isApproved === "waiting") && (
                    <>
                      <i
                        id='poorIcon'
                        className={`mx-2 p-2 border rounded-circle red ${
                          status === "poor" && "bg-danger text-white"
                        } fa fa-thumbs-down `}
                        onClick={() => setStatus("poor")}
                      />
                      <ToolTip
                        message={"Poor Code"}
                        id='poorIcon'
                        placement='bottom'
                      />
                    </>
                  )}
                </div>
              </div>
              <label className='form-label mb-2'>Write Code Review</label>
              <div className='mb-3 border '>
                {isEdit && solution && solution.review && (
                  <TextEditor
                    handleTextEditor={handleCodeText}
                    text={solution && solution.review ? codeText : ""}
                  />
                )}
                {!isEdit && (
                  <TextEditor
                    handleTextEditor={handleCodeText}
                    text={solution && solution.review ? codeText : ""}
                  />
                )}
              </div>
              {/* <textarea
                  name="review"
                  placeholder=""
                  placeholder={'code review according to the standard check points'}
                  value={review}
                  type='textarea'
                  className="form-input-field-textarea"
                  onChange={(e) => handleReviewChange(e)}
                // disabled={isDisabled ? true : false}
                /> */}
              <div className='text-right'>
                <button
                  onClick={() =>
                    handleSubmitReview(
                      problem?.value || problemId,
                      solution.userName,
                    )
                  }
                  type='button'
                  className='mt-3 btn custom-border-capsule-button'
                >
                  <small>Save Review</small>
                </button>
              </div>
              <p className='red'>{showError}</p>
            </div>
          </div>
        </Collapse>
        <div className='row mx-0 my-2'>
          <div className='col-12 px-0 text-left text-primary'>
            <span className='pointer' onClick={() => toggle(i)}>
              {showWriteReview === i ? (
                <span>
                  Hide Review
                  <i className='fa fa-angle-up text-dark px-3' />
                </span>
              ) : (
                <span>
                  Write Review
                  <i className='fa fa-angle-down text-dark px-3' />
                </span>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubmissionFrame;
