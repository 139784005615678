import React from "react";
import { getCompletedDays } from "../../../DaysCode/helpers";

// const getStatus = (status) => {
//   if (status) {
//     return <i className="fas fa-user-check green" />
//   } else {
//     return <i className="fas fa-user-clock" />
//   }
// }

const getSubmissionsStatusData = (row) => {
  return (
    <div className='col-12 p-0'>
      <div className='row mx-0'>
        <div className='col-8 p-0'>
          <small>
            <i className='green far fa-check-circle mr-2' />
            Correct:
          </small>
        </div>
        <div className='col-2 p-0'>
          <b className=''>{row.correctCount}</b>
        </div>
      </div>
      <div className='row mx-0 d-flex align-items-center'>
        <div className='col-8 p-0'>
          <small>
            <i className='fal fa-times-circle red mr-2' />
            Wrong:
          </small>
        </div>
        <div className='col-2 p-0'>
          <b>{row.wrongCount}</b>
        </div>
      </div>
    </div>
  );
};

const getSubmissionsReviewData = (row) => {
  return (
    <div className='col-12 p-0'>
      <div className='row mx-0'>
        <div className='col-8 p-0'>
          <small className=''>
            <i className='far fa-comment-alt-smile mr-2 voilet' />
            Good Review:
          </small>
        </div>
        <div className='col-2 p-0'>
          <b className='ml-2'>{row.goodCount}</b>
        </div>
      </div>
      <div className='row mx-0 d-flex align-items-center'>
        <div className='col-8 p-0'>
          <small className='text-nowrap'>
            <i className='far fad fa-frown mr-2 orange' />
            Poor Review:
          </small>
        </div>
        <div className='col-2 p-0'>
          <b className='ml-2'>{row.poorCount}</b>
        </div>
      </div>
      <div className='row mx-0 d-flex align-items-center'>
        <div className='col-8 p-0'>
          <small>
            <i className='far fa-poll mr-2 text-warning' />
            Under Review:
          </small>
        </div>
        <div className='col-2 p-0'>
          <b className='ml-2'>{row.waitingCount}</b>
        </div>
      </div>
    </div>
  );
};

const getMentors = (row) => {
  return row.mentorAssign.map((item) => (
    <p className=''>
      <i className='fal fa-user-shield mr-2' />
      {item.mentorName}
    </p>
  ));
};

export const getColumns = () => [
  {
    dataField: "",
    text: "S.NO.",
    align: "center",
    headerAlign: "center",
    headerClasses: "table-header s-no",
    formatter: (cell, row, index) => <span>{++index}</span>,
  },
  {
    dataField: "name",
    text: "Name",
    sort: true,
    headerClasses: "table-header status",
    style: { color: "#757575" },
  },
  {
    dataField: "",
    text: "Mentors",
    sort: true,
    headerClasses: "table-header status",
    style: { color: "#757575" },
    formatter: (cell, row, index) => getMentors(row),
  },
  {
    dataField: "",
    text: "Pending",
    sort: true,
    style: { color: "#757575" },
    align: "left",
    headerAlign: "left",
    headerClasses: "table-header status",
    formatter: (cell, row, index) => {
      return (
        <div className='row mx-0'>
          <small className='d-flex align-items-center text-dark badge-pill bg-pastel-red px-2'>
            <i className='fal fa-alarm-exclamation mr-2' />
            Pending:{" "}
            <b className='m-0 ml-2'>
              {getCompletedDays() + 1 - (row.correctCount + row.wrongCount)}
            </b>
          </small>
          <small className='mt-2 d-flex align-items-center text-dark badge-pill bg-pastel-green px-2'>
            <i className='fal fa-clipboard-check mr-2' />
            Submitted: <b className='m-0 ml-2'>{row?.submittedDays?.length}</b>
          </small>
        </div>
      );
    },
  },
  {
    dataField: "",
    text: "Code Status",
    sort: true,
    style: { color: "#757575" },
    align: "left",
    headerAlign: "left",
    headerClasses: "table-header status",
    formatter: (cell, row, index) => (
      <div className='row mx-0'>{getSubmissionsStatusData(row)}</div>
    ),
  },
  {
    dataField: "",
    text: "Review",
    sort: true,
    style: { color: "#757575" },
    align: "left",
    headerAlign: "left",
    headerClasses: "table-header name",
    formatter: (cell, row, index) => (
      <div className='row mx-0'>{getSubmissionsReviewData(row)}</div>
    ),
  },
  {
    dataField: "present",
    text: "Attendance",
    sort: true,
    style: { color: "#757575" },
    align: "center",
    headerAlign: "center",
    headerClasses: "table-header clock",
    formatter: (cell, row, index) => (
      <div className='text-center'>
        <h5 className='text-primary'>{row.present}</h5>
      </div>
    ),
  },
];
