import React from "react";

const offerCards = [
  {
    title: "Gamified Courses",
    content: "We help you develop creative ideas so that your business can grow more rapidly",
    link: "",
    image: "http://res.cloudinary.com/datacode/image/upload/v1687996277/i4udfgyqmphbebaquhbp.png",
  },
  {
    title: "Live Classes",
    content: "We provide services with the best designs than our designer team for your business",
    link: "",
    image: "http://res.cloudinary.com/datacode/image/upload/v1687996277/i4udfgyqmphbebaquhbp.png",
  },
  {
    title: "Workshops",
    content: "We help develop company websites to be more professional and attractive",
    link: "",
    image: "http://res.cloudinary.com/datacode/image/upload/v1687996277/i4udfgyqmphbebaquhbp.png",
  },
  {
    title: "Code Challenges",
    content: "We help develop company mobile apps to be more professional and attractive",
    link: "",
    image: "http://res.cloudinary.com/datacode/image/upload/v1687996277/i4udfgyqmphbebaquhbp.png",
  },
]

const LearnAndPracticeSection = () => {
  return (
    <>
      <div className="row mx-0 d-flex justify-content-center learn-practice-section">
        <div className="col-md-10 col-12">
          <div className="row mx-0">
            <div className="col-md-6 col-12">
              <div className="row mx-0 learn-card-row">
                {
                  offerCards && offerCards.map((item, i) => (
                    <div key={i} className="col-12 col-md-6 p-md-4 p-2 d-flex align-items-stretch">
                      <div className="learn-card shadow">
                        <img
                          className="rounded-circle border shadow"
                          height="50"
                          width="50"
                          loading="lazy"
                          src={item.image}
                          alt="avatar"
                        />
                        <div className="">
                          <h6>{item.title}</h6>
                          <span>{item.content}</span>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
            <div className="col-md-6 col-12 d-flex align-items-center learn-text-block">
              <div className="">
                <h1 className="heading">Learn and Practice with Bootcamps and Live Classes</h1>
                <p>Get personalized mentorship and guidance from several Industry experts who work in leading companies, and courses which are Designed by Industry experts</p>
                <ul>
                  <li>100% Job Placement Support</li>
                  <li>Mentors from Top Global Product companies</li>
                  <li>A Portfolio of Real-world Projects</li>
                  <li>Helping our students to learn and grow</li>
                  <li>Globally Recognized Certification</li>
                </ul>
                <button className="mt-5 btn btn-primary">Explore Live Classes</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LearnAndPracticeSection