import AddInterviewQuestions from "./AddInterviewQuestions";

import { connect } from "react-redux";
import {
  addInterviewQuestion,
  getInterviewQuestionByUserName,
  editInterviewQuestion,
  deleteInterviewQuestion,
} from "../../../actions";

const mapStateToProps = ({ placement, user }) => ({
  currentUser: user.currentUser ? user.currentUser : {},
  addInterviewQuestionLoading: placement.addInterviewQuestionLoading,
  interviewQuestionsByUser: placement.interviewQuestionsByUser,
  interviewQuestionsListLoading: placement.interviewQuestionsListLoading,
});

const mapDispatchToProps = {
  addInterviewQuestion,
  getInterviewQuestionByUserName,
  editInterviewQuestion,
  deleteInterviewQuestion,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddInterviewQuestions);
