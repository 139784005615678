import * as constants from "../constants";

const getClubApplications = (state, action) => {
  return {
    ...state,
    applicationsList: action.payload.applications,
    totalApplications: action.payload.count,
    clubApplicationLoading: !state.clubApplicationLoading,
  }
};

export const getClubHandlers = {
  [constants.GET_CLUB_APPLICATIONS]: getClubApplications,
};
