import React from "react";

const YoutubeBlockPreview = ({ item }) => {
  const youtube_parser = (item) => {
    var regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = item && item?.payload && item.payload.match(regExp);
    return match && match[7].length === 11 ? match[7] : false;
  };

  return (
    <>
      <h2 className='text-center'>Learn from Video</h2>
      <div className='video-responsive'>
        <iframe
          width='853'
          height='480'
          src={`https://www.youtube.com/embed/${youtube_parser(item)}`}
          // frameBorder="0"
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
          title='Embedded youtube'
        />
      </div>
    </>
  );
};
export default YoutubeBlockPreview;
