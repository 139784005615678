import React from "react"
import DaysCodeBatchPageNav from "./DaysCodeBatchPageNav"


const LayoutContainerBatchPage = ({
    children,
    activeTab,
    setActiveTab,
}) => {
    return (
        <>
            <div className="row m-0">
                <div className="col-12">
                    <DaysCodeBatchPageNav
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                    />
                    {children}
                </div>
            </div>
        </>
    )

}

export default LayoutContainerBatchPage