import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Link as LinkScroll } from "react-scroll";
import { Field, reduxForm } from "redux-form";

import Loader from "react-loader-spinner";
import { RenderInputField } from "../../../../../../shared_components/ReduxFormFields";
import { email, required } from "../../../../../../utils/validators";

import Head from "next/head";

const GettingStartedWebDev = ({
  reset,
  handleSubmit,
  submitting,
  eventRegister,
  eventRegistationLoading,
}) => {
  const [speakerAdded, setSpeakerAdded] = useState(false);

  const onSubmit = (values) => {
    const speaker = { ...values };
    eventRegister(speaker).then((res) => {
      if (res && res.success) {
        setSpeakerAdded(true);
        reset("eventRegisterForm");
      }
    });
  };
  const description =
    "We at Datacode.in aims in creating a platform for beginners' communities where they will get the best online technical content like the latest technical blogs, tutorials and courses, practice questions, quizzes, training and certification, and lot more on a single platform.";
  const title = "Getting Stated with Web-Development";
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta
          data-react-helmet='true'
          name='description'
          content={description}
        ></meta>
        <meta
          data-react-helmet='true'
          http-equiv='cleartype'
          content='on'
        ></meta>
        <meta
          data-react-helmet='true'
          name='apple-mobile-web-app-capable'
          content='yes'
        ></meta>
        <meta
          data-react-helmet='true'
          name='robots'
          content='max-snippet:-1, max-image-preview:large, max-video-preview:-1'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:locale'
          content='en_US'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:type'
          content='website'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:title'
          content={title}
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:description'
          content={description}
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:url'
          content='https://www.datacode.in/'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:site_name'
          content='Datacode.in'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:image'
          content='https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:image:secure_url'
          content='https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:image:width'
          content='400'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:image:height'
          content='400'
        ></meta>
        <meta
          data-react-helmet='true'
          name='keywords'
          content='programming,coding,online, code online, online judge, practice, run code, test, expected outcome, programming competition, programming contest, online programming, online computer programming'
        ></meta>
        <meta
          data-react-helmet='true'
          name='twitter:card'
          content='summary_large_image'
        ></meta>
        <meta
          data-react-helmet='true'
          name='twitter:description'
          content={description}
        ></meta>
        <meta
          data-react-helmet='true'
          name='twitter:title'
          content={title}
        ></meta>
        <meta
          data-react-helmet='true'
          name='twitter:site'
          content='@datacode_in'
        ></meta>
        <meta
          data-react-helmet='true'
          name='twitter:creator'
          content='@datacode_in'
        ></meta>
        <meta
          data-react-helmet='true'
          name='twitter:image'
          content='https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png'
        ></meta>
        <link
          data-react-helmet='true'
          rel='shortlink'
          href='https://www.datacodoe.in/'
        ></link>
        <link
          data-react-helmet='true'
          rel='dns-prefetch'
          href='//www.datacode.in'
        ></link>
        <link
          data-react-helmet='true'
          rel='dns-prefetch'
          href='//s.w.org'
        ></link>
        <link
          data-react-helmet='true'
          rel='alternate'
          type='application/rss+xml'
          title='Datacode'
          href='https://www.datacode.in/'
        ></link>
        <link
          data-react-helmet='true'
          rel='canonical'
          href='https://www.datacode.in/'
        ></link>
      </Head>
      <div className='row m-0 event-page-header'>
        <div className='col-12 px-md-5 px-3'>
          <h1 className='mt-5 event-heading'>
            Getting started with Web-Development
          </h1>
          <h3 className='mt-4 event-sub-heading'>
            A complete roadmap to your web development journey
          </h3>
          <div className='mt-5'>
            <h5 className='event-date-time'>
              <i className='far fa-calendar-alt mr-3' />
              May 09 2021
            </h5>
            <h5 className='event-date-time'>
              <i className='far fa-clock mr-3' />
              5PM IST - 7PM IST
            </h5>
          </div>
          <LinkScroll to='form' spy={true} smooth={true}>
            <button className='btn mb-5 join-us-button text-center card-schadow'>
              Read Event Story!
            </button>
          </LinkScroll>
        </div>
      </div>
      <div className='row m-0 login m-0 mt-4 mt-md-1 main'>
        <div className='col-md-8 px-0 px-md-5 left mt-md-0 mt-5' id='form'>
          <h6 className='px-3 px-md-0 mt-3'>About Speaker</h6>
          <div className='row m-0 mt-3'>
            <div className='col-md-2 col-4'>
              <img
                height='100px'
                width='100px'
                src={
                  "http://res.cloudinary.com/datacode/image/upload/v1731155903/ymbvp1xylcg8jieteglp.svg" ||
                  "/icon192.png"
                }
                alt='avatar'
                loading='lazy'
                className='rounded-circle text-right'
              />
            </div>
            <div className='col-md-10 col-8'>
              <h6>Dhanushka Chandana</h6>
              <p>
                Associate Technical Lead at Cambio Software Engineering | Core
                Team member at FOSS Sri Lanka
              </p>
            </div>
          </div>
          <h2 className='px-3 px-md-0 mt-5'>Our Event Story</h2>
          <div className='px-3 px-md-0'>
            <p>
              The web development industry has a long history of successful
              developers with varying backgrounds, so people tend to care more
              about what you’ve actually built than how you got there with this
              same Motto we created a session “Getting started with Web
              Development” which include a complete road map about how to start
              with from Zero to be professional in the field of Web technology.
            </p>
            <h6>Pre Learning Activities : </h6>
            <p>
              Without any preparation we can’t lead to any successful event!
              That’s why we Datacode team prepared whole agenda for the event
              which included various ways such as :- We started our agenda with
              the poster preparation. Then we proceeded with our promotion for a
              session on different social media platforms so that everyone will
              be aware about this. We had also prepared a “Kahoot Quiz “ so that
              people can engage in the session and boost their interest during
              the session. Regarding proper coordination between everyone we
              catched up with the speaker for better understanding of the event.
            </p>
            <h6>Gist of The Meeting :</h6>
            <div className='text-center'>
              <img
                height='auto'
                width='auto'
                src={
                  "http://res.cloudinary.com/datacode/image/upload/v1731155642/grejmx16d06pb2f9rsjq.jpg" ||
                  "/icon192.png"
                }
                alt='avatar'
                loading='lazy'
                className='img-fluid mx-auto text-center p-3'
              />
            </div>
            <p className='mt-2'>
              We tried to summarize the covered topic of the Session which
              started from the basics of any path that is first learn the basics
              then switch to advance which lead to master in any field.
            </p>
            <p className='mt-2'>
              He emphasizes the languages which are mandatory to become a web
              developer which includes Front end and back end technologies with
              their respective frameworks and libraries .
            </p>
            <div className='text-center'>
              <img
                height='auto'
                width='auto'
                src={
                  "http://res.cloudinary.com/datacode/image/upload/v1731155716/gs58vyijuxrsbz2ahlky.jpg" ||
                  "/icon192.png"
                }
                alt='avatar'
                loading='lazy'
                className='img-fluid mx-auto text-center p-3'
              />
            </div>
            <p>
              He suggested top resources to grab the knowledge, mainly the
              emphasis on freecodecamp(FCC) which is quite understandable for
              the new beginners.
              <br />
              He helped participants with the slight knowledge of git and github
              for their development perspective.
            </p>
            <div className='text-center'>
              <img
                height='auto'
                width='auto'
                src={
                  "http://res.cloudinary.com/datacode/image/upload/v1731155751/u40vbkdjvwq6kbgh2h5q.jpg" ||
                  "icon192.png"
                }
                alt='avatar'
                loading='lazy'
                className='img-fluid mx-auto text-center p-3'
              />
            </div>
            <p>
              He briefed about the local storage , API handling and other
              browsing DEv TOOL .
            </p>
            <p>
              He suggested to learn Javascript before switching to typescript He
              concluded himself through sharing his teaching experience .At last
              he shared his social handles for better connection.
            </p>
            <h6>Post activities :</h6>
            <ul>
              <li>
                The session was very great and happened in a cheerful manner.
                All the participants were satisfied with the event.
              </li>
              <li>
                In the end we had a Kahoot session ,which was successful because
                of enthusiastic 45+ participants' involvement.
              </li>
              <li>
                In the kahoot quiz we had some interesting questions related to
                web development to boost their knowledge.
              </li>
            </ul>
            <div className='text-center'>
              <img
                height='auto'
                width='auto'
                src={
                  "http://res.cloudinary.com/datacode/image/upload/v1731155779/sottbfxdenniuqwesjxx.jpg" ||
                  "/icon192.png"
                }
                alt='avatar'
                loading='lazy'
                className='img-fluid mx-auto text-center p-3'
              />
            </div>
            <h6>Ending note / Our Event Team :</h6>
            <p>
              Participants satisfaction matters the most, and we luckily reached
              this point where all the members praised the learning session with
              such a great personality as a speaker.
            </p>
            <p>
              Without a team no one can achieve a goal,so here’s the heartly
              thanks to all the organising members who made this event
              successful which includes the technical manager team, host of the
              event, activities host and most importantly patienceful speaker
              and audience we thank them from the bottom of our heart!
            </p>
            <h6 className='text-center'>
              A big Thanks to indirectly, directly people who made this event
              Great!
            </h6>
          </div>
        </div>
        <div className='col-12 col-md-4 text-center bg-white right'>
          <div className='pt-md-3 px-md-5 px-0 login-card'>
            <h1 className='text-center register-text mb-5 mb-md-3'>
              Feedback For Event
            </h1>
            {eventRegistationLoading && eventRegistationLoading ? (
              <Loader
                type='ThreeDots'
                color='#30006d'
                height={100}
                width={100}
                timeout={19000} //8 secs
              />
            ) : (
              <>
                {speakerAdded ? (
                  <>
                    <h6>
                      Thanks for valuable feedback, Stay tune with us, We will
                      organize such events very soon
                    </h6>
                    <Link to='/signup'>
                      <button className='btn join-us-button text-center'>
                        Join Community
                      </button>
                    </Link>
                  </>
                ) : (
                  <form className='' onSubmit={handleSubmit(onSubmit)}>
                    <div>
                      <Field
                        type='text'
                        name='name'
                        label='Enter Name'
                        placeholder=''
                        component={RenderInputField}
                        validate={[required]}
                      />
                      <Field
                        type='email'
                        name='email'
                        label='Enter Email'
                        placeholder='xyz@yui.com'
                        component={RenderInputField}
                        validate={[required, email]}
                      />
                      <Field
                        type='text'
                        name='phone'
                        label='How satisfied were you with this webinar, on a scale from 1-10, where 1 is very unsatisfied and 10 is very satisfied?'
                        placeholder=''
                        component={RenderInputField}
                        validate={[required]}
                      />
                      <Field
                        type='text'
                        name='university'
                        label='Where can we improve? What did you like least?'
                        //placeholder="xyz@yui.com"
                        component={RenderInputField}
                        validate={[required]}
                      />
                      <Field
                        type='textarea'
                        name='bio'
                        label='What did you like the most about this Webinar?'
                        //placeholder="xyz@yui.com"
                        component={RenderInputField}
                        // validate={[required]}
                      />
                    </div>
                    <div className='row mt-4'>
                      <div className='col-12 text-center'>
                        <button
                          type='submit'
                          className='btn btn-primary login-button'
                          disabled={submitting}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default reduxForm({
  form: "eventRegisterForm",
})(GettingStartedWebDev);
