import React from 'react'

const EventSponsors = () => {
  return (
    <>
      <h1>Sponsors Details</h1>
    </>
  )
}

export default EventSponsors