import React from 'react'
const LoginRequireAction = () => {
  return (
    <>
      <h1>LoginRequireAction</h1>
      <p>Please login to add feedback</p>
    </>
  )
}

export default LoginRequireAction