import React, { useEffect } from "react";
import AOS from "aos";

const projectData = [
  {
    img: require("../../../../../assets/images/internship/datacode.png"),
    head: "DataCode",
    link: "https://www.datacode.in/",
    description:
      "DataCode is an learning platform where you can learn new skills and become expert in it.",
  },
  {
    img: require("../../../../../assets/images/internship/p2.png"),
    head: "Taxrules",
    link: "https://taxrules.vercel.app/",
    description:
      "Taxrules is an platform where you can fill and check your taxes effortlessly.",
  },
  {
    img: require("../../../../../assets/images/internship/p3.png"),
    head: "Physiospine",
    link: "https://dr-physio-next.vercel.app/",
    description:
      "Dr Physio is an platform where you can take the appointment of Dr Mallika sharma and also checkout all the details.",
  },
  {
    img: require("../../../../../assets/images/internship/p4.png"),
    head: "Surya Stone and Builders",
    link: "https://suryastoneandbuilders.com/",
    description:
      "Surya Stone and Builders from where you can purchase the variety of stones and makes things more beautiful.",
  },
  {
    img: require("../../../../../assets/images/internship/p5.png"),
    head: "Viral Haat",
    link: "https://viral-haat.vercel.app/",
    description:
      "Viral Haat is a new age of digital marketing platform which has many more services.",
  },
  {
    img: require("../../../../../assets/images/internship/p6.png"),
    head: "React Indore",
    link: "https://www.reactindore.com/",
    description:
      "React Indore is platform of connecting with the people of react community.",
  },
];

const Projects = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="row mx-0 justify-content-center projects my-5">
      <div className="col-10 px-0">
        <h1 className="text-center">
          This type of Websites you can develop with DataCode
        </h1>
        <p className="para py-4 text-center">
          You can develop a wide range of fully customisable website with
          DataCode. Here are some of the most popular websites.
        </p>
        <div className="row mx-0 mt-5">
          {projectData.map((items) => (
            <div
              className="col-12 col-sm-12 col-md-6 col-lg-6 py-3"
              data-aos="fade-up"
            >
              <div className="row container">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 box1 px-0">
                  <div className="px-5 pt-5">
                    <img src={items.img} className="img-fluid projectimg" />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 box2 px-0">
                  <div className="p-3">
                    <h4 className="text-light">{items.head}</h4>
                    <p className="py-2 text-light">{items.description}</p>
                    <a href={items.link} className="learn-more px-4 py-2">
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Projects;
