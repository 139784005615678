import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import { checkProfileProgress } from "../../../../../utils";

const MyProfile = ({
  getUserProfile,
  match: { params },
  match: {
    params: { userName },
  },
  userProfile,
  userProfile: { followers },
  currentUser,
  followUser,
  unfollowUser,
}) => {
  const [isFollowed, setFollowed] = useState(false);
  const [followersCount, setFollowersCount] = useState(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setFollowed(
      followers &&
        followers.find(
          (follower) => follower === currentUser && currentUser.userName,
        ),
    );

    if (userName && currentUser) {
      getUserProfile(userName).then((res) => {
        if (res && res.data) {
          setFollowed(
            res.data.followers.find(
              (follower) => follower === currentUser && currentUser.userName,
            ),
          );
          setFollowersCount(res.data && res.data.followers.length);
        }
      });
    }
  }, [userName]);

  const handleFollowUser = (userEmail, currentUserName) => {
    followUser({ userEmail, currentUserName }).then((res) => {
      if (res && res.data) {
        setFollowed(
          res.data &&
            res.data.followers.find(
              (follower) => follower === currentUser.userName,
            ),
        );
        setFollowersCount(res.data && res.data.followers.length);
      }
    });
  };

  const handleUnfollowUser = (userEmail, currentUserName) => {
    unfollowUser({ userEmail, currentUserName }).then((res) => {
      if (res && res.data) {
        setFollowed(
          res.data &&
            res.data.followers.find(
              (follower) => follower === currentUser.userName,
            ),
        );
        setFollowersCount(res.data && res.data.followers.length);
      }
    });
  };
  const generateLinkedin = (linkedin) => {
    return linkedin.includes("https://") ? linkedin : `https://${linkedin}`;
  };

  const {
    firstName,
    branch,
    university,
    imgUrl,
    gender,
    bio,
    graduationYear,
    linkedin,
    github,
    twitter,
    email,
    myTutorialsLibrary,
    createdAt,
  } = userProfile ? userProfile : {};

  return (
    <>
      <div className='row m-0 my-profile p-md-5 p-2'>
        <div className='col-md-4 col-12 text-center'>
          <div className='row m-0'>
            <div className='col-12 left-profile-section px-0'>
              <div className='mt-4'>
                <img
                  className='rounded-circle header-profile-img'
                  height='140'
                  width='140'
                  loading='lazy'
                  src={
                    imgUrl
                      ? imgUrl
                      : require(`../../../../../assets/images/svg/monolog.svg`)
                  }
                  alt='avatar'
                />
              </div>
              <h6 className='mt-3 mb-0'>{firstName}</h6>
              <span className='bio'>{bio}</span>
              <div className='mt-3'>
                {linkedin && linkedin.length > 10 && (
                  <a
                    href={generateLinkedin(linkedin)}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <i className='fab fa-linkedin mr-3' />
                  </a>
                )}
                {twitter && twitter.length > 10 && (
                  <a href={twitter} target='_blank' rel='noopener noreferrer'>
                    <i className='fab fa-twitter-square mr-3' />
                  </a>
                )}
                {github && github.length > 10 && (
                  <a href={github} target='_blank' rel='noopener noreferrer'>
                    <i className='fab fa-github' />
                  </a>
                )}
              </div>
              {userName === (currentUser && currentUser.userName) ? (
                <Link to='/edit_profile'>
                  <div className='btn edit-profile-btn my-3'>
                    Edit Profile <i className='fa fa-user-edit ml-2' />
                  </div>
                </Link>
              ) : (
                <>
                  {isFollowed ? (
                    <div
                      className='btn edit-profile-btn my-3'
                      onClick={() =>
                        handleUnfollowUser(
                          userProfile && userProfile.email,
                          currentUser && currentUser.userName,
                        )
                      }
                    >
                      Unfollow
                      <i className='fad fa-user ml-2' />
                    </div>
                  ) : (
                    <div
                      className='btn edit-profile-btn my-3'
                      onClick={() =>
                        handleFollowUser(
                          userProfile && userProfile.email,
                          currentUser && currentUser.userName,
                        )
                      }
                    >
                      Follow
                      <i className='fad fa-user ml-2' />
                    </div>
                  )}
                </>
              )}
              <div className='profile-dashboard mt-0 mt-2 py-3'>
                <div className='row m-0'>
                  <div className='col-6 p-md-2 text-center'>
                    <i className='fa fa-grin-hearts' />
                    <span>{followersCount} followers</span>
                  </div>
                  <div className='col-6 p-md-2 text-center'>
                    <i className='fa fa-books' />
                    <Link to='/my_library'>
                      <span>
                        {myTutorialsLibrary && myTutorialsLibrary.length}{" "}
                        library items
                      </span>
                    </Link>
                  </div>
                </div>

                <div className='row m-0 px-md-4 px-2 my-3'>
                  <div className='col-12 text-left'>
                    {userName === (currentUser && currentUser.userName)
                      ? checkProfileProgress(currentUser)
                      : checkProfileProgress(userProfile)}
                  </div>
                </div>
                {/* <div className="row m-0  mt-0 mt-2">
                  <div className="col-6 p-md-2 text-left">
                    <i className="fal fa-user-friends" />
                    <span>5 following</span>
                  </div>
                  <div className="col-6 p-md-2 text-left">
                    <i className="fal fa-hand-heart" />
                    <span>6 contributions</span>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-8 col-12 '>
          <div className='row m-0 my-3 graduation-details'>
            <div className='col-12 p-3'>
              <h6>
                <i className='fa fa-id-card' />
                {userName}
              </h6>
              <h6>
                <i className='fa fa-mail-bulk' />
                {email}
              </h6>
              <h6>
                <i className='fa fa-user' />
                {gender}
              </h6>
              <h6>
                <i className='fa fa-lightbulb' />
                Joined {moment(createdAt).fromNow()}
              </h6>
            </div>
          </div>
          <div className='row m-0 graduation-details'>
            <div className='col-12 p-3'>
              <h6>
                <i className='fa fa-university' />
                {university}
              </h6>
              <h6>
                <i className='fa fa-code-branch' />
                {branch}
              </h6>
              <h6>
                <i className='fa fa-graduation-cap' />
                {graduationYear}
              </h6>
            </div>
          </div>
          {/* <div className="row m-0">
            <div className="col-md-6 col-12 mt-0 mt-5">
              <h6>Contributions</h6>
            </div>
            <div className="col-md-6 col-12 mt-0 mt-5">
              <h6>Activities</h6>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

MyProfile.defaultProps = {
  email: "",
  userProfile: {},
};

MyProfile.propTypes = {
  email: PropTypes.string,
  userProfile: PropTypes.object,
};

export default MyProfile;
