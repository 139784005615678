import {
  SHOW_FOOTER,
  HIDE_FOOTER,
  SHOW_ADD_TUTORIAL,
  HIDE_ADD_TUTORIAL,
  SHOW_ADD_TESTIMONIAL,
  SET_TESTIMONIALS,
  SET_EDIT_TESTIMONIAL,
  HIDE_COMPLETE_FOOTER,
  HIDE_COMPLETE_HEADER,
} from "../constants";

export const showFooter = (data) => ({
  type: SHOW_FOOTER,
});

export const hideFooter = (data) => ({
  type: HIDE_FOOTER,
});

export const hideCompleteFooter = (data) => ({
  type: HIDE_COMPLETE_FOOTER,
  payload: data,
});

export const hideCompleteHeader = (data) => ({
  type: HIDE_COMPLETE_HEADER,
  payload: data,
});

export const showAddTutorial = (data) => ({
  type: SHOW_ADD_TUTORIAL,
});

export const hideAddTutorial = (data) => ({
  type: HIDE_ADD_TUTORIAL,
});

export const showAddTestimonial = (data) => ({
  type: SHOW_ADD_TESTIMONIAL,
});

export const setTestimonials = (data) => ({
  type: SET_TESTIMONIALS,
  payload: data,
});

export const setEditTestimonial = (testimonial) => ({
  type: SET_EDIT_TESTIMONIAL,
  payload: testimonial,
});
