import moment from "moment";
import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { Link as LinkScroll } from "react-scroll";
import { reduxForm } from "redux-form";
import BannerImageUpload from "../../../../../../shared_components/BannerImageUpload";
import ColorPicker from "../../../../../../shared_components/ColorPicker";
import { timeConvert } from "../../../../../../utils";

const EventLayoutDetails = ({
  reset,
  handleSubmit,
  submitting,
  initialize,
  eventRegistationLoading,
  currentUser: { userName, email },
  eventDetails,
  editEvent,
  formStates,
  getEventDetails,
}) => {
  const [isEdit, setEdit] = useState(false);
  const [bgColor, getBgColor] = useState("#f5f5f5");
  const [textColor, getTextColor] = useState("#000");
  const [dateColor, getDateColor] = useState("#797676");
  const [uplodedImg, getUploadImgUrl] = useState(
    "http://res.cloudinary.com/datacode/image/upload/v1625513805/u6vcbtd1dafipu1mxhik.png",
  );
  const [tags, setTags] = useState([]);

  const [htmlText, getHtmlText] = useState("<p> </p>");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (eventDetails) {
      initialize(eventDetails);
      setEdit(true);
      getBgColor(eventDetails.bg_color);
      getHtmlText(eventDetails.description);
      getUploadImgUrl(eventDetails.banner_img);
      setTags(eventDetails.tags);
    }
  }, [eventDetails]);

  // const history = useHistory();

  const onSubmit = (values) => {
    const event = { ...values };
    event["userName"] = userName && userName;
    event["type"] = event.type && event.type.value;
    event["bg_color"] = bgColor;
    event["text_color"] = textColor;
    event["date_color"] = dateColor;
    event["banner_img"] = uplodedImg;
    event["description"] = htmlText;
    event["tags"] = tags;
    console.log(
      "dd",
      moment(event.start_date + " " + event.start_time).format(),
    );
    event["start_date_time"] = moment(
      event.start_date + " " + event.start_time,
    ).format();
    event["end_date_time"] = moment(
      event.end_date + " " + event.end_time,
    ).format();

    if (isEdit) {
      editEvent(event).then((res) => {
        if (res && res.success) {
          getEventDetails({ id: event.id, email });
        }
      });
    }
  };

  const handleBgColorPicker = (color) => {
    getBgColor(color.hex);
  };
  const handleTextColorPicker = (color) => {
    getTextColor(color.hex);
  };
  const handleDateColorPicker = (color) => {
    getDateColor(color.hex);
  };

  // const handleTextEditor = (text) => {
  //   getHtmlText(text);
  // };

  // const addTags = () => {
  //   setTags([...tags, formStates.tag]);
  // };
  // const handleTagDelete = (e) => {
  //   const data = tags.filter((item) => item !== e);
  //   setTags(data);
  // };

  return (
    <>
      <div className='row m-0 mt-5 p-md-5 p-3 border rounded bg-white event-reg'>
        <div className='col-12 px-md-2 px-0 py-md-0 py-2 col-md-5'>
          <h6>Layout Container</h6>
          <p>
            This information will be displayed publicly so be careful what you
            share.{" "}
          </p>
          <div className='p-md-5 p-2'>
            <div
              className='row m-0 px-2 event-page-header '
              style={{
                backgroundColor: bgColor ? bgColor : "#f5f5f5",
                color: "#04d819",
              }}
            >
              <div className='col-12 px-3 rounded-4'>
                <h1 className='mt-5 event-heading' style={{ color: textColor }}>
                  {eventDetails && eventDetails.title}
                </h1>
                <tt>
                  <h3
                    className='mt-4 event-sub-heading'
                    style={{ color: textColor }}
                  >
                    {eventDetails && eventDetails.sub_title}
                  </h3>
                </tt>
                <div className='mt-5'>
                  <h5 className='event-date-time' style={{ color: dateColor }}>
                    <i className='far fa-calendar-alt mr-3' />
                    {moment(eventDetails && eventDetails.start_date).format(
                      "LL",
                    )}
                  </h5>
                  <h5 className='event-date-time' style={{ color: dateColor }}>
                    <i className='far fa-clock mr-3' />
                    {`${timeConvert(
                      eventDetails && eventDetails.start_time,
                    )} IST - 
                ${timeConvert(eventDetails && eventDetails.end_time)} IST `}
                  </h5>
                  <LinkScroll to='form' spy={true} smooth={true}>
                    <button className='btn mb-5 join-us-button text-center card-schadow'>
                      Join Virtual Session!
                    </button>
                  </LinkScroll>
                </div>
              </div>
              <div className='col-12 text-center align-items-center'>
                <img
                  height='300px'
                  width='300px'
                  src={uplodedImg}
                  alt='avatar'
                  loading='lazy'
                  className='my-3 mx-auto my-auto img-fluid'
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className='col-12 col-md-7 px-0 text-center border form-shadow'
          id='form'
        >
          <div className='pt-md-3 px-md-4 px-3 login-card '>
            {eventRegistationLoading && eventRegistationLoading ? (
              <Loader
                type='ThreeDots'
                color='#30006d'
                height={100}
                width={100}
                timeout={19000} //8 secs
              />
            ) : (
              <form className='' onSubmit={handleSubmit(onSubmit)}>
                <div className='row my-3'>
                  <div className='col-6 text-left'>
                    <label className='form-label'>Pick Background Color</label>
                    <ColorPicker
                      color={bgColor}
                      handleColorPicker={handleBgColorPicker}
                    />
                  </div>
                  <div className='col-6 text-left'>
                    <label className='form-label'>Pick Text Color</label>
                    <ColorPicker
                      color={textColor}
                      handleColorPicker={handleTextColorPicker}
                    />
                  </div>
                  <div className='col-6 text-left'>
                    <label className='form-label'>Pick Date Color</label>
                    <ColorPicker
                      color={dateColor}
                      handleColorPicker={handleDateColorPicker}
                    />
                  </div>
                </div>
                <div>
                  <label className='form-label'>Upload Banner Image</label>
                  <BannerImageUpload getUploadImgUrl={getUploadImgUrl} />
                </div>
                <div className='row my-4'>
                  <div className='col-12 text-right'>
                    <button
                      type='submit'
                      className='btn custom-button'
                      disabled={submitting}
                    >
                      <span>Save Event</span>
                    </button>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default reduxForm({
  form: "create-event",
  fields: ["tag"],
})(EventLayoutDetails);
