import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Combobox from "react-widgets/Combobox";
import { Field, reduxForm } from "redux-form";

import CustomLoader from "../../../../../../../shared_components/CustomLoader";
import {
  RenderInputField,
  renderSwitchField,
} from "../../../../../../../shared_components/ReduxFormFields";
import { techCompanies } from "../../../../../../../utils";
import { required } from "../../../../../../../utils/validators";
import TextEditor from "../../../../../aticles/BlogsEditor/TextEditor";
import { DSAContent } from "../../../DaysCodeDashboard/DaysCodeTimeLine/helper";
import { getTopicOptions } from "../../../utils";
import LayoutContainer from "../../LayoutContainer";
import AddSolution from "./AddSolution";
import AddTestCases from "./AddTestCases";
import { getProblemTypeOptions, problemTypeOptions } from "./helper";

const AddProblem = ({
  match: {
    params: { id },
  },
  reset,
  handleSubmit,
  submitting,
  initialize,
  addProblem,
  getProblemDetails,
  problemDetails,
  problemDetailsLoading,
  editProblem,
  addAdminSolution,
  deleteAdminSolution,
  deleteProblemTestCase,
  addProblemTestCase,
  editProblemTestCase,
}) => {
  const history = useHistory();

  const [isEdit, setEdit] = useState(false);
  const [descriptionText, getDescriptionText] = useState("<p> </p>");
  const [inputText, getInputText] = useState("<p> </p>");
  const [explanationText, getExplanationText] = useState("<p> </p>");
  const [outputText, getOutputText] = useState("<p> </p>");
  const [constrainsText, getConstrainsText] = useState("<p> </p>");
  const [solutionNav, setSolutionNav] = useState("problem");
  const [companies, setCompanies] = useState([]);
  const [tempCompany, setTempCompany] = useState();
  const [problemType, setProblemType] = useState();
  const [topic, setTopic] = useState();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (id !== "new") {
      getProblemDetails(id).then((res) => {
        if (res && res.success && res.data) {
          const data = res.data;
          data["status"] =
            res.data && res.data.status === "public" ? true : false;
          initialize(data);
          setCompanies(res.data && res.data.companies);
          setProblemType(res.data.problem_type);
          getDescriptionText(res.data.description);
          getInputText(res.data.input);
          getExplanationText(res.data.explanation);
          getOutputText(res.data.output);
          getConstrainsText(res.data.constrains);
          setEdit(true);
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  const onSubmit = (values) => {
    const problem = { ...values };
    problem["description"] = descriptionText;
    problem["input"] = inputText;
    problem["output"] = outputText;
    problem["explanation"] = explanationText;
    problem["constrains"] = constrainsText;
    problem["status"] = problem.status ? "public" : "draft";
    problem["companies"] = companies;
    problem["problem_type"] = problemType.value;
    problem["topic"] = topic.name;

    if (isEdit) {
      editProblem(problem);
    } else {
      problem["id"] = Math.random().toString(36).substr(4, 9);
      addProblem(problem);
    }
    reset("add-problem");
  };

  const handleDescriptionText = (text) => {
    getDescriptionText(text);
  };

  const handleInputText = (text) => {
    getInputText(text);
  };

  const handleOutputText = (text) => {
    getOutputText(text);
  };

  const handleExplanationText = (text) => {
    getExplanationText(text);
  };

  const handleConstrainsText = (text) => {
    getConstrainsText(text);
  };

  const addCompanies = () => {
    setCompanies([...companies, tempCompany]);
  };

  const onDelete = (e) => {
    const data = companies.filter((item) => item !== e);
    setCompanies(data);
  };

  const getCompaniesOptions = (companies) => {
    const options = [];
    companies.map((item, i) => {
      options.push({ company: item.name, value: i });
    });
    return options;
  };

  return (
    <>
      <LayoutContainer>
        {problemDetailsLoading ? (
          <CustomLoader />
        ) : (
          <>
            <div className='row mx-0'>
              <div className='col-md-8 col-6 mx-0 d-flex align-items-center'>
                {isEdit ? (
                  <>
                    <h4 className='mb-0 py-md-3 py-2 mr-3'>
                      <i className='fal fa-edit mr-2' />
                      {"Edit Problem |"}
                    </h4>
                    <h4 className='m-0'>{problemDetails.day}</h4>
                  </>
                ) : (
                  <h4 className='mb-0 py-md-3 py-2'>{"Add Problem"}</h4>
                )}
              </div>
              <div className='col-md-4 col-6 d-flex justify-content-end align-items-center'>
                <button
                  onClick={() => history.push("/admin/dayscode/problems")}
                  type='button'
                  className='btn enroll-small-btn'
                >
                  <small>
                    <i className='fal fa-file-alt mr-2' />
                    All Problems List
                  </small>
                </button>
              </div>
            </div>
            <div className='row m-0 solution-nav'>
              <div
                className={`col-4 p-2 m-0 border-right solutiob-col ${
                  solutionNav === "problem" && "active font-weight"
                } text-center `}
                onClick={() => setSolutionNav("problem")}
              >
                <span>Problem</span>
              </div>
              <div
                className={`col-4 p-2 m-0 border-right solutiob-col ${
                  solutionNav === "problem" && "active font-weight"
                } text-center `}
                onClick={() => setSolutionNav("testcase")}
              >
                <span>Test Cases</span>
              </div>
              {id !== "new" && (
                <div
                  className={`col-4 p-2 m-0 solutiob-col ${
                    solutionNav === "solution" && "active font-weight"
                  } text-center `}
                  onClick={() => setSolutionNav("solution")}
                >
                  <span>
                    Solution <i className='fal fa-lock-alt' />
                  </span>
                </div>
              )}
            </div>
            {solutionNav === "problem" && (
              <form
                className='py-3 px-md-5 px-1'
                onSubmit={handleSubmit(onSubmit)}
              >
                <small>
                  Lorazepam, sold under the brand name Ativan among others, is a
                  benzodiazepine medication. It is used to treat anxiety
                  disorders, trouble sleeping, severe agitation, active seizures
                  including status epilepticus, alcohol withdrawal, and
                  chemotherapy-induced nausea and vomiting
                </small>
                <Field
                  type='text'
                  name='title'
                  label='Problem Statement'
                  placeholder=''
                  component={RenderInputField}
                  validate={[required]}
                />
                <div className='row m-0 border rounded-lg my-2 p-md-3 p-0'>
                  <div className='col-md-6 col-12'>
                    <div className=''>
                      <label className='mr-4 form-label'>Status: Public</label>
                      <label className='switch mt-4'>
                        <Field
                          type='checkbox'
                          name='status'
                          component={renderSwitchField}
                        />
                      </label>
                    </div>
                    <div className='row mx-0'>
                      <div className='col-md-6 col-12 pl-md-0 pr-md-2 '>
                        <Field
                          type='text'
                          name='level'
                          label='level'
                          placeholder=''
                          component={RenderInputField}
                          validate={[required]}
                        />
                      </div>
                      <div className='col-md-6 col-12 pl-md-2 pr-md-0'>
                        <Field
                          type='number'
                          name='day'
                          label='Problem Day'
                          placeholder=''
                          component={RenderInputField}
                          validate={[required]}
                        />
                      </div>
                    </div>
                    {problemType?.value === "practice" && (
                      <Field
                        type='text'
                        name='link'
                        label='Link'
                        placeholder=''
                        component={RenderInputField}
                        validate={[required]}
                      />
                    )}
                  </div>
                  <div className='col-md-6 col-12 mt-3'>
                    <div className='row mx-0'>
                      <div className='col-md-6 col-12 pl-0'>
                        <label className='mr-4 form-label'>Problem Type:</label>
                        <div className='mb-2'>
                          <Combobox
                            data={getProblemTypeOptions(problemTypeOptions)}
                            dataKey={"value"}
                            textField='problem_type'
                            placeholder={"Select Problem Type"}
                            value={problemType}
                            onChange={(value) => setProblemType(value)}
                          />
                        </div>
                      </div>
                      <div className='col-md-6 col-12 pr-0'>
                        <label className='mr-4 form-label'>
                          Problem Topic:
                        </label>
                        <div className='mb-2'>
                          <Combobox
                            data={getTopicOptions(DSAContent)}
                            dataKey={"value"}
                            textField='name'
                            placeholder={"Select Topic Name"}
                            value={topic}
                            onChange={(value) => setTopic(value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className=''>
                      <label className='mr-4 form-label'>Companies:</label>
                      <div className='border rounded-lg card-schadow p-3'>
                        {companies.map((item, i) => (
                          <div className='p-2 d-flex align-items-center justify-content-between'>
                            <h6 className='mb-0'>{item.company}</h6>
                            <i
                              className='fal fa-times'
                              onClick={() => onDelete(item)}
                            />
                          </div>
                        ))}
                        <div className='mt-4'>
                          <Combobox
                            data={getCompaniesOptions(techCompanies)}
                            dataKey={"value"}
                            textField='company'
                            placeholder={"Select Problem Name"}
                            // value={tempCompany}
                            onChange={(value) => setTempCompany(value)}
                          />
                          <div className='d-flex mt-3 justify-content-end'>
                            <button
                              className='btn btn-sm btn-primary'
                              type='button'
                              onClick={() => addCompanies()}
                            >
                              Add
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='my-3'>
                  <label className='form-label text-left pt-5'>
                    Problem Description {`(${descriptionText.length - 8})`}
                  </label>
                  <div className='mb-3 border '>
                    {isEdit && problemDetails && problemDetails.description && (
                      <TextEditor
                        handleTextEditor={handleDescriptionText}
                        text={problemDetails.description ? descriptionText : ""}
                      />
                    )}
                    {!isEdit && (
                      <TextEditor
                        handleTextEditor={handleDescriptionText}
                        text={
                          problemDetails?.description ? descriptionText : ""
                        }
                      />
                    )}
                  </div>
                </div>
                <div className='my-3'>
                  <label className='form-label text-left pt-5'>
                    Input {`(${inputText.length - 8})`}
                  </label>
                  <div className='mb-3 border '>
                    {isEdit && problemDetails && problemDetails.input && (
                      <TextEditor
                        handleTextEditor={handleInputText}
                        text={problemDetails.input ? inputText : ""}
                      />
                    )}
                    {!isEdit && (
                      <TextEditor
                        handleTextEditor={handleInputText}
                        text={problemDetails?.input ? inputText : ""}
                      />
                    )}
                  </div>
                </div>
                <div className='my-3'>
                  <label className='form-label text-left pt-5'>
                    Output {`(${outputText.length - 8})`}
                  </label>
                  <div className='mb-3 border '>
                    {isEdit && problemDetails && problemDetails.output && (
                      <TextEditor
                        handleTextEditor={handleOutputText}
                        text={problemDetails?.output ? outputText : ""}
                      />
                    )}
                    {!isEdit && (
                      <TextEditor
                        handleTextEditor={handleOutputText}
                        text={problemDetails?.output ? outputText : ""}
                      />
                    )}
                  </div>
                </div>
                <div className='my-3'>
                  <label className='form-label text-left pt-5'>
                    Constrains {`(${constrainsText.length - 8})`}
                  </label>
                  <div className='mb-3 border '>
                    {isEdit && problemDetails && problemDetails.constrains && (
                      <TextEditor
                        handleTextEditor={handleConstrainsText}
                        text={problemDetails?.constrains ? constrainsText : ""}
                      />
                    )}
                    {!isEdit && (
                      <TextEditor
                        handleTextEditor={handleConstrainsText}
                        text={problemDetails?.constrains ? constrainsText : ""}
                      />
                    )}
                  </div>
                </div>
                <div className='my-3'>
                  <label className='form-label text-left pt-5'>
                    Explanation {`(${explanationText.length - 8})`}
                  </label>
                  <div className='mb-3 border '>
                    {isEdit && problemDetails && problemDetails.explanation && (
                      <TextEditor
                        handleTextEditor={handleExplanationText}
                        text={
                          problemDetails?.explanation ? explanationText : ""
                        }
                      />
                    )}
                    {!isEdit && (
                      <TextEditor
                        handleTextEditor={handleExplanationText}
                        text={
                          problemDetails?.explanation ? explanationText : ""
                        }
                      />
                    )}
                  </div>
                </div>
                <div className='row my-4'>
                  <div className='col-12 text-right'>
                    <button
                      type='submit'
                      className='btn custom-button'
                      disabled={submitting}
                    >
                      <span>{isEdit ? "Save" : "Add Problem"}</span>
                    </button>
                  </div>
                </div>
              </form>
            )}
            {solutionNav === "solution" && id !== "new" && (
              <AddSolution
                id={id}
                addAdminSolution={addAdminSolution}
                adminSolutions={problemDetails.admin_solution}
                deleteAdminSolution={deleteAdminSolution}
              />
            )}
            {solutionNav === "testcase" && id !== "new" && (
              <AddTestCases
                id={id}
                addProblemTestCase={addProblemTestCase}
                testCase={problemDetails.testCase}
                deleteProblemTestCase={deleteProblemTestCase}
                editProblemTestCase={editProblemTestCase}
              />
            )}
          </>
        )}
      </LayoutContainer>
    </>
  );
};

export default reduxForm({
  form: "add-problem",
})(AddProblem);
