export const problemTypeOptions = [
  { name: 'Assignment', value: 'assignment' },
  { name: 'Practice', value: 'practice' },
  { name: 'Example', value: 'example' },
  { name: 'Mentor', value: 'mentor' },
  { name: 'Contest', value: 'contest' }
]

export const getProblemTypeOptions = (types) => {
  const options = []
  types.map((item, i) => {
    options.push({ problem_type: item.name, value: item.value })
  })
  return options
}