import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Field, reduxForm } from "redux-form";
import Loader from "react-loader-spinner";
import { RenderInputField } from "../../../../../../shared_components/ReduxFormFields";
import { required } from "../../../../../../utils/validators";
import ImageUpload from "../../../../../../shared_components/ImageUpload";
import CustomLoader from "../../../../../../shared_components/CustomLoader";

const EventSpeakers = ({
  speakersLoading,
  reset,
  handleSubmit,
  submitting,
  initialize,
  addEventSpeaker,
  imageUploading,
  editEventSpeaker,
  getEventDetails,
  getSpeakers,
}) => {
  const { eventDetails, eventDetailsLoading } =
    useSelector((state) => state.event) || {};

  const [isEdit, setEdit] = useState(false);
  const [imgUrl, setImgUrl] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // if (eventDetails?.speakers && eventDetails.speakers.length) {
    //   initialize(eventDetails)
    //   setEdit(true)
    // }
    getSpeakers();
    console.log("Speaker Event", eventDetails.speakers);
  }, [eventDetails]);

  const getUploadImgUrl = (url) => {
    if (url) {
      setImgUrl(url);
    }
  };

  const onSubmit = (values) => {
    const speaker = { ...values };
    console.log("speaker", speaker);
    speaker["img"] = imgUrl;
    speaker["id"] = eventDetails && eventDetails.id;
    if (isEdit) {
      editEventSpeaker(speaker).then((res) => {
        if (res && res.success) {
          getEventDetails({ id: speaker.id });
        }
      });
    } else {
      // speaker['speakerId'] = Math.random().toString(36).substr(4, 9)
      speaker["speakerId"] = "626fc6fa402b5f2f10278eae";
      addEventSpeaker(speaker).then((res) => {
        if (res && res.success) {
          reset("add-speaker");
        }
      });
    }
  };

  return (
    <>
      <h1>Speaker Details</h1>

      <div className='row mx-0 p-2'>
        <div className='col-12 col-md-6'>
          <form className='' onSubmit={handleSubmit(onSubmit)}>
            <div className='mt-4 text-center'>
              {imageUploading ? (
                <Loader
                  type='Puff'
                  color='#30006d'
                  height={100}
                  width={100}
                  timeout={3000} //3 secs
                />
              ) : (
                <>
                  <img
                    className='rounded-circle header-profile-img'
                    height='140'
                    width='140'
                    loading='lazy'
                    src={
                      imgUrl
                        ? imgUrl
                        : require(`../../../../../../assets/images/svg/monolog.svg`)
                    }
                    alt='avatar'
                  />
                </>
              )}
            </div>
            <div className='text-center'>
              <ImageUpload getUploadImgUrl={getUploadImgUrl} />
              <label
                className='btn edit-profile-btn my-3'
                for='edit-profile-btn'
              >
                Add Profile Picture
                <i className='fad fa-user-edit ml-2' />
              </label>
            </div>
            <Field
              type='text'
              name='name'
              label='Speaker Name'
              placeholder=''
              component={RenderInputField}
              validate={[required]}
            />
            <Field
              type='text'
              name='bio'
              label='About Speaker'
              placeholder=''
              component={RenderInputField}
              validate={[required]}
            />
            <div className='row mt-4'>
              <div className='col-12 text-center'>
                <button
                  type='submit'
                  className='btn custom-button'
                  disabled={submitting}
                >
                  <span>Add Speaker</span>
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className='col-12 col-md-6'>
          {eventDetailsLoading ? (
            <CustomLoader />
          ) : (
            <>
              <div className='row mx-0'>
                {eventDetails &&
                  eventDetails.speakers &&
                  eventDetails.speakers.map((speaker, i) => (
                    <div className='col-12 col-md-3' key={i}>
                      <div className='p-3 border rounded-lg text-center'>
                        <img
                          className='rounded-circle header-profile-img mb-2'
                          height='80'
                          width='80'
                          loading='lazy'
                          src={
                            speaker.img
                              ? speaker.img
                              : require(`../../../../../../assets/images/svg/monolog.svg`)
                          }
                          alt='avatar'
                        />
                        <h5 className=''>{speaker.name}</h5>
                        <p className='mb-0'>{speaker.bio}</p>
                      </div>
                    </div>
                  ))}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default reduxForm({
  form: "add-speaker",
})(EventSpeakers);
