import React from "react";

const RoadmapListCard = ({ roadmapList, handelroadmapdelete }) => {
  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th>Day</th>
            <th>Title</th>
            <th>Topic</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {roadmapList
            .sort((a, b) => a.day - b.day)
            .map((roadmap) => {
              return (
                <tr>
                  <td>{roadmap.day}</td>
                  <td>{roadmap.title}</td>
                  <td>{roadmap.topic}</td>
                  <td>
                    <i
                      className="fas fa-trash"
                      onClick={() => handelroadmapdelete(roadmap._id)}
                    />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </>
  );
};

export default RoadmapListCard;
