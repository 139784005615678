import React, { useState, useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import { useHistory } from "react-router-dom";
import Combobox from "react-widgets/Combobox";

import LayoutContainer from "../../LayoutContainer";
import {
  RenderInputField,
  renderSwitchField,
  renderRadioField,
} from "../../../../../../../shared_components/ReduxFormFields";
import { required } from "../../../../../../../utils/validators";
import CustomLoader from "../../../../../../../shared_components/CustomLoader";
import TextEditor from "../../../../../aticles/BlogsEditor/TextEditor";
import { DSAContent } from "../../../DaysCodeDashboard/DaysCodeTimeLine/helper";
import { getTopicOptions } from "../../../utils";

const AddQuizChallenge = ({
  match: {
    params: { id },
  },
  reset,
  handleSubmit,
  submitting,
  initialize,
  createQuizChallenge,
  editQuizChallenge,
  getQuizChallengeDetails,
  problemDetailsLoading,
  quizChallengeDetails,
  formStates,
}) => {
  const history = useHistory();
  const [codeText, getCodeText] = useState("<p> </p>");
  const [isEdit, setEdit] = useState(false);
  const [topic, setTopic] = useState();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (id !== "new") {
      getQuizChallengeDetails(id).then((res) => {
        if (res && res.success && res.data) {
          const data = res.data;
          data["status"] =
            res.data && res.data.status === "public" ? true : false;
          getCodeText(res.data && res.data.code);
          initialize(data);
          setTopic(data.topic);
          setEdit(true);
        }
      });
    }
  }, []);

  const handleCodeText = (text) => {
    getCodeText(text);
  };

  const onSubmit = (values) => {
    const quizChallenge = { ...values };
    quizChallenge["status"] = quizChallenge.status ? "public" : "draft";
    quizChallenge["code"] = codeText;
    quizChallenge["topic"] = topic.name;

    if (isEdit) {
      editQuizChallenge(quizChallenge);
    } else {
      quizChallenge["id"] = Math.random().toString(36).substr(4, 9);
      createQuizChallenge(quizChallenge);
    }
    reset("add-quiz-challenge");
  };

  const optionsRow = () => {
    var row = [];
    for (let i = 1; i <= 4; i++) {
      row.push(
        <div className='p-3 my-2 border' key={i}>
          <div className='row mx-0 quiz-challenge-option type-checkbox d-flex'>
            <div className='col-1 px-0 d-flex justify-content-center align-items-center'>
              <Field
                name='correct_answer'
                component={renderRadioField}
                value={`option${i}`}
                type='radio'
                label={i}
                validate={[required]}
              />
            </div>
            <div className='col-11 px-0 option-text d-flex align-items-center'>
              <Field
                type='text'
                name={`option${i}`}
                placeholder=''
                component={RenderInputField}
                validate={[required]}
              />
            </div>
          </div>
        </div>,
      );
    }
    return row;
  };

  return (
    <>
      <LayoutContainer>
        {problemDetailsLoading ? (
          <CustomLoader />
        ) : (
          <>
            <div className='row mx-0 solution-nav'>
              <div className='col-8 mx-0 d-flex align-items-center'>
                <h3 className='py-md-3 mb-0 py-2'>
                  {isEdit
                    ? `Edit Quiz Challenge Section`
                    : "Create New Quiz Challenge"}
                </h3>
              </div>
              <div className='col-4 d-flex justify-content-end align-items-center'>
                <button
                  onClick={() =>
                    history.push("/admin/dayscode/quiz_challenges")
                  }
                  type='button'
                  className='btn enroll-small-btn'
                >
                  <small>All Challenges List</small>
                </button>
              </div>
            </div>
            <div className='row m-0'>
              <div className='col-12'>
                <form
                  className='py-3 px-md-5 px-1'
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <small>
                    Lorazepam, sold under the brand name Ativan among others, is
                    a benzodiazepine medication. It is used to treat anxiety
                    disorders, trouble sleeping, severe agitation, active
                    seizures including status epilepticus, alcohol withdrawal,
                    and chemotherapy-induced nausea and vomiting
                  </small>
                  <div className='row m-0 border rounded-lg my-2 p-md-3 p-0'>
                    <div className='col-md-6 col-12'>
                      <div className=''>
                        <label className='mr-4 form-label'>
                          Status: Public
                        </label>
                        <label className='switch mt-4'>
                          <Field
                            type='checkbox'
                            name='status'
                            component={renderSwitchField}
                          />
                        </label>
                      </div>
                      <Field
                        type='text'
                        name='level'
                        label='level'
                        placeholder=''
                        component={RenderInputField}
                        validate={[required]}
                      />
                      <div>
                        <label className='form-label'>Select Topic</label>
                        <Combobox
                          data={getTopicOptions(DSAContent)}
                          dataKey={"value"}
                          textField='name'
                          placeholder={"Select Topic Name"}
                          value={topic}
                          onChange={(value) => setTopic(value)}
                        />
                      </div>
                      <div className='mt-4'>
                        <Field
                          type='number'
                          name='day'
                          label='Quiz Challenge Day'
                          placeholder=''
                          component={RenderInputField}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='my-3'>
                    <label className='form-label text-left pt-5'>
                      Question {`(${codeText && codeText.length - 8})`}
                    </label>
                    <div className='mb-3 border '>
                      {isEdit &&
                        quizChallengeDetails &&
                        quizChallengeDetails.code && (
                          <TextEditor
                            handleTextEditor={handleCodeText}
                            text={quizChallengeDetails.code ? codeText : ""}
                          />
                        )}
                      {!isEdit && (
                        <TextEditor
                          handleTextEditor={handleCodeText}
                          text={quizChallengeDetails?.code ? codeText : ""}
                        />
                      )}
                    </div>
                    <label className='form-label text-left pt-5'>Options</label>
                    {optionsRow()}
                  </div>
                  <div className='row my-4'>
                    <div className='col-12 text-right'>
                      <button
                        type='submit'
                        className='btn custom-button'
                        disabled={submitting}
                      >
                        <span>{isEdit ? "Edit Quiz" : "Create Quiz"}</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </>
        )}
      </LayoutContainer>
    </>
  );
};

export default reduxForm({
  form: "add-quiz-challenge",
  fields: ["day"],
})(AddQuizChallenge);
