export const GET_LOGIN_USER = "GET_LOGIN_USER";
export const GET_LOGIN_USER_ERROR = "GET_LOGIN_USER_ERROR";
export const GET_SIGNUP_USER = "GET_SIGNUP_USER";
export const GET_SIGNUP_USER_ERROR = "GET_SIGNUP_USER_ERROR";
export const GET_LOGOUT_USER = "GET_LOGOUT_USER";
export const SET_SIGNUP_USER_LOADING = "SET_SIGNUP_USER_LOADING";
export const SET_LOGIN_USER_LOADING = "SET_LOGIN_USER_LOADING";
export const SET_FORGOT_PASSWORD_ERROR = "SET_FORGOT_PASSWORD_ERROR";
export const SET_RESET_PASSWORD_ERROR = "SET_RESET_PASSWORD_ERROR"

export const SET_USER_PROFILE = "SET_USER_PROFILE";
export const SET_USER_PROFILE_LOADING = "SET_SIGNUP_USER_LOADING";
export const SET_IMAGE_UPLOADING = "SET_IMAGE_UPLOADING";

export const SET_LOCAL_USER = "SET_LOCAL_USER";

export const SET_ADD_SPEAKER_LOADING = "SET_ADD_SPEAKER_LOADING";
