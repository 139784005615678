import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const CommonModelPopup = ({
  model,
  toggle,
  message,
  submitButtonText,
  modeltitle,
}) => {
  return (
    <div>
      <Modal centered isOpen={model} toggle={toggle} className="text-center">
        <ModalHeader
          className="d-flex justify-content-between align-items-center"
          toggle={toggle}
        ></ModalHeader>
        <ModalBody>
          <h4 className="modal-title mb-5">{modeltitle}</h4>
          <p>{message}</p>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center align-items-center">
          <Button color="success" onClick={toggle}>
            {submitButtonText}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CommonModelPopup;
