import MyLibrary from "./MyLibrary";

import { connect } from "react-redux";
import { fetchTutorials } from "../../../../main_component/tutorials/actions";

const mapStateToProps = ({ app, user }) => ({
  userProfileLoading: user.userProfileLoading,
  currentUser: user.currentUser ? user.currentUser : {},
});

const mapDispatchToProps = {
  fetchTutorials,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyLibrary);
