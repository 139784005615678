import React from "react"

const HeroSection = () => {
  return (
    <>
      <div className="row mx-0 d-flex justify-content-center academy-hero">
        <div className="col-10 px-0">
          <div className="row mx-0">
            <div className="col-md-6 col-12 d-flex align-items-center">
              <div className="">
                <p className="hero-head-text">DataCode Academy</p>
                <h1 className="hero-heading">The all-in-one  Platform for Learners & Creators home heading</h1>
                <span>Agency provides a full service range including technical skills, design, business understanding.</span>
                <div className="">
                  <button className="btn btn-primary">Get Started</button>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12 p-5">
              <img
                className="img-fluid"
                loading="lazy"
                src={`http://res.cloudinary.com/datacode/image/upload/v1687962103/tommrnv5wkxeydixnlro.png`}
                alt="avatar"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HeroSection