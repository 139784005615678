import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

export const getColumns = () => [
  {
    dataField: "",
    text: "S.NO.",
    align: "center",
    headerAlign: "center",
    headerClasses: "table-header s-no",
    formatter: (cell, row, index) => <span>{++index}</span>,
  },
  {
    dataField: "email",
    text: "Email",
    sort: true,
    style: { color: "#757575" },
    headerClasses: "table-header status",
    formatter: (cell, row) => (
      <span>
        <Link to={`/profile/${row.userName}`}>{row.email}</Link>
      </span>
    ),
  },
  {
    dataField: "firstName",
    text: "Name",
    sort: true,
    headerClasses: "table-header name",
    style: { color: "#757575" },
  },

  {
    dataField: "phone",
    text: "Phone",
    sort: true,
    style: { color: "#757575" },
    align: "left",
    headerAlign: "left",
    headerClasses: "table-header group-name",
  },
  {
    dataField: "university",
    text: "University",
    sort: true,
    style: { color: "#757575" },
    align: "left",
    headerAlign: "left",
    headerClasses: "table-header group-name",
  },
  {
    dataField: "createdAt",
    text: "Registered Date",
    sort: true,
    style: { color: "#757575" },
    align: "left",
    headerAlign: "left",
    headerClasses: "table-header group-name",
    formatter: (cell, row) => (
      <span>
        {row.createdAt === null
          ? "Invalid date"
          : moment(row.createdAt).format("LLL")}
      </span>
    ),
  },
];

export const daysCodeList = [
  "chandanasamineni23@gmail.com",
  "anshuwadhwani887@gmail.com",
  "Yashashri",
  "Diksha",
  "priankatiwari66@gmail.com",
  "gassemeya@gmail.com",
  "gahanagpt462@gmail.com",
  "Snehita S Naik",
  "Army",
  "Sheetal",
  "Anutoshi333",
  "naaz2rahela@gmail.com",
  "meghali.likhar@gmail.com",
  "Zehra Sadik",
  "janicemonteiro",
  "eliiie",
  "DevansheeGupta",
  "twinkalkushwah01@gmail.com",
  "Shivangi11",
  "aarishalu16@gmail.com",
  "babita.majumdar.1@gmail.com",
  "Chandana",
  "sahithi2539@gmail.com",
  "tanwarsumit440@gmail.com",
  "rc30062001@gmail.com",
  "Madhav2204",
  "tnihore536@gmail.com",
  "Pranav_SR",
  "jmohammedthoufeeq@gmail.com",
  "tarunchoudhary1805@gmail.com",
  "mayankjaiswal20180@gmail.com",
  "sarsodiyah@gmail.com",
  "kshitij466e@gmail.com",
  "maheepjain25@gmail.com",
  "paliwaln10@gmail.com",
  "jay_vk18",
  "venkataravikumaralladi@gmail.com",
  "bernce",
  "Annan1035",
  "antim@123",
  "RJ_SWARAJ",
  "anmol957",
  "deepak",
  "utkarshshetye2000@gmail.com",
  "Bhavesh Sove",
  "priyanshu2.nagde@gmail.com",
  "kunal.g0815@gmail.com",
  "chinmay.sutar1998@gmail.com",
  "Rohit_Bhati",
  "Saurabhsawant123",
  "archana01072003@gmail.com",
  "vishwajeeththakur@gmail.com",
  "manas.sav@gmail.com",
  "Nagesh98@",
  "nitingupta3152001@gmail.com",
  "shivanshtiwariofficial@gmail.com",
  "mihirbulsara1712@gmail.com",
  "jakarta90",
  "Harsh@01",
  "kunash1432@gmail.com",
  "Ravi2001",
  "singhadarsh1449@gmail.com",
  "nigamkumarrtp@gmail.com",
  "zalahitesh960@gmail.com",
  "Saksham",
  "Aditya Maltare",
  "MENTALIST@2526",
  "CoderX",
  "neerajfunkyy1101@gmail.com",
];
