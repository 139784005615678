import React, { Component } from "react";

class MockInterviewSlot extends Component {
  state = {};
  render() {
    return (
      <>
        <div className='row'>
          <div className='col-md-2'></div>
          <div className='col-md-8'>
            <table
              width='100%'
              cellpadding='0'
              cellspacing='0'
              border='0'
              align='center'
            >
              <tr>
                <td align='center'>
                  <table
                    align='center'
                    border='0'
                    cellpadding='0'
                    cellspacing='0'
                    width='600'
                    style={{ backgroundColor: "#191919" }}
                  >
                    <tr>
                      <td>
                        <div className='row'>
                          <div clsassName='card text-white border border-white'>
                            <a href='https://www.datacode.in/'>
                              <img
                                className=' card-img-top mx-auto img-fluid img-circle d-block'
                                // src="https://ci4.googleusercontent.com/proxy/1sVq3CdW95K_MDHVT_48HLNozKhCNrbd5pg96PhFh_opwXJ_E0f7j2pEwGUhxEe6rsnI51Bgo9qld8vRxVA54QSPGPhHuezeYStlTlGvl0xfr0O5Qd2opx3axX6uNyrg8vdMuQ=s0-d-e1-ft#https://media.geeksforgeeks.org/wp-content/cdn-uploads/20200925143555/GW_MH_2.png"
                                src='https://i.postimg.cc/qqFSjxg0/5.png'
                                alt='avatar'
                              />
                            </a>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div
                          className='row'
                          style={{
                            textAlign: "center",
                            backgroundColor: "#191919",
                          }}
                        >
                          <div className='col'>
                            <div
                              className='row text-white mt-4 '
                              style={{
                                textAlign: "center",
                                backgroundColor: "#191919",
                              }}
                            >
                              <p
                                className='mx-auto'
                                style={{ fontWeight: "bold" }}
                              >{`Hey!!`}</p>
                            </div>

                            <div
                              className='row text-white'
                              style={{
                                textAlign: "center",
                                backgroundColor: "#191919",
                              }}
                            >
                              <p
                                className='mx-auto'
                                style={{ fontWeight: "bold" }}
                              >{`Greetings from Datacode.in`}</p>
                            </div>
                            <div
                              className='row text-white'
                              style={{
                                textAlign: "center",
                                backgroundColor: "#191919",
                              }}
                            >
                              <p className='pl-5 pr-5 pt-2'>{` Thanks for your active participation Datacode’s Mock Placement Test.
 `}</p>
                            </div>

                            <div
                              className='row text-white'
                              style={{
                                textAlign: "center",
                                backgroundColor: "#191919",
                              }}
                            >
                              <p className='pl-5 pr-5 pt-2 mx-auto'>{`Your efforts stood out to us and we would like to invite you for an interview with a panel of expert software developers from well-known MNCs.
`}</p>
                            </div>

                            <div
                              className='row text-white'
                              style={{
                                textAlign: "center",
                                backgroundColor: "#191919",
                              }}
                            >
                              <p className='pl-5 pr-5 pt-3'>{`We have shared your Interview Slot right below, please have a look. 
And make sure to be present at the exact time allotted to you.
You can find your calendar invitation attached to this mail, You have to join at the given time with this invitation link only.
`}</p>
                            </div>
                            <div
                              className='row text-white'
                              style={{
                                textAlign: "center",
                                backgroundColor: "#191919",
                              }}
                            >
                              <p className='pl-5 pr-5 pt-3 mx-auto'>{`Your Time Slot:-
`}</p>
                            </div>
                            <div
                              className='row text-white'
                              style={{
                                textAlign: "center",
                                backgroundColor: "#191919",
                                marginTop: "-10px",
                              }}
                            >
                              <p className='pl-5 pr-5 pt-0 mx-auto'>{`Monday 12:30pm

`}</p>
                            </div>
                            <div
                              className='row text-white'
                              style={{
                                textAlign: "center",
                                backgroundColor: "#191919",
                              }}
                            >
                              <p className='pl-5 pr-5 pt-3 mx-auto'>{`Your Interview Invitation link:

`}</p>
                            </div>
                            <div
                              className='row text-white'
                              style={{
                                textAlign: "center",
                                backgroundColor: "#191919",
                                marginTop: "-10px",
                              }}
                            >
                              <p className='pl-5 pr-5 pt-0 mx-auto'>{`Link here
`}</p>
                            </div>
                            <div
                              className='row text-white'
                              style={{
                                textAlign: "center",
                                backgroundColor: "#191919",
                              }}
                            >
                              <p className='pl-5 pr-5 pt-3 mx-auto'>{`All the Best for your Interview!

`}</p>
                            </div>
                            <div
                              className='row text-white'
                              style={{
                                textAlign: "center",
                                backgroundColor: "#191919",
                                marginTop: "-15px",
                              }}
                            >
                              <p className='pl-5 pr-5 pt-0 mx-auto'>{`We hope you will find it valuable and insightful.

`}</p>
                            </div>

                            <div
                              className='row text-white'
                              style={{
                                textAlign: "center",
                                backgroundColor: "#191919",
                              }}
                            >
                              <p className='pl-5 pr-5 pt-0 mx-auto'>{`For more updates regarding Placement Preparation Material,
stay tuned with us and


`}</p>
                            </div>
                            <div
                              className='row text-white'
                              style={{
                                textAlign: "center",
                                backgroundColor: "#191919",
                                fontWeight: "bold",
                                marginTop: "-15px",
                              }}
                            >
                              <p className='pl-5 pr-5 pt-0'>{`Join our Placement Preparation Group by clicking on the link below`}</p>
                            </div>
                            <div
                              className='row text-white'
                              style={{
                                textAlign: "center",
                                backgroundColor: "#191919",
                              }}
                            >
                              <p className='pl-5 pr-5 pt-2 mx-auto'>{`Thanks and Regards,`}</p>
                            </div>
                            <div
                              className='row text-white'
                              style={{
                                textAlign: "center",
                                backgroundColor: "#191919",
                              }}
                            >
                              <p className='pl-5 pr-5 mx-auto'>{`Team Datacode.in`}</p>
                            </div>
                          </div>
                        </div>

                        <div
                          className='row pt-4'
                          style={{
                            backgroundColor: "#191919",
                          }}
                        >
                          <div className='col-3 text-center ml-2'>
                            <div
                              className='row'
                              style={{
                                paddingLeft: 35,
                                backgroundColor: "#191919",
                              }}
                            >
                              <a href='https://www.facebook.com/Datacode.in'>
                                <img
                                  style={{ width: 100, height: 100 }}
                                  src='https://i.postimg.cc/R0tCNTzm/Asset-11-288x.png'
                                  alt='avatar'
                                />
                              </a>
                            </div>
                            <div className='row pl-4 pr-4 mt-1'>
                              <div className='col-4 text-center'>
                                <a href='https://www.facebook.com/Datacode.in'>
                                  <img
                                    className=''
                                    src='https://68ef2f69c7787d4078ac-7864ae55ba174c40683f10ab811d9167.ssl.cf1.rackcdn.com/facebook-icon_16x16.png'
                                    alt='avatar'
                                  />
                                </a>
                              </div>
                              <div className='col-4 text-center'>
                                <a href='https://www.datacode.in/'>
                                  <img
                                    className=''
                                    src='https://68ef2f69c7787d4078ac-7864ae55ba174c40683f10ab811d9167.ssl.cf1.rackcdn.com/linkedin-icon_16x16.png'
                                    alt='avatar'
                                  />
                                </a>
                              </div>
                              <div className='col-4 text-center'>
                                <a href='https://www.instagram.com/datacode.in/'>
                                  <img
                                    className=''
                                    src='https://68ef2f69c7787d4078ac-7864ae55ba174c40683f10ab811d9167.ssl.cf1.rackcdn.com/instagram-icon_16x16.png'
                                    alt='avatar'
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                          <div
                            className='col-8 border-left border-dark p-2'
                            style={{ backgroundColor: "#191919" }}
                          >
                            <div className='row'>
                              <div className='col text-center'>
                                <h3
                                  className='text-center'
                                  style={{
                                    color: "#37c9ef",
                                    fontFamily: "Aileron Heavy",
                                  }}
                                >
                                  Learn With Datacode
                                </h3>
                                <a href='https://www.datacode.in/'>
                                  <p
                                    style={{ fontSize: 12 }}
                                    className='text-white pt-0 mt-0 pl-2 pr-2'
                                  >
                                    An online learning portal for computer
                                    science beginners, start your learning from
                                    best resources over the internet
                                  </p>
                                </a>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col text-center'>
                                <a href='https://www.datacode.in/'>
                                  <div className='btn btn-success btn-sm'>
                                    Start Learning
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </div>
          <div className='col-md-2'></div>
        </div>
      </>
    );
  }
}

export default MockInterviewSlot;
