import React, { Component } from "react";
import { Link } from "react-router-dom";
import RightSideSection from "../../right_section/RightSideSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faAward } from "@fortawesome/free-solid-svg-icons";
import FbLikeShare from "../../FbLikeShare";
import Technologies from "../../right_section/Technologies";
import CertificateShowCard from "./CertificateShowCard";
import LearningSideSection from "../../right_section/learning_sidesection/LearningSideSection";
import TrainingCertificationCardCarousel from "./TrainingCertificationCardCarousel";

class ReactJS extends Component {
  state = {};
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <>
        <div className='row'>
          <div className='col-1'></div>
          <div className='col-md-8 mt-4 '>
            <h2
              className='text-center mb-4 display-5'
              style={{ color: "#072C71" }}
            >
              Find the Best React Training & Certification
            </h2>
            <Link
              to='/home'
              className='badge mr-1'
              style={{ backgroundColor: "#06CB6C" }}
            >
              <FontAwesomeIcon icon={faHome} size='2x' color='#072C71' />
            </Link>
            <Link
              to='/index_training_certification'
              className='badge mr-1'
              style={{ backgroundColor: "#072C71" }}
            >
              <FontAwesomeIcon icon={faAward} size='2x' color='#06CB6C' />
            </Link>
            <div className='card-deck'>
              <CertificateShowCard
                title='Front-End Web Development with React'
                level='Intermediate'
                duration='43 hr '
                enroll='Pay for Certificate only'
                platform=''
                tag1='react'
                tag2='Web Development'
                tag3='Front-End'
                link='https://www.coursera.org/learn/front-end-react'
              />
              <CertificateShowCard
                title='[NEW] React + Firebase: For Beginners
                '
                level='Beginner'
                duration='5hr 52min  '
                enroll='FREE'
                platform='Udemy'
                tag1='react'
                tag2='Firebase'
                tag3='Beginners'
                link='https://www.udemy.com/course/new-react-firebase-real-time-serverless-apps/'
              />
            </div>

            <h4 className='text-secondary mt-5'>
              <code className='text-primary'>
                {" "}
                For More Training & Certification
              </code>
            </h4>
            <TrainingCertificationCardCarousel />
          </div>

          <div className='col-md-3 mt-md-5 mt-3'>
            <div className='row'>
              <FbLikeShare />{" "}
            </div>
            <LearningSideSection language='react' />
            <a
              href='https://stude.co/848611'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                className=' card-img-top mx-auto  img-fluid img-circle d-block mt-4 '
                src={require("../../../assets/images/bitdegree.jpeg")}
                alt='avatar'
              />
            </a>

            <Technologies />
            <RightSideSection />
          </div>
        </div>
      </>
    );
  }
}

export default ReactJS;
