import React from "react";

const EventDashboardNav = ({ activeTab, setActiveTab }) => {
  return (
    <>
      <div className='row m-0'>
        <div className='col-12'>
          <div className='event-dashboard-header w-100 px-0 table-responsive table'>
            <ul>
              <li>
                <span
                  onClick={() => setActiveTab("basic")}
                  className={activeTab === "basic" ? "active" : ""}
                >
                  <i className='fal fa-cog mr-2' />
                  Basic Details
                </span>
              </li>
              <li>
                <span
                  onClick={() => setActiveTab("layout")}
                  className={activeTab === "layout" ? "active" : ""}
                >
                  <i className='fal fa-crop-alt mr-2' />
                  Layout Details
                </span>
              </li>
              <li>
                <span
                  onClick={() => setActiveTab("timeline")}
                  className={activeTab === "timeline" ? "active" : ""}
                >
                  <i className='far fa-stream mr-2' /> Time Line
                </span>
              </li>
              <li>
                <span
                  onClick={() => setActiveTab("speaker")}
                  className={activeTab === "speaker" ? "active" : ""}
                >
                  <i className='fal fa-user-headset mr-2' />
                  Speakers
                </span>
              </li>
              <li>
                <span
                  onClick={() => setActiveTab("sponsor")}
                  className={activeTab === "sponsor" ? "active" : ""}
                >
                  Sponsors / Partners
                </span>
              </li>
              <li>
                <span
                  onClick={() => setActiveTab("registerList")}
                  className={activeTab === "registerList" ? "active" : ""}
                >
                  <i className='far fa-users mr-2' />
                  Registration List
                </span>
              </li>
              <li>
                <span
                  onClick={() => setActiveTab("question")}
                  className={activeTab === "question" ? "active" : ""}
                >
                  Questions
                </span>
              </li>
              <li>
                <span
                  onClick={() => setActiveTab("feedback")}
                  className={activeTab === "feedback" ? "active" : ""}
                >
                  Feedbacks
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventDashboardNav;
