import React from "react";
import Parser from "html-react-parser";

const TextEditorBlockPreview = ({ item }) => {
  return (
    <div>
      {!item.hasOwnProperty("payload") || item.payload === "<p></p>\n" ? (
        <div className="d-flex justify-content-center py-2">
          <p>There is nothing to preview yet.</p>
        </div>
      ) : (
        <div>{Parser(String(item.payload))}</div>
      )}
    </div>
  );
};

export default TextEditorBlockPreview;
