import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  addProblem,
  getProblemDetails,
  editProblem,
  addAdminSolution,
  deleteAdminSolution,
  deleteProblemTestCase,
  addProblemTestCase,
  editProblemTestCase,
} from "../../../../actions";

import AddProblem from "./AddProblem";

const mapStateToProps = ({ dayscode, user }) => ({
  currentUser: user.currentUser ? user.currentUser : {},
  problemDetailsLoading: dayscode.problemDetailsLoading,
  problemDetails: dayscode.problemDetails,
});

const mapDispatchToProps = {
  addProblem,
  getProblemDetails,
  editProblem,
  addAdminSolution,
  deleteAdminSolution,
  deleteProblemTestCase,
  addProblemTestCase,
  editProblemTestCase,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddProblem)
);
