import React from 'react'

const data = [
  {
    img: "https://res.cloudinary.com/datacode/image/upload/v1693150632/vikoqvakue0pkdaulxl4.png",
    title: "Elevate Your Skills",
    content: "Our workshops are tailor-made to help you excel in your chosen field.",
  },
  {
    img: "https://res.cloudinary.com/datacode/image/upload/v1693150567/qgaydcai6kcjoxxs2yyg.png",
    title: 'Inspiring Learning Environment',
    content: 'Step into an atmosphere buzzing with creativity and innovation',
  },
  {
    img: "https://res.cloudinary.com/datacode/image/upload/v1690999871/fh3ax2qdgvqdxeatlfbz.png",
    title: 'Hands-On Experience',
    content: 'Theory meets practice as you dive into hands-on activities and real-world projects',
  },
  {
    img: "https://res.cloudinary.com/datacode/image/upload/v1690999871/fh3ax2qdgvqdxeatlfbz.png",
    title: 'Certified Excellence',
    content: 'Gain recognition for your efforts! Upon completion of the workshop',
  },
]
const StepsLearningWonders = () => {
  return (
    <>
      <div className="row mx-0 d-flex justify-content-center steps-learning-section">
        <div className="col-10 border text-center ">
          <h1>Step into a World of Learning Wonders!</h1>
          <p>Unlock Your Potential with Our Dynamic Workshops!</p>
          <div className="row mx-0 d-flex ">
            {
              data.map((item, i) => (
                <div className="col-12 col-md-3 step-card">
                  <img
                    width='70px'
                    className="img-fluid"
                    loading="lazy"
                    src={item.img}
                    alt="avatar"
                  />
                  <h6>{item.title}</h6>
                  <span>{item.content}</span>
                </div>
              ))
            }
          </div>
          <div className="btn btn-primary my-5">
            Explore Workshops....
          </div>
        </div>
      </div>
    </>
  )
}

export default StepsLearningWonders