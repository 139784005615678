import React from "react";
import Codepen from "react-codepen-embed";

const CodePenBlockPreview = () => {
  return (
    <>
      <Codepen hash='JyxeVP' user='shettypuneeth' />
    </>
  );
};

export default CodePenBlockPreview;
