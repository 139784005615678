import { Checkbox, Radio } from "antd";
import Parser from "html-react-parser";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  FaBookmark,
  FaComment,
  FaEllipsisH,
  FaEye,
  FaHeart,
  FaShareAlt,
} from "react-icons/fa";
import { LuClipboardType } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import SyntaxHighlighter from "react-syntax-highlighter";
import { monokai } from "react-syntax-highlighter/dist/esm/styles/hljs";
import CustomLoader from "../../../shared_components/CustomLoader";
import ToolTip from "../../../shared_components/Tooltip";
import {
  clearLessonDetails,
  getAllLessons,
  getLessonDetails,
} from "../DaysCode/actions";
import CodePenBlockPreview from "./CodePenBlockPreview";
import GistBlockPreview from "./GistBlockPreview";
import YoutubeBlockPreview from "./YoutubeBlockPreview";

const BlogPreview = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { lessonDetails, lessonsList, problemsListLoading } = useSelector(
    (state) => state.dayscode,
  );
  const [isLiked, setIsLiked] = useState(false);
  const [quizSingleOption, setQuizSingleOption] = useState("");
  const [quizMultiOption, setQuizMultiOption] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    dispatch(getAllLessons());
    dispatch(getLessonDetails(id));
    console.log(problemsListLoading);
    return () => {
      dispatch(clearLessonDetails());
    };
  }, []);

  const blog = {
    id: parseInt(id),
    title: "Getting Started with React",
    author: "John Doe",
    date: "2024-03-15",
    category: "Development",
    views: 1200,
    likes: 45,
    comments: 23,
    image: `https://picsum.photos/seed/blog${id}/800/400`,
    excerpt:
      "Learn the fundamentals of React and start building modern web applications with this comprehensive guide.",
    content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.

    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
    
    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.`,
    isLiked: false,
    isSaved: false,
    readTime: "5 min read",
    tags: ["React", "JavaScript", "Web Development"],
  };

  const handleShare = () => {
    console.log(lessonDetails);
    console.log(lessonsList);
    if (navigator.share) {
      navigator.share({
        title: blog.title,
        text: blog.excerpt,
        url: window.location.href,
      });
    } else {
      navigator.clipboard.writeText(window.location.href);
      alert("Link copied to clipboard!");
    }
  };

  return (
    <>
      {problemsListLoading ? (
        <CustomLoader />
      ) : (
        <div className='d-flex flex-column'>
          <div className='container pt-3 pb-5  d-flex flex-column col-md-8 col-sm-12 blogs blog-w'>
            {/* <div className='back-btn-container'>
              <button
                className='back-button btn btn-link text-decoration-none mb-4'
                onClick={() => history.push("/blogs")}
              >
                <FaArrowLeft /> Back to Blogs
              </button>
            </div> */}
            <article className='blog-preview mt-3'>
              <h1 className='display-4 mb-3'>{lessonDetails.name}</h1>

              <div className='d-flex align-items-center gap-3 mb-3 '>
                <span className='text-muted d-flex align-items-center'>
                  <img
                    className=' mr-2 border rounded-pill'
                    height={45}
                    width={45}
                    src={
                      lessonDetails.author?.imgUrl ||
                      "https://miro.medium.com/v2/resize:fill:20:20/1*abnkL8PKTea5iO2Cm5H-Zg.png"
                    }
                    alt=''
                  />
                  <span className='title-name'>
                    <span className=''>
                      {lessonDetails.author
                        ? lessonDetails.author?.firstName +
                          " " +
                          lessonDetails.author?.lastName
                        : "Divy Attarde"}
                    </span>
                    <span className='text-muted d-flex align-items-center'>
                      {/* <FaCalendarAlt className='mr-1' /> */}
                      {/* {new Date(blog.date).toLocaleDateString()} */}
                      Publised on
                      {moment(lessonDetails?.updatedAt).format("MMMM Do YYYY")}
                    </span>
                  </span>
                </span>
              </div>
              <div className='blog-actions border-top border-bottom my-3'>
                <button
                  className={`btn btn-link ${
                    blog.isLiked ? "text-danger" : "text-muted"
                  }`}
                >
                  <FaHeart />
                  <span>{blog.likes}</span>
                </button>

                <button className='btn btn-link text-muted'>
                  <FaComment />
                  <span>{blog.comments}</span>
                </button>

                <button
                  className={`btn btn-link ${
                    blog.isSaved ? "text-primary" : "text-muted"
                  }`}
                >
                  <FaBookmark />
                  <span>Save</span>
                </button>

                <button
                  className='btn btn-link text-muted '
                  onClick={handleShare}
                >
                  <FaShareAlt />
                  <span>Share</span>
                </button>
              </div>
              <img
                src={lessonDetails.thumbnail}
                className='img-fluid mb-4 animate__animated animate__fadeIn'
                alt={lessonDetails.name}
              />

              <div className='mb-4'>
                <div className='d-flex flex-wrap justify-content-between align-items-center mb-3'>
                  <span className='badge bg-primary px-2 py-2 text-white blog-preview-badge'>
                    <b>{lessonDetails.category}</b>
                  </span>
                  <div className='d-flex align-items-center gap-3'>
                    <span className='text-muted d-flex align-items-center'>
                      <FaEye className='mr-1' /> {"1200"}
                    </span>
                    <span className='text-muted'>10 min Read</span>
                  </div>
                </div>

                <div className='d-flex flex-wrap justify-content-between align-items-center mb-4'></div>

                <div className='d-flex flex-wrap gap-2 mb-4'>
                  {blog.tags.map((tag) => (
                    <span key={tag} className='badge bg-light text-dark'>
                      {tag}
                    </span>
                  ))}
                </div>
              </div>

              <div className='blog-content '>
                <p className='lead mb-4'>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Impedit, obcaecati. Lorem ipsum dolor sit amet consectetur
                  adipisicing elit. Iusto, eius?
                </p>
                {lessonDetails?.description
                  ?.split("\n\n")
                  .map((paragraph, index) => (
                    <p key={index}>{paragraph}</p>
                  ))}
              </div>

              <div>
                {lessonDetails?.contents?.map((content) => {
                  return (
                    <div className='textEditor'>
                      {content.type === "textEditor" && (
                        <div>
                          <p>{Parser(String(content.payload))}</p>
                        </div>
                      )}
                      {content.type === "quizSingleOption" && (
                        <div className=' form-check d-flex flex-column border shadow-sm py-3 rounded-lg mb-4'>
                          <label className='border-bottom mr-2'>
                            <b>{content.payload.question}?</b>
                          </label>
                          <Radio.Group
                            className='d-flex flex-column'
                            value={quizSingleOption}
                            onChange={(e) => {
                              setQuizSingleOption(e.target.value);
                            }}
                          >
                            <Radio
                              value={content.payload.option1}
                              className='border rounded my-2 p-2'
                            >
                              {content.payload.option1}
                            </Radio>
                            <Radio
                              value={content.payload.option2}
                              className='border rounded my-2 p-2'
                            >
                              {content.payload.option2}
                            </Radio>
                            <Radio
                              value={content.payload.option3}
                              className='border rounded my-2 p-2'
                            >
                              {content.payload.option3}
                            </Radio>
                            <Radio
                              value={content.payload.option4}
                              className='border rounded my-2 p-2'
                            >
                              {content.payload.option4}
                            </Radio>
                          </Radio.Group>
                          <div className='blog-content-btn'>
                            <button className='btn btn-success float-right '>
                              Submit
                            </button>
                          </div>
                        </div>
                      )}{" "}
                      {content.type === "quizMultiOption" && (
                        <div className='form-check d-flex flex-column border py-3 shadow-sm rounded-lg mb-4'>
                          <label className='border-bottom mr-2'>
                            <b>{content.payload.question}?</b>
                          </label>
                          <Checkbox.Group className='d-flex flex-column'>
                            <Checkbox
                              value={content.payload.text1}
                              className='border rounded my-2 p-2 mr-2'
                              onChange={(e) => {
                                setQuizMultiOption(e.target.value);
                              }}
                            >
                              {content.payload.text1}
                            </Checkbox>
                            <Checkbox
                              value={content.payload.text2}
                              className='border rounded my-2 p-2  mr-2'
                              onChange={(e) => {
                                setQuizMultiOption(e.target.value);
                              }}
                            >
                              {content.payload.text2}
                            </Checkbox>
                            <Checkbox
                              value={content.payload.text3}
                              className='border rounded my-2 p-2  mr-2'
                              onChange={(e) => {
                                setQuizMultiOption(e.target.value);
                              }}
                            >
                              {content.payload.text3}
                            </Checkbox>
                            <Checkbox
                              value={content.payload.text4}
                              className='border rounded my-2 p-2  mr-2'
                              onChange={(e) => {
                                setQuizMultiOption(e.target.value);
                              }}
                            >
                              {content.payload.text4}
                            </Checkbox>
                          </Checkbox.Group>
                          <div className='blog-content-btn'>
                            <button className='btn btn-success float-right '>
                              Submit
                            </button>
                          </div>
                        </div>
                      )}
                      {content.type === "textEditorElements" && (
                        <div className='my-5 textEditorElements'>
                          {content.subType === "tip" && (
                            <div className='tip rounded-lg'>
                              <div className='head px-3'>{content.subType}</div>
                              <div className='p-3 content'>
                                {Parser(String(content?.payload))}
                              </div>
                            </div>
                          )}
                          {content.subType === "info" && (
                            <div className='info rounded-lg'>
                              <div className='head px-3'>{content.subType}</div>
                              <div className='p-3 content'>
                                {Parser(String(content?.payload))}
                              </div>
                            </div>
                          )}
                          {content.subType === "important" && (
                            <div className='important rounded-lg'>
                              <div className='head px-3'>{content.subType}</div>
                              <div className='p-3 content'>
                                {Parser(String(content?.payload))}
                              </div>
                            </div>
                          )}
                          {content.subType === "note" && (
                            <div className='note rounded-lg'>
                              <div className='head px-3'>{content.subType}</div>
                              <div className='p-3 content'>
                                {Parser(String(content?.payload))}
                              </div>
                            </div>
                          )}
                          {content.subType === "fact" && (
                            <div className='fact rounded-lg'>
                              <div className='head px-3'>{content.subType}</div>
                              <div className='p-3 content'>
                                {Parser(String(content?.payload))}
                              </div>
                            </div>
                          )}
                          {content.subType === "warning" && (
                            <div className='warning rounded-lg'>
                              <div className='head px-3'>{content.subType}</div>
                              <div className='p-3 content'>
                                {Parser(String(content?.payload))}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                      {content.type === "codeBlock" && (
                        <div className='my-5'>
                          <div className='border-top border-left border-right rounded'>
                            <div className='d-flex justify-content-between'>
                              <h5 className='pt-2 px-2'>Code Block</h5>
                              <ToolTip
                                id={"copytoClip"}
                                message={"Copy to clipboard"}
                                placement={"right"}
                              />
                              <h5
                                className='pt-2 px-2 copytoClip'
                                id='copytoClip'
                              >
                                <LuClipboardType />
                              </h5>
                            </div>
                            <SyntaxHighlighter
                              language={content?.payload?.language}
                              style={monokai}
                            >
                              {content?.payload?.code}
                            </SyntaxHighlighter>
                          </div>{" "}
                        </div>
                      )}{" "}
                      {content.type === "imageBlock" && (
                        <div className='my-5 '>
                          <h4 className='text-center mb-2 blog-headings'>
                            Image Resources
                          </h4>
                          <img
                            src={content?.payload}
                            alt={"img-block"}
                            height={"100%"}
                            width={"100%"}
                          />
                        </div>
                      )}{" "}
                      {content.type === "codePenBlock" && (
                        <CodePenBlockPreview item={content.payload} />
                      )}{" "}
                      {content.type === "youtubeBlock" && (
                        <YoutubeBlockPreview item={content.payload} />
                      )}
                      {content.type === "githubGistBlock" && (
                        <div className='my-4'>
                          <GistBlockPreview item={content.payload} />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </article>
            <div className=' featured-blog-preview mx-0 '>
              <h4 className='blog-headings text-center'>Featured Blogs</h4>
              <div className='row justify-content-between mx-0'>
                {lessonsList &&
                  lessonsList.slice(0, 4).map((lesson) => {
                    return (
                      <div className='col-6 px-0 rounded my-2' key={lesson._id}>
                        <div className='border mx-2'>
                          <div className='blog-img '>
                            <img
                              src={
                                lesson.thumbnail ||
                                "https://c7.alamy.com/comp/D6BK05/shadow-looming-over-frightened-boy-D6BK05.jpg"
                              }
                              alt={lesson?.name}
                            />
                          </div>
                          <div className='d-flex justify-content-between align-items-center px-1 mt-2 blog-head'>
                            <span className=''>
                              <img
                                src={
                                  lesson.author?.imgUrl ||
                                  "https://c7.alamy.com/comp/D6BK05/shadow-looming-over-frightened-boy-D6BK05.jpg"
                                }
                                alt='Author name'
                                height={30}
                                width={30}
                                className=' rounded-pill border'
                              />
                              <span className='ml-1'>
                                {lesson.author?.firstName +
                                  lesson.author?.lastName || "Deep Giando"}
                              </span>
                            </span>
                            <span>
                              <span className='mr-2 d-felx align-items-center'>
                                <span>
                                  <FaEye />
                                </span>
                                <span> 1200</span>
                              </span>
                              <span className='ml-2'>5 min Read</span>
                            </span>
                          </div>
                          <div className='blog-content px-2 mt-2'>
                            <h4>{lesson?.name || "None is there but WHO??"}</h4>
                            <p>
                              {lesson.description
                                ? lesson.description?.slice(0, 75) + "..."
                                : " adipisicingelit. Voluptatum suscipit labore ad perferendis odi odi odi odi..."}
                            </p>
                          </div>
                          <div className='blog-footer px-2 mb-2'>
                            <span>
                              <span
                                role='img'
                                aria-label='icon'
                                className='mx-2'
                              >
                                ✨
                              </span>
                              <span className=''>
                                {moment(lessonDetails?.updatedAt).format(
                                  "MMM D",
                                )}
                              </span>
                              <span>
                                <FaHeart className='mx-2' />
                                450
                              </span>
                              <span>
                                <FaComment className='mx-2' />
                                27
                              </span>
                            </span>
                            <span>
                              <span>
                                <FaBookmark className='mx-2' />
                              </span>
                              <span>
                                <FaEllipsisH className='mx-2' />
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BlogPreview;
