import React from "react";
import { Field, reduxForm } from "redux-form";

import { RenderInputField } from "../../../../../../shared_components/ReduxFormFields";
import { required } from "../../../../../../utils/validators";

const ParticipantQuestionForm = ({
  id,
  eventRegistered,
  reset,
  handleSubmit,
  submitting,
  setQuestionSubmitted,
  eventQuestion,
  registerName,
}) => {
  const onSubmitQuestion = (values) => {
    const question = { ...values };
    if (id && eventRegistered) {
      question["id"] = id;
      question["name"] = registerName;
      eventQuestion(question).then((res) => {
        if (res && res.success) {
          setQuestionSubmitted(true);
          reset("eventRegisterForm");
        }
      });
    }
  };

  return (
    <>
      <h1>Participant Question Form</h1>
      <form className='' onSubmit={handleSubmit(onSubmitQuestion)}>
        <div className='row m-0'>
          <div className='col-12'>
            <Field
              type='textarea'
              name='question'
              label='Do you have any question for our speaker? (Please ask here)'
              placeholder=''
              component={RenderInputField}
              validate={[required]}
            />
          </div>
        </div>
        <div className='row mx-0 mt-4 justify-content-center'>
          <div className='col-12 col-md-2 text-center'>
            <button
              type='submit'
              className='btn reg-button text-white mb-2'
              disabled={submitting}
            >
              Ask Question
            </button>
            <span
              className='pointer blue'
              onClick={() => setQuestionSubmitted(true)}
            >
              Skip
            </span>
          </div>
        </div>
      </form>
    </>
  );
};

export default reduxForm({
  form: "eventRegisterForm",
})(ParticipantQuestionForm);
