import { faChalkboardTeacher, faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import RightSideSection from "../../../../right_section/RightSideSection";
import McqsShowCard from "../McqsShowCard";

class IndexHtmlMCQ extends Component {
  state = {};
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <>
        <div className='row'>
          <div className='col-1'></div>
          <div className='col-md-8 mt-4 '>
            <h2
              className='text-center mb-4 display-5'
              style={{ color: "#072C71" }}
            >
              Find the Best HTML Multiple choice Questions
            </h2>
            <Link
              to='/home'
              className='badge mr-1'
              style={{ backgroundColor: "#06CB6C" }}
            >
              <FontAwesomeIcon icon={faHome} size='2x' color='#072C71' />
            </Link>
            <Link
              to='/index_mcqs'
              className='badge mr-1'
              style={{ backgroundColor: "#072C71" }}
            >
              <FontAwesomeIcon
                icon={faChalkboardTeacher}
                size='2x'
                color='#06CB6C'
              />
            </Link>

            <div className='card-deck m-2'>
              <McqsShowCard
                link='html_mcq_1'
                title='HTML MCQs Questions Set 1'
              />
              <McqsShowCard link='index_html_mcqs' title='Upcoming' />
            </div>
          </div>

          <div className='col-md-3'>
            <RightSideSection />
          </div>
        </div>
      </>
    );
  }
}

export default IndexHtmlMCQ;
