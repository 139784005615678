import React from 'react'

import Carousel from 'react-multi-carousel';

import PeopleWords from '../app/components/header_component/OurStory/PeopleWords';
import { testimonialResponsive } from '../utils'

const Testimonial = ({
  testimonials, heading
}) => {

  return (
    <div className="row mx-0 my-5 py-5 d-flex justify-content-center align-items-center">
      <div className="col-md-10 col-12 text-center">
        <h1>{heading}</h1>
        <div className="my-5" style={{ position: "relative" }}>
          <Carousel
            draggable={true}
            infinite={true}
            dotListClass="custom-dot-list-style"
            // responsive={responsive}
            responsive={testimonialResponsive}
            autoPlay={true}
            className="card-group"
          >
            {testimonials && testimonials.map((item, i) => (
              <PeopleWords
                key={i}
                item={item}
              />
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  )
}

export default Testimonial