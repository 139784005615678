import { setInterviewQuestionsHandlers } from "./setInterviewQuestions";
import { createReducer } from "../../../../core/reduxUtils";

const initialState = {
  interviewQuestionsList: [],
  interviewQuestion: {},
  interviewQuestionByUser: [],
  addInterviewQuestionLoading: false,
  interviewQuestionsListLoading: false,
  error: "4444",
};

const handlers = {
  ...setInterviewQuestionsHandlers,
};

const placementReducer = createReducer(initialState, handlers);

export default placementReducer;
