import React, { useEffect } from "react";
import CreateEventForm from "./CreateEventForm";
import { Link as LinkScroll } from "react-scroll";
import { useState } from "react";
import ShareEventForm from "./ShareEventForm";

const CreateEventPage = () => {
  const [selectFrom, setSelectForm] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <div className='row m-0 event-page-header'>
        <div className='col-12 px-md-5 px-3'>
          <h1 className='mt-5 event-heading'>Create Your Event</h1>
          <h3 className='mt-4 event-sub-heading'>
            A complete roadmap to your web development journey
          </h3>
          <LinkScroll to='form' spy={true} smooth={true}>
            <button className='btn mb-5 join-us-button text-center card-schadow'>
              Register Now!
            </button>
          </LinkScroll>
        </div>
      </div>
      <div className='row m-0 event-page-header'>
        <div className='col-6 px-md-5 px-3'>
          <h1 className='mt-5 event-heading'>
            Share Community Events / Webinars
          </h1>
          <h3 className='mt-4 event-sub-heading'>
            Share community events with Datacode Community Members
          </h3>
          <LinkScroll to='form' spy={true} smooth={true}>
            <button
              onClick={() => setSelectForm("share")}
              className='btn mb-5 join-us-button text-center card-schadow'
            >
              Share Now
            </button>
          </LinkScroll>
        </div>
        <div className='col-6 px-md-5 px-3'>
          <h1 className='mt-5 event-heading'>Create Your Own Event</h1>
          <h3 className='mt-4 event-sub-heading'>
            A complete roadmap to your web development journey
          </h3>
          <LinkScroll to='form' spy={true} smooth={true}>
            <button
              onClick={() => setSelectForm("create")}
              className='btn mb-5 join-us-button text-center card-schadow'
            >
              Register Now!
            </button>
          </LinkScroll>
        </div>
      </div>
      <div id='form' className='row m-0'>
        <div className='col-12'>
          {selectFrom === "share" && <ShareEventForm />}
          {selectFrom === "create" && <CreateEventForm />}
        </div>
      </div>
    </>
  );
};

export default CreateEventPage;
