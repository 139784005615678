const c = `#include <stdio.h>
int main(void) {
	// your code goes here
	return 0;
}
`;

const cpp = `#include <iostream>
using namespace std;

int main() {
	// your code goes here
	return 0;
}
`;

export const preCode = {
  1: c,
  2: cpp,
};

export const getLanguageId = (language) => {
  const languageDictionary = {
    c: 1,
    cpp: 2,
    java: 4,
    python: 10,
  };
  return languageDictionary[language];
};
