import * as constants from "../constants";

const setClubApplicationLoading = (state) => ({
  ...state,
  clubApplicationLoading: !state.clubApplicationLoading,
});

const setClubApplication = (state, action) => ({
  ...state,
  clubApplication: action.payload,
  clubApplicationLoading: !state.clubApplicationLoading,
})

export const setClubHandlers = {
  [constants.CLUB_APPLICATION_LOADING]: setClubApplicationLoading,
  [constants.SET_CLUB_APPLICATION]: setClubApplication,
};
