import React, { useState } from "react";
import CommonModelPopup from "../../../../../../../../../../shared_components/CommonModelPopup";

const QuizBlockSingleOptionPreview = ({ item }) => {
  // console.log(item);

  const [selected, setSelected] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isWrongAnswerModalVisible, setIsWrongAnswerModalVisible] =
    useState(false);
  const options = () => {
    var row = [];
    for (let i = 1; i <= 4; i++) {
      row.push(
        <div className="d-flex align-items-center">
          <input
            type="radio"
            className="mr-3"
            name="options"
            value={item.payload && item?.payload[`option${i}`]}
            onChange={(e) => setSelected(e.target.value)}
          />
          <label>{item.payload && item?.payload[`option${i}`]} </label>
        </div>
      );
    }
    return row;
  };

  const handelSubmit = (e) => {
    e.preventDefault();
    if (selected === item.payload[`${item?.payload?.correct_answer}`]) {
      setIsModalVisible(true);
    } else setIsWrongAnswerModalVisible(true);
    setSelected("");
  };
  const closeModal = () => {
    setIsModalVisible(false);
  };
  const closeWrongAnswerModal = () => {
    setIsWrongAnswerModalVisible(false);
  };
  return (
    <>
      <div className="border-bottom rounded-lg p-3">
        <div>
          <p className="my-3">Choose the correct answer : </p>
        </div>
        <form onSubmit={handelSubmit}>
          <div>
            <h5 className="mb-3">{item?.payload?.question}</h5>
            {options()}
          </div>
          {selected ? (
            <>
              <div className="d-flex justify-content-center">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </>
          ) : null}
        </form>
      </div>
      {isModalVisible && (
        <CommonModelPopup
          model={isModalVisible}
          toggle={closeModal}
          message={"Congratulations !! your Answer is correct"}
          submitButtonText={"Okey"}
          modeltitle={"Answer Submited!"}
        />
      )}
      {isWrongAnswerModalVisible && (
        <CommonModelPopup
          model={isWrongAnswerModalVisible}
          toggle={closeWrongAnswerModal}
          message={"Opps !! Wrong Answer"}
          submitButtonText={"Okey"}
          modeltitle={"Wrong Answer Submited!"}
        />
      )}
    </>
  );
};

export default QuizBlockSingleOptionPreview;
