import React, { useEffect } from "react";
import AOS from "aos";

const partnersData = [
  {
    image: require("../../../../../assets/images/internship/techvraksh.jpg"),
    name: "TechVraksh",
  },
  // {
  //   image: require("../../../../../assets/images/internship/techvraksh.jpg"),
  //   name: "TechVraksh",
  // },
  // {
  //   image: require("../../../../../assets/images/internship/techvraksh.jpg"),
  //   name: "TechVraksh",
  // },
];

const HiringPartners = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="row mx-0 px-0 justify-content-center hiringPartners py-5">
      <div className="col-10 px-0">
        <h1 className="text-center pt-5">Our IT Partners</h1>
        <p className="text-center px-5 py-5 hiringPara">
          "Unlock Your Potential with Us! We connect talent with top IT
          partners, paving the way for successful careers. Join the ranks of
          students who've landed dream roles, thanks to our industry-leading
          placement network. Your future starts here!"
        </p>
        <div className="row mx-0 justify-content-center">
          {partnersData.map((items) => (
            <>
              <div
                className="col-12 col-sm-12 col-md-2 col-lg-2 p-3 d-flex justify-content-center"
                data-aos="fade-up"
              >
                <img src={items.image} className="" height="30vh" />
                <h5 className="d-flex align-items-center mb-0 pl-2">
                  {items.name}
                </h5>
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};
export default HiringPartners;
