import ResetPassword from "./ResetPassword";

import { connect } from "react-redux";

import { resetPassword } from "../../actions";

const mapStateToProps = ({ user }) => ({
  userError: user.forgotpassword_error,
});

const mapDispatchToProps = {
  resetPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
