import React from "react";
import DaysCodeRoadMap from "./DaysCodeRoadMap";
import DaysCodeSessionTimeLine from "./DaysCodeSessionTimeLine";
import ProgramGuide from "./ProgramGuide";

const DaysCodeTimeLine = ({ setTimelineTab, timelineTab }) => {
  const renderActiveComponent = (timelineTab) => {
    const componentDictionary = {
      roadmap: <DaysCodeRoadMap />,
      sessionTimeline: <DaysCodeSessionTimeLine />,
      guide: <ProgramGuide />,
    };

    return componentDictionary[timelineTab];
  };

  return (
    <>
      <div className='row m-0 mt-3 pt-4 px-md-5'>
        <div className='col-12 px-0 text-left'>
          <h1 className='dashboard-heading'>Program Timeline</h1>
          <div className='d-flex'>
            <div
              className={`text-center pointer pb-2 px-md-4 px-2 ${
                timelineTab === "roadmap" ? "nav-active" : "border-bottom"
              }`}
              onClick={() => setTimelineTab("roadmap")}
            >
              <small className='text-nowrap'>Content</small>
            </div>
            <div
              className={`text-center pointer pb-2 px-md-4 px-2 ${
                timelineTab === "sessionTimeline"
                  ? "nav-active"
                  : "border-bottom"
              }`}
              onClick={() => setTimelineTab("sessionTimeline")}
            >
              <small className='text-nowrap'>Session Timeline</small>
            </div>
            {/* <div
              className={`text-center pointer pb-2 px-md-4 px-2 ${timelineTab === "guide" ? "nav-active" : "border-bottom"
                }`}
              onClick={() => setTimelineTab("guide")}
            >
              <small className="text-nowrap">Program Guide</small>
            </div> */}
          </div>
        </div>
      </div>
      <div className='row m-0 pt-3 px-md-5 pb-md-2'>
        <div className='col-12 p-md-3 p-2 border bg-light card-schadow rounded-lg submissions-list-section'>
          {renderActiveComponent(timelineTab)}
        </div>
      </div>
    </>
  );
};

export default DaysCodeTimeLine;
