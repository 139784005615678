import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getBatchRoadmap, getCodeBatchDetails } from "../../../actions";
import LayoutContainer from "../LayoutContainer";
import CustomLoader from "../../../../../../shared_components/CustomLoader";
import LayoutContainerBatchPage from "./LayoutContainerBatchPage";
import ProblemList from "./ProblemList";
import LessonList from "./LessonList";
import QuizList from "./QuizList";
import MentorList from "./MentorList";
import ParticipantList from "./ParticipantList";
import RoadmapList from "./RoadmapList";

const DaysCodeBatchPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { codeBatchDetails, codeBatchesLoading } =
    useSelector((state) => state.dayscode) || {};
  const [activeTab, setActiveTab] = useState("problem");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    dispatch(getCodeBatchDetails(id));
    dispatch(getBatchRoadmap(id));
  }, []);

  const renderActiveComponent = (activeTab) => {
    const componentDictionary = {
      problem: (
        <ProblemList
          setActiveTab={setActiveTab}
          batch={id}
          codeBatchDetails={codeBatchDetails}
          codeBatchesLoading={codeBatchesLoading}
        />
      ),
      lesson: (
        <LessonList
          setActiveTab={setActiveTab}
          batch={id}
          codeBatchDetails={codeBatchDetails}
          codeBatchesLoading={codeBatchesLoading}
        />
      ),
      quiz: (
        <QuizList
          setActiveTab={setActiveTab}
          batch={id}
          codeBatchDetails={codeBatchDetails}
          codeBatchesLoading={codeBatchesLoading}
        />
      ),
      mentor: (
        <MentorList
          setActiveTab={setActiveTab}
          batch={id}
          codeBatchDetails={codeBatchDetails}
          codeBatchesLoading={codeBatchesLoading}
        />
      ),
      participant: (
        <ParticipantList
          setActiveTab={setActiveTab}
          batch={id}
          codeBatchDetails={codeBatchDetails}
          codeBatchesLoading={codeBatchesLoading}
        />
      ),
      roadmap: (
        <RoadmapList
          setActiveTab={setActiveTab}
          batch={id}
          codeBatchDetails={codeBatchDetails}
          codeBatchesLoading={codeBatchesLoading}
        />
      ),
    };

    return componentDictionary[activeTab];
  };

  return (
    <>
      <LayoutContainer>
        <div className='row mx-0'>
          <div className='col-12'>
            {codeBatchesLoading ? (
              <CustomLoader />
            ) : (
              <div className='row mx-0'>
                <div className='col-12'>
                  <h1>Batch Name: {codeBatchDetails?.title}</h1>
                  <div className='row mx-0 batch-header-section'>
                    <div className='col-12'>
                      <p className=''>Days: {codeBatchDetails.days}</p>
                      <p className=''>
                        Start Date: {codeBatchDetails.start_date}
                      </p>
                      <p className=''>End Date: {codeBatchDetails.end_date}</p>
                    </div>
                  </div>
                  <LayoutContainerBatchPage
                    setActiveTab={setActiveTab}
                    activeTab={activeTab}
                  >
                    {renderActiveComponent(activeTab)}
                  </LayoutContainerBatchPage>
                </div>
              </div>
            )}
          </div>
        </div>
      </LayoutContainer>
    </>
  );
};

export default DaysCodeBatchPage;

// < div className = "row mx-0" >
//   <div className="col-12">
//     <AddBatchProblemDrawer batch={id} />
//     <AddBatchLessonDrawer batch={id} />
//     <AddBatchChallengeDrawer batch={id} />
//     <AddBatchMentorDrawer batch={id} />
//   </div>
//                     </div >
// <div className="problems-section">
//   <h1>Problems</h1>
//   {
//     codeBatchDetails.problems && codeBatchDetails.problems.map((problem) => (
//       <ProblemListCard day={problem?.day} batchContentId={problem._id} item={problem.problemDetails} showDeleteFromBatchIcon={true} handleDeleteFromBatch={handleDeleteProblemFromBatch} />
//     ))
//   }
//   <h1>Lessons</h1>
//   {
//     codeBatchDetails.lessons && codeBatchDetails.lessons.map((lesson) => (
//       <ProblemListCard days={lesson?.days} batchContentId={lesson._id} item={lesson.lessonDetails} showDeleteFromBatchIcon={true} handleDeleteFromBatch={handleDeleteLessonFromBatch} />
//     ))
//   }
//   <h1>Quiz</h1>
//   {
//     codeBatchDetails.quizes && codeBatchDetails.quizes.map((quiz) => (
//       <ProblemListCard day={quiz?.day} batchContentId={quiz._id} item={quiz.quizDetails} showDeleteFromBatchIcon={true} handleDeleteFromBatch={handleDeleteQuizFromBatch} />
//     ))
//   }
// </div>

// const handleDeleteProblemFromBatch = (problem) => {
//   dispatch(deleteProblemFromBatch({ batch: id, problem }))
// }

// const handleDeleteLessonFromBatch = (lesson) => {
//   debugger
//   dispatch(deleteLessonFromBatch({ batch: id, lesson }))
// }

// const handleDeleteQuizFromBatch = (quiz) => {
//   dispatch(deleteQuizFromBatch({ batch: id, quiz }))
// }
