import React from "react";

const YoutubeBlockPreview = ({ item }) => {
  const youtube_parser = (item) => {
    console.log(item, "llink");
    var regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = item && item && item.match(regExp);
    return match && match[7].length == 11 ? match[7] : false;
  };

  return (
    <>
      <div className='my-5'>
        <h4 className='text-center mb-2 blog-headings'>Learn From Here</h4>
        <div className='video-responsive '>
          <iframe
            width='100%'
            height='300'
            src={`https://www.youtube.com/embed/${youtube_parser(item)}`}
            // frameBorder="0"
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
            title='Embedded youtube'
          />
        </div>
      </div>
    </>
  );
};
export default YoutubeBlockPreview;
