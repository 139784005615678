import React, { useEffect, useRef, useState } from "react";
import ToolTip from "../../../shared_components/Tooltip";
import { Button, message } from "antd";
import { FaFilter } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getSort } from "../../../utils";
import {
  deleteLesson,
  getAllLessons,
  getLessonDetails,
} from "../DaysCode/actions";
import BlogListCard from "./BlogListCard";
import BlogListTable from "./BlogListTable";
import CustomLoader from "../../../shared_components/CustomLoader";

const BlogDashBoard = () => {
  const [listView, setListView] = useState("grid");
  const [editModal, setEditModal] = useState(false);
  const [showDelModal, setShowDelModal] = useState(false);
  const [editType, setEditType] = useState();
  const { lessonsList, problemsListLoading } = useSelector(
    (state) => state.dayscode,
  );
  const selectedBLog = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const items = [
    {
      key: "1",
      label: (
        <div onClick={(e) => handleManage(e, selectedBLog.current)}>
          {" "}
          Manage
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div onClick={(e) => handleEdit(e, selectedBLog.current)}>
          Change Status
        </div>
      ),
    },
    {
      key: "3",
      label: <div onClick={(e) => toggleDel()}> Delete</div>,
    },
    {
      key: "4",
      label: (
        <div
          onClick={(e) => {
            e.preventDefault();
            copyToClipboard(
              `http://localhost:3000/blog/preview/${selectedBLog.current}`,
            );
          }}
        >
          Copy Link
        </div>
      ),
    },
    {
      key: "5",
      label: <div className='text-danger'> Report!</div>,
    },
  ];

  const handleNavigation = (event, id) => {
    selectedBLog.current = id;
    // Check if the click target is not the dropdown
    if (!event.target.closest(".dropdown-trigger ,.ant-dropdown-menu ")) {
      history.push(`/blog/preview/${id}`);
    }
  };

  const handleManage = (e, id) => {
    e.preventDefault();
    history.push(`/blog/${id}/manage`);
  };

  const handleEdit = (e, id) => {
    e.preventDefault();
    setEditType("changeStatus");
    dispatch(getLessonDetails(id));
    setEditModal(true);
  };

  const handleDel = () => {
    dispatch(deleteLesson(selectedBLog.current));
    setShowDelModal(false);
  };

  const toggleDel = () => {
    setShowDelModal(!showDelModal);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        message.success("text copied to clipboard!");
      })
      .catch((err) => {
        message.error("Failed to copy the text!");
        console.error("Could not copy text: ", err);
      });
  };

  const handleApprove = (id) => {
    selectedBLog.current = id;
    setEditType("approve");
    dispatch(getLessonDetails(id));
    setEditModal(true);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    dispatch(getAllLessons());
  }, [dispatch]);

  return (
    <div className='row mx-0 blogs '>
      <div
        className={
          listView === "grid"
            ? "blog-container col-sm-9 col-12 "
            : "blog-container col-lg-9 col-12"
        }
      >
        <div className='header-blog row align-items-center my-0  mx-1'>
          <div className=' d-flex flex-column justify-content-center col-6 '>
            <div className='blog-title py-3'>Blogs </div>
            {/*  <div className="">Home-Blogs</div> */}
          </div>
          <div className='col-6 d-flex justify-content-end align-items-center gap-3 gap-lg-1 buttons'>
            <ToolTip
              message={"Table View"}
              id='table'
              placement='bottom'
            ></ToolTip>
            <i
              id='table'
              onClick={() => setListView("table")}
              className='p-2  border rounded fas fa-list d-none d-md-block'
            />
            <ToolTip
              message={"Grid View"}
              id='grid'
              placement='bottom'
            ></ToolTip>
            <i
              id='grid'
              onClick={() => setListView("grid")}
              className='p-2 border rounded fas fa-th-large vis d-none d-md-block'
            />
            <div className='filterr'>
              {/* <CustomFilterPopup type={"blog"} /> */}
              <Button>
                <FaFilter />
                Filter
              </Button>
            </div>
            {/* <Button
              className=' btn btn-primary py-0 px-3'
              //   onClick={handleCreate}
            >
              <div>Create</div>
            </Button> */}
          </div>
        </div>
        {problemsListLoading ? (
          <CustomLoader />
        ) : (
          <div className='blog-body row mx-0 px-0'>
            {listView === "grid" ? (
              <>
                {lessonsList &&
                  getSort(lessonsList, "day").map((item, i) => (
                    <div
                      key={item._id}
                      className='blog-card col-md-4 col-sm-6 col-12 p-3'
                      onClick={(event) => handleNavigation(event, item._id)}
                    >
                      <BlogListCard
                        lesson={item}
                        items={items}
                        selectedBLog={selectedBLog}
                      />
                    </div>
                  ))}
              </>
            ) : (
              <div className='card-body col-12 '>
                <div className='row mx-0 d-flex justify-content-space-between mb-4'>
                  <div className='col-4 d-flex justify-content-end align-items-stretch flex-wrap mt-3'>
                    <input
                      type='text'
                      className=' form-control form-control-solid ps-15 '
                      placeholder='Search'
                    />
                  </div>
                </div>
                <BlogListTable
                  lessons={lessonsList}
                  items={items}
                  handleApprove={handleApprove}
                  selectedBLog={selectedBLog}
                />{" "}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default BlogDashBoard;
