import React from "react";

const learnCardDetails = [
  {
    number: "1",
    title: "",
    content: "",
  }
]

const WorkshopPageBodySection = () => {
  return (
    <>
      <div className="row mx-0 d-flex justify-content-center about-workshop">
        <div className="col-12 col-md-10">
          <div className="">
            <h1 className="text-center">About Workshop</h1>
            <p className="text-center">Full Stack Blockchain Development course is a live mentor-led certification program with by iNeuron. In this course you will learn the entire stack required to work in Permissionless Blockchain development. This course focuses on latest Blockchain industry standards like Ethereum Blockchain, Solidity, Decentralized Autonomous Organisations, Decentralized Finance, Non Fungible Tokens, Polygon Network, Polkadot Blockchain, Oracles along with complete development stack in Javascript and many more Blockchain concepts.</p>
          </div>
        </div>
      </div>
      <div className="row mx-0 d-flex justify-content-center what-you-learn-workshop">
        <div className="col-12 col-md-10">
          <div className="row mx-0">

          </div>
          {
            learnCardDetails

          }
          <div className="learn-card schadow">
            <span>1</span>
            <h6>Secrets of Good Design</h6>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default WorkshopPageBodySection