import axios from "axios";
import DatacodeConfig from "../core/config";

const baseURL = DatacodeConfig.baseURL;

// export const makePayment = async () => {
//   const stripe = await loadStripe('pk_test_51HLVfLKTsQcssoZDt1jx1GoisdUJtFXOqLtRZooh7nGM53WZxgNVE1jhlNsBG36fdKETLJ3UDplTMria89oVrgMG00CORLvyCs');
//   checkOutAPI().then((res) => {
//     console.log("session", res)
//     const session = res.data?.session
//     if (session) {

//       const result = stripe.redirectToCheckout({
//         sessionId: session?.id
//       })

//       if (result.error) {
//         console.error(result.error)
//       }
//     }
//   })

// }

export const makePayment = async ({ order, batch }) => {
  var options = {
    key: "",
    amount: 200000,
    currency: "INR",
    name: "30 Days Code Batch Enrollment",
    description: "Test Transaction",
    image: "https://i.ibb.co/5Y3m33n/test.png",
    order_id: order.id,
    handler: async (response) => {
      const body = { ...response };
      const validateResponse = await axios
        .post(`${baseURL}/dayscode/code_batch/payment/validate/${batch}`, body)
        .then((res) => {
          if (res.status === 200) {
            return { success: true, data: res.data };
          } else {
            return { success: false };
          }
        })
        .catch((error) => {
          console.log("payment Error", error);
        });
      const jsonResponse = validateResponse;
      console.log("jsonResponse", jsonResponse);
    },
    prefill: {
      name: "Datacode",
      email: "datacode.in@gmail.com",
      contact: "7400898402",
    },
    notes: {
      address: "Razorpay Corporate Office",
    },
    theme: {
      color: "#3399cc",
    },
  };

  const razorpay = new window.Razorpay(options);

  razorpay.on("payment.failed", function (response) {
    alert(response.error.code);
    alert(response.error.description);
    alert(response.error.source);
    alert(response.error.step);
    alert(response.error.reason);
    alert(response.error.metadata.order_id);
    alert(response.error.metadata.payment_id);
  });

  razorpay.open();
};
