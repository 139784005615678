import axios from "axios";
import React, { useEffect, useState } from "react";

const modules = [
  { module: "users", access: ["create", "read", "update", "delete"] },
  { module: "products", access: ["create", "read", "update", "delete"] },
  { module: "orders", access: ["create", "read", "update", "delete"] },
  { module: "settings", access: ["create", "read", "update", "delete"] },
  { module: "reports", access: ["create", "read", "update", "delete"] },
  { module: "customers", access: ["create", "read", "update", "delete"] },
  { module: "inventory", access: ["create", "read", "update", "delete"] },
  { module: "marketing", access: ["create", "read", "update", "delete"] },
  { module: "analytics", access: ["create", "read", "update", "delete"] },
  { module: "security", access: ["create", "read", "update", "delete"] },
];

const RoleForm = ({ isEdit }) => {
  const [roleName, setRoleName] = useState("");
  const [permissions, setPermissions] = useState(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (isEdit) {
      const fetchData = async () => {
        try {
          const response = await axios.get("/role/665f82703ace80e2f3ffecd5"); // Replace with your actual API URL
          const { role } = response.data;

          setRoleName(role.roleName);

          // Initialize permissions state
          const initialPermissions = modules.reduce((acc, module) => {
            acc[module.module] = module.access.reduce((a, permission) => {
              a[permission] =
                role.permissions
                  .find((p) => p.module === module.module)
                  ?.access.includes(permission) || false;
              return a;
            }, {});
            return acc;
          }, {});
          setPermissions(initialPermissions);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    } else {
      setPermissions(
        modules.reduce((acc, module) => {
          acc[module.module] = module.access.reduce((a, permission) => {
            a[permission] = false;
            return a;
          }, {});
          return acc;
        }, {}),
      );
    }
  }, []);

  const handleRoleNameChange = (e) => {
    setRoleName(e.target.value);
  };

  const handlePermissionChange = (module, permission) => {
    setPermissions((prevPermissions) => ({
      ...prevPermissions,
      [module]: {
        ...prevPermissions[module],
        [permission]: !prevPermissions[module][permission],
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formattedData = modules.map((module) => ({
      module: module.module,
      access: Object.entries(permissions[module.module])
        .filter(([_, value]) => value)
        .map(([key]) => key),
    }));
    try {
      const response = await axios.post("/role/create", {
        roleName,
        permissions: formattedData,
      }); // Assuming your API endpoint for creating roles
      console.log("Response:", response);
    } catch (err) {
      console.error(err);
      // Handle errors appropriately (e.g., display an error message)
    }
  };

  if (!permissions) {
    return <div>Loading...</div>; // Display a loading message while fetching data
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div>
          <label>
            Role Name:
            <input
              type='text'
              value={roleName}
              onChange={handleRoleNameChange}
            />
          </label>
        </div>
        {modules.map((module, moduleIndex) => (
          <div key={moduleIndex}>
            <h3>{module.module}</h3>
            {module.access.map((permission, permissionIndex) => (
              <label key={permissionIndex}>
                <input
                  type='checkbox'
                  checked={permissions[module.module][permission] || false}
                  onChange={() =>
                    handlePermissionChange(module.module, permission)
                  }
                />
                {permission}
              </label>
            ))}
          </div>
        ))}
        <button type='submit'>Submit</button>
      </form>
    </>
  );
};

export default RoleForm;
