import moment from "moment";
import React, { useEffect, useState } from "react";

import { IoLocationSharp } from "react-icons/io5";
import peopleImg from "../../../../../../assets/images/Containerpeople.png";
import { timeConvert } from "../../../../../../utils";
import { eventActionsController } from "./eventPageController";

const EventHeader = ({
  eventDetails,
  text_color,
  date_color,
  state,
  banner_img,
  handleEventType,
  userData,
}) => {
  const [eventActions, setEventActions] = useState({});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    let actions = eventActionsController(eventDetails, userData);
    setEventActions(actions);
  }, []);

  return (
    <>
      <div className='row  event-page-header mx-auto px-md-5 '>
        <div className='col-12 px-0'>
          <div className='row justify-content-around mx-2 px-2 mx-md-0 px-md-0 mx-md-5 mt-3'>
            <div className='col-12 col-md-12 p-2 p-md-3 pt-md-2 pt-0 pb-sm-3 border card-schadow rounded-corner  bg-white'>
              <div className='row'>
                <div className='col-12 col-md-6'>
                  <img
                    // height="500vh"
                    // width="auto"
                    src={
                      eventDetails?.eventImg ||
                      `https://res.cloudinary.com/datacode/image/upload/v1650287597/h2y3w1ftk24ticvyawtu.png`
                    }
                    alt='avatar'
                    loading='lazy'
                    className='my-md-0 img-fluid mx-auto card-schadow rounded-lg'
                  />
                </div>
                <div className='col-12 col-md-6'>
                  <div className='row mx-0 my-0 d-flex justify-content-between align-items-center h-100'>
                    <div className='d-flex flex-column'>
                      <h1 className='mt-2 event-heading'>
                        {eventDetails && eventDetails.title}
                      </h1>
                      <span className='d-flex event-host-heading align-items-center'>
                        <img src='/icon192.png' alt='' height={20} width={20} />
                        <span className='text-secondary ml-2'>
                          Presented by Datacode
                        </span>
                      </span>
                      <span className='event-date'>
                        <i className='far fa-calendar-alt mr-2 mt-3' />
                        <span>
                          {moment(
                            eventDetails && eventDetails.start_date,
                          ).format("dddd, MMMM Do, YYYY")}
                        </span>
                      </span>
                      <span className='event-date-time'>
                        <i className='far fa-clock mr-2' />
                        <span>
                          {`${timeConvert(
                            eventDetails && eventDetails.start_time,
                          )} IST `}
                        </span>
                      </span>
                      {eventDetails.address && (
                        <span className='event-location'>
                          <span>
                            <IoLocationSharp className='mr-2' size={"18px"} />
                          </span>
                          <span>{`${timeConvert(
                            eventDetails.address,
                          )} IST `}</span>
                        </span>
                      )}
                    </div>
                    <div className='row px-md-0 text-center px-0 mx-2 py-2 py-md-0 mt-3  justify-content-between w-100'>
                      <div className='event-calender px-0  col-1'>
                        <span className='event-cal-month'>
                          {moment(
                            eventDetails && eventDetails.start_date,
                          ).format("MMM")}
                        </span>
                        <span className='event-cal-day'>
                          {moment(
                            eventDetails && eventDetails.start_date,
                          ).format("D")}
                        </span>
                      </div>
                      <div className='mt-2 event-peoples col-8'>
                        <span>442 people are attending</span>
                        <img
                          src={peopleImg}
                          height={40}
                          width={"100%"}
                          alt=''
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className='col-12 col-md-5 col-sm-12 border card-schadow bg-white rounded-lg event-header-details'>
              <GetEventPostActionBox
                eventDetails={eventDetails}
                handleEventType={handleEventType}
                userData={userData}
                eventActions={eventActions}
              />

              <h6 className='mt-3 event-sub-heading'>
                {eventDetails && eventDetails.sub_title}
              </h6>
              <div className='mx-0 my-4'>
                {eventDetails.tags &&
                  eventDetails.tags.map((tag, i) => (
                    <span key={i} className='event-header-tag px-2'>
                      {tag}
                    </span>
                  ))}
                <span className='event-header-tag px-2'>
                  {eventDetails.state}
                </span>
                <span className='event-header-tag px-2'>
                  {eventDetails.event_type}
                </span>
              </div>
              <div className='d-flex my-4'>
                {eventDetails.category && (
                  <span className='event-catagory p-2 mr-3'>
                    <i className='fal fa-game-board-alt mr-2' />
                    {eventDetails.category}
                  </span>
                )}
                {eventDetails.event_platform && (
                  <span className='event-platform p-2'>
                    <i className='fal fa-video mr-2' />
                    {eventDetails.event_platform}
                  </span>
                )}
              </div>
              <div className='row d-flex my-4'>
                <span className='col-md-5 col-12 event-date-time pr-3'>
                  <div className=''>
                    <i className='far fa-calendar-alt mr-3' />
                    {moment(eventDetails && eventDetails.start_date).format(
                      "LL",
                    )}
                  </div>
                  <div className=''>
                    <i className='far fa-calendar-alt mr-3' />
                    {moment(eventDetails && eventDetails.end_date).format("LL")}
                  </div>
                </span>
                <span className='col-md-7 col-12 event-date-time border-left text-left text-md-center'>
                  <i className='far fa-clock mr-3' />
                  {`${timeConvert(
                    eventDetails && eventDetails.start_time,
                  )} IST - 
                    ${timeConvert(eventDetails && eventDetails.end_time)} IST `}
                </span>
                {eventDetails.address && (
                  <>
                    <span className='d-flex'>
                      <IoLocationSharp className='mt-1 mx-3' size={"20px"} />
                      <span className='col-md-10 col-12 event-location mx-0 px-0'>
                        {eventDetails.address}
                      </span>
                    </span>
                  </>
                )}
              </div>
            </div> */}
            {/* */}
          </div>
        </div>
      </div>
    </>
  );
};

export default EventHeader;
