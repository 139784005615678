import React from 'react'

const WorkshopHeroSection = () => {
  return (
    <>
      <div className="row mx-0 d-flex justify-content-center workshop-hero-section">
        <div className="col-10 border">

        </div>
      </div>
    </>
  )
}

export default WorkshopHeroSection