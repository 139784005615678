import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddBRoadmapDrawer from "./AddBRoadmapDrawer";
import {
  deleteRoadmapfromBatch,
  getBatchRoadmap,
} from "../../../actions/operations";
import RoadmapListCard from "./RoadmapListCard";

const RoadmapList = ({ batch, codeBatchDetails, codeBatchesLoading }) => {
  const dispatch = useDispatch();
  const { roadmapList } = useSelector((state) => state.dayscode) || {};

  const handelroadmapdelete = (id) => {
    dispatch(
      deleteRoadmapfromBatch({
        batch: batch,
        roadmap: id,
      }),
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    dispatch(getBatchRoadmap(batch));
  }, []);

  console.log(roadmapList);

  return (
    <>
      <div className='d-flex justify-content-between'>
        <h1>RoadMap</h1>
        <AddBRoadmapDrawer batch={batch} />
      </div>
      {roadmapList.length > 0 ? (
        <RoadmapListCard
          roadmapList={roadmapList}
          handelroadmapdelete={handelroadmapdelete}
        />
      ) : (
        <div>No roadMap to show</div>
      )}
    </>
  );
};

export default RoadmapList;
