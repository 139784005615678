import React, { Component } from "react";
import { Link } from "react-router-dom";
class PostCard extends Component {
  state = {};

  render() {
    return (
      <>
        <div className='card post-card  border border-white shadow mb-3'>
          <Link to={this.props.link}>
            <img
              className=' card-img-top mx-auto img-fluid img-circle d-block'
              src={this.props.imgsource}
              alt='avatar'
            />
          </Link>
          <div className='card-body'>
            <Link to={this.props.link}>
              <h5 className='card-title mb-0' style={{ color: "#072C71" }}>
                {this.props.title}
              </h5>
            </Link>
            <p className='card-text'>{this.props.paragraph}</p>
          </div>
          <div className='card-footer border border-white bg-white shadow pt-1'>
            <div className='row m-0'>
              <div className='col p-0 align-itmes-center'>
                <Link to={this.props.link}>
                  <span className='align-itmes-center'>
                    Start Evaluating Yourself{" "}
                    <i className='fal fa-long-arrow-right' />
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PostCard;
