import React from "react";

const GistBlockPreview = ({ item }) => {
  const gist_parser = (item) => {
    var regExp = /https?:\/\/gist\.github\.com\/[^\/]+\/([^\/]+)/;
    var match = item && item && item.match(regExp);
    return match && match[1] ? match[1] : false;
  };

  const gistId = gist_parser(item);

  return (
    <>
      <div className='my-5'>
        {gistId ? (
          <div className='my-3'>
            <script
              src={`https://gist.github.com/${item.split("/")[4]}/${gistId}.js`}
            ></script>
          </div>
        ) : (
          <p>Invalid Gist URL</p>
        )}
      </div>
    </>
  );
};

export default GistBlockPreview;
