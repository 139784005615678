import React, { useState } from "react";
import Combobox from "react-widgets/Combobox";

const errorMessage = (error, warning) =>
  (error && <span className='field-error pl-1'>{error}</span>) ||
  (warning && <span className='field-error pl-1'>{warning}</span>);

export const RenderInputField = ({
  input,
  label,
  placeholder,
  isDisabled,
  type,
  inputClass,
  isRequired,
  isAutoFocus,
  meta: { touched, error, warning },
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className='text-left mt-3 mt-md-4'>
      <label className='form-label'>
        {label}
        {isRequired && <span className='required-marker text-danger'> *</span>}
      </label>
      {type === "textarea" ? (
        <textarea
          {...input}
          placeholder={placeholder}
          type={type}
          autoFocus={isAutoFocus}
          className='form-input-field-textarea'
          disabled={isDisabled}
        />
      ) : (
        <div className='input-group d-flex align-items-center'>
          <input
            {...input}
            placeholder={placeholder}
            type={
              type === "password" ? (showPassword ? "text" : "password") : type
            }
            autoFocus={isAutoFocus}
            className={
              type === "password" ? "form-control" : "form-input-field"
            }
            disabled={isDisabled}
          />
          {type === "password" && (
            <div
              className='input-group-append p-2'
              onClick={toggleShowPassword}
            >
              <i
                className={`fa ${showPassword ? "fa-eye-slash" : "fa-eye"}`}
                aria-hidden='true'
              />
            </div>
          )}
        </div>
      )}
      {touched && errorMessage(error, warning)}
    </div>
  );
};

export const renderSelectField = ({
  input,
  label,
  options,
  placeholder,
  dataKey,
  textField,
  meta: { touched, error, warning },
}) => {
  return (
    <div className='text-left mt-3 mt-md-4 form-set'>
      <label className='form-label'>{label}</label>
      <Combobox
        data={options}
        dataKey={dataKey}
        textField={textField}
        placeholder={placeholder}
        value={input.value}
        onChange={(value) => input.onChange(value)}
      />
      {touched && errorMessage(error, warning)}
    </div>
  );
};

export const renderCheckboxField = ({
  input,
  label,
  meta: { touched, error, warning },
}) => {
  return (
    <>
      <div className='option-pair text-left d-flex align-items-start'>
        <input
          {...input}
          type='checkbox'
          value={input.value}
          onChange={(value) => input.onChange(value)}
          checked={input.value}
          className='cursor-pointer'
        />
        <label className='ml-2 mb-0 form-label cursor-pointer'>{label}</label>
      </div>
      {touched && errorMessage(error, warning)}
    </>
  );
};

export const renderMultiCheckboxField = ({
  input,
  meta: { touched, error, warning },
  required,
  options,
}) => {
  return (
    <div className='option-pair align-items-center text-left'>
      {options &&
        options.map((option, i) => (
          <label
            htmlFor={i}
            className='form-label d-flex align-items-center'
            key={i}
          >
            <input
              type='checkbox'
              name={`${input.value}[${i}]`}
              id={i}
              value={option.value}
              checked={input.value.indexOf(option.value) !== -1}
              onChange={(event) => {
                const newValue = [...input.value];
                if (event.target.checked) {
                  newValue.push(option.value);
                } else {
                  newValue.splice(newValue.indexOf(option.value), 1);
                }

                return input.onChange(newValue);
              }}
            />
            <p id={i} className='mb-0 ml-3'>
              {option.label}
            </p>
          </label>
        ))}
      {touched && errorMessage(error, warning)}
    </div>
  );
};

export const renderRadioField = ({
  input,
  label,
  isDisabled,
  meta: { touched, error, warning },
}) => {
  return (
    <div className={`d-flex my-2 align-items-center`}>
      <input
        {...input}
        type='radio'
        value={input.value}
        onChange={(value) => input.onChange(value)}
        onBlur={() => input.onBlur(input.value)}
        id={input.value}
        disabled={isDisabled}
        className='pointer'
      />
      <label htmlFor={input.value} className='pointer ml-2 form-label mb-0'>
        <p className='mb-0' id={input.value}>
          {label}
        </p>
      </label>
      {touched && errorMessage(error, warning)}
    </div>
  );
};

export const renderNumberRadioField = ({
  input,
  label,
  isDisabled,
  meta: { touched, error, warning },
}) => {
  return (
    <div className={`justify-content-center align-items-center`}>
      <input
        {...input}
        type='radio'
        value={input.value}
        onChange={(value) => input.onChange(value)}
        onBlur={() => input.onBlur(input.value)}
        id={input.value}
        disabled={isDisabled}
        className='pointer'
      />
      <label htmlFor={input.value} className='pointer form-label mb-0'>
        <p className='mb-0 text-center' id={input.value}>
          {label}
        </p>
      </label>
      {touched && errorMessage(error, warning)}
    </div>
  );
};

export const renderMultiRadioField = ({
  input,
  meta: { touched, error, warning },
  items,
}) => {
  return (
    <div className=''>
      {items &&
        items.map((item, i) => (
          <label
            htmlFor={input.value}
            className='form-label d-flex align-items-center'
            key={i}
          >
            <input
              {...input}
              type='radio'
              value={item.value}
              onChange={(value) => input.onChange(value)}
              checked={input.value == item.value}
              id={input.value}
            />
            <p className='mb-0 ml-3'>{item.label}</p>
          </label>
        ))}
      {touched && errorMessage(error, warning)}
    </div>
  );
};

export const renderSwitchField = ({
  input,
  meta: { touched, error, warning },
}) => {
  return (
    <>
      <input {...input} type='checkbox' />
      <span className='slider round'></span>
      {touched && errorMessage(error, warning)}
    </>
  );
};

export const renderEmojiRadioField = ({
  input,
  label,
  isDisabled,
  meta: { touched, error, warning },
  iconClass,
}) => {
  return (
    <div className='d-inline text-center'>
      <div className='d-flex align-items-center radio-emoji-input'>
        <label htmlFor={input.value} className='ml-2 form-label mb-0'>
          <input
            {...input}
            type='radio'
            value={input.value}
            onChange={(value) => input.onChange(value)}
            onBlur={() => input.onBlur(input.value)}
            id={input.value}
            disabled={isDisabled}
          />
          <i className={`h4 mb-0 ${iconClass}`} id={input.value} />
        </label>
      </div>
      {touched && errorMessage(error, warning)}
    </div>
  );
};
