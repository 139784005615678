import MyProfile from "./MyProfile";

import { connect } from "react-redux";

import { getUserProfile, followUser, unfollowUser } from "../../../actions";

const mapStateToProps = ({ app, user }) => ({
  userProfile: user.userProfile,
  userProfileLoading: user.userProfileLoading,
  currentUser: user.currentUser ? user.currentUser : {},
});

const mapDispatchToProps = {
  getUserProfile,
  followUser,
  unfollowUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
