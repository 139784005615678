import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { RenderInputField } from "../../../../../../shared_components/ReduxFormFields";
import { required } from "../../../../../../utils/validators";
import {
  createCodeBatch,
  editCodeBatch,
  getCodeBatchDetails,
} from "../../../actions";
import LayoutContainer from "../LayoutContainer";

const CreateMentor = ({ reset, handleSubmit, submitting, initialize }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const [isEdit, setEdit] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (id !== "new") {
      dispatch(getCodeBatchDetails(id)).then((res) => {
        if (res && res.success && res.mentor) {
          const data = res.mentor;
          data["status"] = res.batch && res.batch.status;
          initialize(data);
          setEdit(true);
        }
      });
    }
  }, []);

  const onSubmit = (values) => {
    const data = {
      ...values,
    };
    if (isEdit) {
      dispatch(editCodeBatch(data)).then((res) => {
        if (res.success) {
          history.push(`/admin/dayscode/batch_dashboard/${res.batch._id}`);
        }
      });
    } else {
      dispatch(createCodeBatch(data));
    }
  };

  return (
    <>
      <LayoutContainer>
        <div className='row mx-0 solution-nav'>
          <div className='col-md-8 col-6 mx-0 d-flex p-2 align-items-center'>
            <h4 className='py-md-3 py-2 mb-0'>
              {isEdit ? "Edit Mentor" : "Create New Batch"}
            </h4>
          </div>
          <div className='col-md-4 col-6 d-flex justify-content-end align-items-center'>
            <button
              onClick={() => history.push("/admin/dayscode/mentors")}
              type='button'
              className='btn enroll-small-btn'
            >
              <small>Mentors List</small>
            </button>
          </div>
        </div>
        <div className='row mx-0'>
          <div className='col-12'>
            <form
              className='py-3 px-md-5 px-1'
              onSubmit={handleSubmit(onSubmit)}
            >
              <small>
                Lorazepam, sold under the brand name Ativan among others, is a
                benzodiazepine medication. It is used to treat anxiety
                disorders, trouble sleeping, severe agitation, active seizures
                including status epilepticus, alcohol withdrawal, and
                chemotherapy-induced nausea and vomiting
              </small>
              <Field
                type='text'
                name='title'
                label='Batch Title'
                placeholder=''
                component={RenderInputField}
                validate={[required]}
              />
              <div className='row m-0 border rounded-lg my-2 p-md-3 p-0'>
                <div className='col-md-6 col-12 mt-3'>
                  <div className='row'>
                    <div className='col-6'></div>
                    <div className='col-6'></div>
                  </div>
                </div>
                {/* <div className="my-3">
                  <label className="form-label text-left pt-5">Constrains {`(${constrainsText.length - 8})`}</label>
                  <div className="mb-3 border ">
                    {(isEdit && problemDetails && problemDetails.constrains) &&
                      <TextEditor handleTextEditor={handleConstrainsText} text={problemDetails?.constrains ? constrainsText : ''} />
                    }
                    {(!isEdit) &&
                      <TextEditor handleTextEditor={handleConstrainsText} text={problemDetails?.constrains ? constrainsText : ''} />
                    }
                  </div>
                </div> */}
                <div className='row my-4'>
                  <div className='col-12 text-right'>
                    <button
                      type='submit'
                      className='btn custom-button'
                      disabled={submitting}
                    >
                      <span>{isEdit ? "Save" : "Create New Mentor"}</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </LayoutContainer>
    </>
  );
};

export default reduxForm({
  form: "create-mentor",
})(CreateMentor);
