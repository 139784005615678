import React, { Component } from "react";
import PostCard from "../../PostCard";
class IndexBooks extends Component {
  state = {};
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <>
        <h1>Index Books</h1>
        <PostCard
          imgsource='http://res.cloudinary.com/datacode/image/upload/v1731157824/bldmtt4wnzer6olgdd1y.jpg'
          title='Programming Example Questions'
          paragraph='Programming Example Qustions fromm Beginners to Advance Study'
          link='/python_books'
        />
      </>
    );
  }
}

export default IndexBooks;
