import { faChalkboardTeacher, faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import RightSideSection from "../../../right_section/RightSideSection";
import PostCard from "../../PostCard";
import InterviewQuestionCardCarousel from "./InterviewQuestionCardCarousel";

import { Helmet } from "react-helmet";
import Technologies from "../../../right_section/Technologies";
class IndexInterviewQuestions extends Component {
  state = {};
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const description =
      "Seeking for Hiring?Confuse with preparation?Don’t worry we are here with the best material which makes your luck good ,it’s time to reserve your seat in the tech world.";
    const title = "Interview Preparation Questions | Datacode.in";
    return (
      <>
        <Helmet>
          <title>{title}</title>
          <meta
            data-react-helmet='true'
            name='description'
            content={description}
          ></meta>
          <meta
            data-react-helmet='true'
            http-equiv='cleartype'
            content='on'
          ></meta>
          <meta
            data-react-helmet='true'
            name='apple-mobile-web-app-capable'
            content='yes'
          ></meta>
          <meta
            data-react-helmet='true'
            name='robots'
            content='max-snippet:-1, max-image-preview:large, max-video-preview:-1'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:locale'
            content='en_US'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:type'
            content='website'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:title'
            content={title}
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:description'
            content={description}
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:url'
            content='https://www.datacode.in/'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:site_name'
            content='Datacode.in'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:image'
            content='https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:image:secure_url'
            content='https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:image:width'
            content='400'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:image:height'
            content='400'
          ></meta>
          <meta
            data-react-helmet='true'
            name='keywords'
            content='programming,coding,online, code online, online judge, practice, run code, test, expected outcome, programming competition, programming contest, online programming, online computer programming'
          ></meta>
          <meta
            data-react-helmet='true'
            name='twitter:card'
            content='summary_large_image'
          ></meta>
          <meta
            data-react-helmet='true'
            name='twitter:description'
            content={description}
          ></meta>
          <meta
            data-react-helmet='true'
            name='twitter:title'
            content={title}
          ></meta>
          <meta
            data-react-helmet='true'
            name='twitter:site'
            content='@datacode_in'
          ></meta>
          <meta
            data-react-helmet='true'
            name='twitter:creator'
            content='@datacode_in'
          ></meta>
          <meta
            data-react-helmet='true'
            name='twitter:image'
            content='https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png'
          ></meta>
          <link
            data-react-helmet='true'
            rel='shortlink'
            href='https://www.datacodoe.in/'
          ></link>
          <link
            data-react-helmet='true'
            rel='dns-prefetch'
            href='//www.datacode.in'
          ></link>
          <link
            data-react-helmet='true'
            rel='dns-prefetch'
            href='//s.w.org'
          ></link>
          <link
            data-react-helmet='true'
            rel='alternate'
            type='application/rss+xml'
            title='Datacode'
            href='https://www.datacode.in/'
          ></link>
          <link
            data-react-helmet='true'
            rel='canonical'
            href='https://www.datacode.in/'
          ></link>
        </Helmet>
        <div className='row m-0 px-3'>
          <div className='col-1'></div>
          <div className='col-md-8 mt-5'>
            <h1
              className='text-center mb-4 rounded p-2'
              style={{ backgroundColor: "#30006b", color: "#ffffff" }}
            >
              <FontAwesomeIcon icon={faChalkboardTeacher} /> Interview Questions
            </h1>
            <h5 className='text-center'>
              Seeking for Hiring?Confuse with preparation?Don’t worry we are
              here with the best material which makes your luck good ,it’s time
              to reserve your seat in the tech world.
            </h5>
            <Link
              to='/home'
              className='badge mr-1 my-5'
              style={{ backgroundColor: "#06CB6C" }}
            >
              <FontAwesomeIcon icon={faHome} size='2x' color='#072C71' />
            </Link>

            <div className='card-deck m-2'>
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731170132/es1mpldbdqg38ip5r6w3.jpg'
                title='DBMS Interview Questions'
                paragraph="Confidence is always directly proportional to preparation. Let's take steps towards your dream job. "
                link='/dbms_interview_question'
              />
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731170154/rt76k8uyllhsbjl5xjgy.jpg'
                title='OOPs Interview Questions'
                paragraph="Confidence is always directly proportional to preparation. Let's take steps towards your dream job. "
                link='/oops_interview_question'
              />
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731170179/j1iq6285e1nfmirw2km2.jpg'
                title='Python Interview Questions'
                paragraph="Confidence is always directly proportional to preparation. Let's take steps towards your dream job."
                link='/python_interview_question'
              />
            </div>
            <div className='card-deck m-2'>
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731170203/fkefw7t77pukny1volgj.jpg'
                title='Java Interview Questions'
                paragraph="Confidence is always directly proportional to preparation. Let's take steps towards your dream job. "
                link='/java_interview_question'
              />
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731170236/coevrqmgirxkysbsik8v.jpg'
                title='C Programming Interview Questions'
                paragraph="Confidence is always directly proportional to preparation. Let's take steps towards your dream job. "
                link='/c_interview_question'
              />
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731170260/r0r8398lepi0m3hs5bso.jpg'
                title='C++ Interview Questions'
                paragraph="Confidence is always directly proportional to preparation. Let's take steps towards your dream job."
                link='/cpp_interview_question'
              />
            </div>
            <div className='card-deck m-2'>
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731170286/z9o367nfcril0xwn7tnb.jpg'
                title='Cloud Computing Interview Questions'
                paragraph="Confidence is always directly proportional to preparation. Let's take steps towards your dream job. "
                link='/cloud_computing_interview_question'
              />
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731170313/ju9ptq2k8yidokfhjlp7.jpg'
                title='Computer Networking Interview Questions'
                paragraph="Confidence is always directly proportional to preparation. Let's take steps towards your dream job. "
                link='/cn_interview_question'
              />
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731170335/vz0wh6nxbiaiabkix73p.jpg'
                title='DataStructure Interview Questions'
                paragraph="Confidence is always directly proportional to preparation. Let's take steps towards your dream job."
                link='/datastructre_interview_question'
              />
            </div>
            <div className='card-deck m-2'>
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731170362/jnxyhexlcf7ye3mdysua.jpg'
                title='Operating System Interview Questions'
                paragraph="Confidence is always directly proportional to preparation. Let's take steps towards your dream job. "
                link='/os_interview_question'
              />
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731170387/vnalbpcrhyvfmusd6aok.jpg'
                title='RDBMS Interview Questions'
                paragraph="Confidence is always directly proportional to preparation. Let's take steps towards your dream job. "
                link='/rdbms_interview_question'
              />
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731170424/pxlpnseiywteaprmwwsp.jpg'
                title='Software Development Life Cycle Interview Questions'
                paragraph="Confidence is always directly proportional to preparation. Let's take steps towards your dream job."
                link='/sdlc_interview_question'
              />
            </div>

            <div className='card-deck m-2'>
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731170464/px4fgazltatd9rdxtuab.jpg'
                title='HTML Interview Questions'
                paragraph="Confidence is always directly proportional to preparation. Let's take steps towards your dream job. "
                link='/html_interview_question'
              />
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731170487/ng8nnss152odzqdxqenn.jpg'
                title='JavaScript Interview Questions'
                paragraph="Confidence is always directly proportional to preparation. Let's take steps towards your dream job. "
                link='/js_interview_question'
              />
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731170509/wutqzfahxzbouflz2hzq.jpg'
                title='REACT Interview Questions'
                paragraph="Confidence is always directly proportional to preparation. Let's take steps towards your dream job."
                link='/react_interview_question'
              />
            </div>
            <div className='card-deck m-2'>
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731170532/pncojgckrzcirzp48vv6.jpg'
                title='Angular Interview Questions'
                paragraph="Confidence is always directly proportional to preparation. Let's take steps towards your dream job. "
                link='/angular_interview_question'
              />
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731170554/wjapxd501pke9zwv0enp.jpg'
                title='Android Interview Questions'
                paragraph="Confidence is always directly proportional to preparation. Let's take steps towards your dream job. "
                link='/android_interview_question'
              />
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731170578/my17zfgbwx8f2df0rvn6.jpg'
                title='Bootstrap Coming Soon'
                paragraph="Confidence is always directly proportional to preparation. Let's take steps towards your dream job."
                link='/bootstrap_interview_question'
              />
            </div>
            <div className='card-deck m-2'>
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731170602/p5g64gerqugmwnfvbpoc.jpg'
                title='SQL Interview Questions'
                paragraph="Confidence is always directly proportional to preparation. Let's take steps towards your dream job."
                link='/sql_interview_question'
              />
              <PostCard
                imgsource='http://res.cloudinary.com/datacode/image/upload/v1731170627/kvczrhdzyamandxl2f4y.jpg'
                title='Testing Interview Questions'
                paragraph="Confidence is always directly proportional to preparation. Let's take steps towards your dream job. "
                link='/testing_interview_question'
              />
            </div>

            <h4 className='text-secondary mt-3'>
              For More Placment Preparation
            </h4>
            <InterviewQuestionCardCarousel />
          </div>
          <div className='col-md-3'>
            <RightSideSection />
            <Technologies />
          </div>
        </div>
      </>
    );
  }
}

export default IndexInterviewQuestions;
