import React from 'react'
import AddParticipantDrawer from '../../../../../../shared_components/AddParticipantDrawer'
import { useDispatch } from 'react-redux'
import { addParticipantToBatch, deleteParticipantFromBatch } from '../../../actions'
import ParticipantListCard from './ParticipantListCard'

const ParticipantList = ({ batch, codeBatchDetails, codeBatchesLoading }) => {

    const dispatch = useDispatch()

    const handleSelectedParticipant = (participantId) => {
        dispatch(addParticipantToBatch({ batch: batch, participant: participantId }))

    }

    const handleDeleteFromBatch = (participantId) => {
        dispatch(deleteParticipantFromBatch({ batch: batch, participant: participantId }))
    }

    return (
        <>
            <div className='d-flex justify-content-between'>
                <h1>Participants</h1>
                <AddParticipantDrawer batch={batch} btnName="Add Participant" handleSelectedParticipant={handleSelectedParticipant} />
            </div>
            {
                codeBatchDetails?.participants && codeBatchDetails.participants.map((participant) => (
                    <ParticipantListCard participant={participant} handleDeleteFromBatch={handleDeleteFromBatch} />
                ))
            }
        </>
    )
}

export default ParticipantList