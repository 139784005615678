import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { PiChalkboardTeacher } from "react-icons/pi";
import { MdPersonAddAlt } from "react-icons/md";
import { GiTeacher } from "react-icons/gi";

import { setSettingActiveTab } from "../../actions";

const SettingsDashboardNav = ({ setSettingActiveTab, activeTab }) => {
  const [activeLink, setActiveLink] = useState();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setActiveLink(window.location.hash);
  }, []);

  const authAdminGuard = () => {
    var localUser = JSON.parse(localStorage.getItem("userDetails"));
    return localUser &&
      localUser.userName === process.env.REACT_APP_ADMIN_USERNAME &&
      localUser.email === process.env.REACT_APP_ADMIN_EMAIL
      ? true
      : false;
  };

  return (
    <>
      <div className='row m-0'>
        <div className='col-12 px-0'>
          <div className='px-0 code-setting-side-nav'>
            <ul>
              {authAdminGuard() && (
                <li>
                  <Link to='/admin/dayscode/batches'>
                    <span
                      onClick={() => setSettingActiveTab("addProblems")}
                      className={
                        window.location.hash === "#/admin/dayscode/batches"
                          ? "active"
                          : ""
                      }
                    >
                      <GiTeacher className='mr-2' /> Batches
                    </span>
                  </Link>
                </li>
              )}
              {authAdminGuard() && (
                <li>
                  <Link to='/admin/dayscode/mentors'>
                    <span
                      onClick={() => setSettingActiveTab("mentors")}
                      className={
                        window.location.hash === "#/admin/dayscode/mentors"
                          ? "active"
                          : ""
                      }
                    >
                      <PiChalkboardTeacher className='mr-2' /> Mentors
                    </span>
                  </Link>
                </li>
              )}
              {authAdminGuard() && (
                <li>
                  <Link to='/admin/dayscode/mentor/new'>
                    <span
                      onClick={() => setSettingActiveTab("addmentor")}
                      className={
                        window.location.hash === "#/admin/dayscode/mentor/new"
                          ? "active"
                          : ""
                      }
                    >
                      <MdPersonAddAlt className='mr-2' />
                      Create Mentor
                    </span>
                  </Link>
                </li>
              )}
              {authAdminGuard() && (
                <li>
                  <Link to='/admin/dayscode/problem/new'>
                    <span
                      onClick={() => setSettingActiveTab("addProblems")}
                      className={
                        window.location.hash === "#/admin/dayscode/problem/new"
                          ? "active"
                          : ""
                      }
                    >
                      <i className='fa fa-code-merge mr-2' />
                      Add Problem
                    </span>
                  </Link>
                </li>
              )}
              <li>
                <Link to='/admin/dayscode/problems'>
                  <span
                    onClick={() => setSettingActiveTab("problemsList")}
                    className={
                      window.location.hash === "#/admin/dayscode/problems"
                        ? "active"
                        : ""
                    }
                  >
                    <i className='fa fa-code mr-2' />
                    Problems List
                  </span>
                </Link>
              </li>
              <li>
                <Link to='/admin/dayscode/submissions'>
                  <span
                    onClick={() => setSettingActiveTab("submissionsList")}
                    className={
                      window.location.hash === "#/admin/dayscode/submissions"
                        ? "active"
                        : ""
                    }
                  >
                    <i className='fas fa-sitemap mr-2' />
                    Submissions List
                  </span>
                </Link>
              </li>
              <li>
                <Link to='/admin/dayscode/batch_users'>
                  <span
                    onClick={() => setSettingActiveTab("batchUsers")}
                    className={
                      window.location.hash === "#/admin/dayscode/batch_users"
                        ? "active"
                        : ""
                    }
                  >
                    <i className='fas fa-users mr-2' />
                    Batch Users List
                  </span>
                </Link>
              </li>
              <li>
                <Link to='/admin/dayscode/session_attendance'>
                  <span
                    onClick={() => setSettingActiveTab("sessionAttendance")}
                    className={
                      window.location.hash ===
                      "#/admin/dayscode/session_attendance"
                        ? "active"
                        : ""
                    }
                  >
                    <i className='fas fa-poll-people mr-2' />
                    Session Attendance
                  </span>
                </Link>
              </li>
              {/* <li >
                <Link to="/admin/dayscode/lesson/new">
                  <span
                    onClick={() => setSettingActiveTab('add_lesson')}
                    className={window.location.hash === "#/admin/dayscode/lesson/new" ? "active" : ""}
                  >
                    <i className="fal fa-file-plus mr-2" />Add Lesson
                  </span>
                </Link>
              </li>
              <li >
                <Link to="/admin/dayscode/lessons">
                  <span
                    onClick={() => setSettingActiveTab('lessons_list')}
                    className={window.location.hash === "#/admin/dayscode/lessons" ? "active" : ""}
                  >
                    <i className="fal fa-file-alt mr-2" />Lessons List
                  </span>
                </Link>
              </li> */}
              {authAdminGuard() && (
                <li>
                  <Link to='/admin/dayscode/users/3'>
                    <span
                      onClick={() => setSettingActiveTab("users")}
                      className={
                        window.location.hash === "#/admin/dayscode/users"
                          ? "active"
                          : ""
                      }
                    >
                      <i className='fas fa-users-cog mr-2' />
                      Users List
                    </span>
                  </Link>
                </li>
              )}
              {authAdminGuard() && (
                <li>
                  <Link to='/admin/dayscode/lesson/new'>
                    <span
                      onClick={() => setSettingActiveTab("add_lesson")}
                      className={
                        window.location.hash === "#/admin/dayscode/lesson/new"
                          ? "active"
                          : ""
                      }
                    >
                      <i className='fas fa-file-plus mr-2' />
                      Add Lesson
                    </span>
                  </Link>
                </li>
              )}
              {authAdminGuard() && (
                <li>
                  <Link to='/admin/dayscode/lessons'>
                    <span
                      onClick={() => setSettingActiveTab("lessons_list")}
                      className={
                        window.location.hash === "#/admin/dayscode/lessons"
                          ? "active"
                          : ""
                      }
                    >
                      <i className='fas fa-file-alt mr-2' />
                      Lessons List
                    </span>
                  </Link>
                </li>
              )}
              {authAdminGuard() && (
                <li>
                  <Link to='/admin/dayscode/quiz_challenge/new'>
                    <span
                      onClick={() => setSettingActiveTab("add_quiz_challenge")}
                      className={
                        window.location.hash ===
                        "#/admin/dayscode/quiz_challenge/new"
                          ? "active"
                          : ""
                      }
                    >
                      <i className='fas fa-plus mr-2' />
                      Add Quiz Challenge
                    </span>
                  </Link>
                </li>
              )}
              {authAdminGuard() && (
                <>
                  <li>
                    <Link to='/admin/dayscode/quiz_challenges'>
                      <span
                        onClick={() =>
                          setSettingActiveTab("quiz_challenges_list")
                        }
                        className={
                          window.location.hash ===
                          "#/admin/dayscode/quiz_challenges"
                            ? "active"
                            : ""
                        }
                      >
                        <i className='fas fa-list mr-2' />
                        Quiz Challenges List
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to='/admin/dayscode/feedbacks'>
                      <span
                        onClick={() => setSettingActiveTab("feedbacks")}
                        className={
                          window.location.hash === "#/admin/dayscode/feedbacks"
                            ? "active"
                            : ""
                        }
                      >
                        <i className='fal fa-smile-plus mr-2' />
                        Users Feedbacks
                      </span>
                    </Link>
                  </li>
                </>
              )}
              <li>
                <Link to='/admin/dayscode/notification'>
                  <span
                    onClick={() => setSettingActiveTab("notification")}
                    className={
                      window.location.hash === "#/admin/dayscode/notification"
                        ? "active"
                        : ""
                    }
                  >
                    <i className='fal fa-bell-plus mr-2' />
                    Notifications
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ dayscode }) => ({
  activeTab: dayscode.activeTab,
});

const mapDispatchToProps = {
  setSettingActiveTab,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SettingsDashboardNav);
