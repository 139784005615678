import React from "react";
import CustomStarRating from "../../../../../../shared_components/CustomStarRating";

const WorkshopCard = ({
  item
}) => {
  return (
    <>
      <div className="row mx-0 p-3">
        <div className="col-12 px-0">
          <div className="">
            <img
              className="img-fluid"
              loading="lazy"
              src={item.img}
              alt="avatar"
            />
          </div>
          <div className="my-3 d-flex justify-content-between">
            <span>
              {item.days}
            </span>
            <span className="p-2 badge badge-primary">
              {item.category}
            </span>
          </div>
          <div className="">
            <h1>{item.title}</h1>
          </div>
          <div className="d-flex justify-content-between speaker-section">
            <div className="">
              <img
                className="rounded-circle border"
                height="40"
                width="40"
                loading="lazy"
                src={require(`../../../../../../assets/images/svg/monolog.svg`)}
                alt="avatar"
              />
            </div>
            <div className="">
              <span>{item.students}</span>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center rating">
            <div className="rating">
              {/* <span>{item.rating}</span> */}
              <CustomStarRating />
            </div>
            <div className="btn btn-primary">
              Enroll Now
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default WorkshopCard