import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import Combobox from "react-widgets/Combobox";

const statusOptions = [
  { status: "pending", value: 0 },
  { status: "accept", value: 3 },
  { status: "waiting", value: 1 },
  { status: "onhold", value: 2 },
  { status: "reject", value: 4 },
  { status: "flag", value: 5 },
  { status: "stage-1", value: 6 },
  { status: "stage-2", value: 7 },
]

const tableAction = (row, handleStatus, handleDeleteAction) => {
  return (
    <div className='text-left d-flex'>
      {/* <i type="button" className="mr-2 fas fa-user-check"
        onClick={() => handleStatus(row._id, 'Approve')}
      />
      <i type="button" className="mr-2 fas fa-user-times"
        onClick={() => handleStatus(row._id, 'Reject')}
      /> */}
      <Combobox
        data={statusOptions}
        dataKey={"value"}
        textField='status'
        placeholder={"Select Status"}
        value={{ status: row.status }}
        onChange={(value) => handleStatus(value, row)}
      />
    </div>
  )
}
export const getColumns = (handleStatus, handleDeleteAction) => [
  {
    dataField: "_id",
    text: "",
    align: "center",
    verticalAlign: "center",
    headerAlign: "center",
    headerClasses: "table-header",
    formatter: (cell, row) => (
      <img
        className="rounded-circle border"
        height="40"
        width="40"
        loading="lazy"
        src={row.imgUrl ? row.imgUrl : require(`../../../../assets/images/svg/monolog.svg`)}
        alt="avatar"
      />
    )
  },
  {
    dataField: "",
    text: "Actions",
    sort: true,
    style: { color: "#757575" },
    align: "left",
    verticalAlign: "center",
    headerAlign: "left",
    headerClasses: "table-header action",
    formatter: (cell, row) => (
      <span>
        {tableAction(row, handleStatus, handleDeleteAction)}
      </span>
    )
  },
  {
    dataField: "email",
    text: "Email",
    sort: true,
    style: { color: "#757575" },
    headerClasses: "table-header status",
    formatter: (cell, row) => (
      <span>
        <Link target="_blank" to={`/admin/club/application/${row._id}`}>{row.email}</Link>
      </span>
    ),
  },
  {
    dataField: "name",
    text: "Name",
    sort: true,
    headerClasses: "table-header name",
    style: { color: "#757575" },
  },
  {
    dataField: "gender",
    text: "Gender",
    sort: true,
    headerClasses: "table-header name",
    style: { color: "#757575" },
  },
  {
    dataField: "phone",
    text: "Phone",
    sort: true,
    style: { color: "#757575" },
    align: "left",
    headerAlign: "left",
    headerClasses: "table-header group-name",
  },
  {
    dataField: "referralName",
    text: "Referral",
    style: { color: "#757575" },
    align: "left",
    headerAlign: "left",
    headerClasses: "table-header group-name",
  },
  {
    dataField: "university",
    text: "University",
    sort: true,
    style: { color: "#757575" },
    align: "left",
    headerAlign: "left",
    headerClasses: "table-header group-name",
  },
  {
    dataField: "createdAt",
    text: "Application Date",
    sort: true,
    style: { color: "#757575" },
    align: "left",
    headerAlign: "left",
    headerClasses: "table-header group-name",
    formatter: (cell, row) => (
      <span>
        {row.createdAt === null
          ? "Invalid date"
          : moment(row.createdAt).format("LLL")}
      </span>
    ),
  },
  {
    dataField: "",
    text: "",
    sort: true,
    style: { color: "#757575" },
    align: "center",
    headerAlign: "center",
    headerClasses: "table-header",
    formatter: (cell, row) => (
      <span>
        <i type="button" className="mr-2 far fa-trash"
          onClick={() => handleDeleteAction(row._id)}
        />
      </span>
    )
  },
];