import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import LayoutContainer from "../../LayoutContainer";
import CustomLoader from "../../../../../../../shared_components/CustomLoader";
import { getSort } from "../../../../../../../utils";
import DeleteLessonModal from "../../Lesson/LessonsList/DeleteLessonModal";

const QuizChallengesList = ({
  getQuizChallenges,
  removeQuizChallenge,
  problemsListLoading,
  quizChallengesList,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [tempDeleteId, setTempDeleteId] = useState();
  const [status, setStatus] = useState("public");
  const history = useHistory();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    getQuizChallenges(status);
  }, [status]);

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const handleRemoveLesson = () => {
    removeQuizChallenge(tempDeleteId).then((res) => {
      if (res) {
        setOpenModal(!openModal);
        console.log(res, "res");
      }
    });
  };

  const deleteLesson = (id) => {
    setTempDeleteId(id);
    setOpenModal(!openModal);
  };

  return (
    <>
      <LayoutContainer>
        <div className='row mx-0 solution-nav'>
          <div className='col-md-8 col-6 mx-0 d-flex p-2 align-items-center'>
            <h4 className='py-md-3 py-2 mb-0'>Quiz Challenges</h4>
          </div>
          <div className='col-md-4 col-6 d-flex justify-content-end align-items-center'>
            <button
              onClick={() => history.push("/admin/dayscode/quiz_challenge/new")}
              type='button'
              className='btn enroll-small-btn'
            >
              <small>Add Quiz Challenge</small>
            </button>
          </div>
        </div>
        {problemsListLoading ? (
          <div className='row d-flex justify-items                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            -center'>
            <div className='col-12 align-items-center text-center '>
              <CustomLoader />
            </div>
          </div>
        ) : (
          <>
            <div className='row m-0'>
              <div className='col-12'>
                <div className='event-dashboard-header w-100 px-0 table-responsive table'>
                  <ul>
                    <li>
                      <span
                        onClick={() => setStatus("public")}
                        className={status === "public" ? "active" : ""}
                      >
                        Public
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={() => setStatus("draft")}
                        className={status === "draft" ? "active" : ""}
                      >
                        Draft
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='px-4'>
              <div className='row m-0 py-2'>
                {quizChallengesList &&
                  getSort(quizChallengesList, "day").map((item, i) => (
                    <div className='col-md-3 col-6  p-2' key={i}>
                      <div
                        key={i}
                        className='rounded-lg border d-flex justify-content-between card-schadow p-2'
                      >
                        <div className=''>
                          <b>Day: {item.day}</b>
                          <p>Topic: {item.topic}</p>
                          <span>
                            <i className='fal fa-user-check mr-2 green' />
                            {item.completedBy && item.completedBy.length}
                          </span>
                        </div>
                        <div>
                          <Link
                            to={`/admin/dayscode/quiz_challenge/${item.id}`}
                          >
                            <i className='far fa-edit mx-3' />
                          </Link>
                          <i
                            className='far fa-trash red'
                            onClick={() => deleteLesson(item.id)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <DeleteLessonModal
              open={openModal}
              toggle={toggleModal}
              onSubmit={handleRemoveLesson}
              submitButtonName={"Delete Lesson"}
            />
          </>
        )}
      </LayoutContainer>
    </>
  );
};

export default QuizChallengesList;
