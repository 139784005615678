import { connect } from "react-redux";
import { setUserImgDetails } from "../../components/user/actions";

import ImageUpload from "./ImageUpload";

const mapStateToProps = ({ app, user }) => ({
  userProfileLoading: user.userProfileLoading,
  currentUser: user.currentUser ? user.currentUser : {},
});

const mapDispatchToProps = {
  setUserImgDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageUpload);
