import React from "react";
import { Field } from 'redux-form'
import { RenderInputField, renderRadioField } from '../../../../../../../shared_components/ReduxFormFields'
import { required, email, phoneNumber } from '../../../../../../../utils/validators'

const ApplicationStep1 = () => {
  return (
    <>
      <div className="row mx-0">
        <div className="col-12 col-md-6">
          <Field
            type="text"
            name="name"
            label="Full Name"
            placeholder=""
            component={RenderInputField}
            validate={[required]}
          />
        </div>
        <div className="col-12 col-md-6">
          <Field
            type="email"
            name="email"
            label="Email Address"
            placeholder=""
            component={RenderInputField}
            validate={[required, email]}
          />
        </div>
      </div>
      <div className="row mx-0">
        <div className="col-12 col-md-6">
          <Field
            type="text"
            name="phone"
            label="Phone Number"
            placeholder=""
            component={RenderInputField}
            validate={[required, phoneNumber]}
          />
        </div>
        <div className="col-12 col-md-6">
          <Field
            type="text"
            name="location"
            label="Location"
            placeholder="ex: City, State, Country"
            component={RenderInputField}
            validate={[required]}
          />
        </div>
      </div>
      <div className="row mx-0 my-4">
        <div className="col-12">
          <label className="form-label">Gender</label>
          <div className="my-2">
            <Field
              name='gender'
              component={renderRadioField}
              value="female"
              label='Female'
              type="radio"
              validate={[required]}
            />
          </div>
          <div className="my-2">
            <Field
              name='gender'
              component={renderRadioField}
              value="male"
              label='Male'
              type="radio"
              validate={[required]}
            />
          </div>
          <div className="my-2">
            <Field
              name='gender'
              component={renderRadioField}
              value="not"
              label='Prefer Not to Say'
              type="radio"
              validate={[required]}
            />
          </div>
        </div>
      </div >

    </>
  )
}

export default ApplicationStep1