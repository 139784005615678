export const generateQueryParams = (query) => {
  let str = "?";
  for (var xyz in query) {
    if (query[xyz] != null) {
      str = `${str}${xyz}=${query[xyz]}&`;
    }
  }
  str = str.slice(0, -1);
  return str !== "" ? str : null;
};

export const getGreetingZone = () => {
  let day = new Date();
  let hr = day.getHours();
  if (hr >= 4 && hr < 12) {
    return "Good Morning!"
  } else if (hr == 12) {
    return "Good Noon!"
  } else if (hr >= 12 && hr <= 17) {
    return "Good Afternoon!"
  } else {
    return "Good Evening!"
  }
}

export const getGreetingZoneColor = () => {
  let day = new Date();
  let hr = day.getHours();
  if (hr >= 4 && hr < 12) {
    return "morning"
  } else if (hr == 12) {
    return "noon"
  } else if (hr >= 12 && hr <= 17) {
    return "noon"
  } else {
    return "evening"
  }
}