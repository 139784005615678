import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { clearEventsList, getOrganiserEvents } from "../../../actions";
import { SharedEventLoadMoreButton } from "../../EventSharedComponent/EventSharedComponent";
import EventCard from "../EventCard";

const EventsSectionFrame = ({
  eventStateTitle,
  eventsListByState,
  eventsListLoading,
  state,
}) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) =>
    state.user.currentUser ? state.user.currentUser : {},
  );

  const [pageNumber, setPageNumber] = useState(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (currentUser.userName && currentUser.email) {
      dispatch(
        getOrganiserEvents({
          page: pageNumber,
          limit: 4,
          state,
          email: currentUser.email,
        }),
      );
    } else {
      dispatch(getOrganiserEvents({ page: pageNumber, limit: 4, state }));
    }

    return () => {
      dispatch(clearEventsList());
    };
  }, []);

  const handleLoadMore = (page) => {
    if (currentUser.userName && currentUser.email) {
      dispatch(
        getOrganiserEvents({ page, limit: 4, state, email: currentUser.email }),
      );
    } else {
      dispatch(getOrganiserEvents({ page, limit: 4, state }));
    }
    setPageNumber(page);
  };
  return (
    <>
      <div className='my-5'>
        <div className='row mb-3 mx-md-0 px-md-0 event-status-head'>
          <div className='col-md-6 px-3 px-md-0 mx-0 col-12 event-state'>
            <h2>{eventStateTitle}</h2>
          </div>
        </div>
        <div className='row m-0 mx-0 d-flex align-items-stretch px-md-0 px-1'>
          {eventsListByState &&
            eventsListByState.map((event, i) => (
              <EventCard data={event} key={i} />
            ))}
        </div>
        <SharedEventLoadMoreButton
          loading={eventsListLoading}
          setPageNumber={handleLoadMore}
          pageNumber={pageNumber}
        />
      </div>
    </>
  );
};

export default EventsSectionFrame;
