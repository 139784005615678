import React, { useEffect } from "react";
import AOS from "aos";

const techData = [
  {
    image1: require("../../../../../assets/images/internship/react.png"),
    name: "React Js",
  },
  {
    image1: require("../../../../../assets/images/internship/next.png"),
    name: "Next Js",
  },
  {
    image1: require("../../../../../assets/images/internship/js.png"),
    name: "Java Script",
  },
  {
    image1: require("../../../../../assets/images/internship/html.png"),
    name: "Html",
  },
  {
    image1: require("../../../../../assets/images/internship/css.png"),
    name: "CSS",
  },
  {
    image1: require("../../../../../assets/images/internship/bootstrap.png"),
    name: "Bootstrap",
  },
  {
    image1: require("../../../../../assets/images/internship/mongoDB.jpg"),
    name: "Mongo DB",
  },
  {
    image1: require("../../../../../assets/images/internship/ts.png"),
    name: "Type Script",
  },
  {
    image1: require("../../../../../assets/images/internship/express.png"),
    name: "Express",
  },
  {
    image1: require("../../../../../assets/images/internship/node.png"),
    name: "Node Js",
  },
  {
    image1: require("../../../../../assets/images/internship/tailwind.png"),
    name: "Tailwind",
  },

  {
    image1: require("../../../../../assets/images/internship/sass.png"),
    name: "Sass",
  },
];

const Technologies = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="row mx-0 my-5 justify-content-center">
      <div className="col-10 px-0 ">
        <h1 className="text-center pt-5">Technologies on which we work</h1>
        <div className="row mx-0 pt-5">
          {techData.map((items) => (
            <div className="col-12 col-sm-12 col-md-3 col-lg-3 justify-content-center pb-4">
              <div
                className="p-3 shadow-sm justify-content-center d-flex"
                data-aos="slide-left"
                data-aos-delay="100"
              >
                <img src={items.image1} className="" height="40vh" />
                <h4 className="pl-2 d-flex align-items-center mb-0">
                  {items.name}
                </h4>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Technologies;
