export const dayscodeResponsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const techCompaniesResponsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

export const DaysFAQ = [
  {
    question: "What is 30 days of code with Datacode.in?",
    answer:
      "30 days of code is a program to help and encourage the learners to chase their goal of being placed in top companies by proper coding environment, questionnaire structure and mentorship to focus on building coding skills to crack their tech dream.",
  },
  {
    question: "How can I participate?, What is the Eligibility Criteria?",
    answer:
      "Anyone can apply for this free coding skill booster, you can apply by signing up on the datacode portal, and simply registering to this program",
  },
  {
    question: "Is there any charges for participation?",
    answer:
      "This program is absolutely free and anyone who is willing to play around with code and at the same time wants to boost their programming skills can register.",
  },
  {
    question: "How can I get benefitted from it?",
    answer:
      "There will be weekly meetings for doubt clearing and analysis of your progress, at that time you can get directly connected with the mentors.",
  },
  {
    question: "How to connect with community mentors?",
    answer:
      "There will be weekly meetings for doubt clearing and analysis of your progress, at that time you can get directly connected with the mentors.",
  },
];

export const getCompletedDays = () => {
  // One day Time in ms (milliseconds)
  var one_day = 1000 * 60 * 60 * 24;

  // To set present_dates to two variables
  var present_date = new Date();

  // 0-11 is Month in JavaScript
  const batchDetails = JSON.parse(localStorage.getItem("batchDetails")) || {};

  if (batchDetails.start_date) {
    var christmas_day = new Date(batchDetails.start_date);

    if (present_date.getMonth() === 11 && present_date.getDate() > 25)
      christmas_day.setFullYear(christmas_day.getFullYear() + 1);

    var Result = (present_date.getTime() - christmas_day.getTime()) / one_day;
  }
  // To remove the decimals from the (Result) resulting days value
  var Final_Result = Math.trunc(Result);

  console.log("dss", +Final_Result);
  return Final_Result <= 0 ? 0 : Final_Result;
};

export const mentors = [
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1731171781/tv49agr1ng0w30txsrap.png",
    name: "Abhinav",
    description: "5* Codechef, Expert Codeforces",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1731171726/gvvzy66zv7jfoj7wchie.png",
    name: "Aaveg",
    description: "Developer",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1731171825/qb72i4k0uzhgyo2vm6dy.png",
    name: "Sonakshi Chauhan",
    description: "Datacode Mentor",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1731171855/njeynjtqwwyhjbs3a2iq.png",
    name: "Manas Panda",
    description: "3* Codechef, Expert Codeforces",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1731171878/z1kwhk2bt4uavl0nmj93.png",
    name: "Rajan Yadav",
    description: "Specialist Programmer at Infosys",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1731171902/yas1pzjh6mkulddtvbim.png",
    name: "Ketki Balde",
    description: "Data Structure Mentor, PHD Pursuing",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1731171934/naqpdvcdxdzlxpxdt3up.png",
    name: "Kona Venkata Sai Lakshmi",
    description:
      "Test Automation Specialist at IBM , Analyst at  ATT Contractor",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1731172000/rgx4yuyniyf9nrxhfpko.png",
    name: "Rajeev Kumar",
    description: "Software Developer at Protonshub",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1731172026/gbzwypxhn48qnjx28nf9.png",
    name: "Ishaan Pradhan",
    description: "Software Developer at Yash Technologies",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1731172154/lybwkiyogvlw9jxihr0x.png",
    name: "Jayesh",
    description: "Software Developer at TCS",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1731172173/j2wwaczlakik3w0f2jlk.jpg",
    name: "Ayushi Godiya",
    description: "Assistant Professor/Programming Trainer",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1731172215/hz9npbg6wvjpv1jvzdqi.jpg",
    name: "Ankit Kharwar",
    description: "M.tech, IIT Madras",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1731172237/jhojc4z4x5j2rkef4yvd.png",
    name: "Gopal Oswal",
    description: "Fullstack Developer",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1731172291/cdk9xvsmzrgwgatieoh5.png",
    name: "Shubhangi Suryavanshi",
    description: "Software Developer",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1731172314/fgqff5gkrnw1nsmatuoo.png",
    name: "Anshika Bhatnagar",
    description: "Software engineer Hashedin by Deloitte, Bangalore",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1731172257/cwq4eltbwaa1aybvemze.png",
    name: "Aditya Sharma",
    description: "Software Developer at Wipro",
  },
];

export const team = [
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1644779350/jc0vca7znq1czk5youw2.png",
    name: "Akshay Mandliya",
    description: "I Follow Dhanshree, Bhavik & Pawan's Instructions",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1644778958/uhdtbntkdmuntekj8msu.png",
    name: "Dhanshree Mam",
    description: "I am Datacode Operations Lead",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1644778052/vtyrovzg6mbz7w6wgzkh.png",
    name: "Bhavik Jain",
    description: "If Akshay is unavailable, Contact me",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1644778874/ofrutkghvnzk69xp2eoo.png",
    name: "Paawan Pradhan",
    description: "I know everything about Datacode.in",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1653671800/uct6cfkrvmtiul9aepqe.jpg",
    name: "Kusumita",
    description: "Mentor",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1653673897/tychfwwwmqfsbrorojrx.jpg",
    name: "Amisha",
    description: "Mentor",
  },
  {
    img: "https://res.cloudinary.com/datacode/image/upload/v1653845944/lmlzth2pmvdyfmsddcgr.png",
    name: "Shweta Thakur",
    description: "Student Mentor",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1644777446/uio8d8bgmlqwssknzcst.png",
    name: "Shivani Rana",
    description: "Community Volunteer",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1644777316/c86ytz7tvjohprxhc4hl.png",
    name: "Aarti",
    description: "Community Volunteer",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1644777382/k9rn5m8blxxrw8zs9tik.png",
    name: "Arpit",
    description: "Community Volunteer",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1644777415/lhelezsjw72loahrawjt.png",
    name: "Ayush Mishra",
    description: "Community Volunteer",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1644777504/yktfgjr40fs5mmfvfgl5.png",
    name: "Sunandani",
    description: "Community Volunteer",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1644779188/bfs93kdagqlmp6opfact.png",
    name: "Bhakti Jain",
    description: "Community Volunteer",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1644777546/ygqjpwu2yi6djtxm34dl.png",
    name: "Rahul",
    description: "Community Volunteer",
  },
];
