import { connect } from "react-redux";
import { getFormValues } from "redux-form";
import { createEvent, editEvent, getEventDetails } from "../../../actions";

import CreateEventForm from "./CreateEventForm";

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser ? state.user.currentUser : {},
  eventRegistationLoading: state.event.eventRegistationLoading,
  formStates: getFormValues("create-event")(state),
});

const mapDispatchToProps = {
  createEvent,
  editEvent,
  getEventDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateEventForm);
