import React, { useState } from "react";
import { reduxForm } from "redux-form";

import CustomLoader from "../../../../../../shared_components/CustomLoader";
import EventRegThankyouCard from "./EventRegThankyouCard";
import EventRegistrationForm from "./EventRegistrationForm";
import ParticipantQuestionForm from "./ParticipantQuestionForm";

const EventRegistration = ({
  reset,
  handleSubmit,
  submitting,
  eventRegister,
  eventFeedback,
  eventQuestion,
  eventRegistationLoading,
  id,
  eventDetails,
  initialize,
  userData,
}) => {
  const [eventRegistered, setEventRegistered] = useState(false);
  const [questionSubmitted, setQuestionSubmitted] = useState(false);
  const [registerName, setRegisterName] = useState("");

  return (
    <>
      <div className='py-5  px-0'>
        {eventRegistationLoading && eventRegistationLoading ? (
          <CustomLoader />
        ) : (
          <>
            {eventRegistered ? (
              <>
                {questionSubmitted ? (
                  <EventRegThankyouCard
                    state={eventDetails?.state}
                    userData={userData}
                  />
                ) : (
                  <ParticipantQuestionForm
                    id={id}
                    eventRegistered={eventRegistered}
                    setQuestionSubmitted={setQuestionSubmitted}
                    eventQuestion={eventQuestion}
                    registerName={registerName}
                  />
                )}
              </>
            ) : (
              <EventRegistrationForm
                id={id}
                eventDetails={eventDetails}
                setRegisterName={setRegisterName}
                setQuestionSubmitted={setQuestionSubmitted}
                setEventRegistered={setEventRegistered}
                eventRegistered={eventRegistered}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default reduxForm({
  form: "eventRegisterForm",
})(EventRegistration);
