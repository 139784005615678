import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const TextEditor = ({ handleTextEditor, text }) => {
  const contentDataState = ContentState.createFromBlockArray(
    htmlToDraft(text || ""),
  );
  const editorDataState = EditorState.createWithContent(contentDataState);

  const [editorState, setEditorState] = useState(editorDataState);
  const [activeTab, setActiveTab] = useState("textTab");
  const [codeHtml, setCodeHtml] = useState(text || "");

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    let data = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    // const htmlConverted = convertImages(data)
    handleTextEditor(data);
  };

  const convertImages = (htmlText) => {
    const regex = /<img\s[^>]*?style\s*=\s*['\"]float([^'\"]*?)['\"][^>]*?>/g;
    let m;

    while ((m = regex.exec(htmlText)) !== null) {
      if (m.index === regex.lastIndex) regex.lastIndex++;
      let repl = null,
        type = null;
      m.forEach((match, groupIndex) => {
        if (groupIndex == 0) repl = match;
        if (groupIndex == 1) type = match;
        if (repl && type) {
          if (type.includes("none")) {
            htmlText = htmlText.replace(
              repl,
              `<div class="text-edior-img" style="text-align: center;width: 100%;">` +
                repl +
                "</div>",
            );
          } else
            htmlText = htmlText.replace(
              repl,
              `<div style="text-align ${type}; width: 100%;">` +
                repl +
                "</div>",
            );
          repl = null;
          type = null;
        }
      });
    }
    return htmlText;
  };

  const handleCodeHtmlChange = (e) => {
    setCodeHtml(e.target.value);
  };

  return (
    <>
      <div className='row mx-0'>
        <div className='col-12 d-flex'>
          <small
            className={`p-2 pointer ${activeTab === "textTab" && "active"}`}
            onClick={() => setActiveTab("textTab")}
          >
            Text
          </small>
          <small
            className={`p-2 pointer ${activeTab === "code" && "active"}`}
            onClick={() => setActiveTab("code")}
          >
            Code
          </small>
        </div>
      </div>
      {activeTab === "textTab" ? (
        <Editor
          editorState={editorState}
          toolbarClassName='toolbarClassName'
          wrapperClassName='wrapperClassName'
          editorClassName='editorClassName'
          mention={{
            separator: " ",
            trigger: "@",
            suggestions: [
              { text: "APPLE", value: "apple", url: "apple" },
              { text: "BANANA", value: "banana", url: "banana" },
              { text: "CHERRY", value: "cherry", url: "cherry" },
              { text: "DURIAN", value: "durian", url: "durian" },
              { text: "EGGFRUIT", value: "eggfruit", url: "eggfruit" },
              { text: "FIG", value: "fig", url: "fig" },
              { text: "GRAPEFRUIT", value: "grapefruit", url: "grapefruit" },
              { text: "HONEYDEW", value: "honeydew", url: "honeydew" },
            ],
          }}
          hashtag={{}}
          onEditorStateChange={onEditorStateChange}
          stripPastedStyles={true}
        />
      ) : (
        <div className='row mx-0'>
          <div className='col-12'>
            <div className='text-left mt-3 mt-md-4'>
              <label className='form-label'>Write Code</label>
              <textarea
                name='review'
                placeholder={
                  "code review according to the standard check points"
                }
                value={text}
                type='textarea'
                style={{ minHeight: "500px" }}
                className='form-input-field-textarea'
                onChange={(e) => handleTextEditor(e.target.value)}
                // disabled={isDisabled ? true : false}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TextEditor;
