import React, { useState, useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import moment from "moment";

import {
  RenderInputField,
  renderCheckboxField,
  renderRadioField,
} from "../../../../../../shared_components/ReduxFormFields";
import { required, dateTimeDiff } from "../../../../../../utils/validators";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";
import TextEditor from "../../../../aticles/BlogsEditor/TextEditor";

const CreateEventForm = ({
  reset,
  handleSubmit,
  submitting,
  initialize,
  eventRegistationLoading,
  currentUser: { userName, email },
  createEvent,
  eventDetails,
  editEvent,
  formStates,
  getEventDetails,
}) => {
  // const [technology, setTechnology] = useState("")
  // const [isBroadCastLink, setIsBroadCastLink] = useState(false)
  const [useRegForm, setUseRegForm] = useState(false);
  const [isOnline, setIsOnline] = useState(true);
  const [isEdit, setEdit] = useState(false);
  // const [uplodedImg, getUploadImgUrl] = useState('http://res.cloudinary.com/datacode/image/upload/v1625513805/u6vcbtd1dafipu1mxhik.png')
  const [tags, setTags] = useState([]);

  const [htmlText, getHtmlText] = useState("<p> </p>");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (eventDetails) {
      initialize(eventDetails);
      setEdit(true);
      getHtmlText(eventDetails.description);
      setTags(eventDetails.tags);
    }
  }, [eventDetails]);

  const history = useHistory();

  const onSubmit = (values) => {
    const event = { ...values };
    event["userName"] = userName && userName;
    event["type"] = event.type && event.type.value;
    event["description"] = htmlText;
    event["tags"] = tags;
    console.log(
      "dd",
      moment(event.start_date + " " + event.start_time).format(),
    );
    event["start_date_time"] = moment(
      event.start_date + " " + event.start_time,
    ).format();
    event["end_date_time"] = moment(
      event.end_date + " " + event.end_time,
    ).format();

    if (isEdit) {
      editEvent(event).then((res) => {
        if (res && res.success) {
          getEventDetails({ id: event.id, email });
        }
      });
    } else {
      event["id"] = Math.random().toString(36).substr(4, 9);
      createEvent(event).then((res) => {
        if (res && res.success) {
          history.push(`/event/dashboard/${res.event && res.event.id}`);
          reset("create-event");
        }
      });
    }
  };

  const handleTextEditor = (text) => {
    getHtmlText(text);
  };

  const addTags = () => {
    setTags([...tags, formStates.tag]);
  };
  const handleTagDelete = (e) => {
    const data = tags.filter((item) => item !== e);
    setTags(data);
  };

  return (
    <>
      <h1 className='text-center register-text mb-5 mb-md-3'>
        {isEdit ? "Edit Event Details" : "Create your event"}
      </h1>
      {eventRegistationLoading && eventRegistationLoading ? (
        <Loader
          type='ThreeDots'
          color='#30006d'
          height={100}
          width={100}
          timeout={19000} //8 secs
        />
      ) : (
        <form className='' onSubmit={handleSubmit(onSubmit)}>
          <div className='row m-0 mt-5 p-md-5 p-3 border rounded-lg bg-white event-reg'>
            <div className='col-12 col-md-4 px-md-2 px-0 py-md-0 py-2'>
              <h6>Basic Details</h6>
              <p>
                This information will be displayed publicly so be careful what
                you share.{" "}
              </p>
            </div>
            <div
              className='col-12 col-md-8 px-0 border rounded-lg form-shadow'
              id='form'
            >
              <div className='pt-md-3 px-md-4 px-3 login-card '>
                <Field
                  type='text'
                  name='title'
                  label='Event Name'
                  placeholder=''
                  component={RenderInputField}
                  validate={[required]}
                />
                <Field
                  type='text'
                  name='sub_title'
                  label='Event Sub Heading'
                  placeholder=''
                  component={RenderInputField}
                  validate={[required]}
                />
                <div className='mt-3'>
                  <label className='form-label text-left pt-5'>
                    Event Description {`(${htmlText.length - 8})`}
                  </label>
                  <div className='mb-3 border '>
                    {isEdit && eventDetails.description && (
                      <TextEditor
                        handleTextEditor={handleTextEditor}
                        text={eventDetails ? htmlText : ""}
                      />
                    )}
                    {!isEdit && (
                      <TextEditor
                        handleTextEditor={handleTextEditor}
                        text={eventDetails ? htmlText : ""}
                      />
                    )}
                  </div>
                </div>
                <div className='row'>
                  <div className='col-6'>
                    <Field
                      type='date'
                      name='start_date'
                      label='Event Start Date'
                      placeholder=''
                      component={RenderInputField}
                      validate={[required]}
                    />
                  </div>
                  <div className='col-6'>
                    <Field
                      type='time'
                      name='start_time'
                      label='Event Start Time'
                      placeholder=''
                      component={RenderInputField}
                      validate={[required]}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-6'>
                    <Field
                      type='date'
                      name='end_date'
                      label='Event End Date'
                      placeholder='xyz@yui.com'
                      component={RenderInputField}
                      // validate={[required]}
                    />
                  </div>
                  <div className='col-6'>
                    <Field
                      type='time'
                      name='end_time'
                      label='Event End Time'
                      placeholder=''
                      component={RenderInputField}
                      // validate={[required]}
                    />
                  </div>
                </div>
                <div className='row my-5'>
                  <div className='col-12'>
                    <h6>Events Tags</h6>
                    <div className='border rounded'>
                      <div className='border rounded-lg card-schadow p-3'>
                        <div className='row mx-0'>
                          {tags.map((item, i) => (
                            <div
                              key={i}
                              className='col-2 p-2 mx-2 border d-flex align-items-center justify-content-between'
                            >
                              <h6 className='mb-0'>{item}</h6>
                              <i
                                className='fal fa-times'
                                onClick={() => handleTagDelete(item)}
                              />
                            </div>
                          ))}
                        </div>
                        <div className='mt-4'>
                          <Field
                            type='text'
                            name='tag'
                            label='Add Tags'
                            placeholder=''
                            component={RenderInputField}
                          />
                          <div className='d-flex mt-3 justify-content-end'>
                            <button
                              className='btn btn-sm btn-primary'
                              type='button'
                              onClick={() => addTags()}
                            >
                              Add
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row m-0 mt-5 p-md-5 p-3 border rounded bg-white event-reg'>
            <div className='col-12 col-md-4 px-md-2 px-0 py-md-0 py-2'>
              <h6>Settings</h6>
              <p>
                Event settings can’t be changed once the event is created. Learn
                more
              </p>
            </div>
            <div
              className='col-12 col-md-8 px-0 border rounded-lg form-shadow'
              id='form'
            >
              <div className='p-md-4 px-3 login-card '>
                <div className='row mx-0'>
                  <div className='col-12 px-0 text-left'>
                    <div className=''>
                      <label className='form-label'>This Event is:</label>
                      <div className='type-tag'>
                        <div className='type-checkbox'>
                          <Field
                            name='event_type'
                            component={renderRadioField}
                            value='public'
                            label='Public'
                            type='radio'
                            validate={[required]}
                          />
                        </div>
                        <div className='mx-3 type-checkbox'>
                          <Field
                            name='event_type'
                            component={renderRadioField}
                            value='private'
                            label='Private'
                            type='radio'
                            validate={[required]}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='mx-3 type-checkbox'>
                      <Field
                        name='event_platform'
                        component={renderRadioField}
                        value='event_platform'
                        label='Event Platform'
                        type='radio'
                        validate={[required]}
                      />
                    </div>
                    <div className='d-flex align-items-center mt-4'>
                      <input
                        type='checkbox'
                        value={useRegForm}
                        onChange={() => setUseRegForm(!useRegForm)}
                        checked={useRegForm}
                      />
                      <label className='ml-1 mb-0 form-label'>
                        Use a Datacode registration form
                      </label>
                      <small className='ml-2 mb-0'>
                        ( you can download attendee details )
                      </small>
                    </div>
                  </div>
                </div>
                <div className='row m-0 mt-5'>
                  <div className='col-12 px-0 text-left'>
                    <h6>Location</h6>
                    <div className='mt-4 d-flex align-items-center'>
                      <input
                        type='checkbox'
                        value={isOnline}
                        onChange={() => setIsOnline(!isOnline)}
                        checked={isOnline}
                      />
                      <label className='ml-1 mb-0 form-label'>
                        {" "}
                        This is an online virtual event
                      </label>
                    </div>
                    {!isOnline && (
                      <>
                        <Field
                          type='text'
                          name='address'
                          label='Address'
                          placeholder=''
                          component={RenderInputField}
                          validate={[required]}
                        />
                        <Field
                          type='text'
                          name='venue'
                          label='Venue'
                          placeholder=''
                          component={RenderInputField}
                          validate={[required]}
                        />
                      </>
                    )}
                  </div>
                </div>
                <Field
                  type='text'
                  name='registrationLink'
                  label={`Enter ${
                    useRegForm ? "Broadcast" : "Registration"
                  } Link`}
                  placeholder=''
                  component={RenderInputField}
                  validate={[required]}
                />
                <div className='mt-4'>
                  <Field
                    name='is_broadcast_link'
                    component={renderCheckboxField}
                    label='This is Broadcast Link'
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='row my-4'>
            <div className='col-12 text-right'>
              <button
                type='submit'
                className='btn custom-button'
                disabled={submitting}
              >
                <span>{isEdit ? "Edit Event" : "Create Event"}</span>
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default reduxForm({
  form: "create-event",
  fields: ["tag"],
  validate: dateTimeDiff,
})(CreateEventForm);
