import React from "react";

const AboutAcademySection = () => {
  return (
    <>
      <div className="row mx-0 my-5 d-flex justify-content-center about-academy">
        <div className="col-10">
          <div className="row mx-0 py-5">
            <div className="col-md-6 col-12">
              <img
                className="img-fluid"
                loading="lazy"
                src={`http://res.cloudinary.com/datacode/image/upload/v1687994577/xq8b2nke0zl69a0lgbie.png`}
                alt="avatar"
              />
            </div>
            <div className="col-md-6 col-12 d-flex align-items-center">
              <div className="pl-md-5 pl-0">
                <p className="sub-head-text">About Datacode Academy h4</p>
                <h1 className="heading">A Place to Learn and Contribute</h1>
                <p>
                  quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse. h5
                </p>
                <div className="">
                  <button className="btn btn-primary">About Us</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutAcademySection