import React, { useEffect } from "react";
import { MdOutlineAppRegistration } from "react-icons/md";
import { GrDocumentTest } from "react-icons/gr";
import { GrHtml5 } from "react-icons/gr";
import { GiHumanTarget } from "react-icons/gi";
import { MdArrowDownward } from "react-icons/md";
import AOS from "aos";
// import { Tabs } from "antd";

// const onChange = (key) => {
//   console.log(key);
// };

// const items = [
//   { key: "1", label: "Tab 1", children: "Content of Tab Pane 1" },
//   { key: "2", label: "Tab 2", children: "Content of Tab Pane 2" },
//   { key: "3", label: "Tab 3", children: "Content of Tab Pane 3" },
// ];

// const Registration = () => (
//   <div className="row mx-0 justify-content-center">
//     <div className="col-10 border">
//       <Tabs
//         defaultActiveKey="1"
//         items={items}
//         onChange={onChange}
//         className="text-center"
//       />
//     </div>
//   </div>
// );

// export default Registration;

const Registration = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="row mx-0 justify-content-center mt-lg-5 pt-5">
      <div className="col-12 registration py-4">
        <h1 className="text-center pb-4">How to Join Our Internship Program</h1>
        <h5 className="pb-4 text-center">
          Follow these <span>4 simple</span> steps to start your journey:
        </h5>
        <div className="row mx-0 mt-lg-4">
          <div className="col-12 col-sm-12 col-md-3 col-lg-3">
            <div className="p-3">
              <h4 className="text-center pt-2">Step-1</h4>
              <h4 className="text-center">
                <MdArrowDownward />
              </h4>
              <div
                className="box-shadow-team p-3 align-items-center justify-content-center text-center boxx"
                data-aos="slide-left"
                data-aos-delay="100"
              >
                <div className="item-icon ">
                  <MdOutlineAppRegistration />
                </div>
                <h6 className="py-1">Register</h6>
                <p className="py-1 para">
                  Fill out the form to show your interest.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-3 col-lg-3">
            <div className="p-3">
              <h4 className="text-center pt-2">Step-2</h4>
              <h4 className="text-center">
                <MdArrowDownward />
              </h4>
              <div
                className="box-shadow-team p-3 align-items-center justify-content-center text-center boxx"
                data-aos="slide-left"
                data-aos-delay="100"
              >
                <div className="item-icon">
                  <GrDocumentTest />
                </div>
                <h6 className="py-1">Quiz Test</h6>
                <p className="py-1 para">
                  Showcase your problem-solving skills through an online quiz.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-3 col-lg-3">
            <div className="p-3">
              <h4 className="text-center pt-2">Step-3</h4>
              <h4 className="text-center">
                <MdArrowDownward />
              </h4>
              <div
                className="box-shadow-team p-3 align-items-center justify-content-center text-center boxx"
                data-aos="slide-left"
                data-aos-delay="100"
              >
                <div className="item-icon">
                  <GrHtml5 />
                </div>
                <h6 className="py-1">Programming Assesment</h6>
                <p className="py-1 para">
                  Submit a web page design based on the provided template.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-3 col-lg-3">
            <div className="p-3">
              <h4 className="text-center pt-2">Step-4</h4>
              <h4 className="text-center">
                <MdArrowDownward />
              </h4>
              <div
                className="box-shadow-team p-3 align-items-center justify-content-center text-center boxx"
                data-aos="slide-left"
                data-aos-delay="100"
              >
                <div className="item-icon">
                  <GiHumanTarget />
                </div>
                <h6 className="py-1">Interview</h6>
                <p className="py-1 para">
                  Show off your passion in a quick discussion with our team
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Registration;
