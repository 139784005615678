import {
  GET_CLUB_APPLICATIONS,
  SET_CLUB_APPLICATION
} from "../constants";

export const getClubApplications = (data) => ({
  type: GET_CLUB_APPLICATIONS,
  payload: data,
});

export const setClubApplication = (data) => ({
  type: SET_CLUB_APPLICATION,
  payload: data,
});