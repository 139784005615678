import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getFormValues } from "redux-form";

import {
  createLesson,
  editLesson,
  getLessonDetails,
} from "../../../../actions";

import AddLesson from "./AddLesson";

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser ? state.user.currentUser : {},
  problemDetailsLoading: state.dayscode.problemDetailsLoading,
  lessonDetails: state.dayscode.lessonDetails,
  formStates: getFormValues("add-lesson")(state),
});

const mapDispatchToProps = {
  createLesson,
  editLesson,
  getLessonDetails,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddLesson)
);
