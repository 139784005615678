import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CustomLoader from "../../../../shared_components/CustomLoader";
import { getMemberClubApplication } from "../actions/operations";

const ClupApplicationView = () => {
  const dispatch = useDispatch();
  const { clubApplication, clubApplicationLoading } =
    useSelector((state) => state.club) || {};
  let { id } = useParams();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (id) {
      dispatch(getMemberClubApplication(id));
    }
  }, [id]);

  return (
    <>
      <div className='row mx-0'>
        <div className='col-12 border-bottom'>
          <h1 className='p-3 mb-0'>{clubApplication?.name} Club Application</h1>
        </div>
      </div>
      {clubApplicationLoading ? (
        <CustomLoader />
      ) : clubApplication ? (
        <div className='row mx-0 d-flex justify-content-center'>
          <div className='col-10 px-0'>
            <div className='block border rounded-lg p-4 my-3'>
              <h5 className='border-bottom mb-3'>Personal Information</h5>
              <div className='row'>
                <div className='col-12 col-md-3'>
                  <h6>Name:</h6>
                  <p>{clubApplication.name}</p>
                </div>
                <div className='col-12 col-md-3'>
                  <h6>Email:</h6>
                  <p>{clubApplication.email}</p>
                </div>
                <div className='col-12 col-md-3'>
                  <h6>Phone:</h6>
                  <p>{clubApplication.phone}</p>
                </div>
                <div className='col-12 col-md-3'>
                  <h6>Gender:</h6>
                  <p>{clubApplication.gender}</p>
                </div>
                <div className='col-12 col-md-3'>
                  <h6>Location:</h6>
                  <p>{clubApplication.location}</p>
                </div>
              </div>
            </div>
            <div className='block border rounded-lg p-4 my-3'>
              <h5 className='border-bottom mb-3'>Academic Details</h5>
              <div className='row'>
                <div className='col-12 col-md-3'>
                  <h6>University / Institution:</h6>
                  <p>{clubApplication.university}</p>
                </div>
                <div className='col-12 col-md-3'>
                  <h6>Degree Program:</h6>
                  <p>{clubApplication.degree}</p>
                </div>
                <div className='col-12 col-md-3'>
                  <h6>Graduation Year:</h6>
                  <p>{clubApplication.graduation_year}</p>
                </div>
                <div className='col-12 col-md-3'>
                  <h6>Year of Study:</h6>
                  <p>{clubApplication.year}</p>
                </div>
              </div>
            </div>
            <div className='block border rounded-lg p-4 my-3'>
              <h5 className='border-bottom mb-3'>Tell us More</h5>
              <div className='row'>
                <div className='col-12'>
                  <h6>
                    Why are you interested in joining the Datacode Student
                    Learning Club?
                  </h6>
                  <p>{clubApplication.why_are_you_interested}</p>
                </div>
                <div className='col-12'>
                  <h6>What specific technology or areas you interested in?:</h6>
                  <p>{clubApplication.interested_topics}</p>
                </div>
                <div className='col-12'>
                  <h6>
                    What are your goals or expectations from joining the Student
                    Learning Group?
                  </h6>
                  <p>{clubApplication.goals_expectations}</p>
                </div>
                <div className='col-12'>
                  <h6>
                    Have you participated in any other student clubs or
                    organizations related to computer science?
                  </h6>
                  <p>{clubApplication.participated_before}</p>
                </div>
              </div>
            </div>
            <div className='block border rounded-lg p-4 my-3'>
              <h5 className='border-bottom mb-3'>Contribution</h5>
              <div className='row'>
                <div className='col-12'>
                  <h6>
                    Are you interested in participating in workshops, guest
                    lectures, or other interactive events?, Let us know what do
                    you want to join
                  </h6>
                  <p>{clubApplication.interest}</p>
                </div>
                <div className='col-12'>
                  <h6>
                    How would you like to contribute to the group by sharing
                    your knowledge, organizing events, or mentoring other
                    members?
                  </h6>
                  <p>{clubApplication.shared_ideas}</p>
                </div>
                <div className='col-12'>
                  <h6>
                    Do you have any suggestions or ideas for activities or
                    topics you'd like to see in the group?
                  </h6>
                  <p>{clubApplication.suggestions}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <h1>Application Not Found</h1>
      )}
    </>
  );
};
export default ClupApplicationView;
