import React from "react";
import { useDispatch } from "react-redux";
import { deleteLessonFromBatch } from "../../../actions";
import ProblemListCard from "../Problems/ProblemsList/ProblemListCard";
import AddBatchLessonDrawer from "./AddBatchLessonDrawer";

const LessonList = ({ batch, codeBatchDetails, codeBatchesLoading }) => {
  const dispatch = useDispatch();

  const handleDeleteLessonFromBatch = (lesson) => {
    dispatch(deleteLessonFromBatch({ batch: batch, lesson }));
  };

  return (
    <>
      <div className='d-flex justify-content-between'>
        <h1>Lessons</h1>
        <AddBatchLessonDrawer batch={batch} />
      </div>
      {codeBatchDetails.lessons &&
        codeBatchDetails.lessons.map((lesson) => (
          <ProblemListCard
            day={lesson?.day}
            batchContentId={lesson._id}
            item={lesson.lessonDetails}
            showDeleteFromBatchIcon={true}
            handleDeleteFromBatch={handleDeleteLessonFromBatch}
          />
        ))}
    </>
  );
};

export default LessonList;
