import * as constants from "../constants";

const setShowFooter = (state, action) => {
  return {
    ...state,
    isShowFooter: true,
  };
};

const setHideFooter = (state, action) => {
  return {
    ...state,
    isShowFooter: false,
  };
};

const setShowAddTutorial = (state, action) => {
  return {
    ...state,
    isShowAddTutorial: true,
  };
};

const setHideAddTutorial = (state, action) => ({
  ...state,
  isShowAddTutorial: false,
});

const setAddTestimonial = (state, action) => {
  return {
    ...state,
    showTestimonial: !state.showTestimonial,
    isEditTestimonial: false,
    addTestimonialLoading: false,
    editTestimonialData: {},
  };
};

const setTestimonials = (state, action) => ({
  ...state,
  testimonials: action.payload,
  addTestimonialLoading: !state.addTestimonialLoading,
});

const setAddSubscribeLoading = (state) => ({
  ...state,
  addSubscribeLoading: !state.addSubscribeLoading,
});

const setIsEditTestimonial = (state, action) => {
  return {
    ...state,
    isEditTestimonial: !state.isEditTestimonial,
    editTestimonialData: action.payload,
    showTestimonial: !state.showTestimonial,
    addTestimonialLoading: false,
  };
};

const setAddContactMessageLoading = (state) => ({
  ...state,
  addContactMessageLoading: !state.addContactMessageLoading,
});

const hideCompleteFooter = (state, action) => ({
  ...state,
  hideFooter: action.payload,
});

const hideCompleteHeader = (state, action) => ({
  ...state,
  hideHeader: action.payload,
});

export const getAppHandlers = {
  [constants.SHOW_FOOTER]: setShowFooter,
  [constants.HIDE_FOOTER]: setHideFooter,
  [constants.SHOW_ADD_TUTORIAL]: setShowAddTutorial,
  [constants.HIDE_ADD_TUTORIAL]: setHideAddTutorial,
  [constants.SHOW_ADD_TESTIMONIAL]: setAddTestimonial,
  [constants.SET_EDIT_TESTIMONIAL]: setIsEditTestimonial,
  [constants.SET_TESTIMONIALS]: setTestimonials,
  [constants.SET_SUBSCRIBE_LOADING]: setAddSubscribeLoading,
  [constants.SET_ADD_CONTACT_MESSAGE_LOADING]: setAddContactMessageLoading,
  [constants.HIDE_COMPLETE_FOOTER]: hideCompleteFooter,
  [constants.HIDE_COMPLETE_HEADER]: hideCompleteHeader,
};
