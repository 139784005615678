import { connect } from "react-redux";
import { getProblems, removeDaysProblem } from "../../../../actions";

import ProblemsList from "./ProblemsList";

const mapStateToProps = ({ dayscode, user }) => ({
  currentUser: user.currentUser ? user.currentUser : {},
  problemsListLoading: dayscode.problemsListLoading,
  problemsList: dayscode.problemsList,
});

const mapDispatchToProps = {
  getProblems,
  removeDaysProblem,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProblemsList);
