import { Button, Drawer, Space } from "antd";
import React, { useState } from "react";
import ParticipantFilter from "./ParticipantFilter";

const AddParticipantDrawer = ({ handleSelectedParticipant, btnName }) => {
  const [open, setOpen] = useState(false);
  // const [placement, setPlacement] = useState("right");
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Space>
        <Button className='add-speaker-btn' type='primary' onClick={showDrawer}>
          {btnName}
        </Button>
      </Space>
      <Drawer
        title='Select Participant'
        // placement={placement}
        placement='right'
        closable={false}
        onClose={onClose}
        open={open}
        // key={placement}
        key={"right"}
      >
        <ParticipantFilter
          handleSelectedParticipant={handleSelectedParticipant}
        />
      </Drawer>
    </>
  );
};
export default AddParticipantDrawer;
