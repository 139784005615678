import React, { Component } from "react";
class CQuizeTest4 extends Component {
  state = {};
  render() {
    return (
      <>
        <h1>Android Quize Test 4</h1>
      </>
    );
  }
}

export default CQuizeTest4;
