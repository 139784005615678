import React from "react";
import AboutAcademySection from "./AboutAcademySection";
import AcademyContributionSection from "./AcademyContributionSection";
import AcademyTeam from "./AcademyTeam";
import DevelopSkillsSection from "./DevelopSkillsSection";
import HeroSection from "./HeroSection";
import LearnAndPracticeSection from "./LearnAndPracticeSection";
import OffersSection from "./OffersSection";
import OurLearnersWorkAt from "./OurLearnersWorkAt";
import TrainingWorkshopSection from "./TrainingWorkshopSection";

const AcademyLandingPage = () => {
  return (
    <>
      <HeroSection />
      <AboutAcademySection />
      <OffersSection />
      {/* <FunWithGamifiedCourses /> */}
      <LearnAndPracticeSection />
      <DevelopSkillsSection />
      <TrainingWorkshopSection />
      <OurLearnersWorkAt />
      <AcademyTeam />
      <AcademyContributionSection />
    </>
  );
};

export default AcademyLandingPage;
