import { connect } from "react-redux";
import { createEvent } from "../../../actions";

import ShareEventForm from "./ShareEventForm";

const mapStateToProps = ({ event, user }) => ({
  currentUser: user.currentUser ? user.currentUser : {},
});

const mapDispatchToProps = {
  createEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShareEventForm);
