import React from "react"

const BecomeMentorSection = () => {
  return (
    <>
      <div className="row mx-0 d-flex justify-content-center">
        <div className="col-12 col-md-10 px-0">
          <div className="row mx-0">
            <div className="col-md-6 col-12">
              <img
                className="img-fluid"
                loading="lazy"
                src={'http://res.cloudinary.com/datacode/image/upload/v1691260250/owtnmhc7xzk8eeh7n3jg.png'}
                alt="avatar"
              />
            </div>
            <div className="col-md-6 col-12 p-5">
              <h1 className="my-5">Want to become a Mentor?</h1>
              <p className="my-5">
                Are you eager to support aspiring professionals, diversity, and inclusion efforts, or for another service opportunity? Or perhaps you are looking for an opportunity to gain some mentoring experience. Look no further!
                <br />
                Datacode Academy provides unique mentoring opportunities as it allows you to mentor individuals outside of your institution
              </p>
              <button className="btn btn-primary my-5">Contact us</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BecomeMentorSection