import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";

import CustomLoader from "../../../../../shared_components/CustomLoader";
import { getColumns } from "./helpers";

const UsersList = ({
  getUsersListDetails,
  setActiveTab,
  userProfileLoading,
}) => {
  let columns = getColumns();

  const [usersData, setUsersData] = useState();
  const [search, setSearch] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    getUsersListDetails().then((res) => {
      if (res) {
        setUsersData(res.data && res.data);
      }
    });
  }, []);

  const filterUsersData =
    usersData &&
    usersData.filter(
      (item) =>
        item?.email?.toUpperCase().includes(search.toUpperCase()) ||
        item?.firstName?.toUpperCase().includes(search.toUpperCase()) ||
        item?.userName?.toUpperCase().includes(search.toUpperCase()),
    );

  return (
    <>
      {userProfileLoading ? (
        <CustomLoader />
      ) : (
        <>
          <h1 className='m-4'>Portal Users List</h1>
          <h6 className='mx-4'>{`Total Registration : ${
            usersData && usersData.length
          }`}</h6>
          <div className='row mx-0 px-md-5 px-3 my-5 category-page'>
            <div className='col-12 text-center d-flex justify-content-center'>
              <div className='d-flex mx-md-5 mx-2 px-md-4 px-1 fontSearch'>
                <input
                  type='text'
                  className='search'
                  value={search}
                  placeholder='Username'
                  onChange={(e) => setSearch(e.target.value)}
                />
                <i className='fa fa-search search-icon' />
              </div>
            </div>
          </div>
          {usersData && usersData.length && (
            <div className='table table-responsive'>
              <BootstrapTable
                keyField='id'
                bordered={true}
                data={filterUsersData}
                columns={columns}
                search
                hover={true}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default UsersList;
