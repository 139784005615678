import React from "react";
import { Field } from "redux-form";
import {
  renderCheckboxField,
  RenderInputField,
} from "../../../../../../../shared_components/ReduxFormFields";
import { required } from "../../../../../../../utils/validators";

const ApplicationStep5 = () => {
  return (
    <>
      <div className='row mx-0'>
        <div className='col-12'>
          <div className='my-5'>
            <Field
              type='text'
              name='referralName'
              label='Please mention any referral, if you have'
              placeholder=''
              component={RenderInputField}
            />
          </div>
          <Field
            name='undertaking'
            component={renderCheckboxField}
            label='I confirm that the information provided is accurate and I am committed 
            to actively participating in the Datacode Student Learning Club.'
            validate={[required]}
          />
        </div>
      </div>
    </>
  );
};

export default ApplicationStep5;
