export const technologyOptions = [
  { tech: "Machine Learning", value: "machine_learning" },
  { tech: "Artificial Intelligence", value: "artificial_intelligence" },
  { tech: "DataScience", value: "datascience" },
  { tech: "Hadoop", value: "hadoop" },
  { tech: "Apache Spark", value: "apache_spark" },
  { tech: "Tensorflow", value: "tensorflow" },
  { tech: "Javascript", value: "Javascript" },
  { tech: "Android", value: "Android" },
  { tech: "Datastructures", value: "Datastructures" },
  { tech: "C++", value: "cplusplus" },
  { tech: "Java", value: "Java" },
  { tech: "React", value: "React" },
  { tech: "Html", value: "Html" },
  { tech: "Angular", value: "Angular" },
  { tech: "SQL", value: "SQL" },
  { tech: "Python", value: "Python" },
  { tech: "C", value: "C" },
  { tech: "Node", value: "Node" },
  { tech: "PHP", value: "PHP" },
  { tech: "Django", value: "Django" },
  { tech: "Bootstrap", value: "Bootstrap" },
  { tech: "Typescript", value: "Typescript" },
  { tech: "React Native", value: "react_native" },
  { tech: "Git", value: "Git" },
  { tech: "Flutter", value: "Flutter" },
  { tech: "Swift", value: "Swift" },
  { tech: "Redux", value: "Redux" },
  { tech: "Vue", value: "Vue" },
  { tech: "Software Testing", value: "software_testing" },
  { tech: "Computer Science", value: "computer_science" },
  { tech: "Ruby", value: "Ruby" },
  { tech: "SEO", value: "SEO" },
  { tech: "CSharp", value: "CSharp" },
  { tech: "jQuery", value: "jQuery" },
  { tech: "Go", value: "Go" },
  { tech: "Css", value: "Css" },
  { tech: "Linux", value: "Linux" },
];

export const getTechLable = (tech) => {
  const data = technologyOptions.filter((item) => item.value === tech);
  return data && data[0].tech;
};
