import { Popover } from "antd";
import React from "react";
import FilterPopover from "./FilterPopover";

const ApplicationSearchHeader = ({
  handleSearch,
  searchText,
  totalApplications,
  downloadExel,
}) => {
  return (
    <>
      <div className='row mx-0 my-3 justify-content-left'>
        <div className='col-md-6 col-12 d-flex text-left mb-md-0 mb-3'>
          <h1 className='mb-0'>Club Members Applications</h1>
          <p>{totalApplications}</p>
        </div>
        <div className='col-md-3 col-12 d-flex justify-content-left'>
          <div className='input-group input-group-lg mr-3'>
            <div className='input-group-prepend'>
              <span
                className='input-group-text bg-white'
                id='inputGroup-sizing-lg'
              >
                {searchText.length ? (
                  <i
                    onClick={() => {
                      handleSearch("");
                    }}
                    className='fas fa-times search-icon'
                  />
                ) : (
                  <i className='fa fa-search search-icon' />
                )}
              </span>
            </div>
            <input
              onChange={(e) => handleSearch(e.target.value)}
              value={searchText}
              type='text'
              name='search'
              className='form-control'
              aria-label='Large'
              aria-describedby='inputGroup-sizing-sm'
            />
          </div>
          <Popover
            arrow={false}
            placement='bottomRight'
            content={FilterPopover}
            title='Filter Applications'
            trigger='click'
          >
            <div className='d-flex align-items-center btn border'>
              <i className='fas fa-filter search-icon' />
            </div>
          </Popover>
        </div>
        <div className='col-md-3 col-12 text-right align-items-center'>
          <button className='btn btn-primary' onClick={() => downloadExel()}>
            <i className='fas fa-download blue mr-2 text-white' />
            Download File
          </button>
        </div>
      </div>
    </>
  );
};

export default ApplicationSearchHeader;
