import React, { useEffect, useState } from "react";
import { techIcon } from "../helpers";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";

import Comment from "../comment";
import { handleTextVisibility } from "../../../../../../utils";
import { Helmet } from "react-helmet";

const TutorialPage = ({
  match: {
    params: { id },
  },
  userData,
  userData: { userName, email, myTutorialsLibrary },
  getTutorialById,
  tutorial,
  tutorial: { tags, img, description, link, createdAt },
  unlikeTutorial,
  likeTutorial,
  saveToLibrary,
  unsaveFromLibrary,
  submitter,
}) => {
  const history = useHistory();
  const [techDetails, setTechDetails] = useState({});
  const [isSaved, setSaved] = useState();
  const [isLiked, setLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    techIcon.forEach((item) => {
      if (item.id === id) {
        return setTechDetails(item);
      }
    });

    getTutorialById(id).then((res) => {
      if (res) {
        setTechDetails(res.data);
      }
    });
    window.scrollTo(0, 0);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    techIcon.find((item) => {
      if (item.id === id) {
        return setTechDetails(item);
      }
    });
    if (tutorial) {
      setLiked(
        tutorial
          ? tutorial.likes && tutorial.likes.find((like) => like === userName)
          : false,
      );
      setLikeCount(tutorial ? tutorial.likes && tutorial.likes.length : 0);
      setSaved(
        userData &&
          userData.myTutorialsLibrary &&
          myTutorialsLibrary.find((lib) => lib === tutorial.id),
      );
    }
  }, [tutorial]);

  const handleUnlike = () => {
    unlikeTutorial({ id, userName }).then((res) => {
      if (res) {
        setLiked(false);
        getTutorialById(id);
        // setLikeCount(likesCount-1)
      }
    });
  };

  const handleLike = () => {
    if (!userName) {
      return history.push(`/login`);
    }
    likeTutorial({ id, userName }).then((res) => {
      if (res) {
        setLiked(true);
        getTutorialById(id);
        // setLikeCount(likesCount + 1)
      }
    });
  };

  const handleSaveToLibrary = () => {
    if (!userName) {
      return history.push(`/login`);
    }
    const saveItem = tutorial.id;
    saveToLibrary({ saveItem, email }).then((res) => {
      if (res) {
        setSaved(true);
      }
    });
  };

  const handleUnsaveFromLibrary = () => {
    const saveItem = tutorial.id;
    unsaveFromLibrary({ saveItem, email }).then((res) => {
      if (res) {
        setSaved(false);
      }
    });
  };

  return (
    <>
      <Helmet>
        <title>{`Find the Best ${
          techDetails && techDetails.technology
        } Courses & Tutorials | Datacode.in`}</title>
        <meta
          data-react-helmet='true'
          name='description'
          content='Are you desperate for learning? Here’s the Best Material awaiting for you, show your determination towards the knowledgeable world.'
        ></meta>
        <meta
          data-react-helmet='true'
          http-equiv='cleartype'
          content='on'
        ></meta>
        <meta
          data-react-helmet='true'
          name='apple-mobile-web-app-capable'
          content='yes'
        ></meta>
        <meta
          data-react-helmet='true'
          name='robots'
          content='max-snippet:-1, max-image-preview:large, max-video-preview:-1'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:locale'
          content='en_US'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:type'
          content='website'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:title'
          content={`Find the Best ${
            techDetails && techDetails.technology
          } Courses & Tutorials | Datacode.in`}
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:description'
          content='Are you desperate for learning? Here’s the Best Material awaiting for you, show your determination towards the knowledgeable world.'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:url'
          content='https://www.datacode.in/'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:site_name'
          content='Datacode.in'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:image'
          content='https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:image:secure_url'
          content='https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:image:width'
          content='400'
        ></meta>
        <meta
          data-react-helmet='true'
          property='og:image:height'
          content='400'
        ></meta>
        <meta
          data-react-helmet='true'
          name='keywords'
          content='programming,coding,online, code online, online judge, practice, run code, test, expected outcome, programming competition, programming contest, online programming, online computer programming'
        ></meta>
        <meta
          data-react-helmet='true'
          name='twitter:card'
          content='summary_large_image'
        ></meta>
        <meta
          data-react-helmet='true'
          name='twitter:description'
          content='Are you desperate for learning? Here’s the Best Material awaiting for you, show your determination towards the knowledgeable world.'
        ></meta>
        <meta
          data-react-helmet='true'
          name='twitter:title'
          content={`Find the Best ${
            techDetails && techDetails.technology
          } Courses & Tutorials | Datacode.in`}
        ></meta>
        <meta
          data-react-helmet='true'
          name='twitter:site'
          content='@datacode_in'
        ></meta>
        <meta
          data-react-helmet='true'
          name='twitter:creator'
          content='@datacode_in'
        ></meta>
        <meta
          data-react-helmet='true'
          name='twitter:image'
          content='https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png'
        ></meta>
        <link
          data-react-helmet='true'
          rel='shortlink'
          href='https://www.datacodoe.in/'
        ></link>
        <link
          data-react-helmet='true'
          rel='dns-prefetch'
          href='//www.datacode.in'
        ></link>
        <link
          data-react-helmet='true'
          rel='dns-prefetch'
          href='//s.w.org'
        ></link>
        <link
          data-react-helmet='true'
          rel='alternate'
          type='application/rss+xml'
          title='Datacode'
          href='https://www.datacode.in/'
        ></link>
        <link
          data-react-helmet='true'
          rel='canonical'
          href='https://www.datacode.in/'
        ></link>
      </Helmet>
      <div className='row m-0 mt-3 mt-md-0 pb-4 tutorial-page justify-content-center'>
        <div className='col-12 p-md-5 p-0'>
          <div className='row m-0 py-4 py-md-0 category-header'>
            <div className='col-12 col-md-6 py-4 text-center left-header-section px-md-5 px-2'>
              <p className='text-left nav-link px-3 px-md-0'>
                <Link to='/index_technologies'>Tutorials</Link> /{" "}
                <Link
                  to={`/tutorials/${techDetails && techDetails.technology}`}
                >{`${
                  techDetails && techDetails.technology
                } Tutorial`}</Link>{" "}
                /{" "}
                <Link
                  to={`/tutorials/${techDetails && techDetails.technology}`}
                >{`${handleTextVisibility(techDetails.title, 50)}`}</Link>
              </p>
              <h2>{techDetails && techDetails.technology}</h2>
              <h1>{techDetails && techDetails.title}</h1>
              <div className='tags-input row m-0'>
                <div className='col-12 p-0'>
                  <div className='tags-area d-flex justify-content-center border-0'>
                    {tags &&
                      tags.map((tag, index) => (
                        <div className='tag' key={index}>
                          <span>{tag}</span>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <p className='mt-3'>
                {`${handleTextVisibility(description, 200)}`}
              </p>
              <a target='blank' href={techDetails && techDetails.link}>
                <button className='text-center btn start-learning-btn'>
                  Start Learning
                </button>
              </a>
            </div>
            <div className='col-12 col-md-6 px-0 text-center d-flex justify-content-right right-header-section'>
              <img
                height='300px'
                width='300px'
                src={
                  img
                    ? img
                    : require(`../../../../../../assets/images/svg/tech_mentor.svg`)
                }
                alt='avatar'
                loading='lazy'
                className='text-right my-auto mx-auto img-fluid img-circle d-block'
              />
            </div>
          </div>
        </div>
        <div className='row m-0 mt-5 px-3 px-0 align-items-center'>
          <div className='col-12 p-3 col-md-6 submitter-card'>
            <Link to={`/profile/${submitter && submitter.userName}`}>
              <h6>Tutorial Submitted By:-</h6>
              <div className='row m-0 mt-3'>
                <div className='col-4'>
                  <img
                    height='100px'
                    width='100px'
                    src={
                      submitter && submitter.imgUrl
                        ? submitter.imgUrl
                        : require(`../../../../../../assets/images/svg/React.png`)
                    }
                    alt='avatar'
                    loading='lazy'
                    className='rounded-circle text-right '
                  />
                </div>
                <div className='col-8'>
                  <h6>
                    {submitter && submitter.firstName
                      ? `${submitter.firstName} ${submitter.lastName}`
                      : "Datacode Contributor"}
                  </h6>
                  <p>{submitter ? submitter.bio : ""}</p>
                  <span>{moment(createdAt).fromNow()}</span>
                </div>
              </div>
            </Link>
          </div>
          <div className='col-12 col-md-6 text-center justify-content-space-between'>
            <div className='row m-0 mt-5 mt-md-0'>
              <div className='col-4 '>
                {isLiked ? (
                  <i
                    onClick={() => handleUnlike()}
                    className='fad fa-heart red mx-2'
                  />
                ) : (
                  <i
                    onClick={() => handleLike()}
                    className='far fa-heart red mx-2'
                  />
                )}
                <h1 className='mt-md-4 mt-2 mb-0'>{likeCount}</h1>
              </div>
              <div className='col-4'>
                {isSaved ? (
                  <>
                    <i
                      onClick={() => handleUnsaveFromLibrary()}
                      className='fad fa-bookmark voilet mx-2'
                    />
                    <p className='mt-md-4 mt-2 mb-0'>unsave from library</p>
                  </>
                ) : (
                  <>
                    <i
                      onClick={() => handleSaveToLibrary()}
                      className='far fa-bookmark voilet mx-2'
                    />
                    <p className='mt-md-4 mt-2 mb-0'>save to library</p>
                  </>
                )}
              </div>
              <div className='col-4'>
                <Link to={`/tutorial/${id}`}>
                  <i className='fad fa-comments-alt voilet mx-2' />
                </Link>
                <h1 className='mt-md-4 mt-2 mb-0'>
                  {tutorial.comments && tutorial.comments.length}
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className='m-0 mt-5 px-3 comment-section justify-content-center'>
          <h6 className='text-center'>
            Write your reviews and comments of this tutorial
          </h6>
          <p>
            ask question and provide information that might be helpful to the
            people taking this course
          </p>
          <Comment id={id} userName={userName} />
          {tutorial.comments &&
            tutorial.comments.reverse().map((item, i) => (
              <div className='mt-5 user-comment p-3' key={i}>
                <div className='d-flex p-0 mb-2 align-items-center'>
                  <img
                    height='35px'
                    width='35px'
                    src={require(`../../../../../../assets/images/svg/React.png`)}
                    alt='avatar'
                    loading='lazy'
                    className='mr-2 text-right img-fluid img-circle d-block'
                  />
                  <div>
                    <h6 className='mb-0'>{item.postedBy}</h6>
                    <span className='comment-time'>
                      {moment(item.createdAt).fromNow()}
                    </span>
                  </div>
                </div>
                <p className='text-left'>{item.text}</p>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

TutorialPage.defaultProps = {
  userName: "",
  email: "",
  id: "",
  userData: {},
  tutorial: {},
  tags: [],
};

TutorialPage.propTypes = {
  userName: PropTypes.string,
  email: PropTypes.string,
  userData: PropTypes.object,
  id: PropTypes.string,
  tutorial: PropTypes.object,
};

export default TutorialPage;
