import * as constants from "../constants";

const setLocalUser = (state, action) => {
  if (action.payload.data.email_verify !== "verified") {
    return { ...state };
  }
  localStorage.setItem("userDetails", JSON.stringify(action.payload.data));
  localStorage.setItem("access_token", action.payload.token);

  return {
    ...state,
    error: null,
    currentUser: action.payload.data,
    batchParticipant: action.payload.batchParticipant,
    codeBatchDetails: action.payload.codeBatchDetails,
  };
};

const getLoginUser = (state, action) => {
  localStorage.setItem("userDetails", JSON.stringify(action.payload.data));
  localStorage.setItem("access_token", action.payload.token);

  return {
    ...state,
    error: null,
    currentUser: action.payload.data,
    loginUserLoading: !state.loginUserLoading,
  };
};

const setLoginUserLoading = (state) => {
  return {
    ...state,
    loginUserLoading: !state.loginUserLoading,
  };
};

const getLogoutUser = (state) => {
  // localStorage.setItem("userDetails", JSON.stringify({}));
  localStorage.removeItem("userDetails");
  localStorage.removeItem("access_token");
  localStorage.removeItem("batchParticipant");
  localStorage.removeItem("batchDetails");

  return {
    ...state,
    currentUser: {},
    userProfile: {},
  };
};

const getLoginUserFailure = (state, action) => ({
  ...state,
  error: action.payload,
});

const getSignupUser = (state, action) => {
  if (action.payload.data.email_verify !== "verified") {
    return {
      ...state,
      loginUserLoading: !state.loginUserLoading,
    };
  }
  localStorage.setItem("userDetails", JSON.stringify(action.payload.data));
  localStorage.setItem("access_token", action.payload.token);

  return {
    ...state,
    error: null,
    currentUser: action.payload.data,
    loginUserLoading: !state.loginUserLoading,
  };
};

const setSignupUserLoading = (state) => ({
  ...state,
  signupUserLoading: !state.signupUserLoading,
});

const getSignupUserFailure = (state, action) => ({
  ...state,
  error: action.payload,
});

// user Profile

const setUserProfile = (state, action) => {
  return {
    ...state,
    error: null,
    userProfile: action.payload,
    userProfileLoading: !state.userProfileLoading,
  };
};

const setUserProfileLoading = (state) => ({
  ...state,
  userProfileLoading: !state.userProfileLoading,
});

const setAddSpeakerLoading = (state) => ({
  ...state,
  addSpeakerLoading: !state.addSpeakerLoading,
});

const setImageUploading = (state) => ({
  ...state,
  imageUploading: !state.imageUploading,
});

const setForgotPasswordFailure = (state, action) => ({
  ...state,
  forgotpassword_error: action.payload,
});

export const getUserDataHandlers = {
  [constants.GET_LOGIN_USER]: getLoginUser,
  [constants.GET_LOGIN_USER_ERROR]: getLoginUserFailure,
  [constants.GET_SIGNUP_USER]: getSignupUser,
  [constants.GET_SIGNUP_USER_ERROR]: getSignupUserFailure,
  [constants.GET_LOGOUT_USER]: getLogoutUser,
  [constants.SET_SIGNUP_USER_LOADING]: setSignupUserLoading,
  [constants.SET_LOGIN_USER_LOADING]: setLoginUserLoading,
  [constants.SET_USER_PROFILE]: setUserProfile,
  [constants.SET_USER_PROFILE_LOADING]: setUserProfileLoading,
  [constants.SET_LOCAL_USER]: setLocalUser,
  [constants.SET_ADD_SPEAKER_LOADING]: setAddSpeakerLoading,
  [constants.SET_IMAGE_UPLOADING]: setImageUploading,
  [constants.SET_FORGOT_PASSWORD_ERROR]: setForgotPasswordFailure,
};
