export const dayscodeTestimonials = [
  {
    imgUrl: 'http://res.cloudinary.com/datacode/image/upload/v1652611521/ypfhrfrnydsslbno1tos.jpg',
    name: 'Anju Kumari',
    testimonial: 'Datacode is the ideal learning platform for a beginner, with interesting questions, quizzes, and coding competitions, as well as helpful mentors and speakers that can answer any questions about any topic, including career advise.',
  },
  {
    imgUrl: 'https://res.cloudinary.com/datacode/image/upload/v1638727510/i8otfaoderairnvbmdy2.jpg',
    name: 'CHANDANA SAMINENI',
    testimonial: `I'm very excited to share my journey with Datacode. Datacode is such an amazing platform to learn to code. I participated in 30 days coding program their I learned new techniques in problem-solving. I improved a lot. Thanks to Datacode. I'm very eager to participate in the new programs of Datacode.`,
  },
  {
    imgUrl: 'https://res.cloudinary.com/datacode/image/upload/v1652614014/kvyi0taoeo2il4y8qgjn.jpg',
    name: 'Kshitij Sharma',
    testimonial: 'Great Great experience, made a month I thought that was gonna be wasted, very very helpful and insightful. Would love to be connected with all participants as well as mentors. Just absolutely loved it. Thank you guys.',
  },
  {
    imgUrl: 'https://res.cloudinary.com/datacode/image/upload/v1652614449/yc7qbx0cm3hshclnhama.png',
    name: 'Lisha Vasundhariya',
    testimonial: `First of all thanks for a amazing learning platform Datacode. My experience with 30 days code challenge  was really awesome. The mentors were really very supportive and friendly. This challenge is helped me a lots during my placement preparation period`,
  },
  {
    imgUrl: 'https://res.cloudinary.com/datacode/image/upload/v1652614108/plf4wn6y2dy5q4u6sbij.jpg',
    name: 'Aaron Dsouza',
    testimonial: `The 30 Days of Code Bootcamp by Datacode was very informative and helpful as it not only helped me brush up on most DSA concepts, but I also got to learn new concepts. I would like to thank Datacode for conducting such a Bootcamp and providing a community for learning and networking. I would recommend this Bootcamp to any coding enthusiast.`,
  },
  {
    imgUrl: 'https://res.cloudinary.com/datacode/image/upload/v1652614418/pzc5qpulsetmjkj4c37t.jpg',
    name: 'Priyanka Rani',
    testimonial: `My expectation for joining this 30 Days Coding Bootcamp of DataCode are full filled. As expected everything is maintained in well manner. DataCode's website portal content is good and most thing I liked that leaderboard section, Which always remember me Where I stand and give me inspiration. There is good mentor support for whole session.`,
  },
  // {
  //   imgUrl: 'https://res.cloudinary.com/datacode/image/upload/v1652614363/rudcrdh6eczsgvc3uhar.jpg',
  //   name: 'Trupti Satsangi',
  //   testimonial: ``,
  // },
  {
    imgUrl: 'https://res.cloudinary.com/datacode/image/upload/v1652616555/qfkbh8obct0k5okfbavq.jpg',
    name: 'Arpit Lad',
    testimonial: `It's a good learning platform for beginners. Team members in the community are very helpful and supportive. They listen to your ideas and also work over it. They also help me to showcase my skills and serve the community at my best.`,
  },
  {
    imgUrl: 'https://res.cloudinary.com/datacode/image/upload/v1652616204/mqozhulbim51gcz5sccw.jpg',
    name: 'Kashish Ahuja',
    testimonial: `Datacode is an amazing learning platform for beginners. Even the community and all the team members are very supportive and understanding. I get to learn something new every time I connect with the team.`,
  },
  {
    imgUrl: 'https://res.cloudinary.com/datacode/image/upload/v1652616233/vq2np5urzujvy5rfladp.png',
    name: 'Sunandani Sharma',
    testimonial: `Best working structure,which gave me the energy,how to work together.Datacode taught me the flow of events ,it helped to enhance and showcase my skills, I'm very thank ful for Datacode giving me this opportunity.`,
  },
  {
    imgUrl: 'https://res.cloudinary.com/datacode/image/upload/v1652616264/w5kf6qahvjlc3dzs1s2t.jpg',
    name: 'Palak Majawdiya ',
    testimonial: `It was really a great experience with Datacode community as a team member. I really enjoyed the timeperiod which i spended over here.Thankyou Datacode for appreciating my work and to give platform to showcase my skills.`,
  },
  {
    imgUrl: 'https://res.cloudinary.com/datacode/image/upload/v1652616291/d3dffodxevftakcbvqqu.jpg',
    name: 'Manvi Agrawal',
    testimonial: `Database is a platform where students can explore their Interests and also the students can learn theirs subject. Add on datacode conduct workshop and session which really help students to get knowledge of every field.`,
  },
  {
    imgUrl: 'https://res.cloudinary.com/datacode/image/upload/v1652616515/a2yka4naq81uqiipmmpe.jpg',
    name: 'Rahul chaurasiya',
    testimonial: `Datacode , an online edtech platform focuses on teaching coding and other programming courses to beginners. The platform provides online programming courses that range from Basic foundation-level courses (to build a strong base) to Advanced-level courses. Most up-to-date programming technologies are taught to match the requirements of the software industry. It is best plateform for Begginners. `,
  },
  {
    imgUrl: 'https://res.cloudinary.com/datacode/image/upload/v1652616454/qwvgghq1ohz0gfczwgzh.jpg',
    name: 'Muskan Gupta',
    testimonial: `"First of all I liked the way how my ideas and thoughts were heard and how all the team members share there thoughts and valuable feedbacks in brainstorming sessions. I worked in many different organizations before but my efforts and ideas were never appreciated that much!
    Overall I must say being in Datacode not only your technical knowledge increases but your soft skills are also enhanced."`,
  },
  {
    imgUrl: 'https://res.cloudinary.com/datacode/image/upload/v1652616485/zoan70g4el6fzyloromm.jpg',
    name: 'Pawan Pradhan',
    testimonial: `It is a really good Community the peoples in Datacode are very helpful and supportive they listen to your suggestion and they actually work upon it `,
  },
  {
    imgUrl: 'https://res.cloudinary.com/datacode/image/upload/v1652616594/qigijyvn70udqbptafg2.png',
    name: 'Aarti Jethaniya',
    testimonial: `I am glad to join this community where everyone is like a friend and guide me in every situation I learned a lot from this community and I hope in future I will learn a lot from this community.`,
  },
]
