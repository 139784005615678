import React from 'react'

const DaysCodeBatchPageNav = ({ activeTab, setActiveTab }) => {
    return (
        <>
            <div className="row m-0">
                <div className="col-12">
                    <div className="event-dashboard-header w-100 px-0 table-responsive table">
                        <ul>
                            <li >
                                <span
                                    onClick={() => setActiveTab('problem')}
                                    className={activeTab === 'problem' ? "active" : ""}
                                >
                                    Problem
                                </span>
                            </li>
                            <li>
                                <span
                                    onClick={() => setActiveTab('lesson')}
                                    className={activeTab === 'lesson' ? "active" : ""}
                                >
                                    Lesson
                                </span>
                            </li>
                            <li>
                                <span
                                    onClick={() => setActiveTab('quiz')}
                                    className={activeTab === 'quiz' ? "active" : ""}
                                >
                                    Quiz
                                </span>
                            </li>
                            <li>
                                <span
                                    onClick={() => setActiveTab('mentor')}
                                    className={activeTab === 'mentor' ? "active" : ""}
                                >
                                    Mentor
                                </span>
                            </li>
                            <li>
                                <span
                                    onClick={() => setActiveTab('participant')}
                                    className={activeTab === 'participant' ? "active" : ""}
                                >
                                    Participant
                                </span>
                            </li>
                            <li>
                                <span
                                    onClick={() => setActiveTab('roadmap')}
                                    className={activeTab === 'roadmap' ? "active" : ""}
                                >
                                    RoadMap
                                </span>
                            </li>
                        </ul>
                        
                    </div>
                </div>
            </div>
        </>
    )
}

export default DaysCodeBatchPageNav