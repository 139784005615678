import axios from "axios";
import { toast } from "react-hot-toast";
import DatacodeConfig from "../../../../core/config";
import { generateQueryParams } from "../../../../utils/helpers";
import { triggerNotifier } from "../../../../utils/notifier";
import { makePayment } from "../../../../utils/payment";
import { setLocalUser } from "../../../user/actions";
import {
  API_LOADING,
  CHECK_IN_BATCH_LOADING,
  GET_ALL_CODE_BATCHES,
  GET_ALL_CODE_BATCHES_LOADING,
  GET_ALL_USERS_FEEDBACKS_LOADING,
  GET_ALL_USERS_PROGRESS,
  GET_ALL_USERS_PROGRESS_LOADING,
  GET_CODE_BATCH,
  GET_CONTENT_DETAILS_LOADING,
  GET_PRACTICE_PROBLEMS_LOADING,
  GET_PROBLEMS_LOADING,
  GET_PROBLEM_DETAILS_LOADING,
  GET_QUIZ_CHALLENGE_REPORT_LOADING,
  GET_SPEAKERS_LIST_LOADING,
  GET_USER_SUBMISSIONS_LOADING,
  SET_CHALLENGES_COMPLETED_STATUS,
  SET_DAYS_USER_REG_LOADING,
  SET_LESSON_COMPLETED_STATUS,
  SET_PROBLEM_LOADING,
  SET_SOLUTION_SUBMISSION_LOADING,
  SET_SUBMISSION_COMPLETED_STATUS,
  SET_UPDATED_QUIZ_CHALLENGES,
  SET_USER_ATTENDANCE_PRESENT_LOADING,
  SET_USER_FEEDBACK_LOADING,
} from "../constants";
import * as actions from "./actionCreators";

const baseURL = DatacodeConfig.baseURL;

export const checkOutAPI = (batch) => (dispatch) => {
  return axios
    .post(`${baseURL}/dayscode/code_batch/create/paymentorder`, { batch })
    .then((res) => {
      if (res.status === 200) {
        dispatch(makePayment(res.data)).then((res) => {
          return { success: true, data: res.data };
        });
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      console.log("payment Error", error);
    });
};

export const addProblem = (problem) => (dispatch) => {
  dispatch({ type: SET_PROBLEM_LOADING });
  return axios
    .post(`${baseURL}/dayscode/add_problem`, problem)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: SET_PROBLEM_LOADING });
        return { success: true, problem: res.data && res.data.problem };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_PROBLEM_LOADING });
      console.log("add problem Error", error);
    });
};

export const addAdminSolution = (id, solution) => (dispatch) => {
  dispatch({ type: SET_PROBLEM_LOADING });
  return axios
    .put(`${baseURL}/dayscode/admin_submission/${id}`, solution)
    .then((res) => {
      if (res.status === 200) {
        console.log("created solution", res.data);
        dispatch({ type: SET_PROBLEM_LOADING });
        dispatch(getProblemDetails(id));
        return { success: true, solution: res.data && res.data.solution };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_PROBLEM_LOADING });
      console.log("add solution Error", error);
    });
};

export const addProblemTestCase = (id, testcase) => (dispatch) => {
  dispatch({ type: SET_PROBLEM_LOADING });
  return axios
    .put(`${baseURL}/dayscode/test_case/${id}`, testcase)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: SET_PROBLEM_LOADING });
        dispatch(getProblemDetails(id));
        return { success: true, testCase: res.data && res.data.testCase };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_PROBLEM_LOADING });
      console.log("add testcase Error", error);
    });
};

export const editProblemTestCase = (id, testcase) => (dispatch) => {
  dispatch({ type: SET_PROBLEM_LOADING });
  return axios
    .put(`${baseURL}/dayscode/edit_test_case/${id}`, testcase)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: SET_PROBLEM_LOADING });
        dispatch(getProblemDetails(id));
        return { success: true, testCase: res.data && res.data.testCase };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_PROBLEM_LOADING });
      console.log("edit testcase Error", error);
    });
};

export const solutionSubmission = (solution) => (dispatch) => {
  return axios
    .post(`${baseURL}/dayscode/submission/create`, solution)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: SET_SOLUTION_SUBMISSION_LOADING });
        dispatch(getProblemDetails(solution.problem));
        dispatch(
          getUserSubmissions({
            codeUser: solution.codeUser,
            batch: solution.batch,
          }),
        );
        dispatch({ type: SET_SUBMISSION_COMPLETED_STATUS, payload: true });
        return { success: true, data: res.data };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_SOLUTION_SUBMISSION_LOADING });
      console.log("solutionSubmission Error", error);
    });
};

// export const eventQuestion = (question) => (dispatch) => {
//   dispatch({ type: SET_EVENT_REGISTRATION_LOADING });
//   return axios
//     .put(`${baseURL}/event/add_question`, question)
//     .then((res) => {
//       if (res.status === 200) {
//         dispatch({ type: SET_EVENT_REGISTRATION_LOADING });
//         return { success: true, data: res.data && res.data.event };
//       } else {
//         return { success: false };
//       }
//     })
//     .catch((error) => {
//       dispatch({ type: SET_EVENT_REGISTRATION_LOADING });
//       console.log("eventRegister Error", error);
//     });
// };

// export const getProblems = () => (dispatch) => {
//   dispatch({ type: SET_EVENT_REGISTRATION_LOADING });
//   return axios
//     .get(`${baseURL}/user/get_speakers`)
//     .then(({ data }) => {
//       if (data.success) {
//         return { success: true, data: data.Speakers };
//       }
//     })
//     .catch((error) => {
//       dispatch({ type: SET_EVENT_REGISTRATION_LOADING });
//       console.log("get speakers error", error);
//     });
// };

export const getProblemDetails = (id) => (dispatch) => {
  dispatch({ type: GET_PROBLEM_DETAILS_LOADING });
  return axios
    .post(`${baseURL}/dayscode/get_problem`, { id })
    .then(({ data }) => {
      if (data.success) {
        dispatch(actions.setProblemDetails(data.problem));
        return { success: true, data: data.problem };
      }
    })
    .catch((error) => {
      dispatch({ type: GET_PROBLEM_DETAILS_LOADING });
      console.log("get problem error", error);
    });
};

// export const eventFeedback = (feedback) => (dispatch) => {
//   dispatch({ type: SET_EVENT_REGISTRATION_LOADING });
//   return axios
//     .put(`${baseURL}/event/feedback_event`, feedback)
//     .then((res) => {
//       if (res.status === 200) {
//         dispatch({ type: SET_EVENT_REGISTRATION_LOADING });
//         return { success: true, data: res.data && res.data.event };
//       } else {
//         return { success: false };
//       }
//     })
//     .catch((error) => {
//       dispatch({ type: SET_EVENT_REGISTRATION_LOADING });
//       console.log("event Feedback Error", error);
//     });
// };

// export const addEventSpeaker = (speaker) => (dispatch) => {
//   dispatch({ type: SET_EVENT_REGISTRATION_LOADING });
//   return axios
//     .put(`${baseURL}/event/event_speaker`, speaker)
//     .then((res) => {
//       if (res.status === 200) {
//         dispatch({ type: SET_EVENT_REGISTRATION_LOADING });
//         return { success: true, data: res.data && res.data.event };
//       } else {
//         return { success: false };
//       }
//     })
//     .catch((error) => {
//       dispatch({ type: SET_EVENT_REGISTRATION_LOADING });
//       console.log("event Speaker Error", error);
//     });
// };

export const getProblems =
  ({ status, problem_type, day, topic }) =>
  (dispatch) => {
    dispatch({ type: GET_PROBLEMS_LOADING });
    return axios
      .get(
        `${baseURL}/dayscode/fetch_problems${generateQueryParams({
          day: day ? day : null,
          problem_type: problem_type ? problem_type : "assignment",
          status: status ? status : "public",
          topic: topic,
        })}`,
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch(actions.setProblemsList(res.data && res.data.problems));
          return { success: true, data: res.data && res.data.problems };
        } else {
          return { success: false };
        }
      })
      .catch((error) => {
        dispatch({ type: GET_PROBLEMS_LOADING });
        console.log("problem List Error", error);
      });
  };

export const getPracticeProblems = (day) => (dispatch) => {
  dispatch({ type: GET_PRACTICE_PROBLEMS_LOADING });
  return axios
    .get(
      `${baseURL}/dayscode/fetch_problems${generateQueryParams({
        day: day ? day : null,
        problem_type: "practice",
        status: "public",
      })}`,
    )
    .then((res) => {
      if (res.status === 200) {
        dispatch(
          actions.setPracticeProblemsList(res.data && res.data.problems),
        );
        return { success: true, data: res.data && res.data.problems };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: GET_PRACTICE_PROBLEMS_LOADING });
      console.log("problem List Error", error);
    });
};

export const getProblemSubmission =
  ({ codeUser, batch, problem }) =>
  (dispatch) => {
    dispatch({ type: GET_PROBLEM_DETAILS_LOADING });
    return axios
      .get(
        `${baseURL}/dayscode/submission/${generateQueryParams({
          codeUser: codeUser ? codeUser : null,
          batch: batch ? batch : null,
          problem: problem ? problem : null,
        })}`,
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: GET_PROBLEM_DETAILS_LOADING });
          return {
            success: true,
            submission: res.data && res.data.submissions,
          };
        } else {
          return { success: false };
        }
      })
      .catch((error) => {
        dispatch({ type: GET_PROBLEM_DETAILS_LOADING });
        console.log("User Submition Loading Error", error);
      });
  };

export const getUserSubmissions =
  ({ codeUser, status, batch }) =>
  (dispatch) => {
    dispatch({ type: GET_USER_SUBMISSIONS_LOADING });
    return axios
      .get(
        `${baseURL}/dayscode/submissions_list${generateQueryParams({
          batch,
          codeUser,
          status,
        })}`,
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: GET_USER_SUBMISSIONS_LOADING });
          dispatch(actions.setUserSubmissionsList(res.data && res.data));
          return { success: true, data: res.data && res.data.submissions };
        } else {
          return { success: false };
        }
      })
      .catch((error) => {
        dispatch({ type: GET_USER_SUBMISSIONS_LOADING });
        console.log("soluitions List Error", error);
      });
  };

export const getUserSubmissionsByStatus = (userName, status) => (dispatch) => {
  dispatch({ type: GET_USER_SUBMISSIONS_LOADING });
  return axios
    .post(
      `${baseURL}dayscode/user_submissions${generateQueryParams({
        batchId: 3,
        userName,
        status,
      })}`,
    )
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: GET_USER_SUBMISSIONS_LOADING });
        return {
          success: true,
          userSubmissions: res.data && res.data.submissions,
        };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: GET_USER_SUBMISSIONS_LOADING });
      console.log("soluitions List Error", error);
    });
};

export const getBatchLeaderboard = (batch) => (dispatch) => {
  return axios
    .get(`${baseURL}/dayscode/code_batch/${batch}/leaderboard`)
    .then((res) => {
      if (res.status === 200) {
        dispatch(actions.setBatchLeaderboard(res.data && res.data.leaderBoard));
        return { success: true, data: res.data && res.data.problems };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      console.log("soluitions List Error", error);
    });
};

export const getAllSubmissions =
  ({ problem, batch }) =>
  (dispatch) => {
    dispatch({ type: GET_PROBLEMS_LOADING });
    dispatch({ type: GET_USER_SUBMISSIONS_LOADING });
    return axios
      .get(
        `${baseURL}/dayscode/submission/${generateQueryParams({
          problem: problem || null,
          batch: batch || null,
        })}`,
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: GET_PROBLEMS_LOADING });
          dispatch({ type: GET_USER_SUBMISSIONS_LOADING });

          dispatch(
            actions.setAllSubmissions(
              res.data && res.data.submissions.submissions,
            ),
          );
          return {
            success: true,
            data: res.data && res.data.submissions.submissions,
          };
        } else {
          return { success: false };
        }
      })
      .catch((error) => {
        dispatch({ type: GET_PROBLEMS_LOADING });
        dispatch({ type: GET_USER_SUBMISSIONS_LOADING });
        console.log("problem all soluitions List Error", error);
      });
  };

export const editProblem = (problem) => (dispatch) => {
  dispatch({ type: SET_PROBLEM_LOADING });
  return axios
    .put(`${baseURL}/dayscode/edit_problem`, problem)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: SET_PROBLEM_LOADING });
        dispatch(getProblemDetails(problem.id));
        return { success: true, problem: res.data && res.data.problem };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_PROBLEM_LOADING });
      console.log("edit problem Error", error);
    });
};

export const removeDaysProblem = (id) => (dispatch) => {
  return axios
    .delete(`${baseURL}/dayscode/delete_problem/${id}`)
    .then(({ data }) => {
      if (data.success) {
        dispatch(getProblems());
        return { success: true };
      }
    })
    .catch((error) => {
      console.log("get days user error", error);
    });
};

export const deleteAdminSolution = (id, solutionId) => (dispatch) => {
  dispatch({ type: SET_PROBLEM_LOADING });
  return axios
    .delete(`${baseURL}/dayscode/delete_admin_solution/${id}/${solutionId}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: SET_PROBLEM_LOADING });
        dispatch(getProblemDetails(id));
        return { success: true };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_PROBLEM_LOADING });
      console.log("delete solution Error", error);
    });
};

export const deleteProblemTestCase = (id, testCaseId) => (dispatch) => {
  dispatch({ type: SET_PROBLEM_LOADING });
  return axios
    .delete(`${baseURL}dayscode/delete_testcase/${id}/${testCaseId}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: SET_PROBLEM_LOADING });
        dispatch(getProblemDetails(id));
        return { success: true };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_PROBLEM_LOADING });
      console.log("delete testcase Error", error);
    });
};

export const deleteUserSubmission = (id, userName) => (dispatch) => {
  dispatch({ type: SET_PROBLEM_LOADING });
  return axios
    .delete(`${baseURL}dayscode/delete_user_solution/${id}/${userName}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: SET_PROBLEM_LOADING });
        dispatch(getAllSubmissions(id));
        return { success: true };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_PROBLEM_LOADING });
      console.log("delete user solution Error", error);
    });
};

export const setDaysUserReg = (data, currentUser) => (dispatch) => {
  dispatch({ type: SET_DAYS_USER_REG_LOADING });
  return axios
    .post(`${baseURL}/dayscode/participant/create`, data)
    .then(({ data }) => {
      //
      if (data.success) {
        dispatch(
          setLocalUser({
            ...currentUser,
            dayscodeUser: data.daysuser && data.daysuser._id,
          }),
        );
        dispatch({ type: SET_DAYS_USER_REG_LOADING });
        return { success: true, data: data };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_DAYS_USER_REG_LOADING });
      console.log("reg user error", error);
    });
};

export const editDaysUserReg = (data, currentUser) => (dispatch) => {
  dispatch({ type: SET_DAYS_USER_REG_LOADING });
  return axios
    .patch(`${baseURL}/dayscode/participant/${data._id}`, { data })
    .then(({ data }) => {
      if (data.success) {
        dispatch(
          setLocalUser({
            ...currentUser,
            dayscodeUser: data.daysuser && data.daysuser._id,
          }),
        );
        dispatch({ type: SET_DAYS_USER_REG_LOADING });
      }
      return { success: true, data: data.user };
    })
    .catch((error) => {
      dispatch({ type: SET_DAYS_USER_REG_LOADING });
      console.log("reg user error", error);
    });
};

export const getDaysUserReg = (daysCodeId) => (dispatch) => {
  dispatch({ type: API_LOADING });
  return axios
    .get(`${baseURL}/dayscode/participant/${daysCodeId}`)
    .then(({ data }) => {
      if (data.success) {
        dispatch({ type: API_LOADING });
        dispatch(actions.setDaysUserDetails(data && data?.daysCodeUser));
        return {
          success: true,
          data: data.daysCodeUser,
          waiting: data.waiting,
        };
      }
    })
    .catch((error) => {
      dispatch({ type: API_LOADING });
      console.log("get days user error", error);
    });
};

export const enrollCodeBatch = (batch) => (dispatch) => {
  dispatch({ type: API_LOADING });
  return axios
    .post(`${baseURL}/dayscode/code_batch/participant/batch/${batch}`)
    .then(({ data }) => {
      if (data.success) {
        dispatch({ type: API_LOADING });
        dispatch(actions.setDaysUserDetails(data && data?.data?.codeUser));
        return {
          success: true,
          data: data?.data?.codeUser,
          waiting: data?.data?.waiting,
        };
      }
    })
    .catch((error) => {
      dispatch({ type: API_LOADING });
      console.log("Enroll Batch error", error);
    });
};

export const getDaysUsers = (batchId, isApproved) => (dispatch) => {
  dispatch({ type: GET_PROBLEMS_LOADING });
  return axios
    .get(
      `${baseURL}/dayscode/batch_users/${"3"}${generateQueryParams({
        status: isApproved,
      })}`,
    )
    .then(({ data }) => {
      if (data.success) {
        dispatch(actions.setDaysUsersList(data.users));
        dispatch({ type: GET_PROBLEMS_LOADING });
        return { success: true, data: data.users };
      }
    })
    .catch((error) => {
      dispatch({ type: GET_PROBLEMS_LOADING });
      console.log("get days users error", error);
    });
};

export const getDaysUsersListOptions = (mentorId, admin) => (dispatch) => {
  dispatch({ type: GET_PROBLEMS_LOADING });
  return axios
    .get(`${baseURL}/dayscode/users_list/${mentorId}/${admin}`)
    .then(({ data }) => {
      if (data.success) {
        dispatch(actions.setDaysUsersList(data.users));
        dispatch({ type: GET_PROBLEMS_LOADING });
        return { success: true, data: data.users };
      }
    })
    .catch((error) => {
      dispatch({ type: GET_PROBLEMS_LOADING });
      console.log("get days users list error", error);
    });
};

export const userSelectionAction = (id, data) => (dispatch) => {
  return axios
    .patch(`${baseURL}/dayscode/participant/${id}`, data)
    .then(({ data }) => {
      if (data.success) {
        dispatch(getDaysUsers("3", "all"));
        return { success: true, data: data.user };
      }
    })
    .catch((error) => {
      console.log("get days users error", error);
    });
};

export const deleteDaysUser = (id) => (dispatch) => {
  return axios
    .delete(`${baseURL}/dayscode/participant/${id}`)
    .then(({ data }) => {
      if (data.success) {
        dispatch(getDaysUsers("3", "all"));
        return { success: true, data: data.user };
      }
    })
    .catch((error) => {
      console.log("delete days users error", error);
    });
};

export const approveUserCodeSubmission = (id, userName, data) => (dispatch) => {
  dispatch({ type: SET_PROBLEM_LOADING });
  return axios
    .put(`${baseURL}/dayscode/approve_submission/${id}/${userName}`, data)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: SET_PROBLEM_LOADING });
        dispatch(getAllSubmissions(id));

        return { success: true };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_PROBLEM_LOADING });
      console.log("approve solution Error", error);
    });
};

export const adminNotificationDaysCodeUser = (data) => (dispatch) => {
  dispatch({ type: SET_PROBLEM_LOADING });
  return axios
    .put(`${baseURL}/dayscode/add_notification`, data)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: SET_PROBLEM_LOADING });
        return { success: true };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_PROBLEM_LOADING });
      console.log("add notification Error", error);
    });
};

export const deleteAdminNotification = (id) => (dispatch) => {
  dispatch({ type: SET_PROBLEM_LOADING });
  return axios
    .put(`${baseURL}/dayscode/delete_notification/${id}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: SET_PROBLEM_LOADING });
        return { success: true };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_PROBLEM_LOADING });
      console.log("delete notification Error", error);
    });
};

// -------------Lessons--------------------------------------------

export const createLesson = (lesson) => (dispatch) => {
  dispatch({ type: SET_PROBLEM_LOADING });
  return axios
    .post(`${baseURL}/dayscode/add_lesson`, lesson)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: SET_PROBLEM_LOADING });
        return { success: true, lesson: res.data && res.data.lesson };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_PROBLEM_LOADING });
      console.log("add lesson Error", error);
    });
};

export const editLesson = (lesson) => (dispatch) => {
  dispatch({ type: SET_PROBLEM_LOADING });
  return axios
    .put(`${baseURL}/dayscode/edit_lesson`, lesson)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: SET_PROBLEM_LOADING });
        dispatch(getLessonDetails(lesson.id));
        return { success: true, lesson: res.data && res.data.lesson };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_PROBLEM_LOADING });
      console.log("edit lesson Error", error);
    });
};

export const getLessons =
  ({ status, topic }) =>
  (dispatch) => {
    dispatch({ type: GET_PROBLEMS_LOADING });
    return axios
      .get(
        `${baseURL}/dayscode/fetch_lessons/${generateQueryParams({
          status: status ? status : "public",
          topic: topic,
        })}`,
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch(actions.setLessonsList(res.data && res.data.lessons));
          return { success: true, data: res.data && res.data.lessons };
        } else {
          return { success: false };
        }
      })
      .catch((error) => {
        dispatch({ type: GET_PROBLEMS_LOADING });
        console.log("Lessons List Error", error);
      });
  };

export const getAllLessons = () => (dispatch) => {
  dispatch({ type: GET_PROBLEMS_LOADING });
  return axios
    .get(`${baseURL}/lesson`)
    .then((res) => {
      if (res.status === 200) {
        dispatch(actions.setLessonsList(res.data && res.data.lessons));
        return { success: true, data: res.data && res.data.lessons };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: GET_PROBLEMS_LOADING });
      console.log("Lessons List Error", error);
      triggerNotifier({
        message: error.response.data.message,
        type: "error",
        duration: 2000,
        icon: "⚠️",
      });
    });
};

export const getLessonDetails = (lessonId) => (dispatch) => {
  dispatch({ type: GET_PROBLEM_DETAILS_LOADING });
  dispatch({ type: GET_PROBLEMS_LOADING });

  return axios
    .get(`${baseURL}/lesson/${lessonId}`)
    .then((res) => {
      if (res.status === 200) {
        // triggerNotifier({
        //   message: "Lesson Details Loaded",
        //   type: "success",
        //   duration: 1000,
        //   icon: "👏",
        // });
        dispatch(actions.setLessonDetails(res && res.data.lesson));
        dispatch({ type: GET_PROBLEM_DETAILS_LOADING });
        dispatch({ type: GET_PROBLEMS_LOADING });
        return { success: true, data: res && res.data };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: GET_PROBLEM_DETAILS_LOADING });
      console.log("Lesson Details Error", error);
      // triggerNotifier({
      //   message: "Lesson Details Error",
      //   type: "error",
      //   duration: 1000,
      //   icon: "⚠️",
      // });
    });
};

// export const getLessonDetails = (id) => (dispatch) => {
//   dispatch({ type: GET_PROBLEM_DETAILS_LOADING });
//   return axios
//     .get(`${baseURL}/dayscode/get_lesson/${id}`)
//     .then(({ data }) => {
//       if (data.success) {
//         dispatch(actions.setLessonDetails(data.lesson));
//         return { success: true, data: data.lesson };
//       }
//     })
//     .catch((error) => {
//       dispatch({ type: GET_PROBLEM_DETAILS_LOADING });
//       console.log("get problem error", error);
//     });
// };

export const deleteLesson = (id) => (dispatch) => {
  return axios
    .delete(`${baseURL}/lesson/${id}`)
    .then((res) => {
      if (res.status === 200) {
        triggerNotifier({
          message: "Lesson Deleted",
          type: "success",
          duration: 1000,
          icon: "👏",
        });
        return { success: true };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      console.log("delete lession Error", error);
      triggerNotifier({
        message: "Delete Lesson Error",
        type: "error",
        duration: 1000,
        icon: "⚠️",
      });
    });
};

export const removeLesson = (id) => (dispatch) => {
  return axios
    .delete(`${baseURL}/dayscode/delete_lesson/${id}`)
    .then(({ data }) => {
      if (data.success) {
        dispatch(getLessons());
        return { success: true };
      }
    })
    .catch((error) => {
      console.log("delete lesson error", error);
    });
};

export const setCompleteLesson = (complete) => (dispatch) => {
  dispatch({ type: SET_SOLUTION_SUBMISSION_LOADING });
  return axios
    .put(`${baseURL}/dayscode/complete_lesson`, complete)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: SET_SOLUTION_SUBMISSION_LOADING });
        dispatch({ type: SET_LESSON_COMPLETED_STATUS });
        return {
          success: true,
          result: res.data && res.data.result,
          completedBy: res.data && res.data.completedBy,
        };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_SOLUTION_SUBMISSION_LOADING });
      console.log("complete lesson Error", error);
    });
};

// -----------------Quiz-Challenge------------

export const createQuizChallenge = (quizChallenge) => (dispatch) => {
  dispatch({ type: SET_PROBLEM_LOADING });
  return axios
    .post(`${baseURL}/dayscode/add_quiz_challenge`, quizChallenge)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: SET_PROBLEM_LOADING });
        return {
          success: true,
          quizChallenge: res.data && res.data.quizChallenge,
        };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_PROBLEM_LOADING });
      console.log("add quizChallenge Error", error);
    });
};

export const editQuizChallenge = (quizChallenge) => (dispatch) => {
  dispatch({ type: SET_PROBLEM_LOADING });
  return axios
    .put(`${baseURL}/dayscode/edit_quiz_challenge`, quizChallenge)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: SET_PROBLEM_LOADING });
        dispatch(getQuizChallengeDetails(quizChallenge.id));
        return {
          success: true,
          quizChallenge: res.data && res.data.quizChallenge,
        };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_PROBLEM_LOADING });
      console.log("edit quiz challenge Error", error);
    });
};

export const getQuizChallenges =
  ({ status, topic }) =>
  (dispatch) => {
    dispatch({ type: GET_PROBLEMS_LOADING });
    return axios
      .get(
        `${baseURL}/dayscode/fetch_quiz_challenges/${generateQueryParams({
          status: status ? status : "public",
          topic: topic,
        })}`,
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch(
            actions.setQuizChallengesList(res.data && res.data.quizChallenges),
          );
          return { success: true, data: res.data && res.data.quizChallenges };
        } else {
          return { success: false };
        }
      })
      .catch((error) => {
        dispatch({ type: GET_PROBLEMS_LOADING });
        console.log("quiz Challenges List Error", error);
      });
  };

export const getQuizChallengeDetails = (id) => (dispatch) => {
  dispatch({ type: GET_PROBLEM_DETAILS_LOADING });
  return axios
    .get(`${baseURL}/dayscode/get_quiz_challenge/${id}`)
    .then(({ data }) => {
      if (data.success) {
        dispatch(actions.setQuizChallengeDetails(data.quizChallenge));
        return { success: true, data: data.quizChallenge };
      }
    })
    .catch((error) => {
      dispatch({ type: GET_PROBLEM_DETAILS_LOADING });
      console.log("get quiz_challenge error", error);
    });
};

export const removeQuizChallenge = (id) => (dispatch) => {
  return axios
    .delete(`${baseURL}/dayscode/delete_quiz_challenge/${id}`)
    .then(({ data }) => {
      if (data.success) {
        dispatch(getQuizChallenges());
        return { success: true };
      }
    })
    .catch((error) => {
      console.log("delete Quiz Challenge error", error);
    });
};

export const submitQuizChallenge = (submitQuiz) => (dispatch) => {
  dispatch({ type: SET_SOLUTION_SUBMISSION_LOADING });
  return axios
    .put(`${baseURL}/dayscode/submit_quiz_challenge`, submitQuiz)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: SET_SOLUTION_SUBMISSION_LOADING });
        dispatch({
          type: SET_UPDATED_QUIZ_CHALLENGES,
          payload: res?.data?.challenges,
        });

        return {
          success: true,
          result: res.data && res.data.result,
          completedBy: res.data && res.data.completedBy,
        };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_SOLUTION_SUBMISSION_LOADING });
      console.log("check quiz challenge Error", error);
    });
};

export const quizChallengeResult = (quiz) => (dispatch) => {
  dispatch({ type: GET_QUIZ_CHALLENGE_REPORT_LOADING });
  return axios
    .post(`${baseURL}/dayscode/quiz_challenge_result`, quiz)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: GET_QUIZ_CHALLENGE_REPORT_LOADING });
        dispatch({ type: SET_CHALLENGES_COMPLETED_STATUS });
        return {
          success: true,
          result: res.data && res.data.result,
        };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: GET_QUIZ_CHALLENGE_REPORT_LOADING });
      console.log("quiz challenge result Error", error);
    });
};

// --------All modules content
export const getContentByDays =
  ({ batch, day }) =>
  (dispatch) => {
    dispatch({ type: GET_CONTENT_DETAILS_LOADING });
    return axios
      .get(`${baseURL}/dayscode/code_batch/${batch}/content/${day}`)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: GET_CONTENT_DETAILS_LOADING });
          dispatch(actions.setContentDetails(data.contentDetails));
          return { success: true, contentDetails: data.contentDetails };
        }
      })
      .catch((error) => {
        dispatch({ type: GET_CONTENT_DETAILS_LOADING });
        console.log("get content error", error);
      });
  };

export const getContentsList = (status) => (dispatch) => {
  dispatch({ type: GET_PROBLEMS_LOADING });
  return axios
    .get(`${baseURL}/dayscode/fetch_contents/${status}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch(actions.setContentsList(res.data && res.data.contents));
        return { success: true, data: res.data && res.data.contents };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: GET_PROBLEMS_LOADING });
      console.log("contents List Error", error);
    });
};

// --------Session Attendace-------------------

export const addUserAttendancePresent = (id, userAttendance) => (dispatch) => {
  dispatch({ type: SET_USER_ATTENDANCE_PRESENT_LOADING });
  return axios
    .put(`${baseURL}/dayscode/add_user_attendance/${id}`, userAttendance)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: SET_USER_ATTENDANCE_PRESENT_LOADING });
        dispatch(
          getDaysUsersListOptions(
            userAttendance.mentor,
            userAttendance.isAdmin,
          ),
        );
        return { success: true };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_USER_ATTENDANCE_PRESENT_LOADING });
      console.log("add user attendance present Error", error);
    });
};

export const deleteUserAttendancePresent = (id, data) => (dispatch) => {
  dispatch({ type: SET_USER_ATTENDANCE_PRESENT_LOADING });
  return axios
    .put(`${baseURL}/dayscode/delete_user_attendance/${id}`, data)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: SET_USER_ATTENDANCE_PRESENT_LOADING });
        dispatch(getDaysUsersListOptions(data.mentor, data.isAdmin));
        return { success: true };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_USER_ATTENDANCE_PRESENT_LOADING });
      console.log("delete user attendance present Error", error);
    });
};

//  Assign Mentor =======================
export const assignUserMentor = (id, mentor) => (dispatch) => {
  dispatch({ type: SET_USER_ATTENDANCE_PRESENT_LOADING });
  return axios
    .put(`${baseURL}/dayscode/assign_user_mentor/${id}`, mentor)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: SET_USER_ATTENDANCE_PRESENT_LOADING });
        dispatch(getDaysUsers("3", "all"));
        return { success: true };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_USER_ATTENDANCE_PRESENT_LOADING });
      console.log("assign mentor user Error", error);
    });
};

export const deleteAssignedUserMentor = (id, mentor) => (dispatch) => {
  dispatch({ type: SET_USER_ATTENDANCE_PRESENT_LOADING });
  return axios
    .put(`${baseURL}/dayscode/delete_user_mentor/${id}`, mentor)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: SET_USER_ATTENDANCE_PRESENT_LOADING });
        dispatch(getDaysUsers("3", "all"));
        return { success: true };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_USER_ATTENDANCE_PRESENT_LOADING });
      console.log("delete assigned mentor user Error", error);
    });
};

//  Add User Feedback =======================
export const addUserFeedback = (id, feedback) => (dispatch) => {
  dispatch({ type: SET_USER_FEEDBACK_LOADING });
  return axios
    .put(`${baseURL}/dayscode/add_user_feedback/${id}`, feedback)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: SET_USER_FEEDBACK_LOADING });
        return { success: true };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_USER_FEEDBACK_LOADING });
      console.log("add user feedback error", error);
    });
};

export const deleteUserFeedback = (id, feedback) => (dispatch) => {
  dispatch({ type: SET_USER_FEEDBACK_LOADING });
  return axios
    .put(`${baseURL}/dayscode/delete_user_feedback/${id}`, feedback)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: SET_USER_FEEDBACK_LOADING });
        dispatch(getAllUsersFeedbacks(feedback.batchId));
        return { success: true };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_USER_FEEDBACK_LOADING });
      console.log("delete user feedback Error", error);
    });
};

export const getAllUsersFeedbacks = (batchId) => (dispatch) => {
  dispatch({ type: GET_ALL_USERS_FEEDBACKS_LOADING });
  return axios
    .get(`${baseURL}/dayscode/get_all_feedbacks/${batchId}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch(actions.setAllUsersFeedbacks(res?.data?.feedbacks));
        return { success: true, feedbacks: res?.data?.feedbacks };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: GET_ALL_USERS_FEEDBACKS_LOADING });
      console.log("get all feedbacks Error", error);
    });
};

export const getUserFeedbacks = (batchId, data) => (dispatch) => {
  dispatch({ type: GET_ALL_USERS_FEEDBACKS_LOADING });
  return axios
    .get(`${baseURL}/dayscode/get_user_feedbacks/${batchId}`, data)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: GET_ALL_USERS_FEEDBACKS_LOADING });
        return { success: true, feedbacks: res?.data?.feedbacks };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: GET_ALL_USERS_FEEDBACKS_LOADING });
      console.log("get user feedbacks Error", error);
    });
};

// All users progress by mentor

export const getAllUsersProgressByMentor =
  (batchId, mentorId, admin) => (dispatch) => {
    dispatch({ type: GET_ALL_USERS_PROGRESS_LOADING });
    return axios
      .get(
        `${baseURL}/dayscode/all_users_progress/${batchId}/${mentorId}/${admin}`,
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: GET_ALL_USERS_PROGRESS,
            payload: res?.data?.allSubmissionsByUser,
          });
          return {
            success: true,
            allUsersProgressByMentor: res?.data?.allSubmissionsByUser,
          };
        } else {
          return { success: false };
        }
      })
      .catch((error) => {
        dispatch({ type: GET_ALL_USERS_PROGRESS_LOADING });
        console.log("get user feedbacks Error", error);
      });
  };

// Download File/Image -------------
export const getFileDownloadDetails = (url, fileName) => (dispatch) => {
  return axios
    .get(`${url}`, { responseType: "blob" })
    .then((res) => {
      if (res.status === 200) {
        return { success: true, data: res?.data };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      console.log("get file data Error", error);
    });
};

// Code Batches

export const createCodeBatch = (batchData) => (dispatch) => {
  dispatch({ type: GET_ALL_CODE_BATCHES_LOADING });
  return axios
    .post(`${baseURL}/dayscode/code_batch/create`, batchData)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: GET_ALL_CODE_BATCHES_LOADING });
        return { success: true, batch: res.data && res.data.batch };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: GET_ALL_CODE_BATCHES_LOADING });
      console.log("add batch Error", error);
    });
};

export const editCodeBatch = (batchData) => (dispatch) => {
  dispatch({ type: GET_ALL_CODE_BATCHES_LOADING });
  return axios
    .patch(`${baseURL}/dayscode/code_batch/${batchData._id}`, {
      data: batchData,
    })
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: GET_ALL_CODE_BATCHES_LOADING });
        return { success: true, batch: res.data && res.data.batch };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: GET_ALL_CODE_BATCHES_LOADING });
      console.log("edit batch Error", error);
    });
};

export const getCodeBatches = () => (dispatch) => {
  dispatch({ type: GET_ALL_CODE_BATCHES_LOADING });
  return axios
    .get(`${baseURL}/dayscode/code_batch`)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: GET_ALL_CODE_BATCHES, payload: res.data.batches });
        return { success: true, batches: res?.data?.batches };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: GET_ALL_CODE_BATCHES_LOADING });
      console.log("get all code batches Error", error);
    });
};

export const getCodeBatchDetails = (batch) => (dispatch) => {
  dispatch({ type: GET_ALL_CODE_BATCHES_LOADING });
  return axios
    .get(`${baseURL}/dayscode/code_batch/${batch}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: GET_CODE_BATCH, payload: res.data.batch });
        return { success: true, batch: res?.data?.batch };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: GET_ALL_CODE_BATCHES_LOADING });
      console.log("get all code batches Error", error);
    });
};

export const deleteCodeBatch = (id) => (dispatch) => {
  return axios
    .delete(`${baseURL}/dayscode/code_batch/${id}`)
    .then(({ data }) => {
      if (data.success) {
        dispatch(getCodeBatches());
        return { success: true };
      }
    })
    .catch((error) => {
      console.log("delete batch error", error);
    });
};

export const addProblemIntoBatch = (batchData) => (dispatch) => {
  return axios
    .post(`${baseURL}/dayscode/code_batch/content/problem`, batchData)
    .then((res) => {
      if (res.status === 200) {
        dispatch(getCodeBatchDetails(batchData.batch));
        toast.success("Problem added into batch", {
          duration: 2000,
        });
        return { success: true, batch: res.data && res.data.batch };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      // toast.success(res.message.data.message, {
      //   duration: 2000,
      // })
      console.log("edit batch Error", error);
    });
};

export const deleteProblemFromBatch = (batchData) => (dispatch) => {
  return axios
    .delete(
      `${baseURL}/dayscode/code_batch/problem/${batchData.batch}/${batchData.problem}`,
    )
    .then((res) => {
      if (res.status === 200) {
        dispatch(getCodeBatchDetails(batchData.batch));
        toast.success("Problem removed from batch", {
          duration: 2000,
        });
        return { success: true, batch: res.data && res.data.batch };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      console.log("edit batch Error", error);
    });
};

export const addLessonIntoBatch = (batchData) => (dispatch) => {
  return axios
    .post(`${baseURL}/dayscode/code_batch/content/lesson`, batchData)
    .then((res) => {
      if (res.status === 200) {
        dispatch(getCodeBatchDetails(batchData.batch));
        toast.success("Lesson added into batch", {
          duration: 2000,
        });
        return { success: true, batch: res.data && res.data.batch };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      console.log("edit batch Error", error);
    });
};

export const deleteLessonFromBatch = (batchData) => (dispatch) => {
  return axios
    .delete(
      `${baseURL}/dayscode/code_batch/lesson/${batchData.batch}/${batchData.lesson}`,
    )
    .then((res) => {
      if (res.status === 200) {
        dispatch(getCodeBatchDetails(batchData.batch));
        toast.success("Lesson removed from batch", {
          duration: 2000,
        });
        return { success: true, batch: res.data && res.data.batch };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      console.log("edit batch Error", error);
    });
};

export const addQuizIntoBatch = (batchData) => (dispatch) => {
  return axios
    .post(`${baseURL}/dayscode/code_batch/content/quiz`, batchData)
    .then((res) => {
      if (res.status === 200) {
        dispatch(getCodeBatchDetails(batchData.batch));
        toast.success("Quiz added into batch", {
          duration: 2000,
        });
        return { success: true, batch: res.data && res.data.batch };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      console.log("edit batch Error", error);
    });
};

export const deleteQuizFromBatch = (batchData) => (dispatch) => {
  return axios
    .delete(
      `${baseURL}/dayscode/code_batch/quiz/${batchData.batch}/${batchData.quiz}`,
    )
    .then((res) => {
      if (res.status === 200) {
        dispatch(getCodeBatchDetails(batchData.batch));
        toast.success("Quiz removed from batch", {
          duration: 2000,
        });
        return { success: true, batch: res.data && res.data.batch };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      console.log("edit batch Error", error);
    });
};

export const addMentorIntoBatch = (batchData) => (dispatch) => {
  return axios
    .post(
      `${baseURL}/dayscode/code_batch/mentor/${batchData.batch}/${batchData.mentor}`,
    )
    .then((res) => {
      if (res.status === 200) {
        dispatch(getCodeBatchDetails(batchData.batch));
        toast.success("Mentor added into batch", {
          duration: 2000,
        });
        return { success: true, batch: res.data && res.data.batch };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      console.log("edit batch Error", error);
    });
};

export const createMentorBatch = (batchData) => (dispatch) => {
  return axios
    .post(`${baseURL}/dayscode/mentor`, batchData)
    .then((res) => {
      if (res.status === 200) {
        dispatch(getCodeBatchDetails(batchData.batch));
        toast.success("Mentor Created in a batch ", {
          duration: 2000,
        });
        return { success: true, batch: res.data && res.data.batch };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      console.log("Error in create Batch Mentor", error);
    });
};

export const deleteMentorFromBatch = (batchData) => (dispatch) => {
  return axios
    .delete(`${baseURL}/dayscode/mentor/${batchData.mentor}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch(getCodeBatchDetails(batchData.batch));
        toast.success("Mentor removed from batch", {
          duration: 2000,
        });
        return { success: true, batch: res.data && res.data.batch };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      console.log("edit batch Error", error);
    });
};

export const getBatchMentor = (batchData) => (dispatch) => {
  return axios
    .get(`${baseURL}/dayscode/mentor/${batchData.mentor}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch(getCodeBatchDetails(batchData.batch));
        return { success: true, batch: res.data && res.data.batch };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      console.log("Error in getting Batch Mentor", error);
    });
};

export const getSpeakers = (data) => (dispatch) => {
  dispatch({ type: GET_SPEAKERS_LIST_LOADING });
  return axios
    .get(
      `${baseURL}/user/speakers${generateQueryParams({
        limit: data.limit,
        page: data.page,
        search: data?.search,
        technology: data?.technology,
      })}`,
    )
    .then((res) => {
      if (res.status === 200) {
        dispatch(actions.setSpeakersList(res.data));
        dispatch({ type: GET_SPEAKERS_LIST_LOADING });
        return { success: true, speakers: res.data && res.data.Speakers };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: GET_SPEAKERS_LIST_LOADING });
      console.log("Get Speakers Error", error);
    });
};

export const getParticipants = (data) => (dispatch) => {
  dispatch({ type: GET_SPEAKERS_LIST_LOADING });
  return axios
    .get(
      `${baseURL}/dayscode/participants${generateQueryParams({
        search: data?.search,
      })}`,
    )
    .then(({ data }) => {
      if (data.success) {
        dispatch(actions.setDaysUsersList(data.participants));
        dispatch({ type: GET_PROBLEMS_LOADING });
        return { success: true, data: data.participants };
      }
    })
    .catch((error) => {
      dispatch({ type: GET_PROBLEMS_LOADING });
      console.log("get days users error", error);
    });
};

export const addParticipantToBatch = (batchData) => (dispatch) => {
  return axios
    .post(`${baseURL}/dayscode/code_batch/content/participant`, batchData)
    .then((res) => {
      if (res.status === 200) {
        dispatch(getCodeBatchDetails(batchData.batch));
        toast.success("Participant Added In Batch", {
          duration: 2000,
        });
        return { success: true, batch: res.data && res.data.batch };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      console.log("Error in adding Participant in Batch", error);
    });
};

export const deleteParticipantFromBatch = (batchData) => (dispatch) => {
  return axios
    .delete(
      `${baseURL}/dayscode/code_batch/participant/${batchData.batch}/${batchData.participant}`,
    )
    .then((res) => {
      if (res.status === 200) {
        dispatch(getCodeBatchDetails(batchData.batch));
        toast.success("Participant removed from batch", {
          duration: 2000,
        });
        return { success: true, batch: res.data && res.data.batch };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      console.log("Error in participant deletion", error);
    });
};

export const createBatchRoadMap = (batchData) => (dispatch) => {
  return axios
    .post(`${baseURL}/dayscode/roadmap/create`, batchData)
    .then((res) => {
      if (res.status === 200) {
        dispatch(getCodeBatchDetails(batchData.batch));
        toast.success("RoadMap created", {
          duration: 2000,
        });
        return { success: true, batch: res.data && res.data.batch };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      console.log("Error in creating Roadmap", error);
    });
};

export const getBatchRoadmap =
  ({ batch }) =>
  (dispatch) => {
    dispatch({ type: GET_SPEAKERS_LIST_LOADING });
    return axios
      .get(
        `${baseURL}/dayscode/roadmap${generateQueryParams({
          batch: batch,
        })}`,
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch(actions.setRoadmapList(res.data));
          dispatch({ type: GET_SPEAKERS_LIST_LOADING });
          return { success: true, roadmaps: res.data && res.data.roadmaps };
        } else {
          return { success: false };
        }
      })
      .catch((error) => {
        dispatch({ type: GET_SPEAKERS_LIST_LOADING });
        console.log("Get Roadmap Error", error);
      });
  };

export const deleteRoadmapfromBatch = (batchData) => (dispatch) => {
  return axios
    .delete(`${baseURL}/dayscode/roadmap/${batchData.roadmap}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch(getCodeBatchDetails(batchData.batch));
        toast.success("Roadmap removed from batch", {
          duration: 2000,
        });
        return { success: true, batch: res.data && res.data.batch };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      console.log("Error in Roadmap deletion", error);
    });
};

// Checkin Batch
export const checkInBatch = (batch) => (dispatch) => {
  dispatch({ type: CHECK_IN_BATCH_LOADING });
  return axios
    .get(`${baseURL}/dayscode/code_batch/${batch}/checkIn`)
    .then((res) => {
      if (res.status === 200) {
        dispatch(actions.setCheckInBatch(res.data));
        dispatch({ type: CHECK_IN_BATCH_LOADING });
        return { success: true, data: res.data };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: CHECK_IN_BATCH_LOADING });
      console.log("Get CheckIn Error", error);
      triggerNotifier({
        message: error.response.data.message,
        type: "error",
        duration: 2000,
        icon: "⚠️",
      });

      return { success: false, message: error.response.data.message };
    });
};
