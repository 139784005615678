import React, { useEffect } from "react";
import { Field, reduxForm } from "redux-form";

import { FaArrowRightLong } from "react-icons/fa6";
import bgImage from "../../../../../../assets/images/bg/Rectangle 14formbg.jpg";
import { RenderInputField } from "../../../../../../shared_components/ReduxFormFields";
import { email, required } from "../../../../../../utils/validators";

const EventRegistrationForm = ({
  reset,
  handleSubmit,
  submitting,
  initialize,
  eventRegister,
  eventFeedback,
  eventRegistered,
  eventRegistationLoading,
  id,
  eventDetails,
  userData,
  setEventRegistered,
  setQuestionSubmitted,
  setRegisterName,
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    let data = {};
    if (userData) {
      data = {
        name: userData.firstName,
        ...userData,
      };
      initialize(data);
    }
  }, []);

  const onSubmit = (values) => {
    const register = { ...values };
    if (id && !eventRegistered) {
      register["id"] = id;
      eventRegister(register).then((res) => {
        if (res && res.success) {
          setRegisterName(register.name);
          setEventRegistered(true);
          reset("eventRegisterForm");
        }
      });
    }
  };

  return (
    <>
      <div className='event-form mx-0'>
        <div className='form-img'>
          <img src={bgImage} alt='img' />
        </div>
        <div className='title'>
          <h1>Secure Your Spot</h1>
          <h3>Register Now For </h3>
          <h3>Exclusive Event </h3>
          <h2>
            <FaArrowRightLong size={"80px"} className='mt-4' />
          </h2>
        </div>
        <div className='form-content border rounded-lg card-schadow'>
          <form className='' onSubmit={handleSubmit(onSubmit)}>
            <div className='row m-0'>
              <div className='col-12'>
                <Field
                  type='text'
                  name='name'
                  label='Enter Name'
                  placeholder=''
                  component={RenderInputField}
                  validate={[required]}
                />
              </div>
            </div>
            <div className='row m-0'>
              <div className='col-md-6 col-12'>
                <Field
                  type='email'
                  name='email'
                  label='Enter Email'
                  placeholder='xyz@yui.com'
                  component={RenderInputField}
                  validate={[required, email]}
                />
              </div>
              <div className='col-md-6 col-12'>
                <Field
                  type='text'
                  name='phone'
                  label='Enter Phone No.'
                  placeholder=''
                  component={RenderInputField}
                  validate={[required]}
                />
              </div>
            </div>
            <div className='row m-0'>
              <div className='col-md-6 col-12'>
                <Field
                  type='text'
                  name='university'
                  label='University / Organization'
                  //placeholder="xyz@yui.com"
                  component={RenderInputField}
                  validate={[required]}
                />
              </div>
              <div className='col-md-6 col-12'>
                <Field
                  type='number'
                  max='5'
                  min='1'
                  name='gratuationYear'
                  label='Year Of Graduation'
                  placeholder='2nd year'
                  component={RenderInputField}
                />
              </div>
            </div>
            <div className='row m-0'>
              <div className='col-md-6 col-12'>
                <Field
                  type='linkedin'
                  name='linkedin'
                  label="Let's Connect on Linkedin, Please share your linkedin profile"
                  component={RenderInputField}
                />
              </div>
              <div className='col-md-6 col-12'>
                <Field
                  type='text'
                  name='github'
                  label='Please share your Github profile'
                  component={RenderInputField}
                />
              </div>
            </div>
            <div className='row m-0'>
              <div className='col-md-6 col-12'>
                <Field
                  type='textarea'
                  name='question1'
                  label='Why do you want to attend this session?'
                  component={RenderInputField}
                />
              </div>
              <div className='col-md-6 col-12'>
                <Field
                  type='textarea'
                  name='question2'
                  label='What are your expectations from this session?'
                  component={RenderInputField}
                />
              </div>
            </div>
            <div className='row mx-0 mt-4 d-flex justify-content-center'>
              <div className='col-md-2 col-6 text-center'>
                <button
                  type='submit'
                  className='btn text-white reg-button'
                  disabled={submitting}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className='empty'></div>
    </>
  );
};

export default reduxForm({
  form: "eventRegisterForm",
})(EventRegistrationForm);
