import React from "react"
import WorkshopHeroSection from "./WorkshopHeroSection"
import StepsLearningWonders from "./StepsLearningWonders"
import PopularWorkshops from "./PopularWorkshops"
import SkillsAndSuccessSection from "./SkillsAndSuccessSection"
import BecomeMentorSection from "./BecomeMentorSection"
import ExploreTrainingBootcampSection from "./ExploreTrainingBootcampSection"
import Testimonial from "../../../../../../shared_components/Testimonial"
import { dayscodeTestimonials } from "../../../../DaysCode/components/DaysCode/DaysCodeTestimonials"

const WorkshopLandingPage = () => {
  return (
    <>
      <WorkshopHeroSection />
      <StepsLearningWonders />
      <PopularWorkshops />
      <SkillsAndSuccessSection />
      <ExploreTrainingBootcampSection />
      <BecomeMentorSection />
      <Testimonial testimonials={dayscodeTestimonials} heading={'What our community members saying'} />
    </>
  )
}

export default WorkshopLandingPage