import React, { useState } from "react";

import "./style.css";
import { getColumns } from "./helpers";
import {
  FaCalendarAlt,
  FaCog,
  FaEye,
  FaHeart,
  FaRegHeart,
  FaShareAlt,
  FaUser,
} from "react-icons/fa";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const BlogListTable = ({ lessons, items, handleApprove, selectedBLog }) => {
  let columns = getColumns(items, handleApprove, selectedBLog);
  const [isLiked, setIsLiked] = useState(false);
  const history = useHistory();
  return (
    <>
      {/* {lessons && (
        <div className='table table-responsive'>
          <BootstrapTable
            keyField='id'
            data={lessons}
            columns={columns}
            search={{
              search: true,
              searchPosition: "top",
              delay: 500,
            }}
            bordered={false}
            hover
            condensed
            headerClasses='header-class'
            rowClasses='row-class'
          />
        </div>
      )} */}
      <div className='blog-list'>
        {lessons.map((lesson) => (
          <div key={lesson._id} className='card mb-4 shadow-sm'>
            <div
              className='row g-0'
              onClick={() => {
                history.push(`/blog/preview/${lesson._id}`);
              }}
            >
              <div className='col-md-4'>
                <span className='badge bg-primary position-absolute list-img-badge'>
                  {"category"}
                </span>
                <img
                  src={
                    lesson.thumbnail ||
                    "https://media.licdn.com/dms/image/v2/C5612AQEr3WmxhGSbxg/article-cover_image-shrink_720_1280/article-cover_image-shrink_720_1280/0/1644862734630?e=2147483647&v=beta&t=M5AxxQvXksyGDUNYiIyZ7G0zAhfqdmYgrlQhMdTkLfI"
                  }
                  alt={"alt"}
                  className='img-fluid h-100 w-100 object-fit-cover'
                  style={{ maxHeight: "250px" }}
                />
              </div>
              <div className='col-md-8'>
                <div className='card-body mt-0 py-1'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <h4 className='card-title mb-2'>
                      {lesson.name.length > 30
                        ? lesson.name.slice(0, 29) + "..."
                        : lesson.name || "Nobody Wants To Work With Our be..."}
                    </h4>
                    <span className='text-muted d-flex align-items-center'>
                      <FaEye className='mr-1' /> {4500}
                    </span>
                  </div>
                  <p className='card-text text-muted mb-3'>
                    {lesson.description?.length > 0
                      ? lesson.description.slice(0, 280) + "..."
                      : "Lorem ipsum dolor sit amet consectetur adipisicing elit. Et corporis cumque facilis dolores ab ipsum tempora explicabo ratione modi illo vel, itaque praesentium eos nihil, minima inventore labore mollitia nemo."}
                  </p>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='d-flex align-items-center gap-3'>
                      <small className='text-muted d-flex align-items-center'>
                        <FaUser className='mr-1' />
                        {lesson.author
                          ? lesson.author?.firstName +
                            " " +
                            lesson.author?.lastName
                          : "Shawn Michael"}
                      </small>
                      <small className='text-muted d-flex align-items-center'>
                        <FaCalendarAlt className='mr-1' />
                        {moment(lesson?.updatedAt).format("MMMM Do YYYY")}
                      </small>
                    </div>
                    <div className='d-flex gap-3 align-items-center card-left-area'>
                      <button
                        className={`btn btn-link p-0 ${
                          isLiked ? "text-danger" : "text-muted"
                        }`}
                        // onClick={() => onLike(blog.id)}
                      >
                        {isLiked ? <FaHeart /> : <FaRegHeart />}
                        <span className='ml-1'>{450}</span>
                      </button>
                      <button
                        className='btn btn-link p-0 text-muted'
                        // onClick={() => handleShare(blog)}
                      >
                        <FaShareAlt />
                      </button>
                      <div className='position-relative'>
                        <button className='btn btn-link p-0 text-muted'>
                          <FaCog />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default BlogListTable;
