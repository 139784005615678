import * as constants from "../constants";

const setEventRegistrationLoading = (state) => ({
  ...state,
  eventRegistationLoading: !state.eventRegistationLoading,
});

export const setEventsHandlers = {
  [constants.SET_EVENT_REGISTRATION_LOADING]: setEventRegistrationLoading,
};
