import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import { getColumns } from "./helpers";
import { getCompletedDays } from "../../../DaysCode/helpers";
import LayoutContainer from "../../LayoutContainer";
import { checkAdmin } from "../../../../../../../core/auth";
import CustomLoader from "../../../../../../../shared_components/CustomLoader";
import CustomSearch from "../../../../../../../shared_components/CustomSearch";

const UsersProgress = ({
  currentUser,
  getAllUsersProgressByMentor,
  getAllUsersProgressByMentorLoading,
}) => {
  const allUsersProgressByMentor = useSelector(
    (state) => state?.dayscode?.allUsersProgressByMentor,
  );
  const [search, setSearch] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    getAllUsersProgressByMentor(
      "3",
      currentUser?.email,
      checkAdmin(currentUser),
    );
  }, [currentUser]);

  let columns = getColumns();

  const getCodeSubmissionsList = (submittedDays) => {
    let temp = [];
    for (let i = 0; i < getCompletedDays() + 1; i++) {
      if (submittedDays.includes(i.toString())) {
        temp.push(
          <span key={i} className='green-progrees-dot'>
            {i}
          </span>,
        );
      } else {
        temp.push(
          <span key={i} className='red-progrees-dot'>
            {i}
          </span>,
        );
      }
    }
    return temp;
  };

  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row, rowIndex) => (
      <div className='row mx-0'>
        <div className='col-md-3 col-12'>
          <span>Code Submissions Status</span>
        </div>
        <div className='col-md-9 col-12 d-flex'>
          {getCodeSubmissionsList(row.submittedDays)}
        </div>
      </div>
    ),
  };

  const getSearchResult = (data) => {
    return (
      data &&
      data.filter(
        (item) =>
          item?.name?.toUpperCase().includes(search.toUpperCase()) ||
          item?.userName?.toUpperCase().includes(search.toUpperCase()),
      )
    );
  };

  return (
    <>
      <LayoutContainer>
        <div className='row mx-0 solution-nav'>
          <div className='col-md-8 col-10 mx-0 p-md-0 p-2 d-flex align-items-center'>
            <h4 className='py-md-3 px-md-3 py-2 mb-0'>
              <i className='fal fa-sitemap mr-2' />
              Users Progress List
            </h4>
          </div>
          <div className='col-md-4 col-2 d-flex justify-content-end align-items-center'>
            {allUsersProgressByMentor && (
              <h2 className='mb-0 p-2 text-white badge badge-pill badge-primary'>
                Total: {allUsersProgressByMentor?.length}
              </h2>
            )}
          </div>
        </div>
        {getAllUsersProgressByMentorLoading ? (
          <CustomLoader />
        ) : (
          <>
            {allUsersProgressByMentor && allUsersProgressByMentor.length && (
              <>
                <div className='p-5'>
                  <CustomSearch setSearch={setSearch} search={search} />
                </div>
                <div className='my-2 bootstrap-table table table-responsive'>
                  <BootstrapTable
                    keyField='userName'
                    bordered={true}
                    data={getSearchResult(allUsersProgressByMentor)}
                    columns={columns}
                    search
                    hover={true}
                    expandRow={expandRow}
                  />
                </div>
              </>
            )}
          </>
        )}
      </LayoutContainer>
    </>
  );
};

export default UsersProgress;
