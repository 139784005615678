import React from "react"

const WorkshopPageHeroSection = () => {
  return (
    <>
      <div className="row mx-0 d-flex justify-content-center workshop-page-hero">
        <div className="col-12 col-md-10">
          <h1 className="text-center">Learn Web Design by Dhanshree Nirgude</h1>
          <p className="text-center">Learn the basics of web designing, learn tools like figma to design the screen of a web page, learn responsive designing and prototyping </p>
        </div>
      </div>
    </>
  )
}

export default WorkshopPageHeroSection