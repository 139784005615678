import React from "react"

const data = [
  {
    img: "https://res.cloudinary.com/datacode/image/upload/v1690999871/fh3ax2qdgvqdxeatlfbz.png",
    title: "Amplify Your Expertise",
    content: "Our high-impact training and bootcamps fast-track your skills, making you a sought-after professional in no time!",
  },
  {
    img: "https://res.cloudinary.com/datacode/image/upload/v1690999871/fh3ax2qdgvqdxeatlfbz.png",
    title: 'Guided by Industry Titans',
    content: 'Learn from seasoned experts who conquer the domains they teach.',
  },
  {
    img: "https://res.cloudinary.com/datacode/image/upload/v1690999871/fh3ax2qdgvqdxeatlfbz.png",
    title: 'Unleash Your True Potential',
    content: 'Beyond acquiring skills, our programs aim to ignite a profound transformation within you.',
  },
]

const SkillsAndSuccessSection = () => {
  return (
    <>
      <div className="row mx-0 d-flex justify-content-center skills-success-section">
        <div className="col-12 col-md-10  border ">
          <div className="row mx-0">
            <div className="col-6">
              <h1>Embark on a Journey of Skills and Success!</h1>
              <span>Discover our Trainings and Bootcamp for Limitless Growth!</span>
            </div>
            <div className="col-6">
              <div className="row mx-0">
                {
                  data.map((item, i) => (
                    <div key={i} className="col-md-4 col-12">
                      <img
                        className="img-fluid"
                        loading="lazy"
                        src={item.img}
                        alt="avatar"
                      />
                      <h6>{item.title}</h6>
                      <span>{item.content}</span>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
          <div className="row mx-0 my-5">
            <div className="col-12 text-right">
              <div className="btn btn-primary">Explore  Trainings</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SkillsAndSuccessSection