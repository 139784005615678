import React, { useEffect } from "react";
import CountUp from "react-countup";
import Gallery from "react-grid-gallery";
import { Link } from "react-router-dom";
import AddTestimonial from "./AddTestimonial";
import { IMAGES } from "./helpers";
import PeopleWords from "./PeopleWords";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const OurStory = ({
  showAddTestimonial,
  testimonials,
  addTestimonialLoading,
  fetchTestimonials,
  currentUser,
  setEditTestimonial,
  deleteTestimonial,
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchTestimonials({}).then((res) => {
      if (res) {
        console.log("done");
      }
    });
  }, []);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const handleTestimonialEdit = (testimonial) => {
    setEditTestimonial(testimonial);
  };

  const handleTestimonialDelete = (testimonial) => {
    deleteTestimonial(testimonial).then((res) => {
      if (res && res.success) {
        fetchTestimonials();
      }
    });
  };

  return (
    <>
      <div className='row m-0 our-story-header'>
        <div className='col-12 text-center'>
          <img
            height='250px'
            width='580px'
            src={require(`../../../../assets/images/svg/logo_white.svg`)}
            alt='avatar'
            loading='lazy'
            className='ourstory-img mt-md-4 mt-0'
          />
          <h1>Our Story</h1>
        </div>
      </div>
      <div className='row m-0 px-md-5'>
        <div className='col-12 px-md-5 px-3'>
          <h2 className='px-md-5 tag-line px-2 text-center my-md-5 py-5'>
            Our mission is to provide world‑class Learning content to anyone,
            anywhere.
          </h2>
          <p className='text-md-center text-left text-dark px-md-5'>
            Starting back in Nov 2018, Today we are a community of over 27k
            members from across the globe, We started with a youtube channel and
            now we have our presence on all the platforms. We started with the
            aim to teach computer science students about Datastructures, because
            computer science students usually lack the knowledge of
            Datastructures, but with time we realised that with datastructure,
            students also lack right mentorship. Apart from college studies when
            a students tries to study a programming language or anything related
            to computer science they don't get the mentorship needed and this
            leads to failure. Seeing the youth facing this problem, we came up
            with the idea of datacode. Datacode acts as a mentor to everyone.
            Anyone can come to Datacode to learn whatever they want to and can
            connect with the mentors to get a proper mentorship
          </p>
          <div className='row m-0 mt-5'>
            <div className='col-md-6 col-12 text-center'>
              <img
                height='500px'
                width='500px'
                src={require(`../../../../assets/images/svg/vision.svg`)}
                alt='avatar'
                loading='lazy'
                className='vision-img'
              />
            </div>
            <div className='col-md-6 col-12'>
              <h2>Our vision</h2>
              <p>
                We at Datacode.in aim in creating a platform for beginners
                community where they will get the best online technical content
                like the latest technical blogs, tutorials and courses, practice
                questions, quizzes, training and certification, and lot more on
                a single platform.
                <br />
                Computer Science world is a vast collection of technologies,
                each and every person entering into this world at first gets
                confused about where to learn, what to learn, and how to learn?
                So, we have figured out everything for them in the form of
                Datacode.in. Our vision is to make learning more interesting and
                peers more intelligent. <br />
                We are committed to achieving new standards of excellence by
                providing the most suitable content and maximizing the potential
                of our peers. We are working hard every day to provide you all a
                better place to learn and achieve your goals.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='row m-0 px-md-5 '>
        <div className='col-12 px-md-5 px-3'>
          <div className='row m-0 mt-md-4 mt-3 our-mision main'>
            <div className='col-md-6 col-12 left'>
              <h2>Our mision</h2>
              <p>
                If you are a beginner and confused how to start with new
                technology? We have a Datacode Learning Buddy for you where
                you’ll find the exact solutions to all your problems. Need
                learning content to start with a technology? <br />
                Come and find what you need, learn what interests you, practice
                what you have learned and Code what you have practiced. We
                create and collect the best resources from all over the internet
                to help you start with this technical journey. We aim at
                creating a better learning experience and divide them in
                categories to make it easier for you
              </p>
            </div>
            <div className='col-md-6 col-12 text-center right'>
              <img
                height='400px'
                width='600px'
                src={require(`../../../../assets/images/svg/mission.png`)}
                alt='avatar'
                loading='lazy'
                className='card-img-top mx-auto img-fluid img-circle d-block'
              />
            </div>
          </div>
        </div>
      </div>
      <div className='row m-0 my-5 py-5 px-md-5 our-community'>
        <div className='col-12 px-md-5 px-3'>
          <div className='row m-0 d-flex justify-content-center'>
            <div className='col-md-3 social-card card-schadow col-12 align-items-center'>
              <Link to='/signup'>
                <img
                  height='200px'
                  width='200px'
                  src={require(`../../../../assets/images/svg/community.svg`)}
                  alt='avatar'
                  loading='lazy'
                  className='ourstory-img mt-md-4 mt-0'
                />
              </Link>
              <h1>
                {" "}
                <CountUp start={0} end={27000} duration={20} />
                <span className='ml-2'>++</span>
              </h1>

              <h2>Beginners Community</h2>
              <p>
                Follow Datacode.in on Instagram for Tech news, code snippets
              </p>
              <Link to='/signup'>
                <div className='btn social-follow-btn'>Join Community</div>
              </Link>
            </div>
            <div className='col-md-3 social-card card-schadow col-12 align-items-center'>
              <Link to='/index_technologies'>
                <img
                  height='200px'
                  width='200px'
                  src={require(`../../../../assets/images/svg/reach.svg`)}
                  alt='avatar'
                  loading='lazy'
                  className='ourstory-img mt-md-4 mt-0'
                />
              </Link>
              <h1>
                {" "}
                <CountUp start={0} end={10000} duration={20} />
                <span className='ml-2'>++</span>
              </h1>
              <h2>Active Learners</h2>
              <p>
                Follow Datacode.in on Instagram for Tech news, code snippets
              </p>
              <Link to='/index_technologies'>
                <div className='btn social-follow-btn'>Start Learning</div>
              </Link>
            </div>
            <div className='col-md-3 social-card card-schadow col-12 align-items-center'>
              <a
                href='https://www.linkedin.com/company/datacode_in/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img
                  height='200px'
                  width='200px'
                  src={require(`../../../../assets/images/svg/tech_mentor.svg`)}
                  alt='avatar'
                  loading='lazy'
                  className='ourstory-img mt-md-4 mt-0'
                />
              </a>
              <h1>
                {" "}
                <CountUp start={0} end={300} duration={20} />
                <span className='ml-2'>++</span>
              </h1>
              <h2>Tech Mentors</h2>
              <p>
                Follow Datacode.in on Instagram for Tech news, code snippets
              </p>
              <a
                href='https://www.facebook.com/Datacode.in/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <div className='btn social-follow-btn'>
                  Let's connect with mentors
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <h1 className='mt-5 text-center'>Unforgettable moments that matter</h1>
      <div className='row m-0 mt-3 px-0 px-md-3'>
        <div className='col-12 px-md-5 px-2'>
          <Gallery images={IMAGES} />
        </div>
      </div>
      <h1 className='mt-5 text-center'>What our community says to us!</h1>
      <div className='m-0 px-md-5 ' style={{ position: "relative" }}>
        <Carousel responsive={responsive}>
          {testimonials &&
            testimonials.map((item, i) => (
              <PeopleWords
                key={i}
                item={item}
                currentUser={currentUser}
                handleTestimonialEdit={handleTestimonialEdit}
                handleTestimonialDelete={handleTestimonialDelete}
              />
            ))}
        </Carousel>
      </div>
      <div className='row m-0 '>
        <div className='col-12 text-center'>
          {!(
            testimonials &&
            testimonials.find((item) => item.email === currentUser.email)
          ) && (
            <div
              onClick={() => showAddTestimonial()}
              className='btn add-testimomnial-btn'
            >
              What do you say for us?
            </div>
          )}
        </div>
      </div>
      <AddTestimonial />
    </>
  );
};

export default OurStory;
