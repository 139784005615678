import { getUserDataHandlers } from "./getUserData";
import { createReducer } from "../../../core/reduxUtils";

const initialState = {
  currentUser: {},
  userProfile: {},
  batchParticipant: {},
  codeBatchDetails: {},
  error: null,
  signupUserLoading: false,
  loginUserLoading: false,
  userProfileLoading: false,
  addSpeakerLoading: false,
  imageUploading: false,
  forgotpassword_error: null,
};

const handlers = {
  ...getUserDataHandlers,
};

const userReducer = createReducer(initialState, handlers);

export default userReducer;
