import * as constants from "../constants";

const getEventDetailsLoading = (state) => ({
  ...state,
  eventDetailsLoading: !state.eventDetailsLoading,
});

const getEventDetails = (state, action) => ({
  ...state,
  eventDetails: action.payload,
  eventDetailsLoading: !state.eventDetailsLoading,
});

const getEventsListLoading = (state, action) => {
  let loading = "";
  if (action.payload === "all") {
    loading = "eventsListLoading";
  } else {
    loading = `${action.payload}EventsListLoading`;
  }
  return {
    ...state,
    [loading]: !state[loading],
  };
};

const setEventsList = (state, action) => {
  return {
    ...state,
    eventsList: state.eventsList.concat(action.payload),
    eventsListLoading: !state.eventsListLoading,
  };
};

const setEventsUpcomingList = (state, action) => {
  return {
    ...state,
    upcomingEventsList: state.upcomingEventsList.concat(action.payload),
    upcomingEventsListLoading: !state.upcomingEventsListLoading,
  };
};

const setEventsPastList = (state, action) => {
  return {
    ...state,
    pastEventsList: state.pastEventsList.concat(action.payload),
    pastEventsListLoading: !state.pastEventsListLoading,
  };
};

const setEventsLiveList = (state, action) => {
  return {
    ...state,
    liveEventsList: state.liveEventsList.concat(action.payload),
    liveEventsListLoading: !state.liveEventsListLoading,
  };
};

const setSearchQueryObject = (state, action) => {
  return {
    ...state,
    searchQueryObject: action.payload,
    showSearchResultPage: true,
    searchEventsList: [],
  };
};

const setSearchEventsListLoading = (state) => ({
  ...state,
  searchEventsListLoading: !state.searchEventsListLoading,
});

const setSearchEventsList = (state, action) => {
  return {
    ...state,
    searchEventsList: state.searchEventsList.concat(action.payload),
    searchEventsListLoading: !state.searchEventsListLoading,
  };
};

const clearEventsList = (state) => ({
  ...state,
  pastEventsList: [],
  liveEventsList: [],
  eventsList: [],
  upcomingEventsList: [],
});

const setShowSearchResultPage = (state, action) => ({
  ...state,
  showSearchResultPage: action.payload,
  searchEventsList: [],
});

export const getEventsHandlers = {
  [constants.GET_EVENT_DETAILS_LOADING]: getEventDetailsLoading,
  [constants.SET_EVENT_DETAILS]: getEventDetails,
  [constants.GET_EVENTS_LIST_LOADING]: getEventsListLoading,
  [constants.SET_EVENTS_LIST]: setEventsList,
  [constants.SET_EVENTS_LIVE_LIST]: setEventsLiveList,
  [constants.SET_EVENTS_PAST_LIST]: setEventsPastList,
  [constants.SET_EVENTS_UPCOMING_LIST]: setEventsUpcomingList,
  [constants.SET_CLEAR_EVENTS_LIST]: clearEventsList,
  [constants.SET_SEARCH_EVENTS_LIST]: setSearchEventsList,
  [constants.SET_SEARCH_EVENTS_LIST_LOADING]: setSearchEventsListLoading,
  [constants.SET_SEARCH_QUERY_OBJECT]: setSearchQueryObject,
  [constants.SET_SHOW_SEARCH_RESULT_PAGE]: setShowSearchResultPage,
};
