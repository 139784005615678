import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  getEventDetails,
  checkUserEventReg,
  addEventAttendees,
} from "../../../actions";

import EventPage from "./EventPage";

const mapStateToProps = ({ event, user }) => ({
  eventRegistationLoading: event.eventRegistationLoading,
  eventDetailsLoading: event.eventDetailsLoading,
  userData: user.currentUser ? user.currentUser : {},
  eventDetails: event.eventDetails
});

const mapDispatchToProps = {
  getEventDetails,
  checkUserEventReg,
  addEventAttendees,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EventPage)
);
