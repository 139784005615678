import React from 'react'

const ProgramGuide = () => {
  return (
    <>
      <div className="row mx-0">
        <div className="col-12 p-md-5 p-3 check-mark-list">
          <h1>Welcome</h1>
          <p>Welcome to Datacode! This is a place for people who want to improve their algorithms and programming skills. You can earn various levels and badges when solving problems with others. First, please read these guidelines when participating on the site.</p>
          <ul>
            <li>Mentor</li>
            <li>Let’s step into the next mile,without wondering of the crowd,so start exploring !</li>
          </ul>
          <div className="row">
            <div className="col-12">
              <h1>Color Indicator</h1>
              <p>As you solve problems, you can earn new levels, obtain new avatar colors, and unlock privileges. Each problem you solve and every solution you write that gets upvoted earns you a certain amount of XP.</p>
              <ul>
                <li><small className="p-2 rounded bg-primary">Current Day</small></li>
                <li><small className="p-2 rounded bg-dark">Upcoming Day</small></li>
                <li><small className="p-2 rounded bg-danger">Not Submitted</small></li>
                <li><small className="p-2 rounded bg-primary">Submitted</small></li>

                <li><small className="p-2 rounded bg-primary">Good Review</small></li>
                <li><small className="p-2 rounded bg-primary">Poor Review</small></li>
                <li><small className="p-2 rounded bg-warning">Under Review</small></li>

                <li><small className="p-2 rounded red">Wrong Submission</small></li>
                <li><small className="p-2 rounded green">Correct Submission</small></li>
                <li><small className="p-2 rounded red">Run Time Error</small></li>
                <li><small className="p-2 rounded yellow">Compile Time Error</small></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProgramGuide