import React from "react"
import { ReactComponent as Confirm } from '../../../assets/images/svg/confirmation.svg'
import { useParams } from "react-router"

const Confirmation = ({
  message
}) => {
  const { type } = useParams()

  const getHeading = (type) => {
    const headings = {
      'new_email_verify': 'Confirm Your Email',
      'forgotpassword_email': 'Check Email to Reset Password'
    }
    return headings[type]
  }

  const getContent = (type) => {
    const content = {
      'new_email_verify': 'Hey! Please Check your Email (spam section also) to comfirm your membership with Datacode.in',
      'forgotpassword_email': 'Hey! Please Check your Email (spam section also) to get reset password url'
    }
    return content[type]
  }

  return (
    <>
      <div className="row m-0 login">
        <div className="col-12 col-md-8 img-section p-5">
          <div className="p-5">
            <Confirm width="auto" height="auto" />
          </div>
        </div>
        <div className="col-12 col-md-4 login-section">
          <h1 className="text-center login-text">{getHeading(type)}</h1>
          <h1 className="text-center login-text"></h1>
          <div className="pt-md-3 mt-5 px-4 login-card">
            <p>
              {getContent(type)}
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Confirmation;