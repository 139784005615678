import * as constants from "../constants";

const setInterviewQuestionsList = (state, action) => {
  return {
    ...state,
    error: null,
    interviewQuestionsList: action.payload.interviewQuestions,
    interviewQuestionsListLoading: !state.interviewQuestionsListLoading,
  };
};

const setInterviewQuestionById = (state, action) => {
  return {
    ...state,
    error: null,
    interviewQuestion: action.payload.interviewQuestion,
    interviewQuestionsListLoading: !state.interviewQuestionsListLoading,
  };
};

const setInterviewQuestionsByUserName = (state, action) => {
  return {
    ...state,
    error: null,
    interviewQuestionsByUser: action.payload.interviewQuestions,
    interviewQuestionsListLoading: !state.interviewQuestionsListLoading,
  };
};

const setAddInterviewQuestionLoading = (state) => ({
  ...state,
  addInterviewQuestionLoading: !state.addInterviewQuestionLoading,
});

const setInterviewQuestionsListLoading = (state) => ({
  ...state,
  interviewQuestionsListLoading: !state.interviewQuestionsListLoading,
});

export const setInterviewQuestionsHandlers = {
  [constants.SET_INTERVIEW_QUESTIONS_LIST]: setInterviewQuestionsList,
  [constants.SET_INTERVIEW_QUESTIONS_BY_ID]: setInterviewQuestionById,
  [constants.SET_ADD_INTERVIEW_QUESTION_LOADING]: setAddInterviewQuestionLoading,
  [constants.SET_INTERVIEW_QUESTIONS_LIST_LOADING]: setInterviewQuestionsListLoading,
  [constants.SET_INTERVIEW_QUESTIONS_BY_USERNAME]: setInterviewQuestionsByUserName,
};
