import React from "react"
import { academymentorresponsive } from "../../../../../utils"
import Carousel from "react-multi-carousel"

const data = [
  {
    name: "",
    image: 'http://res.cloudinary.com/datacode/image/upload/v1687962103/tommrnv5wkxeydixnlro.png',
  },
  {
    name: "",
    image: 'http://res.cloudinary.com/datacode/image/upload/v1687962103/tommrnv5wkxeydixnlro.png',
  },
  {
    name: "",
    image: 'http://res.cloudinary.com/datacode/image/upload/v1687962103/tommrnv5wkxeydixnlro.png',
  },
  {
    name: "",
    image: 'http://res.cloudinary.com/datacode/image/upload/v1687962103/tommrnv5wkxeydixnlro.png',
  },
  {
    name: "",
    image: 'http://res.cloudinary.com/datacode/image/upload/v1687962103/tommrnv5wkxeydixnlro.png',
  },
  {
    name: "",
    image: 'http://res.cloudinary.com/datacode/image/upload/v1687962103/tommrnv5wkxeydixnlro.png',
  },
]
const AcademyTeam = () => {
  return (
    <>
      <div className="row mx-0 d-flex justify-content-center academy-team">
        <div className="col-md-10 col-12">
          <h1 className="text-center my-5">Meet the Faces Behind Datacode Academy</h1>
          <div className="my-5" style={{ position: "relative" }}>
            <Carousel
              draggable={true}
              infinite={true}
              dotListClass="custom-dot-list-style"
              responsive={academymentorresponsive}
              autoPlay={true}
            >
              {data.map((item, i) => (
                <div className="row mx-0 academy-mentor-card d-flex align-items-center" key={i}>
                  <div className="col-12 col-md-4 pr-5">
                    <h1>Teamwork is the only way we work </h1>
                    <p>Through True Rich Attended does no end it his mother since real had half every him case in packages enquire we up ecstatic unsatiable.</p>
                  </div>
                  <div className="col-12 col-md-8 text-right">
                    <img
                      src={item.image}
                      alt="avatar"
                      loading="lazy"
                      className="my-md-0 my-3 my-auto img-fluid"
                    />
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </>
  )
}

export default AcademyTeam