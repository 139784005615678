import React from 'react'
import Carousel from 'react-multi-carousel';
import { techCompanies, techCompaniesResponsive } from '../../../../../utils';

const OurLearnersWorkAt = () => {
  return (
    <>
      <div className="row mx-0 justify-content-center our-learner-section">
        <div className="col-md-10 col-12">
          <div className="row mx-0">
            <div className="col-12 text-center">
              <h1>Our Learners WorkAt</h1>
              <div className="my-5" style={{ position: "relative" }}>
                <Carousel
                  draggable={true}
                  infinite={true}
                  dotListClass="custom-dot-list-style"
                  responsive={techCompaniesResponsive}
                  autoPlay={true}
                >
                  {techCompanies.map((item, i) => (
                    <div className="row" key={i}>
                      <div className="col-12">
                        <img
                          height="150px"
                          width="150px"
                          src={require(`../../../../../assets/images/companies/${item.img}`)}
                          alt="avatar"
                          loading="lazy"
                          className="my-md-0 my-3 my-auto img-fluid"
                        />
                      </div>
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OurLearnersWorkAt