import React from 'react'
import moment from 'moment'
import { timeConvert } from '../../../../../../utils'

const PostEventRegSection = ({
  handleAttendance,
  eventDetails
}) => {
  return (
    <>
      <h2 className="pt-2">Your are joining us!</h2>
      <p>Stay tune with us!!</p>
      {
        Date.parse(new Date(`${moment(eventDetails.start_date).format('LL')}, ${eventDetails.start_time}`)) - Date.parse(new Date()) <= 3600000 ?
          <button className="btn mb-5 join-us-button text-center card-schadow" onClick={() => handleAttendance()}>
            Go To Live Session!
          </button>
          :
          <>
            <i className="fal fa-hourglass-start wait-timer-icon my-4" />
            <p>{`This event will start on ${moment(eventDetails.start_date).format('LL')}, ${timeConvert(eventDetails && eventDetails.start_time)} IST`}</p>
            <p>Refresh 15 minutes before to join.</p>
          </>
      }
    </>
  )
}

export default PostEventRegSection