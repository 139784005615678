import axios from "axios";
import * as actions from "./actionCreators";
import DatacodeConfig from "../../../../core/config";
import {
  SET_EVENT_REGISTRATION_LOADING,
  GET_EVENT_DETAILS_LOADING,
  GET_EVENTS_LIST_LOADING,
  SET_SEARCH_EVENTS_LIST,
  SET_SEARCH_EVENTS_LIST_LOADING,
} from "../constants";
import { generateQueryParams } from "../../../../utils/helpers";

const baseURL = DatacodeConfig.baseURL;

export const createEvent = (event) => (dispatch) => {
  dispatch({ type: SET_EVENT_REGISTRATION_LOADING });
  return axios
    .post(`${baseURL}/event/create_event`, event)
    .then((res) => {
      if (res.status === 200) {
        console.log("eventRegister", res.data);
        dispatch({ type: SET_EVENT_REGISTRATION_LOADING });
        return { success: true, event: res.data && res.data.event };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_EVENT_REGISTRATION_LOADING });
      console.log("eventRegister Error", error);
    });
};

export const handleDeleteEvent = (id) => (dispatch) => {
  dispatch({ type: SET_EVENT_REGISTRATION_LOADING });
  return axios
    .put(`${baseURL}/event/delete_event`, { id })
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: SET_EVENT_REGISTRATION_LOADING });
        dispatch(actions.clearEventsList());
        return { success: true, event: res.data && res.data.event };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_EVENT_REGISTRATION_LOADING });
      console.log("Delete Event Error", error);
    });
};

export const eventRegister = (register) => (dispatch) => {
  dispatch({ type: SET_EVENT_REGISTRATION_LOADING });
  return axios
    .put(`${baseURL}/event/register_event`, register)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: SET_EVENT_REGISTRATION_LOADING });
        return { success: true, data: res.data && res.data.event };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_EVENT_REGISTRATION_LOADING });
      console.log("eventRegister Error", error);
    });
};

export const eventQuestion = (question) => (dispatch) => {
  dispatch({ type: SET_EVENT_REGISTRATION_LOADING });
  return axios
    .put(`${baseURL}/event/add_question`, question)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: SET_EVENT_REGISTRATION_LOADING });
        return { success: true, data: res.data && res.data.event };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_EVENT_REGISTRATION_LOADING });
      console.log("eventRegister Error", error);
    });
};

export const getEventRegisters = () => (dispatch) => {
  dispatch({ type: SET_EVENT_REGISTRATION_LOADING });
  return axios
    .get(`${baseURL}/user/get_speakers`)
    .then(({ data }) => {
      if (data.success) {
        return { success: true, data: data.Speakers };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_EVENT_REGISTRATION_LOADING });
      console.log("get speakers error", error);
    });
};

export const getEventDetails = (eventId) => (dispatch) => {
  dispatch({ type: GET_EVENT_DETAILS_LOADING });
  return axios
    .get(`${baseURL}/event/${eventId}`)
    .then(({ data }) => {
      if (data.success) {
        dispatch(actions.setEventDetails(data.event));
        return { success: true, data: data.event };
      }
    })
    .catch((error) => {
      dispatch({ type: GET_EVENT_DETAILS_LOADING });
      console.log("get event error", error);
    });
};

export const getOrganiserEvents = (data) => (dispatch) => {
  dispatch({ type: GET_EVENTS_LIST_LOADING, payload: data.state });
  return axios
    .get(
      `${baseURL}/event/list/events/${generateQueryParams({
        page: data.page,
        limit: data.limit,
        filterTags: data.filterTags,
        organizerUserName: data.organizerUserName,
        eventRegUserEmail: data.eventRegUserEmail,
        currentUserEmail: data.email,
        state: data.state,
        filterTags: data.tags,
        order: data.order,
      })}`,
    )
    .then((res) => {
      if (
        res.status === 200 &&
        res?.data?.events &&
        data.state === "upcoming"
      ) {
        dispatch(actions.setEventsUpcoming(res?.data?.events));
        return { success: true, data: res?.data?.events };
      } else if (
        res.status === 200 &&
        res?.data?.events &&
        data.state === "live"
      ) {
        dispatch(actions.setEventsLive(res?.data?.events));
        return { success: true, data: res?.data?.events };
      } else if (
        res.status === 200 &&
        res?.data?.events &&
        data.state === "past"
      ) {
        dispatch(actions.setEventsPast(res?.data?.events));
        return { success: true, data: res?.data?.events };
      } else if (
        res.status === 200 &&
        res?.data?.events &&
        data.state === "all"
      ) {
        dispatch(actions.setEventsList(res?.data?.events));
        return { success: true, data: res?.data?.events };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: GET_EVENTS_LIST_LOADING, payload: data.state });
      console.log("organiser events List Error", error);
    });
};

export const getSearchEvents = (data) => (dispatch) => {
  dispatch({ type: SET_SEARCH_EVENTS_LIST_LOADING });
  return axios
    .get(
      `${baseURL}/event/list/events${generateQueryParams({
        page: data.page,
        limit: data.limit,
        filterTags: data.filterTags,
        organizerUserName: data.organizerUserName,
        eventRegUserEmail: data.eventRegUserEmail,
        currentUserEmail: data.email,
        state: data.state,
        filterTags: data.tags,
        order: data.order,
      })}`,
    )
    .then((res) => {
      if (res.status === 200 && res?.data?.events) {
        dispatch({ type: SET_SEARCH_EVENTS_LIST, payload: res?.data?.events });
        return { success: true, data: res?.data?.events };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_SEARCH_EVENTS_LIST_LOADING });
      console.log("Searched events List Error", error);
    });
};

export const eventFeedback = (feedback) => (dispatch) => {
  dispatch({ type: SET_EVENT_REGISTRATION_LOADING });
  return axios
    .put(`${baseURL}/event/feedback_event`, feedback)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: SET_EVENT_REGISTRATION_LOADING });
        return { success: true, data: res.data && res.data.event };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_EVENT_REGISTRATION_LOADING });
      console.log("event Feedback Error", error);
    });
};

export const addEventSpeaker = (speaker) => (dispatch) => {
  dispatch({ type: SET_EVENT_REGISTRATION_LOADING });
  return axios
    .put(`${baseURL}/event/event_speaker`, speaker)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: SET_EVENT_REGISTRATION_LOADING });
        dispatch(getEventDetails({ id: speaker.id }));
        return { success: true, data: res.data && res.data.event };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_EVENT_REGISTRATION_LOADING });
      console.log("event Speaker Error", error);
    });
};

export const editEventSpeaker = (speaker) => (dispatch) => {
  dispatch({ type: SET_EVENT_REGISTRATION_LOADING });
  return axios
    .put(`${baseURL}/event/edit_event_speaker`, speaker)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: SET_EVENT_REGISTRATION_LOADING });
        return { success: true, data: res.data && res.data.event };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_EVENT_REGISTRATION_LOADING });
      console.log("edit event Speaker Error", error);
    });
};

export const getEventsList = () => (dispatch) => {
  dispatch({ type: GET_EVENTS_LIST_LOADING });
  return axios
    .get(`${baseURL}/event/fetch_events`)
    .then((res) => {
      if (res.status === 200) {
        dispatch(actions.setEventsList(res.data && res.data.events));
        return { success: true, data: res.data && res.data.events };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: GET_EVENTS_LIST_LOADING });
      console.log("event List Error", error);
    });
};

export const editEvent = (event) => (dispatch) => {
  dispatch({ type: SET_EVENT_REGISTRATION_LOADING });
  return axios
    .put(`${baseURL}/event/edit_event`, event)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: SET_EVENT_REGISTRATION_LOADING });
        return { success: true, data: res.data && res.data.event };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_EVENT_REGISTRATION_LOADING });
      console.log("event Edit Error", error);
    });
};

export const checkUserEventReg = (data) => (dispatch) => {
  dispatch({ type: SET_EVENT_REGISTRATION_LOADING });
  return axios
    .put(`${baseURL}/event/check_event_reg`, data)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: SET_EVENT_REGISTRATION_LOADING });
        return { success: true, data: res.data && res.data.registeredUser };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_EVENT_REGISTRATION_LOADING });
      console.log("check user event reg Error", error);
    });
};

export const addEventAttendees = (data) => (dispatch) => {
  dispatch({ type: SET_EVENT_REGISTRATION_LOADING });
  return axios
    .put(`${baseURL}/event/attendees`, data)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: SET_EVENT_REGISTRATION_LOADING });
        return { success: true, data: res.data && res.data.event };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_EVENT_REGISTRATION_LOADING });
      console.log("check user event reg Error", error);
    });
};
