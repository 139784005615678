import {
  SET_EVENTS_LIST,
  SET_EVENTS_UPCOMING_LIST,
  SET_EVENTS_PAST_LIST,
  SET_EVENTS_LIVE_LIST,
  SET_EVENT_DETAILS,
  SET_CLEAR_EVENTS_LIST,
  SET_SHOW_SEARCH_RESULT_PAGE,
  SET_SEARCH_QUERY_OBJECT,
} from "../constants";

export const setEventsList = (data) => ({
  type: SET_EVENTS_LIST,
  payload: data,
});

export const setEventsUpcoming = (data) => ({
  type: SET_EVENTS_UPCOMING_LIST,
  payload: data,
});

export const setEventsPast = (data) => ({
  type: SET_EVENTS_PAST_LIST,
  payload: data,
});

export const setEventsLive = (data) => ({
  type: SET_EVENTS_LIVE_LIST,
  payload: data,
});

export const setEventDetails = (data) => ({
  type: SET_EVENT_DETAILS,
  payload: data,
});

export const clearEventsList = () => ({
  type: SET_CLEAR_EVENTS_LIST,
})

export const setSearchQueryObject = (data) => ({
  type: SET_SEARCH_QUERY_OBJECT,
  payload: data,
})

export const setShowSearchResultPage = (data) => ({
  type: SET_SHOW_SEARCH_RESULT_PAGE,
  payload: data
})