import React from "react";
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import { Avatar, Card, Dropdown } from 'antd';
import { Link } from "react-router-dom";

const { Meta } = Card;


const BatchCard = ({ batch, deleteBatch }) => {

  const items = [
    {
      label: <span>Add Mentor</span>,
      key: '1',
    },
    {
      label: <span>Participants</span>,
      key: '2',
    },
    {
      type: 'divider',
    },
    {
      label: <span className="text-danger" onClick={() => deleteBatch(batch._id)}>Delete</span>,
      key: '0',
    },
  ];

  return (
    <>
      <div className="days-batch-card">
        <Card
          style={{
            width: 300,
          }}
          cover={
            <img
              alt="example"
              src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
            />
          }
          actions={[
            <Link to={`/admin/dayscode/batch_dashboard/${batch._id}`}><SettingOutlined key="setting" /></Link>,
            <Link to={`/admin/dayscode/batch/${batch._id}`}><EditOutlined key="edit" /></Link>,
            <>
              <Dropdown menu={{ items }} trigger={['click']}>
                <EllipsisOutlined onClick={(e) => e.preventDefault()} key="ellipsis" />
              </Dropdown>
            </>
          ]}
        >
          <Meta
            avatar={<Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel" />}
            title={batch.title}
            description={batch.description}
          />
        </Card>
      </div>
    </>
  )
}

export default BatchCard