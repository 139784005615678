import React, { useState, useEffect } from "react";

import Editor from "react-simple-code-editor";
import Highlight, { defaultProps } from "prism-react-renderer";
import theme from "prism-react-renderer/themes/nightOwl";

const styles = {
  root: {
    borderRadius: "7px",
    minHeight: "250px",
    boxSizing: "border-box",
    fontFamily: '"Dank Mono", "Fira Code", monospace',
    ...theme.plain,
  },
};

const CodeEditor = ({ exampleCode, onChange }) => {
  const [code, setCode] = useState(exampleCode || "");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setCode(exampleCode);
  }, [exampleCode]);

  const onValueChange = (code) => {
    setCode(code);
    onChange(code);
  };

  const highlight = (code) => (
    <Highlight {...defaultProps} theme={theme} code={code} language='jsx'>
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <>
          {tokens.map((line, i) => (
            <>
              <div className='line' {...getLineProps({ line, key: i })}>
                {/* <span className='editorLineNumber'>{i + 1}</span> */}
                <span className='editorText'>
                  {line.map((token, key) => (
                    <span {...getTokenProps({ token, key })} />
                  ))}
                </span>
              </div>
            </>
          ))}
        </>
      )}
    </Highlight>
  );

  return (
    <div>
      <Editor
        value={code}
        onValueChange={onValueChange}
        highlight={highlight}
        padding={10}
        textareaId='codeArea'
        style={styles.root}
        className={"code-editor"}
      />
    </div>
  );
};

export default CodeEditor;
