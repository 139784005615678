import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Field, reduxForm } from "redux-form";

import { ReactComponent as Reset } from "../../../../assets/images/svg/reset-password.svg";
import { RenderInputField } from "../../../../shared_components/ReduxFormFields";
import { matchPassword, required } from "../../../../utils/validators";

const ResetPassword = ({ reset, handleSubmit, submitting, resetPassword }) => {
  const { resetCode } = useParams();
  const history = useHistory();

  const onSubmit = (values) => {
    const user = { ...values };
    user["email_code"] = resetCode;
    resetPassword(user).then((res) => {
      if (res.success) {
        history.push("/login");
      }
    });
    reset("resetForm");
  };

  return (
    <>
      <div className='row m-0 login'>
        <div className='col-12 col-md-8 img-section p-5'>
          <div className='p-4'>
            <Reset width='500px' height='500px' />
          </div>
        </div>
        <div className='col-12 col-md-4 login-section'>
          <div className='pt-md-3 mt-5 px-4 login-card'>
            <h1 className='text-center login-text'>Reset Password</h1>
            <form className='' onSubmit={handleSubmit(onSubmit)}>
              <div>
                <Field
                  type='password'
                  name='password'
                  label='New Password'
                  placeholder='********'
                  component={RenderInputField}
                  validate={[required]}
                />
                <Field
                  type='password'
                  name='confirm_password'
                  label='Re-Type New Password'
                  placeholder='********'
                  component={RenderInputField}
                  validate={[required]}
                />
              </div>
              <div className='row mt-4'>
                <div className='col-12 text-right'>
                  <button
                    type='submit'
                    className='btn btn-primary login-button'
                    disabled={submitting}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default reduxForm({
  form: "resetForm",
  validate: matchPassword,
})(ResetPassword);
