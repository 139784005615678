import React from 'react'

const ParticipantListCard = ({ participant, handleDeleteFromBatch }) => {
    return (
        <>
            <div className="row mx-0 my-2 border rounded-lg d-flex justify-content-between card-schadow p-2">
                <div className="col-9 px-1">
                    <div className="row mx-0">
                        <p className="">Name: <b>{participant.name}</b></p>
                    </div>
                    <div className="row mx-0">
                        <p className="">Email: <b>{participant.email}</b></p>
                    </div>
                </div>
                <div className='col-3 px-0 align-items-center d-flex justify-content-around'>
                    <i className="fas fa-trash" onClick={() => handleDeleteFromBatch(participant._id)} />
                </div>
            </div></>
    )
}

export default ParticipantListCard