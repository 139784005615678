import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { getEventDetails } from "../../../actions";
import EventDashboard from "./EventDashboard";

const mapStateToProps = ({ event, user }) => ({
  eventRegistationLoading: event.eventRegistationLoading,
  currentUser: user.currentUser ? user.currentUser : {},
});

const mapDispatchToProps = { getEventDetails };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EventDashboard)
);
