import React from "react";

import { useHistory } from "react-router-dom";
import { Field, reduxForm } from "redux-form";

import { RenderInputField } from "../../../../../../shared_components/ReduxFormFields";
import { required } from "../../../../../../utils/validators";

const Comment = ({
  reset,
  handleSubmit,
  submitting,
  id,
  userName,
  commentTutorial,
}) => {
  const history = useHistory();
  const onSubmit = (values) => {
    if (!userName) {
      history.push(`/login`);
    }
    const value = { ...values };
    const text = value.comment;
    if (id && text && userName) {
      commentTutorial({ id, text, userName }).then((res) => {
        if (res) {
          console.log("comm", res.data);
        }
      });
    }

    reset("commentForm");
  };

  return (
    <>
      <form
        className='d-flex justify-content-center align-items-center'
        onSubmit={handleSubmit(onSubmit)}
      >
        <Field
          name='comment'
          type='textarea'
          component={RenderInputField}
          placeholder='review'
          validate={[required]}
        />
        <div className='row mt-2 ml-4'>
          <div className='col-12 text-right'>
            <button
              type='submit'
              className='btn btn-primary login-button'
              disabled={submitting}
            >
              Submit Review
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default reduxForm({
  form: "commentForm", // a unique name for this form
})(Comment);
