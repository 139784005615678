import React from 'react'
import CodePenBlockPreview from "./EmbedBlocks/EnbedBlocksPreview/CodePenBlockPreview";
import GithubGistPreview from "./EmbedBlocks/EnbedBlocksPreview/GithubGistPreview";
import YoutubeBlockPreview from "./EmbedBlocks/EnbedBlocksPreview/YoutubeBlockPreview";
import ImageUploadBlockPreview from "./ImageBlock/ImageUploadBlockPreview";
import QuizBlockMultiOptionsPreview from "./QuizBlock/QuizBlockMultiOptionsPreview";
import QuizBlockSingleOptionPreview from "./QuizBlock/QuizBlockSingleOptionPreview";
import TestEditorElementsBlockPreview from "./TextBlocks/TestEditorElementsBlockPreview";
import TextEditorBlockPreview from "./TextBlocks/TextEditorBlockPreview";


export const BlocksFramPreviewDictionary = (item) => {
  const componentDictionary = {
    quizMultiOption: <QuizBlockMultiOptionsPreview item={item} form={`form-${item._id}`} />,
    quizSingleOption: <QuizBlockSingleOptionPreview item={item} form={`form-${item._id}`} />,
    textEditor: <TextEditorBlockPreview item={item} />,
    textEditorElements: <TestEditorElementsBlockPreview item={item} />,
    youtubeBlock: <YoutubeBlockPreview item={item} />,
    codePenBlock: <CodePenBlockPreview item={item} />,
    githubGistBlock: <GithubGistPreview item={item} />,
    imageBlock: <ImageUploadBlockPreview item={item} />
  };

  return componentDictionary[item.type];
};