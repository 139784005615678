export const DSAContent = [
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1643738356/xghsaods47f7tpf2qbeb.png",
    name: "Fundamental",
    description:
      "Introduction to programming, programming fundamentals and DSA, with Basic Quiz and Assignment problem to make you comfortable with the portal",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1643731660/jb6vyn4pbuzm9uy5zccj.png",
    name: "Math",
    description:
      "Learn Finding the number of digits in a number, Arithmetic and Geometric Progressions, Quadratic Equations, and Maths quiz and Assignment problem.",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1643730274/t5owya4hpfgokz9sx1qe.png",
    name: "Array",
    description:
      "Introduction and Advantages, Types of Arrays, Operations on Arrays and Quizzes based on array and Assignment problems",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1643731702/xluej8oexwtjpd7iskcn.png",
    name: "Matrix",
    description:
      "Introduction to Matrix, Multidimensional Matrix, Pass Matrix as Argument, related quiz and Assignment problem.",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1643731495/mnlsopvdhlfrlb9bhnml.png",
    name: "Searching",
    description:
      "Introduction to Searching, Binary Search Iterative and Recursive, Binary Search and various associated problems and Searching quiz and Assignment problem.",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1643731529/gc0dkvnaw5a6r7inzvja.png",
    name: "Sorting",
    description:
      "Introduction to Sorting, Implementation of sort() function in Arrays, different types of sorting methods, quiz and assignment problem.",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1643731730/ksxi5delx8xxelawtig2.png",
    name: "Two Pointers",
    description:
      "Introduction to two pointer, implementation of Two pointers, quiz and assignment problem.",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1643731601/xxr5jab8thbzfkn81yrv.png",
    title: "Day 11 to 15 : Strings",
    name: "String",
    description:
      "Discussion of String DS, String Manipulation, Problems related to string, String Quiz and Assignment Problem.",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1643738197/ibpojefrchatdobw0laf.png",
    title: "Day 14 : Bit Manipulation",
    name: "Bit Manipulation",
    description:
      "Learn Bitwise Operators in all programming languages, Bit manipulation quiz and Assignment problem",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1643731407/sqx9kuedlsc9bdzo3ghu.png",
    title: "Day 16 to 19 : Recursion & Backtracking",
    name: "Recursion & Backtracking",
    description:
      "Introduction to Recursion, Applications of Recursion, Writing base cases in Recursion, Recursion, Concepts of Backtracking, quiz and Assignment problem.",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1643731287/fv6vd34rhhnt6kcdxdz4.png",
    title: "Day 20 to 22 : Linked List",
    name: "Linked List",
    description:
      "Introduction, Doubly Linked List, Circular Linked List, Loop Problems, Quiz and Assignment Problem.",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1643731560/u1muj9xi2ooxnlsyuwoq.png",
    title: "Day 23 to 26 : Stack",
    name: "Stack",
    description:
      "Understanding the Stack data structure, Applications of Stack, Implementation of Stack in Array and Linked List, Quiz and Assignment Problem",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1643731210/nl3zexok8b38ih2f90yx.png",
    title: "Day 27 to 29 : Queue",
    name: "Queue",
    description:
      "Introduction and Application, Implementation of the queue using array and LinkedList, Quiz and Assignment Problem",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1643731156/v8xh5bfgguiwxuwrlvzg.png",
    title: "Day 30 : Hashing",
    name: "Hashing",
    description:
      "Introduction and Time complexity analysis, Application of Hashing, Discussion on Direct Address Table and Quiz and Assignment Problem",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1643731626/lkmwmedyjfbqjdrvinvp.png",
    title: "Day 22 & 23 : Tree",
    name: "Tree",
    description:
      "Introduction, Implementation in various Transversals, Quiz and Assignment Problem",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1643738238/xgjh5yyu4kkuxelhvv5h.png",
    title: "Day 24 : Binary Search Tree",
    name: "BST",
    description:
      "Background, Introduction and Application, Implementation of Search in BST, Insertion in BST , Quiz and Assignment Problem",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1643730691/hl2ets5v6b6hlmqqkrgk.png",
    title: "Day 25 & 26 : Heap",
    name: "Heap",
    description:
      "Introduction to Heap, Implementation of Heap, Applications, Quiz and Assignment Problem.",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1643730358/qlqgvaragcyd1sbaizi9.png",
    title: "Day 27 & 28 : Graph",
    name: "Graph",
    description:
      "Introduction to Graph, Graph Representation, Breadth-First Search, Quiz and Assignment Problem",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1643738287/qevghvx4is736xkhscu5.png",
    title: "Day 29 & 30 : Dynamic Programming",
    name: "Dynamic Programming",
    description:
      "Introduction, Dynamic Programming and Quiz and Assignment Problem",
  },
];

//------------------------------------

export const LearningRoadmap = [
  // {
  //   img: "http://res.cloudinary.com/datacode/image/upload/v1643738356/xghsaods47f7tpf2qbeb.png",
  //   title: "Day 1 : Programming Fundamentals",
  //   name: "Fundamental",
  //   description:
  //     "Introduction to programming, programming fundamentals and DSA, with Basic Quiz and Assignment problem to make you comfortable with the portal",
  // },
  // {
  //   img: "http://res.cloudinary.com/datacode/image/upload/v1643731660/jb6vyn4pbuzm9uy5zccj.png",
  //   title: "Day 2 : Math",
  //   name: "Math",
  //   description:
  //     "Learn Finding the number of digits in a number, Arithmetic and Geometric Progressions, Quadratic Equations, and Maths quiz and Assignment problem.",
  // },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1643730274/t5owya4hpfgokz9sx1qe.png",
    title: "Day 1 to 4 : Arrays",
    name: "Array",
    description:
      "Introduction and Advantages, Types of Arrays, Operations on Arrays and Quizzes based on array and Assignment problems",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1643731702/xluej8oexwtjpd7iskcn.png",
    title: "Day 5 & 6 : Matrix",
    name: "Matrix",
    description:
      "Introduction to Matrix, Multidimensional Matrix, Pass Matrix as Argument, related quiz and Assignment problem.",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1643731495/mnlsopvdhlfrlb9bhnml.png",
    title: "Day 7 & 8 : Searching",
    name: "Searching",
    description:
      "Introduction to Searching, Binary Search Iterative and Recursive, Binary Search and various associated problems and Searching quiz and Assignment problem.",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1643731529/gc0dkvnaw5a6r7inzvja.png",
    title: "Day 9 & 10 : Sorting",
    name: "Sorting",
    description:
      "Introduction to Sorting, Implementation of sort() function in Arrays, different types of sorting methods, quiz and assignment problem.",
  },
  // {
  //   img: "http://res.cloudinary.com/datacode/image/upload/v1643731730/ksxi5delx8xxelawtig2.png",
  //   title: "Day 10 : Two Pointer",
  //   name: "Two Pointers",
  //   description:
  //     "Introduction to two pointer, implementation of Two pointers, quiz and assignment problem.",
  // },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1643731601/xxr5jab8thbzfkn81yrv.png",
    title: "Day 11 to 15 : Strings",
    name: "String",
    description:
      "Discussion of String DS, String Manipulation, Problems related to string, String Quiz and Assignment Problem.",
  },
  // {
  //   img: "http://res.cloudinary.com/datacode/image/upload/v1643738197/ibpojefrchatdobw0laf.png",
  //   title: "Day 14 : Bit Manipulation",
  //   name: "Bit Manipulation",
  //   description:
  //     "Learn Bitwise Operators in all programming languages, Bit manipulation quiz and Assignment problem",
  // },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1643731407/sqx9kuedlsc9bdzo3ghu.png",
    title: "Day 16 to 19 : Recursion & Backtracking",
    name: "Recursion & Backtracking",
    description:
      "Introduction to Recursion, Applications of Recursion, Writing base cases in Recursion, Recursion, Concepts of Backtracking, quiz and Assignment problem.",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1643731287/fv6vd34rhhnt6kcdxdz4.png",
    title: "Day 20 to 22 : Linked List",
    name: "Linked List",
    description:
      "Introduction, Doubly Linked List, Circular Linked List, Loop Problems, Quiz and Assignment Problem.",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1643731560/u1muj9xi2ooxnlsyuwoq.png",
    title: "Day 23 to 26 : Stack",
    name: "Stack",
    description:
      "Understanding the Stack data structure, Applications of Stack, Implementation of Stack in Array and Linked List, Quiz and Assignment Problem",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1643731210/nl3zexok8b38ih2f90yx.png",
    title: "Day 27 to 29 : Queue",
    name: "Queue",
    description:
      "Introduction and Application, Implementation of the queue using array and LinkedList, Quiz and Assignment Problem",
  },
  {
    img: "http://res.cloudinary.com/datacode/image/upload/v1643731156/v8xh5bfgguiwxuwrlvzg.png",
    title: "Day 30 : Hashing",
    name: "Hashing",
    description:
      "Introduction and Time complexity analysis, Application of Hashing, Discussion on Direct Address Table and Quiz and Assignment Problem",
  },
  // {
  //   img: "http://res.cloudinary.com/datacode/image/upload/v1643731626/lkmwmedyjfbqjdrvinvp.png",
  //   title: "Day 22 & 23 : Tree",
  //   name: "Tree",
  //   description:
  //     "Introduction, Implementation in various Transversals, Quiz and Assignment Problem",
  // },
  // {
  //   img: "http://res.cloudinary.com/datacode/image/upload/v1643738238/xgjh5yyu4kkuxelhvv5h.png",
  //   title: "Day 24 : Binary Search Tree",
  //   name: "BST",
  //   description:
  //     "Background, Introduction and Application, Implementation of Search in BST, Insertion in BST , Quiz and Assignment Problem",
  // },
  // {
  //   img: "http://res.cloudinary.com/datacode/image/upload/v1643730691/hl2ets5v6b6hlmqqkrgk.png",
  //   title: "Day 25 & 26 : Heap",
  //   name: "Heap",
  //   description:
  //     "Introduction to Heap, Implementation of Heap, Applications, Quiz and Assignment Problem.",
  // },
  // {
  //   img: "http://res.cloudinary.com/datacode/image/upload/v1643730358/qlqgvaragcyd1sbaizi9.png",
  //   title: "Day 27 & 28 : Graph",
  //   name: "Graph",
  //   description:
  //     "Introduction to Graph, Graph Representation, Breadth-First Search, Quiz and Assignment Problem",
  // },
  // {
  //   img: "http://res.cloudinary.com/datacode/image/upload/v1643738287/qevghvx4is736xkhscu5.png",
  //   title: "Day 29 & 30 : Dynamic Programming",
  //   name: "Dynamic Programming",
  //   description:
  //     "Introduction, Dynamic Programming and Quiz and Assignment Problem",
  // },
];