import React, { useEffect } from "react";
import AOS from "aos";

const FirstStepToFuture = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="row mx-0 justify-content-center firstStep my-5">
      <div className="col-10 px-0">
        <h1 className="text-center pt-lg-5 pb-3">
          The first step towards your future
        </h1>
        <div className="row mx-0 mt-lg-3">
          <div
            className="col-12 col-sm-12 col-md-6 col-lg-6 d-flex align-items-center"
            data-aos="fade-up"
          >
            <p className="para text-center text-lg-left text-md-left">
              The first step toward your future begins—your gateway to a
              successful career in the IT field. This internship offers you the
              invaluable opportunity to work on real-time projects, gaining
              hands-on experience that will set you apart in the job market.
              You'll collaborate with industry professionals, sharpen your
              technical skills, and learn how to tackle real-world challenges.
              It's a chance to build your network, grow your expertise, and lay
              a strong foundation for your future career in technology. Take the
              first step today and unlock your potential with DataCode!
            </p>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="d-flex justify-content-center" data-aos="fade-up">
              <img
                src={require("../../../../../assets/images/internship/firstStep.jpg")}
                className="img-fluid rounded-circle"
              />
            </div>
          </div>
        </div>
        {/* ----------------------------------------------------------------------------------- */}
      </div>
    </div>
  );
};
export default FirstStepToFuture;
