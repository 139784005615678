import Parser from "html-react-parser";
import React, { useEffect, useState } from "react";

const TestEditorElementsBlockPreview = ({ item }) => {
  const [elementStyle, setElementStyle] = useState({
    color: "",
    icon: "",
  });

  const getElementStyle = () => {
    if (item.subType === "info") {
      setElementStyle({
        color: "#17A2B8",
        icon: "fal fa-info",
        backgroundColor: "#E1F7FF",
        textColor: "#17A2B8",
      });
    } else if (item.subType === "tip") {
      setElementStyle({
        color: "#B45AF2",
        icon: "fas fa-check-circle",
        backgroundColor: "#F9EBFF",
        textColor: "#B45AF2",
      });
    } else if (item.subType === "warning") {
      setElementStyle({
        color: "#FFC010",
        icon: "fas fa-hand-point-down",
        backgroundColor: "#FEF7DB",
        textColor: "#944F01",
      });
    } else if (item.subType === "note") {
      setElementStyle({
        color: "#007BFF",
        icon: "fas fa-comment",
        backgroundColor: "#E1F7FF",
        textColor: "#007BFF",
      });
    } else if (item.subType === "important") {
      setElementStyle({
        color: "#FFC010",
        icon: "fas fa-exclamation-circle",
        backgroundColor: "#FEF7DB",
        textColor: "#944F01",
      });
    } else {
      setElementStyle({
        color: "#6C757D",
        icon: "fas fa-check-circle",
        backgroundColor: "#E1F7FF",
        textColor: "#6C757D",
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    getElementStyle();
  }, []);

  const blockStyle = {
    border: `2px solid  ${elementStyle.backgroundColor}`,
    borderLeft: `5px solid  ${elementStyle.color}`,
  };

  return (
    <>
      <div>
        {!item.hasOwnProperty("payload") || item.payload === "<p></p>\n" ? (
          <div className='d-flex justify-content-center py-2'>
            <p>There is nothing to preview yet.</p>
          </div>
        ) : (
          <div className={` border-preview `} style={blockStyle}>
            <div className='m-0'>
              <div
                className={` block-header d-flex rounded-top`}
                style={{
                  backgroundColor: elementStyle.backgroundColor,
                  color: elementStyle.textColor,
                }}
              >
                <i className={`${elementStyle.icon} mr-3 mt-1`} />
                <p
                  className='p-0 m-0'
                  style={{ color: elementStyle.textColor }}
                >
                  {" "}
                  {item.subType.toUpperCase()}
                </p>
              </div>
              <div className='block-content'>
                {Parser(String(item.payload))}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TestEditorElementsBlockPreview;
