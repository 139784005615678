import {
  SET_PROBLEMS_LIST,
  SET_PROBLEM_DETAILS,
  SET_PROBLEM_DETAILS_BLANK,
  SET_USER_SUBMISSIONS_LIST,
  SET_BATCH_LEADERBOARD,
  SET_SETTING_ACTIVE_TAB,
  SET_ALL_SUBMISSIONS,
  SET_DAYS_USERS_LIST,
  SET_DAYS_USER_DETAILS,
  SET_PROGRESS_COUNT,
  SET_LESSONS_LIST,
  SET_LESSON_DETAILS,
  SET_QUIZ_CHALLENGES_LIST,
  SET_QUIZ_CHALLENGE_DETAILS,
  SET_CONTENT_DETAILS,
  SET_CONTENTS_LIST,
  SET_TEST_CASES_RESPONCES_SUCCESS,
  SET_TEST_CASES_RESPONCES,
  SET_TEST_CASES_RESPONCES_FAILURE,
  SET_SOLUTION_SUBMISSION_LOADING,
  GET_CONTENT_COMPLETED_STATUS,
  SET_ALL_USERS_FEEDBACKS,
  SET_PRACTICE_PROBLEMS_LIST,
  SET_SPEAKERS_LIST,
  SET_ROADMAP_LIST,
  SET_CHECK_IN_BATCH_DETAILS,
  CLEAR_LESSON_DETAILS,
} from "../constants";

export const setProblemsList = (data) => ({
  type: SET_PROBLEMS_LIST,
  payload: data,
});

export const setPracticeProblemsList = (data) => ({
  type: SET_PRACTICE_PROBLEMS_LIST,
  payload: data,
});

export const setProblemDetails = (data) => ({
  type: SET_PROBLEM_DETAILS,
  payload: data,
});

export const setProblemDetailsBlank = (data) => ({
  type: SET_PROBLEM_DETAILS_BLANK,
  payload: data,
});

export const setUserSubmissionsList = (data) => ({
  type: SET_USER_SUBMISSIONS_LIST,
  payload: data,
});

export const setBatchLeaderboard = (data) => ({
  type: SET_BATCH_LEADERBOARD,
  payload: data,
});

export const setSettingActiveTab = (data) => ({
  type: SET_SETTING_ACTIVE_TAB,
  payload: data,
});

export const setAllSubmissions = (data) => ({
  type: SET_ALL_SUBMISSIONS,
  payload: data,
});

export const setDaysUsersList = (data) => ({
  type: SET_DAYS_USERS_LIST,
  payload: data,
});

export const setDaysUserDetails = (data) => ({
  type: SET_DAYS_USER_DETAILS,
  payload: data,
});

export const setProgressCount = (data) => ({
  type: SET_PROGRESS_COUNT,
  payload: data,
});

export const setLessonsList = (data) => ({
  type: SET_LESSONS_LIST,
  payload: data,
});

export const setLessonDetails = (data) => ({
  type: SET_LESSON_DETAILS,
  payload: data,
});

export const clearLessonDetails = (data) => ({
  type: CLEAR_LESSON_DETAILS,
  payload: data,
});

export const setQuizChallengesList = (data) => ({
  type: SET_QUIZ_CHALLENGES_LIST,
  payload: data,
});

export const setQuizChallengeDetails = (data) => ({
  type: SET_QUIZ_CHALLENGE_DETAILS,
  payload: data,
});

export const setContentDetails = (data) => ({
  type: SET_CONTENT_DETAILS,
  payload: data,
});

export const setContentsList = (data) => ({
  type: SET_CONTENTS_LIST,
  payload: data,
});

export const setTestCasesResponcesSuccess = (data) => ({
  type: SET_TEST_CASES_RESPONCES_SUCCESS,
  payload: data,
});

export const setTestCasesResponces = () => ({
  type: SET_TEST_CASES_RESPONCES,
});

export const setTestCasesResponcesFailure = (data) => ({
  type: SET_TEST_CASES_RESPONCES_FAILURE,
  payload: data,
});

export const setSolutionSubmissionLoading = (data) => ({
  type: SET_SOLUTION_SUBMISSION_LOADING,
});

export const getContentCompletedStatus = (data) => ({
  type: GET_CONTENT_COMPLETED_STATUS,
  payload: data,
});

export const setAllUsersFeedbacks = (data) => ({
  type: SET_ALL_USERS_FEEDBACKS,
  payload: data,
});

export const setSpeakersList = (data) => ({
  type: SET_SPEAKERS_LIST,
  payload: data,
});

export const setRoadmapList = (data) => ({
  type: SET_ROADMAP_LIST,
  payload: data,
});

export const setCheckInBatch = (data) => ({
  type: SET_CHECK_IN_BATCH_DETAILS,
  payload: data,
});
