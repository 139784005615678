import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getEventDetails } from "../../../actions";

import RegisterList from "./RegisterList";

const mapStateToProps = ({ event }) => ({
  eventRegistationLoading: event.eventRegistationLoading,
});

const mapDispatchToProps = { getEventDetails };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RegisterList)
);
