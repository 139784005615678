import Signup from "./Signup";
import { connect } from "react-redux";

import { signupUser, googleLogin, authCheck } from "../../actions";

const mapStateToProps = ({ user }) => ({
  userError: user.error,
  loginUserLoading: user.loginUserLoading,
  currentUser: user.currentUser ? user.currentUser : {},
});

const mapDispatchToProps = {
  signupUser,
  googleLogin,
  authCheck,
};

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
