var eventActions = {
  btnVisible: false,
  btnContent: "",
  statusBoxVisible: false,
  statusBoxContent: "",
  statusBoxKey: "",
  loginRequire: false,
};

export const eventActionsController = (eventDetails, userData) => {
  if (
    !userData.email &&
    eventDetails.event_type === "public" &&
    (eventDetails.state === "upcoming" || eventDetails.state === "live") &&
    !eventDetails.hasRegistred
  ) {
    eventActions = {
      btnVisible: true,
      btnContent: "Join {Virtual / Inperson} Event",
      loginRequire: false,
      type: "register",
    };
  }
  if (
    !userData.email &&
    (eventDetails.event_type === "public" ||
      eventDetails.event_type === "private") &&
    eventDetails.state === "past" &&
    !eventDetails.hasRegistred
  ) {
    eventActions = {
      btnVisible: true,
      btnContent: "Login to get access On Demand",
      loginRequire: true,
      type: "register",
    };
  }
  if (
    !userData.email &&
    eventDetails.event_type === "private" &&
    (eventDetails.state === "upcoming" || eventDetails.state === "live") &&
    !eventDetails.hasRegistred
  ) {
    eventActions = {
      btnVisible: true,
      btnContent: "Login to Register",
      loginRequire: true,
      type: "register",
    };
  }
  if (
    !userData.email &&
    eventDetails.event_type === "private" &&
    eventDetails.state === "live" &&
    !eventDetails.hasRegistred
  ) {
    eventActions = {
      btnVisible: true,
      btnContent: "Login to Join",
      loginRequire: true,
      type: "register",
    };
  }
  if (
    userData.email &&
    (eventDetails.event_type === "public" ||
      eventDetails.event_type === "private") &&
    eventDetails.state === "past" &&
    eventDetails.hasRegistred === false
  ) {
    eventActions = {
      btnVisible: true,
      btnContent: "Please Share Event Feedback!!!",
      loginRequire: false,
      type: "feedback",
    };
  }
  if (
    userData.email &&
    eventDetails.event_type === "private" &&
    (eventDetails.state === "upcoming" || eventDetails.state === "live") &&
    !eventDetails.hasRegistred
  ) {
    eventActions = {
      btnVisible: true,
      btnContent: "Register to Join Event!",
      loginRequire: false,
      type: "register",
    };
  }
  if (
    userData.email &&
    eventDetails.event_type === "private" &&
    (eventDetails.state === "upcoming" || eventDetails.state === "live") &&
    eventDetails.hasRegistred === false
  ) {
    eventActions = {
      btnVisible: true,
      btnContent: "Join Live Session",
      loginRequire: false,
      statusBoxVisible: true,
      statusBoxKey: "postReg",
      statusBoxContent: "Registred Successfully",
      type: "register",
    };
  }
  if (
    userData.email &&
    eventDetails.event_type === "public" &&
    eventDetails.state === "upcoming" &&
    !eventDetails.hasRegistred
  ) {
    eventActions = {
      btnVisible: true,
      btnContent: "Register Now to Join",
      loginRequire: false,
      type: "register",
    };
  }
  if (
    userData.email &&
    eventDetails.event_type === "public" &&
    eventDetails.state === "live" &&
    !eventDetails.hasRegistred
  ) {
    eventActions = {
      btnVisible: true,
      btnContent: "Join Live Session",
      loginRequire: false,
      type: "register",
    };
  }
  if (
    userData.email &&
    eventDetails.event_type === "public" &&
    (eventDetails.state === "upcoming" || eventDetails.state === "live") &&
    eventDetails.hasRegistred
  ) {
    eventActions = {
      btnVisible: true,
      btnContent: "Join Virtual Session!",
      loginRequire: false,
      type: "register",
    };
  }
  if (
    userData.email &&
    (eventDetails.event_type === "public" ||
      eventDetails.event_type === "private") &&
    eventDetails.state === "past" &&
    !eventDetails.hasRegistred
  ) {
    eventActions = {
      btnVisible: true,
      btnContent: "Watch on Demand",
      loginRequire: false,
      type: "feedback",
    };
  }

  return eventActions;
};
