import _ from "lodash";
import React, { useEffect, useState } from "react";
import Combobox from "react-widgets/Combobox";

import { checkAdmin } from "../../../../../../../core/auth";
// import DatacodeConfig from "../../../../../../../core/config";
import CompilerModal from "../../../../../../../shared_components/Compiler/CompilerModal";
import CustomLoader from "../../../../../../../shared_components/CustomLoader";
import ToolTip from "../../../../../../../shared_components/Tooltip";
import { getSort } from "../../../../../../../utils";
import LayoutContainer from "../../LayoutContainer";
import DeleteProblemModal from "../../Problems/ProblemsList/DeleteProblemModal";
import SubmissionFrame from "./SubmissionFrame";

// const batchMentors = DatacodeConfig.batchMentors || [];

const SubmissionsList = ({
  getAllSubmissions,
  problemsList,
  getProblems,
  allSubmissions,
  problemsListLoading,
  approveUserCodeSubmission,
  deleteUserSubmission,
  getDaysUsersListOptions,
  daysUsers,
  getUserSubmissions,
  getUserSubmissionsByStatus,
  submissions,
  getUserSubmissionsLoading,
  currentUser,
  getAllUsersProgressByMentor,
}) => {
  const [problem, setproblem] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [openCompilerModal, setOpenCompilerModal] = useState(false);
  const [tempDeleteId, setTempDeleteId] = useState();
  const [tempCode, setTempCode] = useState();
  const [tempLanguage, setTempLanguage] = useState();
  const [allSubmissionsFilter, setAllSubmissionsFilter] = useState([]);
  const [status, setStatus] = useState();
  const [user, setUser] = useState();
  const [submissionData, setSubmissionData] = useState([]);
  const [listView, setListView] = useState("grid");

  const statusOptions = [
    { status: "all", value: 0 },
    { status: "waiting", value: 3 },
    { status: "good", value: 1 },
    { status: "poor", value: 2 },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    getProblems({ status: "public" });
    getDaysUsersListOptions(currentUser?.email, checkAdmin(currentUser));
    getAllUsersProgressByMentor(
      "3",
      currentUser?.email,
      checkAdmin(currentUser),
    );
  }, [currentUser]);

  const onChangeProblemOption = (e) => {
    setproblem(e);
    getAllSubmissions(e.value).then((res) => {
      if (res) {
        setSubmissionData(filterProblemByMentorUsers(res.data, true));
        if (status) {
          const data =
            res.data.length &&
            res.data.filter((item) => item.isApproved === status.status);
          setAllSubmissionsFilter(filterProblemByMentorUsers(data, true));
        } else {
          setAllSubmissionsFilter(filterProblemByMentorUsers(res.data, true));
        }
      }
    });
  };

  const filterProblemByMentorUsers = (data, isUnion) => {
    let filteredList = [];

    for (var i = 0; i < data.length; i++) {
      var item1 = data[i],
        found = false;
      for (var j = 0; j < daysUsers.length && !found; j++) {
        found = item1.userName === daysUsers[j].userName;
      }
      if (found === !!isUnion) {
        // isUnion is coerced to boolean
        filteredList.push(item1);
      }
    }
    return filteredList;
  };

  const onChangeStatus = (e) => {
    setAllSubmissionsFilter([]);
    setStatus(e);
    if (user && user.value && e) {
      getUserSubmissionsByStatus(user.value, e.status).then((res) => {
        if (res && res.success) {
          setAllSubmissionsFilter(getSort(res.userSubmissions, "day"));
        }
      });
    } else if (problem && problem.value) {
      if ((e && e.status) === "all") {
        setAllSubmissionsFilter(submissionData);
      } else {
        const data =
          submissionData.length &&
          submissionData.filter((item) => item.isApproved === (e && e.status));
        setAllSubmissionsFilter(data);
      }
    }
  };

  const getProlemOptions = (problems) => {
    const options = [];
    getSort(problems, "day").map((item, i) =>
      options.push({ problem: `Day: ${i} | ${item.title}`, value: item.id }),
    );
    return options;
  };

  const getUsersOptions = (users) => {
    const options = [];
    getSort(users, "userName").map((item) =>
      options.push({ user: item.userName, value: item.userName }),
    );
    return options;
  };

  const onChangeUserOption = (e) => {
    setUser(e);
    console.log(status);
    if (status === undefined) {
      getUserSubmissionsByStatus(e.value, "all").then((res) => {
        if (res && res.success) {
          setAllSubmissionsFilter(getSort(res.userSubmissions, "day"));
        }
      });
    }
  };

  const handleSubmittionApproval = (reviewData) => {
    approveUserCodeSubmission(
      reviewData.id,
      reviewData.userName,
      reviewData,
    ).then((res) => {
      if (res) {
        if (problem) {
          onChangeProblemOption(problem);
          onChangeStatus(status);
        }
        if (user) {
          onChangeUserOption(user);
          onChangeStatus(status);
        }
      }
    });
  };

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const toggleCompilerModal = () => {
    setOpenCompilerModal(!openCompilerModal);
  };

  const handleDeleteSolution = () => {
    deleteUserSubmission(tempDeleteId.id, tempDeleteId.userName).then((res) => {
      if (res) {
        setOpenModal(!openModal);
        if (problem) {
          onChangeProblemOption(problem);
          onChangeStatus(status);
        }
        if (user) {
          onChangeUserOption(user);
          onChangeStatus(status);
        }
      }
    });
  };

  const deleteSolution = (id, userName) => {
    setTempDeleteId({ id, userName });
    setOpenModal(!openModal);
  };

  const compileSolution = (code, language) => {
    setTempCode(code);
    setOpenCompilerModal(!openCompilerModal);
    setTempLanguage(language);
  };

  const handleResetFilter = () => {
    getDaysUsersListOptions(currentUser?.email, checkAdmin(currentUser));
    setproblem();
    setUser();
    setStatus();
    setAllSubmissionsFilter([]);
    setSubmissionData([]);
  };

  return (
    <>
      <LayoutContainer>
        <div className='row mx-0 solution-nav'>
          <div className='col-md-8 col-10 mx-0 p-md-0 p-2 d-flex align-items-center'>
            <h4 className='py-md-3 px-md-3 py-2 mb-0'>
              <i className='fal fa-sitemap mr-2' />
              Submissions List
            </h4>
          </div>
          <div className='col-md-4 col-2 d-flex justify-content-end align-items-center'>
            {submissionData && (
              <h2 className='mb-0 p-2 text-white badge badge-pill badge-primary'>
                Total:{" "}
                {allSubmissionsFilter?.length
                  ? allSubmissionsFilter?.length
                  : submissionData?.length}
              </h2>
            )}
          </div>
        </div>
        <div className='px-3'>
          <div className='py-md-5 px-0'>
            <div className='card-schadow border rounded-lg mt-3'>
              <div className='row m-0'>
                <div className='col-md-4 col-12 px-md-4 px-2 my-3'>
                  <label className='form-label'>Select User</label>
                  <Combobox
                    data={getUsersOptions(daysUsers)}
                    dataKey={"value"}
                    textField='user'
                    placeholder={"Select User Name"}
                    value={user}
                    onChange={(value) => onChangeUserOption(value)}
                    disabled={(problem && true) || (status && true)}
                  />
                </div>
                {
                  <div className='col-md-4 col-12 px-md-4 px-2 my-3'>
                    <label className='form-label'>Select Problem</label>
                    <Combobox
                      data={getProlemOptions(problemsList)}
                      dataKey={"value"}
                      textField='problem'
                      placeholder={"Select Problem Name"}
                      value={problem}
                      onChange={(value) => onChangeProblemOption(value)}
                      disabled={user && true}
                    />
                  </div>
                }
                {(user && user.value) || (problem && problem.value) ? (
                  <div className='col-md-4 col-12 px-md-4 px-2 my-3'>
                    <label className='form-label'>Select Review Status</label>
                    <Combobox
                      data={statusOptions}
                      dataKey={"value"}
                      textField='status'
                      placeholder={"Select Status"}
                      value={status}
                      onChange={(value) => onChangeStatus(value)}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className='row mx-0 my-3'>
                <div className='col-12 col-12 px-md-4 px-2'>
                  <div
                    className='btn btn-sm btn-secondary'
                    onClick={() => handleResetFilter()}
                  >
                    <small>
                      <i className='far fa-history mr-2' />
                      Reset Filter
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='px-0'>
            <div className='row mx-0'>
              <div className='col-12 d-flex align-items-center p-3 border-bottom rounded-lg justify-content-end'>
                <div className=''>
                  <span
                    id='table'
                    onClick={() => setListView("table")}
                    className='p-2 mr-3 border rounded'
                  >
                    <i className='fas fa-list' />
                  </span>
                  <ToolTip
                    message={"Table View"}
                    id='table'
                    placement='bottom'
                  />
                  <span
                    id='grid'
                    onClick={() => setListView("grid")}
                    className='p-2 border rounded'
                  >
                    <i className='fas fa-th-large' />
                  </span>
                  <ToolTip message={"Grid View"} id='grid' placement='bottom' />
                </div>
              </div>
            </div>
          </div>
          {listView === "grid" ? (
            <>
              {getUserSubmissionsLoading ? (
                <CustomLoader />
              ) : (
                <div className='row m-0 my-5'>
                  <div className='col-12 px-0'>
                    {!_.isEmpty(allSubmissionsFilter) ? (
                      <>
                        {allSubmissionsFilter &&
                          allSubmissionsFilter.map((solution, i) => (
                            <div key={i}>
                              <SubmissionFrame
                                i={i}
                                handleSubmittionApproval={
                                  handleSubmittionApproval
                                }
                                deleteSolution={deleteSolution}
                                solution={
                                  user?.value ? solution.submissions : solution
                                }
                                problemId={
                                  user?.value
                                    ? solution.problemId
                                    : problem.value
                                }
                                day={user?.value ? solution.day : ""}
                                compileSolution={compileSolution}
                                problem={problem}
                                currentUser={currentUser}
                              />
                            </div>
                          ))}
                      </>
                    ) : (
                      <div className='row mx-0 px-md-5 px-2'>
                        <div className='col-12 border rounded'>
                          <h1 className='text-center p-3 '>No Data</h1>
                        </div>
                      </div>
                    )}
                  </div>
                  <CompilerModal
                    open={openCompilerModal}
                    toggle={toggleCompilerModal}
                    solutionSubmitted={true}
                    preCode={tempCode}
                    preCompilerLanguage={tempLanguage}
                  />
                  <DeleteProblemModal
                    open={openModal}
                    toggle={toggleModal}
                    onSubmit={handleDeleteSolution}
                    submitButtonName={"Delete User Solution"}
                  />
                </div>
              )}
            </>
          ) : (
            <>
              <h1>Table</h1>
            </>
          )}
        </div>
      </LayoutContainer>
    </>
  );
};

export default SubmissionsList;
