import React from "react";
import ToolTip from "../../../../shared_components/Tooltip";
import { getCompletedDays } from "./DaysCode/helpers";

export const getSubmissionStatusIndicator = (solution) => {
  if (solution.status === "correct") {
    return (
      <span className='green' style={{ fontSize: "15px" }}>
        <i className='green far fa-check-circle' /> Correct
      </span>
    );
  }
  if (solution.status === "wrong") {
    return (
      <span className='red' style={{ fontSize: "15px" }}>
        <i className='red far fa-times' /> Wrong
      </span>
    );
  }
  if (solution.status === 6) {
    return (
      <span className='red' style={{ fontSize: "15px" }}>
        <i className='yellow fal fa-exclamation-triangle' /> Compile Time Error
      </span>
    );
  }
  if (solution.status === 11) {
    return (
      <span className='red' style={{ fontSize: "15px" }}>
        <i className='fal fa-exclamation-circle' /> Run Time Error
      </span>
    );
  }
};

export const getDaysStatus = (solutions) => {
  let statusClass = "border";
  if (+solutions.day === getCompletedDays()) {
    return (statusClass = "blue");
  }
  if (+solutions.day > getCompletedDays()) {
    return (statusClass = "border");
  }
  if (+solutions.day < getCompletedDays()) {
    return (statusClass = "grey");
  }
};

export const getSubmissionStatus = (solutions, userName) => {
  let status = <i className='blue far fa-clock'>Upcoming</i>;
  if (
    solutions.submissions[0] &&
    solutions.submissions[0].userName === userName
  ) {
    if (solutions.submissions[0].status === "correct") {
      return (status = (
        <span
          className='green d-flex align-items-center'
          style={{ fontSize: "15px" }}
        >
          <i className='green far fa-check-circle pr-2' />
          <span className='d-md-block d-none'>Correct</span>
        </span>
      ));
    }
    if (solutions.submissions[0].status === "wrong") {
      return (status = (
        <span
          className='red d-flex align-items-center'
          style={{ fontSize: "15px" }}
        >
          <i className='fas fa-times-circle red pr-2' />
          <span className='d-md-block d-none'>Wrong</span>
        </span>
      ));
    }
    if (solutions.submissions[0].status === 6) {
      return (status = (
        <span
          className='yellow d-flex align-items-center'
          style={{ fontSize: "15px" }}
        >
          <i className='fal fa-exclamation-triangle yellow pr-2' />
          <span className='d-md-block d-none'>Compile Time Error</span>
        </span>
      ));
    }
    if (solutions.submissions[0].status === 11) {
      return (status = (
        <span
          className='red d-flex align-items-center'
          style={{ fontSize: "15px" }}
        >
          <i className='red fal fa-exclamation-circle pr-2' />
          <span className='d-md-block d-none'>Run Time Error</span>
        </span>
      ));
    }
  } else if (+solutions.day < getCompletedDays()) {
    return (status = (
      <span className='d-flex align-items-center' style={{ fontSize: "15px" }}>
        <i className='red fal fa-alarm-exclamation pr-2' />
        <span className='d-md-block d-none'>Not Submitted</span>
      </span>
    ));
  } else if (+solutions.day === getCompletedDays()) {
    return (status = (
      <span className='d-flex align-items-center'>
        <i className='blue fal fa-calendar-day pr-2' />
        <span className='d-md-block d-none'>Current Day</span>
      </span>
    ));
  } else {
    return (status = (
      <span className='d-flex align-items-center'>
        <i className='grey far fa-clock pr-2' />
        <span className='d-md-block d-none'>Upcoming</span>
      </span>
    ));
  }
};

export const checkSolutionStatus = (status) => {
  if (status === "correct") {
    return "green-card";
  } else {
    return "red-card";
  }
};

export const checkSolutionApprovalStatus = (reviewStatus) => {
  if (reviewStatus === "good") {
    return "border-left-voilet";
  }
  if (reviewStatus === "poor") {
    return "border-left-orange";
  }
  if (reviewStatus === "waiting") {
    return "border-left-yellow";
  }
};

export const getSubmissionStatusForList = (solutions, userName) => {
  if (
    solutions &&
    solutions.submissions[0] &&
    solutions.submissions[0].userName === userName
  ) {
    if (solutions.submissions[0].status === "correct") {
      return (
        <>
          <i id='correct' className={`m-0 fas fa-check-circle green`} />
          <ToolTip
            message={"Correct Submission"}
            id='correct'
            placement='right-end'
          />
        </>
      );
    }
    if (solutions.submissions[0].status === "wrong") {
      return (
        <>
          <i id='wrong' className={`m-0 fas fa-times-circle red`} />
          <ToolTip
            message={"Wrong Submission"}
            id='wrong'
            placement='right-end'
          />
        </>
      );
    }
    if (solutions.submissions[0].status === 6) {
      return (
        <>
          <i
            id='compiletime'
            className={`m-0 far fa-exclamation-triangle yellow`}
          />
          <ToolTip
            message={"Compile Time Error"}
            id='compiletime'
            placement='right-end'
          />
        </>
      );
    }
    if (solutions.submissions[0].status === 11) {
      return (
        <>
          <i id='runtime' className={`m-0 fal fa-exclamation-circle red`} />
          <ToolTip
            message={"Run Time Error"}
            id='runtime'
            placement='right-end'
          />
        </>
      );
    }
  } else if (solutions && +solutions.day < getCompletedDays()) {
    return (
      <>
        <i id='alarm' className={`m-0 fal fa-alarm-exclamation`} />
        <ToolTip
          message={"Assigment Reminder"}
          id='alarm'
          placement='right-end'
        />
      </>
    );
  } else if (solutions && +solutions.day === getCompletedDays()) {
    return (
      <>
        <i id='currentDay' className={`m-0 text-warning far fa-lightbulb-on`} />
        <ToolTip
          message={"Current Day"}
          id='currentDay'
          placement='right-end'
        />
      </>
    );
  }
};

export const showIcon = (type) => {
  if (type === "problem") {
    return (
      <>
        <i className='fa fa-code' id='code' />
        <ToolTip message={"Code Assigment"} id='code' placement='right-end' />
      </>
    );
  }
  if (type === "lesson") {
    return (
      <>
        <i className='fal fa-file-alt' id='lesson' />
        <ToolTip message={"Lesson"} id='lesson' placement='right-end' />
      </>
    );
  } else {
    return (
      <>
        <i className='fal fa-ballot-check' id='quiz' />
        <ToolTip message={"Quiz Challenge"} id='quiz' placement='right-end' />
      </>
    );
  }
};
