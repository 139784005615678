import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrganiserEvents } from "../../../actions";

import { SharedEventLoadMoreButton } from "../../EventSharedComponent/EventSharedComponent";
import EventCard from "../../EventsList/EventCard";

const MyRegEvents = () => {
  const dispatch = useDispatch();
  const { userName, email } = useSelector((state) => state?.user?.currentUser);
  const { eventsList, eventsListLoading } = useSelector(
    (state) => state?.event,
  );

  const [pageNumber, setPageNumber] = useState(0);
  // const [openModal, setOpenModal] = useState(false)
  // const [tempDeleteId, setTempDeleteId] = useState()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (userName && email) {
      dispatch(
        getOrganiserEvents({
          page: pageNumber,
          limit: 4,
          eventRegUserEmail: email,
          email,
          state: "all",
        }),
      );
    }
  }, [userName]);

  const handleLoadMore = (page) => {
    if (userName && email) {
      dispatch(
        getOrganiserEvents({
          page,
          limit: 4,
          eventRegUserEmail: email,
          state: "all",
          email,
        }),
      );
    }
    setPageNumber(page);
  };

  return (
    <>
      <h1>My Events List</h1>

      <div className='row m-0 mx-md-5  mx-0 d-flex align-items-stretch px-md-5 px-1'>
        {eventsList &&
          eventsList.map((event, i) => {
            if (eventsList.length === i + 1) {
              // return <div ref={lastBookElementRef} key={i}><EventCard deleteEvent={deleteEvent} data={event} /></div>
              return (
                <div key={i}>
                  <EventCard data={event} />
                </div>
              );
            } else {
              return <EventCard data={event} />;
            }
          })}
      </div>
      <SharedEventLoadMoreButton
        loading={eventsListLoading}
        setPageNumber={handleLoadMore}
        pageNumber={pageNumber}
      />
    </>
  );
};
export default MyRegEvents;
