import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";

import CustomLoader from "../../../../../../shared_components/CustomLoader";
import LayoutContainer from "../LayoutContainer";

import { getAllUsersFeedbacks } from "../../../actions";
import { getColumns, getFeedbackColumns } from "./helpers";

const FeedBacks = () => {
  const dispatch = useDispatch();
  const feedbacks = useSelector((state) => state?.dayscode?.feedbacks);
  const feedbacksLoading = useSelector(
    (state) => state?.dayscode?.feedbacksLoading,
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    dispatch(getAllUsersFeedbacks("2"));
  }, []);

  const handleUserAction = () => {};
  const handleUserDeleteAction = () => {};

  let columns = getColumns(handleUserAction, handleUserDeleteAction);
  let feedbackColumns = getFeedbackColumns();

  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row, rowIndex) =>
      row.feedbacks &&
      row.feedbacks.length && (
        <div className='table table-responsive'>
          <BootstrapTable
            keyField='_id'
            bordered={true}
            data={row.feedbacks}
            columns={feedbackColumns}
            search
            hover={true}
          />
        </div>
      ),
  };

  return (
    <>
      <LayoutContainer>
        <h1>FeedBacks</h1>
        {feedbacksLoading ? (
          <CustomLoader />
        ) : (
          <>
            {feedbacks && feedbacks.length && (
              <div className='table table-responsive'>
                <BootstrapTable
                  keyField='_id'
                  bordered={true}
                  data={feedbacks}
                  columns={columns}
                  search
                  hover={true}
                  expandRow={expandRow}
                />
              </div>
            )}
          </>
        )}
      </LayoutContainer>
    </>
  );
};

export default FeedBacks;
