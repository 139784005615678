import React, { useEffect } from "react";
import AOS from "aos";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const testimonialData = [
  {
    image1: require("../../../../../assets/images/internship/pj.png"),
    name: "Poorti Jain",
    designation: "React Developer",
    review:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias pariatur sit culpa perferendis et possimus animi, temp assumenda, voluptatibus repellendus magnam deserunt incidunt optio excepturi quae ipsum reprehenderit nemo sed?",
  },
  {
    image1: require("../../../../../assets/images/internship/pj.png"),
    name: "Poorti Jain",
    designation: "React Developer",
    review:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias pariatur sit culpa perferendis et possimus animi, temp assumenda, voluptatibus repellendus magnam deserunt incidunt optio excepturi quae ipsum reprehenderit nemo sed?",
  },
  {
    image1: require("../../../../../assets/images/internship/pj.png"),
    name: "Poorti Jain",
    designation: "React Developer",
    review:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias pariatur sit culpa perferendis et possimus animi, temp assumenda, voluptatibus repellendus magnam deserunt incidunt optio excepturi quae ipsum reprehenderit nemo sed?",
  },
  {
    image1: require("../../../../../assets/images/internship/pj.png"),
    name: "Poorti Jain",
    designation: "React Developer",
    review:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias pariatur sit culpa perferendis et possimus animi, temp assumenda, voluptatibus repellendus magnam deserunt incidunt optio excepturi quae ipsum reprehenderit nemo sed?",
  },
];

const Testimonials = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="row mx-0 justify-content-center projects my-5">
      <div className="col-8 px-0">
        <h1 className="text-center pb-5">Words of our Intern</h1>
        <Carousel responsive={responsive} arrows={true} autoPlay={true}>
          {testimonialData.map((items) => (
            <div className="row mx-0 container mb-4">
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 box1 px-0">
                <div className="p-4">
                  <img
                    src={items.image1}
                    className="img-fluid projectimg rounded-circle"
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-8 col-lg-8 box2 px-0">
                <div className="p-4">
                  <h4 className="text-light">{items.name}</h4>
                  <p className="py-2 text-light">{items.review}</p>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};
export default Testimonials;
