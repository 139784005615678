import React, { Component } from "react";
import { Link } from "react-router-dom";
import PostCard from "../PostCard";
import RightSideSection from "../../right_section/RightSideSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faNewspaper } from "@fortawesome/free-solid-svg-icons";
import RecommendPost from "../../right_section/RecommendPost";
import ArticleCardCarousel from "./ArticleCardCarousel";
import Discuss from "../Discuss";
import Technologies from "../../right_section/Technologies";
import axios from "axios";
import { Helmet } from "react-helmet";

class IndexArticles extends Component {
  state = {
    persons: [],
  };
  componentDidMount() {
    axios
      .get(`http://localhost:3000/api/get_users`)
      .then((req, res) => {
        console.log(res.json);
        this.setState({ persons: res.json });
        console.log("Data AA RAHA HE");
      })
      .catch(function (error) {
        console.log("error  aaa rhi he");
      });
    window.scrollTo(0, 0);
  }
  render() {
    const description =
      "Time to enhance your beauty with the information of special documentation made for Special Learners";
    const title = "Technical Learning Blogs | Datacode.in";
    return (
      <>
        {/* Card Code */}
        <Helmet>
          <title>{title}</title>
          <meta
            data-react-helmet='true'
            name='description'
            content={description}
          ></meta>
          <meta
            data-react-helmet='true'
            http-equiv='cleartype'
            content='on'
          ></meta>
          <meta
            data-react-helmet='true'
            name='apple-mobile-web-app-capable'
            content='yes'
          ></meta>
          <meta
            data-react-helmet='true'
            name='robots'
            content='max-snippet:-1, max-image-preview:large, max-video-preview:-1'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:locale'
            content='en_US'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:type'
            content='website'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:title'
            content={title}
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:description'
            content={description}
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:url'
            content='https://www.datacode.in/'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:site_name'
            content='Datacode.in'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:image'
            content='https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:image:secure_url'
            content='https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:image:width'
            content='400'
          ></meta>
          <meta
            data-react-helmet='true'
            property='og:image:height'
            content='400'
          ></meta>
          <meta
            data-react-helmet='true'
            name='keywords'
            content='programming,coding,online, code online, online judge, practice, run code, test, expected outcome, programming competition, programming contest, online programming, online computer programming'
          ></meta>
          <meta
            data-react-helmet='true'
            name='twitter:card'
            content='summary_large_image'
          ></meta>
          <meta
            data-react-helmet='true'
            name='twitter:description'
            content={description}
          ></meta>
          <meta
            data-react-helmet='true'
            name='twitter:title'
            content={title}
          ></meta>
          <meta
            data-react-helmet='true'
            name='twitter:site'
            content='@datacode_in'
          ></meta>
          <meta
            data-react-helmet='true'
            name='twitter:creator'
            content='@datacode_in'
          ></meta>
          <meta
            data-react-helmet='true'
            name='twitter:image'
            content='https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png'
          ></meta>
          <link
            data-react-helmet='true'
            rel='shortlink'
            href='https://www.datacodoe.in/'
          ></link>
          <link
            data-react-helmet='true'
            rel='dns-prefetch'
            href='//www.datacode.in'
          ></link>
          <link
            data-react-helmet='true'
            rel='dns-prefetch'
            href='//s.w.org'
          ></link>
          <link
            data-react-helmet='true'
            rel='alternate'
            type='application/rss+xml'
            title='Datacode'
            href='https://www.datacode.in/'
          ></link>
          <link
            data-react-helmet='true'
            rel='canonical'
            href='https://www.datacode.in/'
          ></link>
        </Helmet>
        <div className='container-fluid'>
          <div className='row m-0'>
            <div className='col-1'></div>
            <div className='col-md-8 '>
              <div className='row m-0 my-5'>
                <div className='col'>
                  <h1
                    className='text-center mb-2 rounded p-2'
                    style={{ backgroundColor: "#30006b", color: "#ffffff" }}
                  >
                    <FontAwesomeIcon
                      icon={faNewspaper}
                      color='#ffffff'
                      size='1x'
                    />{" "}
                    Communities Learning Blogs
                  </h1>
                  <h5 className='text-center my-3'>
                    Time to enhance your beauty with the information of special
                    documentation made for Special Learners
                  </h5>
                  <p className='text-center mb-5'>let’s scratch your brain.</p>
                  {/* API gET CODE */}
                  <h1>{this.state.persons}</h1>
                  {this.state.persons.map((persons, i) => (
                    <li key={i}>{persons.user.name}</li>
                  ))}
                  <Link
                    to='/home'
                    className='badge mr-1'
                    style={{ backgroundColor: "#06CB6C" }}
                  >
                    <FontAwesomeIcon icon={faHome} size='2x' color='#072C71' />
                  </Link>
                </div>
              </div>

              <div className='row m-0 my-5'>
                <div className='col'>
                  <div className='card-deck m-2'>
                    <PostCard
                      // imgsource='web_blog.jpeg'
                      imgsource='http://res.cloudinary.com/datacode/image/upload/v1731157170/znri1uoltjiuj5xxlqmr.jpg'
                      title='Learning Platforms'
                      paragraph='Curious minds do adventures- LEARN GROW REPEAT'
                      link='/index_learning_platforms'
                    />
                    <PostCard
                      imgsource='http://res.cloudinary.com/datacode/image/upload/v1731157219/ocekzr8xbepps94jmrhz.jpg'
                      title='Programming Languages Blogs'
                      paragraph='Small coding steps lead to large destinations~Time to enjoy the errors!'
                      link='/prog_lan_blog'
                    />
                    <PostCard
                      imgsource='http://res.cloudinary.com/datacode/image/upload/v1731157170/znri1uoltjiuj5xxlqmr.jpg'
                      title='Web Design Blogs'
                      paragraph='Art of Technology,creation starts from designing/styling.'
                      link='/webdesign_blog'
                    />
                  </div>
                  <div className='card-deck m-2'>
                    <PostCard
                      imgsource='http://res.cloudinary.com/datacode/image/upload/v1731157298/rg6whmbdh3ewvhnhety2.jpg'
                      title='DBMS Blogs'
                      paragraph='Let’s wake up our data  by asking a query for building relationships.'
                      link='/dbms_blog'
                    />
                    <PostCard
                      imgsource='http://res.cloudinary.com/datacode/image/upload/v1731157325/lbdjpgwmtikkggsxxssz.jpg'
                      title='Documentation Blogs'
                      paragraph='Stealing source code gives you pleasure,but not the succes-#SELFCODE..'
                      link='/documentation_blog'
                    />
                    <PostCard
                      imgsource='http://res.cloudinary.com/datacode/image/upload/v1731157348/vuj9wiugwee2y2hdjsqx.jpg'
                      title='Machine Learning Blogs'
                      paragraph='AI: Time to change data into information for creating human intelligence.'
                      link='/machine_learning_blog'
                    />
                  </div>
                  <h3 className='mt-4 mb-2' style={{ color: "#072C71" }}>
                    Discuss with the Community
                  </h3>
                  <Discuss
                    url='https://www.datacode.in/?p=/react-datacode/#/index_articles'
                    title='Communities Learning Blogs'
                    identifier='index_articles'
                  />
                  <h4 className='mt-4 mb-4' style={{ color: "#072C71" }}>
                    For More Community Blogs
                  </h4>
                  <ArticleCardCarousel />
                </div>
              </div>
            </div>

            <div className='col-md-3 mt-5'>
              <h5 className='mb-4 mt-5' style={{ color: "#072C71" }}>
                Recommended for you
              </h5>
              <RecommendPost
                imgsource='python_mcq.jpeg'
                RecPostHading='Python MCQs Questions'
                link='/index_python_mcqs'
              />
              <RecommendPost
                imgsource='java_mcq.jpeg'
                RecPostHading='Java MCQs Questions'
                link='/index_java_mcqs'
              />
              <RecommendPost
                imgsource='c_mcq.jpeg'
                RecPostHading='C MCQs Questions'
                link='/index_c_mcqs'
              />
              <RightSideSection />
              <Technologies />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default IndexArticles;
