import { setAppointmentHandlers } from "./setAppointment";
import { createReducer } from "../../../../core/reduxUtils";

const initialState = {
  appointmentSlotsList: [{ name: 'dd' }],
  appointmentSlotsLoading: false,
  appointmentEventDetails: {},
  availableSlots: [],
  error: "4444",
};

const handlers = {
  ...setAppointmentHandlers,
};

const appointmentReducer = createReducer(initialState, handlers);

export default appointmentReducer;
