import React, { useEffect } from "react";

import { Link } from "react-router-dom";
import { reduxForm } from "redux-form";
import CreateEvent from "../../CreateEvent/CreateEventForm";

const EventBasicDetails = ({
  reset,
  handleSubmit,
  submitting,
  eventDetails,
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    window.scroll(0, 0);
  });
  return (
    <>
      <div className='row m-0 my-5 align-items-center'>
        <div className='col-6'>
          <h2>Basic Details</h2>
        </div>
        <div className='col-6 text-right'>
          <Link to={eventDetails && `/event/${eventDetails.id}`}>
            <div className='btn btn-primary'>Priview Event</div>
          </Link>
        </div>
      </div>
      <CreateEvent eventDetails={eventDetails} />
    </>
  );
};

export default reduxForm({
  form: "basic-details",
})(EventBasicDetails);
