import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { setDaysUsersList } from "../../components/main_component/DaysCode/actions/actionCreators";
import { getParticipants } from "../../components/main_component/DaysCode/actions/operations";

const ParticipantFilter = ({ handleSelectedParticipant }) => {
  const dispatch = useDispatch();
  const { daysUsers } = useSelector((state) => state.dayscode) || {};

  const [filterQuery, setFilterQuery] = useState({
    search: "",
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      if (filterQuery.search?.trim() === "") {
        dispatch(setDaysUsersList([]));
      } else {
        dispatch(getParticipants(filterQuery));
      }
    }, 800);

    return () => clearTimeout(debounceTimeout);
  }, [filterQuery, dispatch]);

  return (
    <>
      <div className='row mx-0'>
        <div className='col-12 px-0'>
          <div className='row mx-0'>
            <div className='col-12'>
              <label className='form-label'>Search Name</label>
              <input
                className='form-control'
                type='type'
                name='search'
                value={filterQuery.search}
                onChange={(e) =>
                  setFilterQuery({ [e.target.name]: e.target.value })
                }
              />
            </div>
          </div>
        </div>
        <div className='col-12 px-0'>
          {
            <div className='row mx-0 event-speaker-search-table'>
              {daysUsers &&
                daysUsers?.map((user) => (
                  <div className='w-100 participant-search-card'>
                    <div className='d-flex justify-content-between'>
                      <div className='d-flex flex-column'>
                        <h6 className=''>{user.name}</h6>
                        <p className=''>{user.email}</p>
                      </div>
                      <div>
                        <FaPlus
                          className='add'
                          onClick={() => handleSelectedParticipant(user._id)}
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default ParticipantFilter;
