import React from "react";
import moment from "moment";

export const getColumns = () => [
  {
    dataField: "",
    text: "S.NO.",
    align: "center",
    headerAlign: "center",
    headerClasses: "table-header s-no",
    formatter: (cell, row, index) => <span>{++index}</span>,
  },
  {
    dataField: "name",
    text: "Name",
    sort: true,
    headerClasses: "table-header name",
    style: { color: "#757575" },
  },
  {
    dataField: "question",
    text: "Question",
    sort: true,
    style: { color: "#757575" },
    headerClasses: "table-header status",
  },
  {
    dataField: "createdAt",
    text: "Registered Date",
    sort: true,
    style: { color: "#757575" },
    align: "left",
    headerAlign: "left",
    headerClasses: "table-header",
    formatter: (cell, row) => (
      <span>
        {row.createdAt === null
          ? "Invalid date"
          : moment(row.createdAt).format("LLL")}
      </span>
    ),
  },
];
