import React from "react";
import { Link as LinkScroll } from "react-scroll";
// Private Event

const GetEventPageActionButton = ({
  eventDetails,
  handleEventType,
  userData,
  eventActions,
}) => {
  // const [actionBtnVisibleStatus, setActionBtnVisibleStatus] = useState({
  //   isVisible: false,
  //   content: '',
  //   loginRequire: false,
  // })

  // useEffect(() => {
  //   actionButtonsVisibleStatus(eventDetails, userData)
  // }, [])

  // const actionButtonsVisibleStatus = (eventDetails, userData) => {
  //   if (!userData.email && eventDetails.event_type === 'public' && (eventDetails.state === 'upcoming' || eventDetails.state === 'live') && !eventDetails.hasRegistred) {
  //     setActionBtnVisibleStatus({ isVisible: true, content: 'Join {Virtual / Inperson} Event', loginRequire: false })
  //   }
  //   if (!userData.email && (eventDetails.event_type === 'public' || eventDetails.event_type === 'private') && eventDetails.state === 'past' && !eventDetails.hasRegistred) {
  //     setActionBtnVisibleStatus({ isVisible: true, content: 'Login to get access On Demand', loginRequire: true })
  //   }
  //   if (!userData.email && eventDetails.event_type === 'private' && (eventDetails.state === 'upcoming' || eventDetails.state === 'live') && !eventDetails.hasRegistred) {
  //     setActionBtnVisibleStatus({ isVisible: true, content: 'Login to Register', loginRequire: true })
  //   }
  //   if (!userData.email && eventDetails.event_type === 'private' && eventDetails.state === 'live' && !eventDetails.hasRegistred) {
  //     setActionBtnVisibleStatus({ isVisible: true, content: 'Login to Join', loginRequire: true })
  //   }
  //   if (userData.email && (eventDetails.event_type === 'public' || eventDetails.event_type === 'private') && eventDetails.state === 'past' && eventDetails.hasRegistred === false) {
  //     setActionBtnVisibleStatus({ isVisible: true, content: 'Please Share Event Feedback!!!', loginRequire: false })
  //   }
  //   if (userData.email && eventDetails.event_type === 'private' && (eventDetails.state === 'upcoming' || eventDetails.state === 'live') && !eventDetails.hasRegistred) {
  //     setActionBtnVisibleStatus({ isVisible: true, content: 'Register to Join Event!', loginRequire: false })
  //   }
  //   if (userData.email && eventDetails.event_type === 'private' && (eventDetails.state === 'upcoming' || eventDetails.state === 'live') && eventDetails.hasRegistred) {
  //     setActionBtnVisibleStatus({ isVisible: true, content: 'Join Live Session', loginRequire: false })
  //   }
  //   if (userData.email && eventDetails.event_type === 'public' && eventDetails.state === 'upcoming' && !eventDetails.hasRegistred) {
  //     setActionBtnVisibleStatus({ isVisible: true, content: 'Register Now to Join', loginRequire: false })
  //   }.
  //   if (userData.email && eventDetails.event_type === 'public' && eventDetails.state === 'live' && !eventDetails.hasRegistred) {
  //     setActionBtnVisibleStatus({ isVisible: true, content: 'Join Live Session', loginRequire: false })
  //   }
  //   if (userData.email && eventDetails.event_type === 'public' && (eventDetails.state === 'upcoming' || eventDetails.state === 'live') && eventDetails.hasRegistred) {
  //     setActionBtnVisibleStatus({ isVisible: true, content: 'Join Virtual Session!', loginRequire: false })
  //   }
  //   if (userData.email && (eventDetails.event_type === 'public' || eventDetails.event_type === 'private') && eventDetails.state === 'past' && !eventDetails.hasRegistred) {
  //     setActionBtnVisibleStatus({ isVisible: true, content: 'Watch on Demand', loginRequire: false })
  //   }
  // }

  // const { id } = useParams();
  const ActionBtnAfterLogin = ({ actionBtnContent }) => {
    return (
      <LinkScroll
        to='form'
        spy={true}
        smooth={true}
        onClick={() => handleEventType(eventActions)}
      >
        <button className='btn mb-5 join-us-button text-center card-schadow rounded-lg'>
          {actionBtnContent}
        </button>
      </LinkScroll>
    );
  };
  return (
    <>
      {eventActions.btnVisible && (
        <ActionBtnAfterLogin actionBtnContent={eventActions.btnContent} />
      )}
    </>
  );
};

export default GetEventPageActionButton;
