import React from "react"
import EventDashboardNav from "./EventDashboardNav"

const LayoutContainer = ({
  children,
  activeTab,
  setActiveTab,
}) => {
  return (
    <>
      <div className="row m-0">
        <div className="col-12">
          <EventDashboardNav
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          {children}
        </div>
      </div>
    </>
  )

}

export default LayoutContainer