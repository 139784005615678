export const batchStatusOptions = [
  { name: 'Pending', value: 'pending' },
  { name: 'Draft', value: 'draft' },
  { name: 'Live', value: 'live' },
  { name: 'Hold', value: 'hold' },
]

export const problemTopicOptions = [
  { name: 'Array', value: 'array' },
  { name: 'String', value: 'string' },
  { name: 'LinkedList', value: 'linkedlist' },
  { name: 'Stack', value: 'stack' },
  { name: 'Queue', value: 'queue' },
]
