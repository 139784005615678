import axios from "axios";

import DatacodeConfig from "../../../../core/config";
import { triggerNotifier } from "../../../../utils/notifier";
import {
  GET_APPOINTMENT_EVENT_DETAILS_LOADING,
  SET_APPOINTMENT_EVENT_DETAILS,
  SET_APPOINTMENT_SLOTS_LOADING,
} from "../constants";

const baseURL = DatacodeConfig.baseURL;

export const createUserAppointment = (appointment) => (dispatch) => {
  dispatch({ type: SET_APPOINTMENT_SLOTS_LOADING });
  return axios
    .post(`${baseURL}/appointment/create`, appointment)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: SET_APPOINTMENT_SLOTS_LOADING });
        return { success: true };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: SET_APPOINTMENT_SLOTS_LOADING });
      console.log("Create Appointment Error", error);
    });
};

export const getAppointmentEventDetails = (id) => (dispatch) => {
  dispatch({ type: GET_APPOINTMENT_EVENT_DETAILS_LOADING });
  return axios
    .get(`${baseURL}/appointment/event/${id}`)
    .then((res) => {
      if (res.status === 200) {
        triggerNotifier({
          message: "Appointment Event Details loaded",
          type: "success",
          duration: 1000,
          icon: "👏",
        });
        dispatch({
          type: SET_APPOINTMENT_EVENT_DETAILS,
          payload: res.data,
        });
        dispatch({ type: GET_APPOINTMENT_EVENT_DETAILS_LOADING });
        return {
          success: true,
          appointmentEvent: res.data && res.data?.appointmentEvent,
        };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      dispatch({ type: GET_APPOINTMENT_EVENT_DETAILS_LOADING });
      console.log("Get Speaker Details Error", error);
      triggerNotifier({
        message: "Something went wrong",
        type: "error",
        duration: 1000,
        icon: "⚠️",
      });
    });
};
