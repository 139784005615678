import React, { useEffect, useState } from "react";
import CustomLoader from "../../../../../../../shared_components/CustomLoader";
import CustomSearch from "../../../../../../../shared_components/CustomSearch";
import ToolTip from "../../../../../../../shared_components/Tooltip";

const UserAttendanceTable = ({
  setSearch,
  search,
  daysUsers,
  sessionDetails,
  userAttendancePresentLoading,
  handlePresentAction,
  handleDeleteAttendanceAction,
}) => {
  const [showMerkAbsentIcon, setShowMerkAbsentIcon] = useState("");
  const [totalPresent, setPresent] = useState(0);
  const [totalAbsent, setAbsent] = useState(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    getAttendanceStatics(daysUsers);
  }, [daysUsers]);

  const checkAttentedUser = (user, sessionDetails) => {
    const attentedUser =
      user &&
      user.attendance &&
      user.attendance.find(
        (x) =>
          x.date === sessionDetails.value &&
          sessionDetails.session === x.session,
      );
    return attentedUser;
  };

  const toggle = (showIconId) => {
    if (showIconId === showMerkAbsentIcon) {
      return setShowMerkAbsentIcon("");
    }
    setShowMerkAbsentIcon(showIconId);
  };

  const getAttendanceStatics = (daysUsers) => {
    // let correct = 0
    // let wrong = 0
    // let good = 0
    // let poor = 0
    let present = 0;
    let absent = 0;
    daysUsers &&
      daysUsers.forEach((item) => {
        if (checkAttentedUser(item, sessionDetails)) {
          present += 1;
          setPresent(present);
        } else {
          absent += 1;
          setAbsent(absent);
        }
      });
  };

  return (
    <>
      <div className='solution-nav py-2'>
        <div className='row mx-0 mt-2'>
          <div className='col-md-6 px-2 px-md-4 col-12 mx-0 align-items-center'>
            <h4 className='mb-2'>Users Table</h4>
            <div className='d-flex align-items-center'>
              <span className='badge badge-pill bg-green p-2 mr-3'>
                Present: {totalPresent}
              </span>
              <span className='badge badge-pill bg-red p-2'>
                Absent: {totalAbsent}
              </span>
            </div>
          </div>
          <div className='col-md-6 p-2 col-12 justify-content-end align-items-center'>
            <div className=''>
              <h6>
                <i className='far fa-users-class mr-2' />
                {sessionDetails.session}
              </h6>
              <p className='mb-0'>
                <i className='fal fa-calendar-alt mr-2' />
                {sessionDetails.value}
              </p>
            </div>
          </div>
        </div>
        <div className='p-5'>
          <CustomSearch setSearch={setSearch} search={search} />
        </div>

        {daysUsers && daysUsers.length ? (
          <div className='border-top'>
            <div className='row mx-0 p-md-3 p-2 border'>
              <div className='col-1 px-0 d-flex align-items-center'>
                <span>Sno.</span>
              </div>
              <div className='col-4 d-flex align-items-center'>
                <span>Username</span>
              </div>
              <div className='col-3 d-flex align-items-center'>
                <span>Name</span>
              </div>
              <div className='col-3 justify-content-end align-items-center'>
                <span>Actions</span>
              </div>
            </div>
            {daysUsers.map((user, i) => (
              <div key={i} className='row mx-0 p-md-3 p-2 border'>
                <div className='col-1 px-0 d-flex align-items-center'>
                  <span>{i + 1}</span>
                </div>
                <div className='col-4 d-flex align-items-center text-primary'>
                  <span>
                    <i className='far fa-user mr-2' />
                    {user.userName}
                  </span>
                </div>
                <div className='col-3 d-flex align-items-center'>
                  <span>{user.name}</span>
                </div>
                <div
                  className='col-3 justify-content-end align-items-center'
                  onMouseLeave={() => setShowMerkAbsentIcon("")}
                  onMouseEnter={() => toggle(i)}
                >
                  {userAttendancePresentLoading ? (
                    <CustomLoader />
                  ) : (
                    <>
                      {checkAttentedUser(user, sessionDetails) ? (
                        <div className='d-flex align-items-center'>
                          <i className='far fa-user-check text-primary' />
                          {showMerkAbsentIcon === i && (
                            <>
                              <i
                                id='absent'
                                className='mx-3 far fa-user-times red'
                                onClick={() =>
                                  handleDeleteAttendanceAction(
                                    user,
                                    sessionDetails,
                                  )
                                }
                              ></i>
                              <ToolTip
                                message={"Mark Absent"}
                                id='absent'
                                placement='bottom'
                              />
                            </>
                          )}
                        </div>
                      ) : (
                        <>
                          <button
                            id='present'
                            className='btn btn-sm btn-secondary'
                            onClick={() =>
                              handlePresentAction(user, sessionDetails)
                            }
                          >
                            Present
                          </button>
                          <ToolTip
                            message={"Mark Present"}
                            id='present'
                            placement='bottom'
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className='row m-0'>
            <div className='col-12 text-center p-5'>
              <img
                height='150'
                width='150'
                src={require(`../../../../../../../assets/images/svg/trophy.png`)}
                alt='laoding'
                className='img-fluid'
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default UserAttendanceTable;
