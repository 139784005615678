import { Button, Drawer, Space } from "antd";
import React, { useState } from "react";
import SpeakerFilter from "./SpeakerFilter";

const AddSpeakerDrawer = ({ handleSelectedSpeaker, btnName }) => {
  const [open, setOpen] = useState(false);
  const [openAddRole, setOpenAddRole] = useState("");
  // const [placement, setPlacement] = useState("right");
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    setOpenAddRole("");
  };

  return (
    <>
      <Space>
        <Button className='add-speaker-btn' type='primary' onClick={showDrawer}>
          {btnName}
        </Button>
      </Space>
      <Drawer
        title='Select Datacode Speaker'
        // placement={placement}
        placement='right'
        closable={false}
        onClose={onClose}
        open={open}
        // key={placement}
        key={"right"}
      >
        <SpeakerFilter
          handleSelectedSpeaker={handleSelectedSpeaker}
          openAddRole={openAddRole}
          setOpenAddRole={setOpenAddRole}
        />
      </Drawer>
    </>
  );
};
export default AddSpeakerDrawer;
