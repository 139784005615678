import {
  SET_INTERVIEW_QUESTIONS_LIST,
  SET_INTERVIEW_QUESTIONS_BY_ID,
  SET_INTERVIEW_QUESTIONS_BY_USERNAME,
} from "../constants";

export const setInterviewQuestionsList = (data) => ({
  type: SET_INTERVIEW_QUESTIONS_LIST,
  payload: data,
});

export const setTutorialById = (data) => ({
  type: SET_INTERVIEW_QUESTIONS_BY_ID,
  payload: data,
});

export const setInterviewQuestionsByUserName = (data) => ({
  type: SET_INTERVIEW_QUESTIONS_BY_USERNAME,
  payload: data,
});
