import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import userReducer from "../components/user/reducers";
import quizTestReducer from "../components/quiz_board/reducers";
import appReducer from "../app/reducers";
import tutorialReducer from "../components/main_component/tutorials/reducers";
import eventReducer from "../components/main_component/events/reducers";
import placementReducer from "../components/main_component/Placement/reducers";
import dayscodeReducer from "../components/main_component/DaysCode/reducers";
import clubReducer from "../components/superadmin/Club/reducers";
import appointmentReducer from "../components/main_component/AppointmentBook/reducers";

export default combineReducers({
  form: formReducer,
  user: userReducer,
  quizTest: quizTestReducer,
  app: appReducer,
  tutorial: tutorialReducer,
  event: eventReducer,
  placement: placementReducer,
  dayscode: dayscodeReducer,
  club: clubReducer,
  appointment: appointmentReducer
});
