import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { eventRegister, eventQuestion, eventFeedback } from "../../../actions";

import EventRegistration from "./EventRegistration";

const mapStateToProps = ({ event }) => ({
  eventRegistationLoading: event.eventRegistationLoading,
});

const mapDispatchToProps = { eventRegister, eventQuestion, eventFeedback };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EventRegistration)
);
