import React, { Component } from "react";
import "../../assets/scss/compiler.scss";
import CodeEditor from "../CodeEditor/CodeEditor";
import ToolTip from "../Tooltip";
import { preCode, getLanguageId } from "./helper";

class Compiler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: ``,
      output: ``,
      language_id: 2,
      language: "",
      user_input: ``,
      customInput: false,
      showOutput: false,
      error: "",
      exampleCode: "",
    };
  }

  componentDidMount = () => {
    const { preCompilerCode, preCompilerLanguage } = this.props;
    if (preCompilerCode && preCompilerCode.length && preCompilerLanguage) {
      this.setState({
        input: preCompilerCode,
        language_id: getLanguageId(preCompilerLanguage),
      });
      document.querySelector("#source").value = preCompilerCode;
    }
  };

  input = (code) => {
    this.setState({ error: "" });
    this.setState({ input: code });
  };
  userInput = (event) => {
    event.preventDefault();
    this.setState({ user_input: event.target.value });
  };
  language = (event) => {
    event.preventDefault();
    this.setPreCode(event.target.value);
    this.setState({ language_id: event.target.value });
  };

  setPreCode = (id) => {
    const languageDictionary = {
      1: preCode["1"],
      2: preCode["2"],
      4: "",
      10: "",
    };
    return this.setState({ exampleCode: languageDictionary[id] });
  };

  getLanguage = (language_id) => {
    const languageDictionary = {
      1: "c",
      2: "cpp",
      4: "java",
      10: "python",
    };
    return languageDictionary[language_id];
  };

  handleCodeSubmmit = () => {
    const { input, language_id } = this.state;
    this.props.handleCodeSubmmit(input, this.getLanguage(language_id));
  };

  runCode = async (e) => {
    e.preventDefault();
    this.setState({ showOutput: !this.state.showOutput });
    let outputText = document.getElementById("output");
    outputText.innerHTML = "";
    outputText.innerHTML += "Creating Submission ...\n";
    const response = await fetch(
      "https://judge0-extra-ce.p.rapidapi.com/submissions",
      // "http://20.197.10.95/submissions",
      {
        method: "POST",
        headers: {
          "x-rapidapi-host": "judge0-extra-ce.p.rapidapi.com",
          "x-rapidapi-key":
            "08e18fd929msh0a8dc6f61904afep1f4c8fjsnc672215e6d62", //// Get yours for free at https://rapidapi.com/hermanzdosilovic/api/judge0
          "content-type": "application/json",
          accept: "application/json",
        },
        body: JSON.stringify({
          source_code: this.state.input,
          stdin: this.state.user_input,
          language_id: this.state.language_id,
        }),
      }
    );
    outputText.innerHTML += "Submission Created ...\n";
    const jsonResponse = await response.json();

    let jsonGetSolution = {
      status: { description: "Queue" },
      stderr: null,
      compile_output: null,
    };

    while (
      jsonGetSolution.status.description !== "Accepted" &&
      jsonGetSolution.stderr == null &&
      jsonGetSolution.compile_output == null
    ) {
      outputText.innerHTML = `Creating Submission ... \nSubmission Created ...\nChecking Submission Status\nstatus : ${jsonGetSolution.status.description}`;
      if (jsonResponse.token) {
        // let url = `http://20.197.10.95/submissions/${jsonResponse.token}?base64_encoded=true`;
        let url = `https://judge0-extra-ce.p.rapidapi.com/submissions/${jsonResponse.token}?base64_encoded=true`;

        const getSolution = await fetch(url, {
          method: "GET",
          headers: {
            "x-rapidapi-host": "judge0-extra-ce.p.rapidapi.com",
            "x-rapidapi-key":
              "08e18fd929msh0a8dc6f61904afep1f4c8fjsnc672215e6d62", //// Get yours for free at https://rapidapi.com/hermanzdosilovic/api/judge0
            "content-type": "application/json",
          },
        });

        jsonGetSolution = await getSolution.json();
      }
    }
    if (jsonGetSolution.stdout) {
      const output = atob(jsonGetSolution.stdout);

      outputText.innerHTML = "";

      outputText.innerHTML += `Results : ${output}\nExecution Time : ${jsonGetSolution.time} Secs\nMemory used : ${jsonGetSolution.memory} bytes`;
    } else if (jsonGetSolution.stderr) {
      const error = atob(jsonGetSolution.stderr);

      outputText.innerHTML = "";

      outputText.innerHTML += `\n Error :${error}`;
    } else {
      const compilation_error = atob(jsonGetSolution.compile_output);

      outputText.innerHTML = "";

      outputText.innerHTML += `\n Error :${compilation_error}`;
    }
  };

  handleCompilerReset = () => {
    document.querySelector("#source").value = "";
    this.setState({ input: "" });
  };

  showError = () => {
    this.setState({ error: "Please Write Code Before Submit" });
  };

  render() {
    const { solutionSubmitted, preCode } = this.props;
    return (
      <>
        <div className="compiler">
          <div className="row m-0 compiler-input">
            <div className="col-12 px-0 ">
              <label htmlFor="tags" className="mr-2">
                <span>
                  <i className="fa fa-code mr-2" />
                  Language{" "}
                </span>
              </label>
              <select
                value={this.state.language_id}
                onChange={this.language}
                id="tags"
                className="form-control form-inline language"
              >
                <option value="2">C++</option>
                {/* <option value="1">C</option> */}
                {/* <option value="4">Java</option> */}
                {/* <option value="10">Python</option> */}
              </select>
              <div className="source my-2 code-editor-textarea">
                <CodeEditor
                  id="source"
                  exampleCode={this.state.exampleCode}
                  className="my-2 form-control"
                  required={true}
                  onChange={this.input}
                />
                {/* <textarea
                  required
                  name="solution"
                  onChange={this.input}
                  className=""
                  id="source"
                  placeholder="Write your code from here..."
                /> */}
              </div>
              <span className="text-danger">{this.state.error}</span>
              {/* <div className="row my-2">
                <div className="col-12">
                  <div>
                    <span className="days-heading my-2 ">
                      <i className="fas fa-exclamation fa-fw fa-md"></i> Output
                    </span>
                    <div className=" output">
                      <textarea
                        disabled
                        id="output"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="row m-0 ">
                <div className="col-md-4 col-12 my-2 px-md-0 px-2 d-flex align-items-center justify-content-between">
                  {/* <span onClick={() => this.handleCompilerReset()} className="reset-compiler">
                    <i className="far fa-repeat-alt mr-1" /><small>Reset Code</small>
                  </span>
                  <div className="input-check">
                    <input
                      type="checkbox"
                      name="customInput"
                      id="check"
                      value={this.state.customInput}
                      onChange={() => this.setState({ customInput: !this.state.customInput })}
                    />
                    <label className="ml-2 mb-0 " htmlFor="check">Custom Input</label>
                  </div> */}
                </div>
                <div className="col-md-8 col-12 px-0 my-2 d-flex justify-content-end align-items-center">
                  {/* <button
                    type="submit"
                    className="btn btn-dark ml-2 mr-2 "
                    onClick={this.state.input.length ? this.runCode : this.showError}
                  >
                    <i className="fas fa-cog fa-fw"></i> Run
                  </button> */}
                  {!solutionSubmitted && (
                    <>
                      <button
                        type="submit"
                        id="submission-btn"
                        className="btn text-white bg-dark-green mx-0 mx-md-2"
                        onClick={
                          this.state.input.length
                            ? this.handleCodeSubmmit
                            : this.showError
                        }
                      >
                        <i className="fal fa-upload" /> Submit Code
                      </button>
                      <ToolTip
                        message="This will submit your solution to mentor for review"
                        id="submission-btn"
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* {
            this.state.customInput &&
            <div className="row m-0 my-2">
              <div className="col-12">
                <span className="days-heading my-2 ">
                  Enter your custom inputs for the above code
                </span>
                <br />
                <textarea
                  id="input"
                  className="form-control input"
                  onChange={this.userInput}
                />
              </div>
            </div>
          } */}
        </div>
      </>
    );
  }
}

export default Compiler;
