import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { Field, reduxForm } from "redux-form";

import {
  RenderInputField,
  renderRadioField,
  renderSelectField,
} from "../../../../../shared_components/ReduxFormFields";
import { required } from "../../../../../utils/validators";

import TutorialListCard from "../TutorialListCard";
import { getTechLable, technologyOptions } from "./helper";

const AddTutorial = ({
  reset,
  handleSubmit,
  submitting,
  initialize,
  addTutorial,
  getTutorialByUserName,
  currentUser,
  currentUser: { userName },
  addTutorialLoading,
  tutorialListLoading,
  tutorialByUser,
  editTutorial,
  linkPreview,
}) => {
  // const [tutorialsList, setTutorialsList] = useState()
  const [isEdit, setIsEdit] = useState(false);

  // const colors = [
  //   { color: 'Red', value: 'ff0000' },
  //   { color: 'Green', value: '00ff00' },
  //   { color: 'Blue', value: '0000ff' }
  // ]

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // const userName = currentUser
    if (userName) {
      handleGetTutorialByUserName();
    }
    window.scrollTo(0, 0);
  }, [userName]);

  const handleGetTutorialByUserName = () => {
    getTutorialByUserName(userName).then((res) => {
      if (res.data) {
        //setTutorialsList(res.data)
      }
    });
  };

  const onSubmit = async (values) => {
    const tutorial = { ...values };
    if (isEdit) {
      const tags = [tutorial.cost, tutorial.type, tutorial.level];
      tutorial["tags"] = tags;
      tutorial["technology"] = tutorial.technology && tutorial.technology.value;
      editTutorial(tutorial).then((res) => {
        if (res && res.success) {
          reset("tutorial");
          initialize({});
          setIsEdit(!isEdit);
        }
      });
    } else {
      await linkPreview(tutorial.link).then((res) => {
        if (res && res.linkPrev) {
          const { image, title, description } = res.linkPrev;
          tutorial["img"] = image;
          tutorial["title"] = title;
          tutorial["description"] = description;
          tutorial["userName"] = userName;
          tutorial["technology"] =
            tutorial.technology && tutorial.technology.value;
          tutorial["id"] = Math.random().toString(36).substr(4, 9);
          const tags = [tutorial.cost, tutorial.type, tutorial.level];
          tutorial["tags"] = tags;
          addTutorial(tutorial).then((res) => {
            if (res && res.success) {
              handleGetTutorialByUserName();
              // setTags([])
              reset("tutorial");
            }
          });
        }
      });
    }
  };

  const handleEditTutorial = (id) => {
    window.scrollTo(0, 0);
    const editTutotial = tutorialByUser.find((item) => item.id === id);
    initialize({
      ...editTutotial,
      cost: editTutotial.tags[0],
      type: editTutotial.tags[1],
      level: editTutotial.tags[2],
      technology: {
        label: getTechLable(editTutotial.technology),
        value: editTutotial.technology,
      },
    });
    setIsEdit(!isEdit);
  };

  const handleCancelEdit = () => {
    initialize({});
    setIsEdit(!isEdit);
  };

  // const tutorialsPerPage = 6
  // const pagesVisited = pageNumber * tutorialsPerPage
  // console.log("tutorialsList", tutorialsList)
  // const displayTutorials = tutorialsList
  //   .slice(pagesVisited, pagesVisited + tutorialsPerPage)
  //   .map((item) => {
  //     return <TutorialListCard tutorial={item} id={item.id} title={item.title} likesCount={item.likes.length} />
  //   })

  // const pageCount = Math.ceil(tutorialsList.length / tutorialsPerPage)

  // const changePage = ({ selected }) => {
  //   setPageNumber(selected)
  // }

  return (
    <>
      <div className='row m-0 my-5 add-totorial'>
        <div className='col-12 col-md-4'>
          <div className='pt-md-5 mb-md-5 px-4 add-form'>
            <h1 className='text-center mt-md-2 mt-0 align-items-center'>
              {isEdit ? "Edit Tutorial" : "Add Tutorial"}
            </h1>

            <form className='' onSubmit={handleSubmit(onSubmit)}>
              {/* <div>
                <Field
                  name="title"
                  type="text"
                  component={RenderInputField}
                  label='Enter Tutorial Title'
                  placeholder=""
                  validate={[required]}
                />
              </div> */}
              <div>
                <Field
                  name='link'
                  type='text'
                  component={RenderInputField}
                  label='Enter Tutorial Link'
                  placeholder=''
                  validate={[required]}
                  isDisabled={isEdit ? true : false}
                />
              </div>
              <div className='mt-3'>
                <label className='form-label'>This tutorial is:</label>
                <div className='type-tag'>
                  <div className='px-1 type-checkbox'>
                    <Field
                      name='cost'
                      component={renderRadioField}
                      value='Free'
                      label='Free'
                      type='radio'
                      validate={[required]}
                    />
                  </div>
                  <div className='px-1 mx-1 type-checkbox'>
                    <Field
                      name='cost'
                      component={renderRadioField}
                      value='Paid'
                      label='Paid'
                      type='radio'
                      validate={[required]}
                    />
                  </div>
                </div>
              </div>
              <div className='mt-3'>
                <label className='form-label'>Tutorial Level</label>
                <div className='type-tag'>
                  <div className='px-1 type-checkbox'>
                    <Field
                      name='level'
                      component={renderRadioField}
                      value='Beginner'
                      label='Beginner'
                      type='radio'
                      validate={[required]}
                    />
                  </div>
                  <div className='px-1 mx-1 type-checkbox'>
                    <Field
                      name='level'
                      component={renderRadioField}
                      value='Intermediate'
                      label='Intermediate'
                      type='radio'
                      validate={[required]}
                    />
                  </div>
                  <div className='px-1 mx-1 type-checkbox'>
                    <Field
                      name='level'
                      component={renderRadioField}
                      value='Advance'
                      label='Advance'
                      type='radio'
                    />
                  </div>
                </div>
              </div>

              <div className='mt-3'>
                <label className='form-label'>Tutorial Type</label>
                <div className='type-tag'>
                  <div className='px-1 type-checkbox'>
                    <Field
                      name='type'
                      component={renderRadioField}
                      value='Video'
                      label='Video'
                      type='radio'
                      validate={[required]}
                    />
                  </div>
                  <div className='px-1 mx-1 type-checkbox'>
                    <Field
                      name='type'
                      component={renderRadioField}
                      value='Blog'
                      label='Blog'
                      type='radio'
                      validate={[required]}
                    />
                  </div>
                  <div className='px-1 mx-1 type-checkbox'>
                    <Field
                      name='type'
                      component={renderRadioField}
                      value='Book'
                      label='Book'
                      type='radio'
                      validate={[required]}
                    />
                  </div>
                </div>
              </div>

              <div>
                <Field
                  name='technology'
                  label='Enter Tutorial Technology'
                  options={technologyOptions}
                  placeholder='Search Technology'
                  component={renderSelectField}
                  validate={[required]}
                  textField='tech'
                  dataKey='value'
                />
              </div>
              <div className='row mt-4'>
                <div className='col-12 text-right'>
                  {addTutorialLoading ? (
                    <Loader
                      type='ThreeDots'
                      color='#30006d'
                      height={100}
                      width={100}
                      timeout={19000} //8 secs
                    />
                  ) : (
                    <>
                      {isEdit && (
                        <div
                          className='btn cancel-button mr-3'
                          onClick={() => handleCancelEdit()}
                        >
                          Cancel
                        </div>
                      )}
                      <button
                        type='submit'
                        className={`btn ${
                          isEdit ? "edit-button" : "add-button"
                        } login-button`}
                        disabled={submitting}
                      >
                        {isEdit ? (
                          <>
                            <i className='far fa-edit voilet mr-2' />{" "}
                            <span>Edit Tutorial</span>
                          </>
                        ) : (
                          <>
                            <i className='fad fa-plus-circle green mr-2' />{" "}
                            <span>Add Tutorial</span>
                          </>
                        )}
                      </button>
                    </>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className='col-12 bg-white col-md-8 mt-md-0 mt-5'>
          <h2 className='text-center'>List of your submitted tutorials</h2>
          {tutorialListLoading ? (
            <div className='d-flex text-center justify-content-center'>
              <Loader
                type='ThreeDots'
                color='#30006d'
                height={100}
                width={100}
                timeout={11000} //8 secs
              />
            </div>
          ) : (
            <div className='row m-0 d-flex justify-content-center tutorial-card-section'>
              {tutorialByUser.length ? (
                tutorialByUser.map((item, i) => (
                  <TutorialListCard
                    key={i}
                    tutorial={item}
                    id={item.id}
                    title={item.title}
                    tags={item.tags}
                    likesCount={item.likes.length}
                    handleEditTutorial={handleEditTutorial}
                    handleGetTutorialByUserName={handleGetTutorialByUserName}
                  />
                ))
              ) : (
                <img
                  height='350px'
                  width='350px'
                  src={
                    "http://res.cloudinary.com/datacode/image/upload/v1731009229/vzrrm6ekolzfh9vsuyj6.png"
                  }
                  alt='avatar'
                  className='mx-auto img-fluid img-circle'
                />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

AddTutorial.defaultProps = {
  userName: "",
  tutorialsList: [],
  addTutorialLoading: false,
  tutorialListLoading: false,
  currentUser: {},
};

AddTutorial.propTypes = {
  userName: PropTypes.string,
  tutorialsList: PropTypes.array,
  currentUser: PropTypes.object,
};

export default reduxForm({
  form: "tutorial", // a unique name for this form
})(AddTutorial);
