import React from "react";
import { Checkbox, Col, Row } from "antd";
// import { useDispatch } from "react-redux";

const WorkshopSortPopover = () => {
  // const dispatch = useDispatch()

  const onChange = (checkedValues) => {
    // dispatch(getClubApplications({ status: checkedValues }))
  };

  return (
    <div className='row mx-0'>
      <div className='col-12 p-0'>
        <div className='border-bottom mb-2'>
          <h6>By Date</h6>
          <Checkbox.Group
            style={{
              width: "100%",
            }}
            onChange={onChange}
          >
            <Row>
              <Col span={8}>
                <Checkbox value='pending'>By Date</Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox value='accept'>Accept</Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox value='onhold'>OnHold</Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox value='reject'>Reject</Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox value='waiting'>Waiting</Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox value='flag'>Flag</Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox value='stage-1'>Stage 1</Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox value='stage-2'>Stage 2</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </div>
      </div>
    </div>
  );
};

export default WorkshopSortPopover;
