import ForgotPassword from "./ForgotPassword";

import { connect } from "react-redux";

import { forgotPassword } from "../../actions";

const mapStateToProps = ({ user }) => ({
  userError: user.forgotpassword_error,
});

const mapDispatchToProps = {
  forgotPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
