// import { styled } from "@mui/material/styles";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
// import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { PickersDay } from "@mui/x-date-pickers/PickersDay";
// import dayjs from "dayjs";
// import React, { useEffect, useState } from "react";

// const HighlightedDay = styled(PickersDay)(({ theme }) => ({
//   "&.Mui-selected": {
//     backgroundColor: theme.palette.primary.main,
//     color: theme.palette.primary.contrastText,
//   },
//   "&.Mui-selected:hover": {
//     backgroundColor: "#000",
//   },
// }));

// const ServerDay = (props) => {
//   const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

//   const isSelected =
//     !props.outsideCurrentMonth &&
//     highlightedDays.includes(day.format("YYYY-MM-DD"));

//   return (
//     <HighlightedDay
//       {...other}
//       outsideCurrentMonth={outsideCurrentMonth}
//       day={day}
//       selected={isSelected}
//     />
//   );
// };

// export default function CustomCalendar({ startDate, endDate }) {
//   const getDatesBetween = (startDate, endDate) => {
//     const dates = [];
//     let currentDate = new Date(startDate);
//     let endDateFormate = new Date(endDate);
//     // Move to the next day
//     currentDate.setDate(currentDate.getDate() + 1);

//     while (currentDate < endDateFormate) {
//       dates.push(currentDate.toISOString().split("T")[0]);
//       currentDate.setDate(currentDate.getDate() + 1);
//     }
//     return dates;
//   };

//   useEffect(() => {
//     if (startDate && endDate) {
//       let datesArray = getDatesBetween(startDate, endDate);
//       console.log(datesArray);
//       setHighlitedDays(datesArray);
//     }
//   }, [startDate, endDate]);

//   // const [value, setValue] = React.useState("");
//   const [highlightedDays, setHighlitedDays] = useState(
//     getDatesBetween(startDate, endDate),
//   );

//   const handleSelectedDate = (date) => {
//     console.log("handleSelectedDate", date);
//   };

//   return (
//     <LocalizationProvider dateAdapter={AdapterDayjs}>
//       <DemoContainer
//         components={["DateCalendar", "DateCalendar", "DateCalendar"]}
//       >
//         <DemoItem label={""}>
//           <DateCalendar
//             onChange={(newValue) => handleSelectedDate(newValue)}
//             defaultValue={dayjs(new Date())}
//             views={["year", "month", "day"]}
//             slots={{
//               day: ServerDay,
//             }}
//             slotProps={{
//               day: {
//                 highlightedDays,
//               },
//             }}
//           />
//         </DemoItem>
//       </DemoContainer>
//     </LocalizationProvider>
//   );
// }

import React, { useEffect, useState } from "react";
import { Calendar } from "antd";
import dayjs from "dayjs";
// import "antd/dist/antd.css"; // Ensure you import Ant Design styles

const CustomCalendar = ({ startDate, endDate }) => {
  const getDatesBetween = (startDate, endDate) => {
    const dates = [];
    let currentDate = new Date(startDate);
    let endDateFormatted = new Date(endDate);
    // Move to the next day
    currentDate.setDate(currentDate.getDate() + 1);

    while (currentDate < endDateFormatted) {
      dates.push(currentDate.toISOString().split("T")[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  };

  const [highlightedDays, setHighlightedDays] = useState(
    getDatesBetween(startDate, endDate),
  );

  useEffect(() => {
    if (startDate && endDate) {
      const datesArray = getDatesBetween(startDate, endDate);
      console.log(datesArray);
      setHighlightedDays(datesArray);
    }
  }, [startDate, endDate]);

  const handleDateSelect = (date) => {
    console.log("Selected date:", date.format("YYYY-MM-DD"));
  };

  const dateCellRender = (value) => {
    const dateString = value.format("YYYY-MM-DD");
    // Highlight the cell if it's in the highlightedDays array
    if (highlightedDays.includes(dateString)) {
      return (
        <div
          style={{
            backgroundColor: "#1890ff",
            color: "#fff",
            padding: "5px",
            borderRadius: "4px",
          }}
        >
          Highlighted
        </div>
      );
    }
    return null;
  };

  return (
    <Calendar
      dateCellRender={dateCellRender}
      onSelect={handleDateSelect}
      defaultValue={dayjs(new Date())}
      style={{ border: "1px solid #ddd", borderRadius: "4px" }} // Optional styling
    />
  );
};

export default CustomCalendar;
