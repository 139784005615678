import React from "react";
import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../../../../assets/css/Carousel.css";

const EventCarousel = () => {
  return (
    <>
      <Carousel
        nextIcon=''
        nextLabel=''
        prevIcon=''
        prevLabel=''
        indicators={false}
        id='carousel'
        className='p-0 mt-0'
      >
        <Carousel.Item>
          <div className='row event-carousel carousel-card pt-md-5 pt-2 pb-3 pb-0 px-md-5 px-2 text-center'>
            <div className='col-12 text-md-left text-center col-md-6'>
              <div className='text-left px-3 px-md-0'>
                <h1 className='mt-3 text-heading'>
                  Find and Join <span className='text-span'></span>
                </h1>
                <h2>
                  <span className='multiple-text-typewriter'>
                    <span>
                      <span>Events</span>
                      <span>Webinars</span>
                      <span>Trainings</span>
                      <span>Workshop</span>
                    </span>
                  </span>
                </h2>
              </div>
              <div className='px-0 qoute text-left'>
                <h6>
                  <q>
                    Speed doesn’t matter when progress start working, let’s
                    emphasize on learning and reframe the world with magical
                    power
                  </q>
                </h6>
                <i>- Every Programmer's Words</i>
              </div>

              <Link to='/index_technologies'>
                <button className='btn get-started-button my-5 my-md-5 mr-3'>
                  <i className='fa fa-search search-icon' /> Join us Now
                </button>
              </Link>
            </div>
            <div className='col-12 px-0 col-md-6 text-center'>
              <img
                height='400px'
                width='400px'
                src={require(`../../../../../assets/images/svg/event-banner.png`)}
                alt='avatar'
                loading='lazy'
                className='img-fluid mx-auto p-md-0 p-3'
              />
            </div>
          </div>
        </Carousel.Item>
        {/* <Carousel.Item>
            <div className="card text-center border border-white">
              <img
                className=" card-img d-block w-100"
                src={require("../../assets/images/datacodeheader1.jpeg")}
                alt="First slide"
              />
            </div>
          </Carousel.Item> */}
        {/* <Carousel.Item>
            <div className="card text-center border border-white">
              <img
                className=" card-img d-block w-100"
                src={require("../../assets/images/datacodeheader2.jpeg")}
                alt="First slide"
              />

              <div className="card-img-overlay d-none d-md-block text-right text-align-center ml-5 mr-1 pl-5 mr-1 mt-5 pr-1">
                <h2 className="h1 display-5 m-5 pl-5 pr-0  text-weight-bold">
                  Datacode helps people to provide Online Learning Tutorials and
                  Courses from all over the Internet.
                </h2>
                <center>
                  <Link to="/home">
                    <button className="btn btn-lg btn-default border border-primary rounded-0 pl-5 pr-5 pt-3 pb-3">
                      <h5 className="text-primary">Getting Started</h5>
                    </button>
                  </Link>
                </center>
              </div>
              <div className="card-img-overlay d-block d-sm-none m-0 p-0 text-right">
                <h2 className="text-weight-bold p-0 ">Free Learning</h2>
                <p>Find | Learn | Code</p>
                <Link to="/home">
                  <div className="btn btn-warning">Getting Start</div>
                </Link>
              </div>
            </div>
          </Carousel.Item> */}
      </Carousel>
    </>
  );
};

export default EventCarousel;
