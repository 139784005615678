import React, { useEffect, useState } from "react";
import { Field, reduxForm } from "redux-form";

import { useHistory } from "react-router-dom";
import {
  RenderInputField,
  renderCheckboxField,
  renderSelectField,
} from "../../../../../../shared_components/ReduxFormFields";
import { required } from "../../../../../../utils/validators";
import { technologyOptions } from "../../../../tutorials/components/AddTutorial/helper";

const ShareEventForm = ({
  reset,
  handleSubmit,
  submitting,
  initialize,
  eventRegistationLoading,
  currentUser: { userName },
  createEvent,
  eventDetails,
}) => {
  // const [isBroadCastLink, setIsBroadCastLink] = useState(false);
  // const [useRegForm, setUseRegForm] = useState(false);
  const [isOnline, setIsOnline] = useState(true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (eventDetails) {
      initialize(eventDetails);
    }
  }, [eventDetails]);

  const history = useHistory();

  const onSubmit = (values) => {
    const event = { ...values };
    event["userName"] = userName && userName;
    event["id"] = Math.random().toString(36).substr(4, 9);
    createEvent(event).then((res) => {
      if (res && res.success) {
        history.push(`/events`);
        reset("share-event");
      }
    });
  };

  return (
    <>
      <div className='row justify-content-center m-0 mt-5'>
        <div className='col-12 col-md-6 text-center bg-white' id='form'>
          <div className='pt-md-3 px-md-5 px-2 login-card'>
            <h1 className='text-center register-text mb-5 mb-md-3'>
              Share Community Event / Webinar
            </h1>
            <form className='' onSubmit={handleSubmit(onSubmit)}>
              <Field
                type='text'
                name='title'
                label='Event / Webinar Name'
                placeholder=''
                component={RenderInputField}
                validate={[required]}
              />
              <Field
                name='technology'
                type='select'
                label='Enter Tutorial Technology'
                options={technologyOptions}
                placeholder='Choose Technology'
                component={renderSelectField}
                validate={[required]}
              />
              <Field
                name='type'
                type='select'
                label='Event Type'
                options={technologyOptions}
                placeholder='Choose Technology'
                component={renderSelectField}
                validate={[required]}
              />
              <div className='row'>
                <div className='col-6'>
                  <Field
                    type='date'
                    name='start_date'
                    label='Event Start Date'
                    placeholder=''
                    component={RenderInputField}
                    validate={[required]}
                  />
                </div>
                <div className='col-6'>
                  <Field
                    type='date'
                    name='end_date'
                    label='Event End Date'
                    placeholder='xyz@yui.com'
                    component={RenderInputField}
                    // validate={[required]}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-6'>
                  <Field
                    type='time'
                    name='start_time'
                    label='Event Start Time'
                    placeholder=''
                    component={RenderInputField}
                    validate={[required]}
                  />
                </div>
                <div className='col-6'>
                  <Field
                    type='time'
                    name='end_time'
                    label='Event End Time'
                    placeholder=''
                    component={RenderInputField}
                    // validate={[required]}
                  />
                </div>
              </div>
              <div className='row m-0 mt-4'>
                <div className='col-12 px-0 text-left'>
                  <h6>Location</h6>
                  <input
                    type='checkbox'
                    value={isOnline}
                    onChange={() => setIsOnline(!isOnline)}
                    checked={isOnline}
                  />
                  <label className='ml-1 form-label'>
                    {" "}
                    This is an online virtual event
                  </label>
                  {!isOnline && (
                    <>
                      <Field
                        type='text'
                        name='address'
                        label='Address'
                        placeholder=''
                        component={RenderInputField}
                        validate={[required]}
                      />
                      <Field
                        type='text'
                        name='venue'
                        label='Venue'
                        placeholder=''
                        component={RenderInputField}
                        validate={[required]}
                      />
                    </>
                  )}
                </div>
              </div>
              <Field
                type='text'
                name='registrationLink'
                label={`Registration / Broadcast Link`}
                placeholder=''
                component={RenderInputField}
                validate={[required]}
              />
              <Field
                name='is_broadcast_link'
                component={renderCheckboxField}
                label='This is Broadcast Link'
              />
              <div className='row mt-4'>
                <div className='col-12 text-center'>
                  <button
                    type='submit'
                    className='btn custom-button'
                    disabled={submitting}
                  >
                    <span>Create Event</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default reduxForm({
  form: "share-event",
})(ShareEventForm);
