import moment from "moment"

export const required = value => (value || typeof value === 'number' ? undefined : 'Required')

export const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined

export const minLength = min => value =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined

export const number = value =>
  value && isNaN(Number(value)) ? 'Must be a number' : undefined

export const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined

export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined

export const aol = value =>
  value && /.+@aol\.com/.test(value)
    ? 'Really? You still use AOL for your email?'
    : undefined

export const alphaNumeric = value =>
  value && /[^a-zA-Z0-9 ]/i.test(value)
    ? 'Only alphanumeric characters'
    : undefined

export const phoneNumber = value =>
  value && !/^([6-9]\d{9})$/i.test(value)
    ? 'Invalid phone number, must be 10 digits'
    : undefined

export const dateTimeDiff = value => {
  let start_date_time = moment(value.start_date + ' ' + value.start_time).format()
  let end_date_time = moment(value.end_date + ' ' + value.end_time).format()
  const errors = {}
  if (start_date_time > end_date_time) {
    errors.end_date = 'End Date/Time must be greate than start Date/Time'
  }
  return errors
}

export const matchPassword = values => {
  const errors = {}

  if (values.confirmPassword !== values.password) {
    errors.confirmPassword = 'Password not matched'
  }

  return errors
}

export const validatePassword = value =>
  value && value.length < 8
    ? 'Password atleast 8 characters maximun'
    : value && !/\d/.test(value)
      ? 'Password must contain at least one numeric character'
      : value && !/[!@#$%^&*(),.?":{}|<>]/.test(value)
        ? 'Password must contain at least one special character'
        : undefined;
