import React from 'react'
import AddToCalendar from 'react-add-to-calendar';

const CustomAddToCalendar = () => {

  let event = {
    title: 'Sample Datacode Event',
    description: 'This is the sample event provided as an example only',
    location: 'Portland, OR',
    startTime: '2016-09-16T20:15:00-04:00',
    endTime: '2016-09-16T21:45:00-04:00'
  };
  /*
  startTime and endTime can use any datetime
  string that is acceptable by MomentJS
  */

  let items = [
    { google: 'Google' },
    { yahoo: 'Yahoo' },
    { outlook: 'Outlook' },
    { outlookcom: 'Outlook.com' },
    { apple: 'Apple Calendar' },
  ];

  let icon = { 'fal fa-calendar-plus': 'left' };
  /*
  object property can be any Font Awesome icon
  and value can be 'left' or 'right'
  */
  return (
    <div className="row mx-0 d-flex justify-content-center">
      <div className="col-md-2 col-lg-3 col-12">
        <AddToCalendar event={event} listItems={items} buttonTemplate={icon} />
      </div>
    </div>
  )

}

export default CustomAddToCalendar