import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import CustomLoader from "../../../../../../shared_components/CustomLoader";
import EventPageBody from "./EventBody/EventPageBody";
import EventHeader from "./EventHeader";

const EventPage = ({
  getEventDetails,
  match: {
    params: { id },
  },
  eventDetailsLoading,
  userData,
  userData: { userName, email },
  checkUserEventReg,
  addEventAttendees,
  eventDetails,
}) => {
  const history = useHistory();
  // const [eventDetails.state, seteventDetails.state] = useState('')
  const [showActionSection, setShowActionSection] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    getEventDetails(id);
  }, []);

  //useEffect(() => {
  //   setInterval(() => {
  //     if (eventDetails) {
  //       if (new Date(`${moment(eventDetails.start_date).format('LL')}, ${eventDetails.start_time}`) > new Date()) {
  //         seteventDetails.state('upcoming')
  //       } else {
  //         seteventDetails.state('past')
  //       }
  //       if (new Date(`${moment(eventDetails.start_date).format('LL')}, ${eventDetails.start_time}`) <= new Date()
  //         && new Date(`${moment(eventDetails.end_date).format('LL')}, ${eventDetails.end_time}`) >= new Date()
  //       ) {
  //         seteventDetails.state('live')
  //       }
  //     }
  //   }, 5000)
  // }, [eventDetails])

  // Private Event

  const handleEventType = (action) => {
    if (action.loginRequire) {
      return history.push(`/login`);
    } else if (
      eventDetails.state === "past" ||
      eventDetails.state === "live" ||
      eventDetails.state === "upcoming"
    ) {
      // setShowActionSection(true);
      // to={`/event/:${id}/register`}
      history.push(`/event/${id}/${action.type}`);
    }
  };

  const handleAttendance = () => {
    const data = {
      userName,
      email,
      firstName,
      phone,
      graduationYear,
      university,
    };
    addEventAttendees(data).then((res) => {
      if (res && res.success) {
        return window.location.replace(
          registrationLink || "https://meet.google.com/jdm-duwq-ory",
        );
      }
    });
  };

  const { firstName, phone, graduationYear, university } = userData;
  const { text_color, date_color, banner_img, event_type, registrationLink } =
    eventDetails || {};

  return eventDetailsLoading ? (
    <CustomLoader />
  ) : (
    <>
      <EventHeader
        eventDetails={eventDetails}
        banner_img={banner_img}
        text_color={text_color}
        date_color={date_color}
        state={eventDetails.state}
        handleEventType={handleEventType}
        userData={userData}
      />
      <EventPageBody
        eventDetails={eventDetails}
        showActionSection={showActionSection}
        handleAttendance={handleAttendance}
        id={id}
        userData={userData}
        handleEventType={handleEventType}
      />
    </>
  );
};

export default EventPage;
