import React, { Component } from "react";

class MockDriveInvitationMail extends Component {
  state = {};
  render() {
    return (
      <>
        <div className='row'>
          <div className='col-md-2'></div>
          <div className='col-md-8'>
            <table
              width='100%'
              cellpadding='0'
              cellspacing='0'
              border='0'
              align='center'
            >
              <tr>
                <td align='center'>
                  <table
                    align='center'
                    border='0'
                    cellpadding='0'
                    cellspacing='0'
                    width='600'
                    style={{ backgroundColor: "#101820FF" }}
                  >
                    <tr>
                      <td>
                        <div className='row'>
                          <div clsassName='card text-white border border-white'>
                            <a href='https://www.datacode.in/'>
                              <img
                                className=' card-img-top mx-auto img-fluid img-circle d-block'
                                // src="https://ci4.googleusercontent.com/proxy/1sVq3CdW95K_MDHVT_48HLNozKhCNrbd5pg96PhFh_opwXJ_E0f7j2pEwGUhxEe6rsnI51Bgo9qld8vRxVA54QSPGPhHuezeYStlTlGvl0xfr0O5Qd2opx3axX6uNyrg8vdMuQ=s0-d-e1-ft#https://media.geeksforgeeks.org/wp-content/cdn-uploads/20200925143555/GW_MH_2.png"
                                src='https://i.postimg.cc/6q5yx57P/Email-Template-posters-7.png'
                                alt='avatar'
                              />
                            </a>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div
                          className='row'
                          style={{
                            textAlign: "center",
                            backgroundColor: "#101820FF",
                          }}
                        >
                          <div className='col'>
                            <div
                              className='row text-light mt-4 '
                              style={{
                                textAlign: "center",
                                backgroundColor: "#101820FF",
                                fontSize: 35,
                              }}
                            >
                              <p
                                className='mx-auto'
                                style={{ fontWeight: "bold" }}
                              >{`“If a window of opportunity appears, don't pull down the shade”
                              `}</p>
                            </div>

                            <div
                              className='row text-white mt-4 '
                              style={{
                                textAlign: "center",
                                backgroundColor: "#101820FF",
                              }}
                            >
                              <p
                                className='mx-auto'
                                style={{ fontWeight: "bold" }}
                              >{`Hey There!!`}</p>
                            </div>

                            <div
                              className='row text-white'
                              style={{
                                textAlign: "center",
                                backgroundColor: "#101820FF",
                              }}
                            >
                              <p className='mx-auto'>{`As the placement season is near and you might be worried about the preparation needed for the placement in this pandemic.
                              `}</p>
                            </div>
                            <div
                              className='row text-white'
                              style={{
                                textAlign: "center",
                                backgroundColor: "#101820FF",
                              }}
                            >
                              <p className='pl-5 pr-5 pt-2'>{`Keeping this in mind,
To make you ready to face the placement processes Datacode brings to you a great opportunity in the form of 

`}</p>
                            </div>

                            <div
                              className='row text-white mt-4 '
                              style={{
                                textAlign: "center",
                                backgroundColor: "#101820FF",
                                fontSize: 30,
                              }}
                            >
                              <p
                                className='mx-auto'
                                style={{ fontWeight: "bold" }}
                              >{`"Mock Placement Drive"
                              `}</p>
                            </div>

                            <div
                              className='row text-white'
                              style={{
                                textAlign: "center",
                                backgroundColor: "#101820FF",
                              }}
                            >
                              <p className='pl-5 pr-5 pt-2 mx-auto'>{`So why to wait? 


`}</p>
                            </div>

                            <div
                              className='row text-white'
                              style={{
                                textAlign: "center",
                                backgroundColor: "#101820FF",
                                marginTop: "-15px",
                              }}
                            >
                              <p className='pl-5 pr-5 mx-auto'>{`Hurry Up!

`}</p>
                            </div>
                            <div
                              className='row text-white'
                              style={{
                                textAlign: "center",
                                backgroundColor: "#101820FF",
                                marginTop: "-25px",
                              }}
                            >
                              <p className='pl-5 pr-5 pt-3 mx-auto'>{`And For more information and Registration


`}</p>
                            </div>

                            <div
                              className='row text-white'
                              style={{
                                textAlign: "center",
                                backgroundColor: "#101820FF",
                                marginTop: "-30px",
                              }}
                            >
                              <p className='pl-5 pr-5 pt-3 mx-auto'>{`Click on the link below 👇



`}</p>
                            </div>
                            <div
                              className='row text-white'
                              style={{
                                marginTop: "-15px",
                                textAlign: "center",
                                backgroundColor: "#101820",
                              }}
                            >
                              <div className='col text-center'>
                                <a href='http://bit.ly/datacode_mock_drive'>
                                  For More Info!
                                </a>
                              </div>
                            </div>
                            <div
                              className='row text-white'
                              style={{
                                textAlign: "center",
                                backgroundColor: "#101820FF",
                              }}
                            >
                              <p className='pl-5 pr-5 pt-2 mx-auto'>{`And for more Placement Preparation content and material, stayed tuned with us and



`}</p>
                            </div>
                            <div
                              className='row text-white'
                              style={{
                                textAlign: "center",
                                backgroundColor: "#101820FF",
                                fontWeight: "bold",
                                marginTop: "-5px",
                              }}
                            >
                              <p className='pl-5 pr-5'>{`You can join our Placement Preparation Group by clicking on the link below`}</p>
                            </div>
                            <div
                              className='row text-white'
                              style={{
                                textAlign: "center",
                                backgroundColor: "#101820",
                              }}
                            >
                              <div className='col text-center'>
                                <a href='https://chat.whatsapp.com/FHSGFCf3vyJ85SxNJlYAfH'>
                                  <p className='pl-5 pr-5'>Join Now!</p>
                                </a>
                              </div>
                            </div>
                            <div
                              className='row text-white'
                              style={{
                                textAlign: "center",
                                backgroundColor: "#101820FF",
                              }}
                            >
                              <p className='pl-5 pr-5 pt-2 mx-auto'>{`Thanks and Regards,`}</p>
                            </div>
                            <div
                              className='row text-white'
                              style={{
                                textAlign: "center",
                                backgroundColor: "#101820FF",
                                marginTop: "-10px",
                              }}
                            >
                              <p className='pl-5 pr-5 mx-auto'>{`Team Datacode.in`}</p>
                            </div>
                          </div>
                        </div>

                        <div
                          className='row pt-4'
                          style={{
                            backgroundColor: "#101820",
                          }}
                        >
                          <div className='col-3 text-center'>
                            <div
                              className='row '
                              style={{
                                paddingLeft: 30,
                                backgroundColor: "#101820",
                              }}
                            >
                              <a href='https://www.facebook.com/Datacode.in'>
                                <img
                                  style={{ width: 100, height: 100 }}
                                  src='https://i.postimg.cc/R0tCNTzm/Asset-11-288x.png'
                                  alt='avatar'
                                />
                              </a>
                            </div>
                            <div className='row pl-4 pr-4 mt-1'>
                              <div className='col-4 text-center'>
                                <a href='https://www.facebook.com/Datacode.in'>
                                  <img
                                    className=''
                                    src='https://68ef2f69c7787d4078ac-7864ae55ba174c40683f10ab811d9167.ssl.cf1.rackcdn.com/facebook-icon_16x16.png'
                                    alt='avatar'
                                  />
                                </a>
                              </div>
                              <div className='col-4 text-center'>
                                <a href='https://www.datacode.in/'>
                                  <img
                                    className=''
                                    src='https://68ef2f69c7787d4078ac-7864ae55ba174c40683f10ab811d9167.ssl.cf1.rackcdn.com/linkedin-icon_16x16.png'
                                    alt='avatar'
                                  />
                                </a>
                              </div>
                              <div className='col-4 text-center'>
                                <a href='https://www.instagram.com/datacode.in/'>
                                  <img
                                    className=''
                                    src='https://68ef2f69c7787d4078ac-7864ae55ba174c40683f10ab811d9167.ssl.cf1.rackcdn.com/instagram-icon_16x16.png'
                                    alt='avatar'
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                          <div
                            className='col-8 p-2 ml-3'
                            style={{ backgroundColor: "#101820" }}
                          >
                            <div className='row'>
                              <div className='col text-center'>
                                <h3
                                  className='text-center'
                                  style={{
                                    color: "#37c9ef",
                                    fontFamily: "Aileron Heavy",
                                  }}
                                >
                                  Learn With Datacode
                                </h3>
                                <a href='https://www.datacode.in/'>
                                  <p
                                    style={{ fontSize: 12 }}
                                    className='text-white pt-0 mt-0 pl-2 pr-2'
                                  >
                                    An online learning portal for computer
                                    science beginners, start your learning from
                                    best resources over the internet
                                  </p>
                                </a>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col text-center'>
                                <a href='https://www.datacode.in/'>
                                  <div className='btn btn-success btn-sm'>
                                    Start Learning
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </div>
          <div className='col-md-2'></div>
        </div>
      </>
    );
  }
}

export default MockDriveInvitationMail;
