import React from 'react'

const FlowTimeLine = () => {
  return (
    <>
      {/* <div className="container mt-3">
        <h1 className="project-name">Start your larning with community</h1>
        <div id="timeline">
          <div className="timeline-item">
            <div className="timeline-icon">


            </div>
            <div className="timeline-content">
              <h2>Find and Leanr form best courses</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Atque, facilis quo maiores magnam modi ab libero praesentium blanditiis.
					</p>
              <a href="#" className="btn">button</a>
            </div>
            <div className="timeline-img right">
              <h2>Find and Leanr form best courses</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Atque, facilis quo maiores magnam modi ab libero praesentium blanditiis.
					</p>
              <a href="#" className="btn">button</a>
            </div>
          </div>

          <div className="timeline-item">
            <div className="timeline-icon">


            </div>
            <div className="timeline-content right">
              <h2>Practice programming with example problems</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque, facilis quo. Maiores magnam modi ab libero praesentium blanditiis consequatur aspernatur accusantium maxime molestiae sunt ipsa.
					</p>
              <a href="#" className="btn">button</a>
            </div>
          </div>

          <div className="timeline-item">
            <div className="timeline-icon">

            </div>
            <div className="timeline-content">
              <h2>Test your programming knowledge</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque, facilis quo. Maiores magnam modi ab libero praesentium blanditiis consequatur aspernatur accusantium maxime molestiae sunt ipsa.
					</p>
              <a href="#" className="btn">button</a>
            </div>
          </div>

          <div className="timeline-item">
            <div className="timeline-icon">

            </div>
            <div className="timeline-content right">
              <h2>Contribute in learning community</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque, facilis quo. Maiores magnam modi ab libero praesentium blanditiis consequatur aspernatur accusantium maxime molestiae sunt ipsa.
					</p>
              <a href="#" className="btn">button</a>
            </div>
          </div>

          <div className="timeline-item">
            <div className="timeline-icon">

            </div>
            <div className="timeline-content">
              <h2>Grow with community opportunities</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque, facilis quo. Maiores magnam modi ab libero praesentium blanditiis consequatur aspernatur accusantium maxime molestiae sunt ipsa.
					</p>
              <a href="#" className="btn">button</a>
            </div>
          </div>
        </div >
      </div > */}
      <h1 className="flow-title text-center mb-0 mt-5">How will you grow with learning community?</h1>
      <div className="timeline-body">
        <div className="timeline">
          <div className="entry">
            <div className="title">
              {/* <div className="img rounded-circle bg-dark-blue p-md-5 p-2"> */}
              <img
                height="80px"
                width="80px"
                src={require(`../../../assets/images/svg/find_timeline.svg`)}
                alt="avatar"
                loading="lazy"
                className="text-center d-block mx-auto img-fluid"
              />
              {/* </div> */}
            </div>
            <div className="body check-mark-list ">
              <h3>First "Find" the Best Learning Resources</h3>
              <ul>
                <li>Explore and get what you want</li>
                <li>Let’s step into the next mile,without wondering of the crowd,so start exploring !</li>
              </ul>
            </div>
          </div>
          <div className="entry">
            <div className="title">
              {/* <div className="img rounded-circle bg-dark-blue p-md-5 p-2"> */}

              <img
                height="80px"
                width="80px"
                src={require(`../../../assets/images/svg/learn_timeline.svg`)}
                alt="avatar"
                loading="lazy"
                className="text-center d-block mx-auto img-fluid"
              />
              {/* </div> */}
            </div>
            <div className="body check-mark-list">
              <h3>Then "Learn", what you have found</h3>
              <ul>
                <li>Learn from top-rated resources</li>
                <li>Upskill yourself</li>
                <li>Time to learn from top-rated resources, and upskill yourself for achieving goals.</li>
              </ul>
            </div>
          </div>
          <div className="entry">
            <div className="title">
              {/* <div className="img rounded-circle bg-dark-blue p-md-5 p-2"> */}

              <img
                height="80px"
                width="80px"
                src={require(`../../../assets/images/svg/test_timeline.svg`)}
                alt="avatar"
                loading="lazy"
                className="text-center d-block mx-auto img-fluid"
              />
              {/* </div> */}
            </div>
            <div className="body check-mark-list">
              <h3>Then "Practice" & "Test" what you have learned</h3>
              <ul>
                <li>Practice ,practice ,practice,so never give up because great things take time ,for moulding iron to diamond!</li>
                <li>Practice from various example questions, practice questions, etc</li>
                <li>And test your practice from Quiz tests, MCQs, and coding challenges</li>
              </ul>
            </div>
          </div>
          <div className="entry">
            <div className="title">
              {/* <div className="img rounded-circle bg-dark-blue p-md-5 p-2"> */}

              <img
                height="80px"
                width="80px"
                src={require(`../../../assets/images/svg/contribute_timeline.svg`)}
                alt="avatar"
                loading="lazy"
                className="text-center d-block img-fluid  mx-auto"
              />
              {/* </div> */}
            </div>
            <div className="body check-mark-list">
              <h3>And finally "Contribute" your learnings to the community</h3>
              <ul>
                <li>Let’s share and solve our errors together, and spread the colours of knowledge for the betterment of community.</li>
                <li>Add your own Tutorials and courses, blogs, practice questions, and more</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FlowTimeLine