import React, { Component } from "react";
class CQuizeTest1 extends Component {
  state = {};
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <>
        <h1>C Quize Test 1</h1>
      </>
    );
  }
}

export default CQuizeTest1;
