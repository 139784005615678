import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import Parser from "html-react-parser";

import { getProblemDetails } from "../../../actions";
import { CompaniesLogo } from "../../SharedComponents";
import LayoutContainer from "../LayoutContainer";

const ProblemPreview = ({
  match,
  match: {
    params: { id },
  },
}) => {
  console.log("match", match);
  const dispatch = useDispatch();
  const problemDetails = useSelector(
    (state) => state?.dayscode?.problemDetails,
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (id) {
      dispatch(getProblemDetails(id));
    }
  }, []);

  const getPublicTestCase = (testCase) => {
    if (testCase.length > 1) {
      return testCase.slice(0, testCase.length - 1);
    } else {
      return testCase;
    }
  };

  const {
    constrains,
    testCase,
    title,
    day,
    description,
    output,
    input,
    explanation,
    companies,
  } = problemDetails || {};

  return (
    <>
      <LayoutContainer>
        <div className='row mx-0 card-schadow p-3'>
          <div className='col-12 d-flex align-items-center'>
            <div className='d-flex align-items-center'>
              <p className='m-0 mr-2'>Problem Day: </p>
              <h2 className='m-0'>{day}</h2>
            </div>
            <small className='mx-3 p-2 badge badge-pill bg-green border'>
              Preview
            </small>
          </div>
        </div>
        <div className='row mx-0'>
          <div className='col-12 px-0'>
            <div className='p-3 p-md-5 problem-section'>
              {companies && (
                <div className='my-2'>
                  <CompaniesLogo companies={companies} />
                </div>
              )}
              <div className='d-flex align-items-center justify-content-between'>
                <h1 className='days-heading'>Problem Statement:</h1>
                <a
                  href='https://www.onlinegdb.com/online_c++_compiler'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <div className='btn button-filled text-center'>
                    <small>Try Code</small>
                  </div>
                </a>
              </div>
              <p className='problem-statment'>{title}</p>
              {Parser(String(description))}
              <h1 className='days-heading mt-5'>Input:</h1>
              {Parser(String(input))}
              <h1 className='days-heading mt-5'>Output:</h1>
              {Parser(String(output))}
              {constrains && constrains.length > 10 && (
                <>
                  <h1 className='days-heading mt-5'>Constrains:</h1>
                  {Parser(String(constrains))}
                </>
              )}
              <div className='row m-0 mt-5 border rounded-lg'>
                {testCase &&
                  getPublicTestCase(testCase).map((test, i) => (
                    <div className='col-md-4 col-12' key={i}>
                      <h1 className='days-heading mt-2'>
                        Sample Test Case {i + 1}
                      </h1>
                      <p className='text-dark mb-1'>Input:</p>
                      <div className='pl-2'>
                        <pre>{test.input}</pre>
                      </div>
                      <p className='text-dark mb-1'>Output:</p>
                      <div className='pl-2'>
                        <pre>{test.output}</pre>
                      </div>
                    </div>
                  ))}
              </div>
              {explanation && explanation.length > 10 && (
                <>
                  <h1 className='days-heading mt-5'>Explanation:</h1>
                  {Parser(String(explanation))}
                </>
              )}
            </div>
          </div>
        </div>
      </LayoutContainer>
    </>
  );
};

export default withRouter(ProblemPreview);
