import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getFormValues } from "redux-form";

import {
  createQuizChallenge,
  editQuizChallenge,
  getQuizChallengeDetails,
} from "../../../../actions";

import AddQuizChallenge from "./AddQuizChallenge";

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser ? state.user.currentUser : {},
  problemDetailsLoading: state.dayscode.problemDetailsLoading,
  quizChallengeDetails: state.dayscode.quizChallengeDetails,
  formStates: getFormValues("create-quiz-challenge")(state),
});

const mapDispatchToProps = {
  createQuizChallenge,
  editQuizChallenge,
  getQuizChallengeDetails,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddQuizChallenge)
);
