import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment";

import { timeConvert, handleTextVisibility } from "../../../../../utils";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

const EventCard = ({ data, deleteEvent }) => {
  return (
    <>
      <div className="col-lg-3 col-md-4 col-sm-6 col-12 p-3">
        <div id="event-card" className="text-decoration-none event-card">
          <div className="mb-3">
            <img
              height="auto"
              width="auto"
              src={`https://res.cloudinary.com/datacode/image/upload/v1650287597/h2y3w1ftk24ticvyawtu.png`}
              alt="avatar"
              loading="lazy"
              className="img-fluid mx-auto"
            />
            {data.hasRegistred && (
              <span className="reg-status-block">
                <i className="fal fa-check-circle" /> Registered
              </span>
            )}
            {
              <>
                <UncontrolledDropdown
                  setActiveFromChild
                  className="event-host-action-toggle"
                >
                  <DropdownToggle tag="a" className="">
                    <i className="far fa-plus" />
                  </DropdownToggle>
                  <DropdownMenu
                    className="dropdown-menu mt-3 card-schadow"
                    left
                  >
                    <DropdownItem header>
                      <span>Host Actions</span>
                    </DropdownItem>
                    <DropdownItem>
                      <Link to={`/event/dashboard/${data._id}`}>
                        <span>
                          <i className="fal fa-trash mr-2" />
                          Edit Event
                        </span>
                      </Link>
                    </DropdownItem>
                    <DropdownItem onClick={() => deleteEvent(data._id)}>
                      <span>
                        <i className="fal fa-pencil mr-2" />
                        Delete Event
                      </span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </>
            }
            <span className="date-block">
              <h1>04</h1>
              <small>April</small>
            </span>
          </div>
          <h1 className="event-card-title px-3 pt-4">
            {handleTextVisibility(data.title, 70)}
          </h1>
          <div className="row m-0 event-card-footer">
            <div className="col-12 px-3">
              {/* <h6 className="event-card-sub-heading">{data.sub_title}</h6> */}
              <p>
                <i className="fa fa-laptop-code mr-2 icon" />
                Training
              </p>
              <p className="">
                <i className="far fa-calendar-alt mr-2 icon" />
                {moment(data.start_date).format("LL")} |{" "}
                {`${timeConvert(data.start_time)} - ${timeConvert(
                  data.end_time
                )}`}
              </p>
              <div className="row m-0 my-4">
                <div className="col-12 px-0">
                  <span className="event-tag px-2">{data.event_type}</span>
                  <span className="event-tag px-2">Begineers</span>
                  <span className="event-tag px-2">Online</span>
                </div>
              </div>
            </div>
          </div>
          <Link
            to={`/event/${data && data._id}`}
            target="_blank"
            className="fuck-line"
          >
            <div className="hover-sutter">
              <div className="btn btn-primary">Join Now!</div>
              <p>Join my Friend</p>
            </div>
          </Link>
        </div>
      </div>
      {/* <ToolTip message="Go To Event Page" id="event-card" /> */}
    </>
  );
};

EventCard.defaultProps = {
  data: {},
};

EventCard.propTypes = {
  data: PropTypes.object,
};

export default EventCard;
