export const removeDuplicatesByStartTime = (availableSlots) => {
  // Create an object to store unique entries based on startTime
  const uniqueEntries = {};

  // Iterate through each available slot
  availableSlots.forEach((slot) => {
    // Use startTime as the key to check for uniqueness
    const key = slot.slottime;

    // Check if the entry with the startTime already exists
    if (!uniqueEntries[key]) {
      // If not, add it to the uniqueEntries object
      uniqueEntries[key] = slot;
    } else {
      // If it already exists, compare endTime and update if necessary
      if (slot.slottime > uniqueEntries[key].slottime) {
        uniqueEntries[key] = slot;
      }
    }
  });

  // Convert the uniqueEntries object back to an array
  const uniqueSlots = Object.values(uniqueEntries);

  return uniqueSlots;
};